<template>
  <div>
    <!-- <el-alert title="页面暂未完成" type="error"> </el-alert> -->
    <div>
      <div class="header-title" style="border-bottom: 0;">
        <span class="clearfix-title">短信充值</span>
        <span class="clearfix-Subtitle">用于催好评提醒及相关短信通知功能</span>
      </div>

      <div class="tisBox">
        <li class="tisTitle">{{storeinfo.smsBalance}}<span
            style="font-size: 16px;margin-right: 10px;">条</span><span>剩余短信</span></li>
      </div>

      <div class="RechargeBox">
        <div class="RechargeItem" :class="{IsRechargeItem:categaryIndex==index}" v-for="(item,index) in messagedata"
          :key="index" @click="clickSelect(index,item.id,item.price)">
          <div>
            <li class="number">{{item.name}}</li>
            <li class="tis">{{item.description}}</li>
            <li class="price">售价<span>{{item.price}}元</span></li>
          </div>
        </div>
        <!--
        <div class="RechargeItem">
          <div>
            <li class="number">1000条</li>
            <li class="tis">充值送100条</li>
            <li class="price">售价<span>28元</span></li>
          </div>
        </div>
        <div class="RechargeItem">
          <div>
            <li class="number">1000条</li>
            <li class="tis">充值送100条</li>
            <li class="price">售价<span>28元</span></li>
          </div>
        </div>
        <div class="RechargeItem">
          <div>
            <li class="number">1000条</li>
            <li class="tis">充值送100条</li>
            <li class="price">售价<span>28元</span></li>
          </div>
        </div>
        <div class="RechargeItem">
          <div>
            <li class="number">1000条</li>
            <li class="tis">充值送100条</li>
            <li class="price">售价<span>28元</span></li>
          </div>
        </div>
        <div class="RechargeItem">
          <div>
            <li class="number">1000条</li>
            <li class="tis">充值送100条</li>
            <li class="price">售价<span>28元</span></li>
          </div>
        </div>
        <div class="RechargeItem">
          <div>
            <li class="number">1000条</li>
            <li class="tis">充值送100条</li>
            <li class="price">售价<span>28元</span></li>
          </div>
        </div>
        <div class="RechargeItem">
          <div>
            <li class="number">1000条</li>
            <li class="tis">充值送100条</li>
            <li class="price">售价<span>28元</span></li>
          </div>
        </div> -->

      </div>
      <div class="RechargeButton">
        <el-button type="primary" style="width: 200px;" @click="paysubmit()">立即充值</el-button>
      </div>
    </div>

    <div style="border-top: 10px solid rgb(247, 248, 250)">
      <div class="header-title" style="border-bottom:0">
        <span class="clearfix-title">短信记录</span>
        <span class="clearfix-Subtitle">用于催好评提醒及相关短信通知功能</span>
      </div>


      <div style="height: 60px;display: flex;align-items: center;justify-content: space-between;">
        <div style="display: flex;align-items: center;padding-left: 20px;">
          <el-radio-group v-model="dateOption" size="small" fill="#585A6E" text-color="#FFFFFF" @change="selectDateChange">
            <el-radio-button :label="0">今日</el-radio-button>
            <el-radio-button :label="1">昨日</el-radio-button>
            <el-radio-button :label="7">近7天</el-radio-button>
            <el-radio-button :label="30">近30天</el-radio-button>
            <el-radio-button :label="-1">自定义</el-radio-button>
          </el-radio-group>
          <el-date-picker style="margin-left: 20px;" v-if="dateOption===-1" size="small" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd" v-model="queryDate" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" ref="daterange" :picker-options="pickerOptions" @change="selectDateChange">
          </el-date-picker>

        </div>
        <div class="screen">
          <el-radio-group v-model="activeName" size="small" fill="#585A6E" text-color="#FFFFFF" @change="dateChange">
            <el-radio-button label="购买记录"></el-radio-button>
            <el-radio-button label="消耗记录"></el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="table" style="margin-top: 0;">
        <div v-if="record==='0'">

          <div style="display: flex;align-items: center;height: 70px;justify-content: space-between;">
            <li style="font-size: 12px;font-weight: 400;color: #222222;opacity: 1;">当前显示{{total}}条数据</li>



          </div>

          <el-table :data="tableData1" :stripe="true" style="width: 100%"
            :header-cell-style="{'padding-left':'20px','padding-right': '20px'}"
            :cell-style="{'padding-left':'20px','padding-right': '20px'}">
            <el-table-column type="index" label="编号" width="100px">
            </el-table-column>
            <el-table-column prop="quantity" label="购买数量" align="right">
            </el-table-column>
            <el-table-column label="金额" align="right">
              <template slot-scope="scope">
                ￥{{scope.row.money}}
              </template>
            </el-table-column>
            <el-table-column prop="buy_time" label="购买时间" align="left" width="200px">
            </el-table-column>
          </el-table>

          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
            :current-page="queryInfo.page" :page-size="queryInfo.limit" layout="prev, pager, next, total" :total="total"
            class="page"></el-pagination>
        </div>

        <div v-if="record==='1'">

          <div style="display: flex;align-items: center;height: 70px;justify-content: space-between;">
            <li style="font-size: 12px;font-weight: 400;color: #222222;opacity: 1;">当前显示{{total}}条数据</li>
            <el-date-picker size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="queryDate"
              type="daterange" :clearable="false" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
              ref="daterange" :picker-options="pickerOptions" @change="selectDateChange">
            </el-date-picker>
          </div>

          <el-table :data="tableData2" :stripe="true" style="width: 100%">
            <el-table-column type="index" label="编号" width="50px">
            </el-table-column>
            <el-table-column prop="mobile" label="发送人" align="left" width="120px">
            </el-table-column>
            <el-table-column prop="content" label="发送内容" align="left">
              <template slot-scope="scope">
                {{scope.row.content}}
              </template>
            </el-table-column>
            <el-table-column prop="ctime" label="发送时间" align="left" width="160px">
            </el-table-column>
          </el-table>

          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
            :current-page="queryInfo.page" :page-size="queryInfo.limit" layout="prev, pager, next, total" :total="total"
            class="page"></el-pagination>

        </div>
      </div>
    </div>

    <!-- 短信充值二维码对话框 -->
    <el-dialog title="扫码付款" :visible.sync="RechargeDialogVisible" width="400px">
      <div class="zfcontenr">
        <li class="money">应付金额：<span>{{price}}</span>元</li>
        <!-- <img class="code" :src="payimg" alt="">ss -->
        <img class="code" :src="'//api.qrserver.com/v1/create-qr-code/?size=150x150&margin=10&data='+payimg">
        <li class="zftis">使用微信扫码支付</li>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeName: '购买记录',
        categaryIndex: '',
        messagedata: [],
        tableData1: [],
        tableData2: [],
        // record为0时显示购买记录，为1时显示消耗记录
        record: '0',
        queryInfo: {
          page: 1,
          limit: 10,
        },
        total: 0,
        RechargeDialogVisible: false,
        Id: '',
        payimg: '',
        out_trade_no: '',
        price: '',
        storeinfo: [],
        queryDate: [
          this.$dateFormat('YYYY-mm-dd', new Date()),
          this.$dateFormat('YYYY-mm-dd', new Date())
        ],
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        datesvalue: '',
        startDate: '',
        endDate: '',
        dateOption: 30,
      }
    },
    methods: {
      selectDateChange(e) {
        
        // console.log(e)
        if (e == undefined) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
          // console.log(this.queryDate)
        }
        if (e !== -1) {
          this.loading = true
          if (typeof e === 'number') {
            const end = new Date()
            if (e < 2) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', start)]
            } else {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
            }
          }
          this.startDate = this.queryDate[0]
          this.endDate = this.queryDate[1]
          
          if(this.activeName == '购买记录'){
            this.getpaysmslog()
          }
          if(this.activeName == '消耗记录'){
            this.getsmslog()
          }
          
          // this.getsmslog()
          // this.getpaysmslog()
        } else {
          this.$refs.daterange.focus()
        }
      },
      clickSelect(index, id, price) {
        this.categaryIndex = index
        this.Id = id
        this.price = price
        // console.log(this.Id)
      },
      dateChange() {
        this.queryInfo.page = 1
        this.queryInfo.limit = 10
        if (this.activeName === '购买记录') {
          this.record = '0'
          this.getpaysmslog()
        }
        if (this.activeName === '消耗记录') {
          this.record = '1'
          this.getsmslog()
        }
      },
      handleSizeChange(newSize) {
        this.queryInfo.limit = newSize
        if (this.record == 0) {
          this.getpaysmslog()
        } else {
          this.getsmslog()
        }
        console.log(newSize)
      },
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage
        if (this.record == 0) {
          this.getpaysmslog()
        } else {
          this.getsmslog()
        }
        console.log(newPage)
      },
      paysubmit() {
        this.postpay()
        this.RechargeDialogVisible = true
      },
      async getMobilemsg() {
        const {
          data: res
        } = await this.$http.get('goods/index/lists')
        console.log(res)
        this.messagedata = res.data
        this.Id = res.data[0].id
        this.price = res.data[0].price
      },

      async postpay() {
        const {
          data: res
        } = await this.$http.post('/goods/pay/submit', {
          id: this.Id,
          s_id: window.sessionStorage.getItem('s_id'),
        })
        this.payimg = res.url
        this.out_trade_no = res.out_trade_no
        // console.log(this.payimg)
        this.monitorBindIntervalId = setInterval(callback => {
          this.queryOrder()
        }, 5000)
        // console.log(res)
      },
      async queryOrder() {
        const {
          data: res
        } = await this.$http.post('/goods/pay/queryOrder', {
          out_trade_no: this.out_trade_no,
          s_id: window.sessionStorage.getItem('s_id'),
        })
        if (res.code == 2) {
          clearInterval(this.monitorBindIntervalId)
          this.$message.success(res.msg)
          this.RechargeDialogVisible = false
          this.getqueryInfo()
          this.getpaysmslog()
          this.getsmslog()
        }
        // console.log(res)
      },
      async getqueryInfo() {
        const {
          data: res
        } = await this.$http.get('/user/shop/queryInfo', {
          params: {
            s_id: window.sessionStorage.getItem('s_id'),
          }
        })
        this.storeinfo = res.data
      },
      async getsmslog() {
        const {
          data: res
        } = await this.$http.post('/user/smslog/list', {
          s_id: window.sessionStorage.getItem('s_id'),
          type: 1,
          startDate: this.startDate,
          endDate: this.endDate,
          limit: this.queryInfo.limit,
          page: this.queryInfo.page,
        })
        this.tableData2 = res.data.list
        this.total = res.data.total
        console.log(res)
      },
      async getpaysmslog() {
        const {
          data: res
        } = await this.$http.post('/user/smslog/buy_log', {
          s_id: window.sessionStorage.getItem('s_id'),
          startDate: this.startDate,
          endDate: this.endDate,
          limit: this.queryInfo.limit,
          page: this.queryInfo.page,
        })
        this.tableData1 = res.data.list
        this.total = res.data.total
        console.log(res)
      }
    },
    created() {
      this.selectDateChange()
      this.getqueryInfo()
      this.getMobilemsg()

      console.log(this.queryDate)

      if (this.record == 0) {
        this.getpaysmslog()
      } else {
        this.getsmslog()
      }
    }
  }
</script>

<style scoped>
  .tisBox {
    height: 40px;
    line-height: 40px;
    background: #FFF8E1;
    opacity: 1;
    border-radius: 2px;
    margin: 0 20px;
  }

  .tisTitle {
    margin-left: 20px;
    font-size: 24px;
    font-weight: 500;
    color: #3F4156;
    opacity: 1;
  }

  .tisTitle span {
    font-size: 12px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .RechargeBox {
    margin-top: 70px;
    justify-content: center;
    text-align: center
  }

  .RechargeItem {
    width: 166px;
    height: 135px;
    opacity: 1;
    border-radius: 2px;
    border: 1px solid #EEEEEE;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .IsRechargeItem {
    border: 1px solid #FFC44D;
    /* background-image: url(../../assets/el-icon/iconfont.svg); */
    background-image: url(../../assets/img/xz.png);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: bottom right;
  }

  .number {
    font-size: 18px;
    font-weight: 500;
    padding: 0;
    color: #222222;
    opacity: 1;
  }

  .tis {
    font-size: 12px;
    font-weight: 400;
    color: #56576A;
    opacity: 1;
  }

  .price {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #FF6A00;
    opacity: 1;
  }

  .price span {
    font-size: 14px;
    font-weight: 500;
    color: #FF6A00;
    opacity: 1;
  }

  .RechargeButton {
    text-align: center;
    margin: 40px;
  }

  .zfcontenr {
    text-align: center;
  }

  .money {
    font-size: 14px;
    font-weight: 400;
    color: #4A4A4A;
    opacity: 1;
    margin-bottom: 10px;
  }

  .money span {
    font-size: 20px;
    font-weight: 400;
    color: #FF710D;
    opacity: 1;
  }

  .code {
    width: 128px;
    height: 128px;
    border: 1px solid #E9EAF2;
    opacity: 1;
    border-radius: 2px;
    margin-bottom: 10px;
  }

  .zftis {
    font-size: 14px;
    font-weight: 400;
    color: #4A4A4A;
    opacity: 1;
  }
</style>
