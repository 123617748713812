<template>
  <div class="login_container">
    <div class="login_box">
      <div class="avatar_box" >
        <img src="../assets/img/logo.png">
      </div>
      <el-form ref="loginFormRef" :model="loginForm" label-width="0px" :rules="rules" class="login_form">
        <!--        账号-->
        <el-form-item prop="username">
          <el-input v-model="loginForm.username"></el-input>
        </el-form-item>
        <!--        密码-->
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" type="password"></el-input>
        </el-form-item>

        <img :src="imgCode" @click="changeImgCode">
        <el-form-item prop="username">
          <el-input v-model="loginForm.captcha"></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loginForm: {
          username: '',
          password: '',
          captchaId: ''
        },
        imgCode: '',
        rules: {
          username: [{
              require: true,
              message: '请输入账号',
              trigger: 'blur'
            },
            {
              min: 4,
              max: 11,
              message: '长度在6到11个字符',
              trigger: 'blur'
            }
          ],
          password: [{
              require: true,
              message: '请输入密码',
              trigger: 'blur'
            },
            {
              min: 5,
              max: 20,
              message: '长度在6到11个字符',
              trigger: 'blur'
            }
          ]
        },
        i: 0
      }
    },
    methods: {

      resetLoginForm() {
        //
        this.$refs.loginFormRef.resetFields()
      },
      login() {
        this.$refs.loginFormRef.validate(async valid => {
          if (!valid) return
          const {
            data: res
          } = await this.$http.post(
            'user/login/dologin',
            this.loginForm
          )
          if (res.code !== 1) {
            this.$message({
              message: res.msg,
              type: 'error'
            })
            this.changeImgCode()
          } else {
            this.$message({
              message: '登录成功',
              type: 'success'
            })
            window.sessionStorage.setItem('token', res.data.access_token)
            this.$router.push('/index')
          }
        })
      },
      async changeImgCode() {
        const {
          data: res
        } = await this.$http.get('api/user/index/new_captcha.html?height=40&width=200&font_size=15&length=4')
        this.imgCode = res.data.imgData
        this.loginForm.captchaId = res.data.captchaId
        this.captcha = ''
      },
      async bd_binding() {
        const {
          data: res
        } = await this.$http.post('/program/index/app_auth?code=' + this.$route.query.code + '&type=' + this.$route
          .query.type)
        if (res.code != 1) {
          this.$alert(res.msg, '错误提示', {
            confirmButtonText: '确定'
          })
          return
        }
        window.sessionStorage.setItem('token', res.data.access_token)
        window.sessionStorage.setItem('s_id', res.data.s_id)
        window.sessionStorage.setItem('type', 1)
        this.$router.push('/index')
      },
      isMobile() {
          let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
          return flag;
         }
    },
    created() {
      if (this.$route.query.code) {
        this.bd_binding()
      } else {
        this.changeImgCode()
      }
    },
    mounted() {
      if (this.isMobile()) {
            // console.log(window.location.origin)
            window.location.href= window.location.origin+'/wap/#/pages/login'
            // this.$router.replace('/m_index');
          } else {
            // alert("pc端");
            // this.$router.replace('/pc_index');
          }
    }
  }
</script>

<style lang="less" scoped>
  .login_container {
    background-color: #2b4b6b;
    height: 100%;
  }

  .login_box {
    width: 500px;
    height: 400px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }

  .login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .btns {
    display: flex;
    justify-content: flex-end;
  }
</style>
