<template>

  <div class="container" v-loading="loading">
    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
      <span class="clearfix-title">活动使用率</span>
      <span class="clearfix-Subtitle">合理创建活动能有效提高客户复购率、客单价以及店铺营业额</span>
    </div>

    <div class="flex relative color3F4156 pad-l20 pad-r20" style="margin-top: 20px;">
      <el-radio-group v-model="dateOption" size="small" fill="#585A6E" text-color="#FFFFFF" @change="selectDateChange">
        <el-radio-button :label="0">今日</el-radio-button>
        <el-radio-button :label="1">昨日</el-radio-button>
        <el-radio-button :label="7">近7天</el-radio-button>
        <el-radio-button :label="30">近30天</el-radio-button>
        <el-radio-button :label="-1">自定义</el-radio-button>
      </el-radio-group>
      <el-date-picker v-if="dateOption===-1" class="mar-l10" style="height: 32px;width: 280px" type="daterange"
        align="right" unlink-panels v-model="queryDate" start-placeholder="开始日期" end-placeholder="结束日期"
        format="yyyy-MM-dd" value-format="yyyy-MM-dd" ref="daterange" :picker-options="pickerOptions"
        @change="selectDateChange">
      </el-date-picker>
    </div>


    <div class="page-body" v-loading="loading">
      <div class="page-body-item">
        <div class="page-body-item-title">活动汇总图</div>
        <div class="page-body-item-content">
          <div class="page-body-chart-box">
            <el-row style="display: flex;" v-if="nodata1!=false&&nodata2!=false">

              <el-col :span="16" v-if="nodata2!=false">
                <div class="use-titles">
                  <el-row>
                    <el-col :span="12">
                      <div class="use-titles-item">活动使用次数占比</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="use-titles-item">活动使用金额占比</div>
                    </el-col>
                  </el-row>
                  <div id="use-times"></div>
                </div>
              </el-col>

              <el-col :span="8" style="margin: auto;" v-if="nodata1!=false">
                <div class="use-titles">
                  <el-row>
                    <el-col :span="24">
                      <div class="use-titles-item">商家美团承担总额占比</div>
                    </el-col>
                  </el-row>
                </div>
                <div id="use-money"></div>
              </el-col>

            </el-row>
            <div v-else style="text-align: center;">
              <img src="../../assets/img/null.png" alt="">
              <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
            </div>
          </div>
        </div>
      </div>

      <div class="page-body-item" v-if="this.detaileddata!=undefined&this.detaileddata!=''">
        <div class="page-body-item-title">各项活动明细图
          <li>已选时间：{{this.queryDate[0]}} 至 {{this.queryDate[1]}} 数据</li>
        </div>
        <div class="page-body-item-content" v-for="(item,index) in detaileddata" :key="index">
          <div class="page-body-chart-box">
            <el-row>
              <el-col :span="24">
                <div class="use-titles">
                  <el-row>
                    <el-col :span="24">
                      <div class="use-titles-item" style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;">
                        {{item.name}}
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div :id="'activity'+index" :ref="'activity'+index" style="width: 100%;height: 400px;">
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: 'ActivityChart',
    data() {
      return {
        s_id: sessionStorage.getItem('s_id'),
        dateOption: 7,
        datesvalue: '',
        startDate: '',
        endDate: '',
        // 饼图
        chartPieObj: null,
        pieOtion: {},
        pieOneData: {
          times: [{
            name: '',
            value: 1,
            text: '次数'
          }],
          money: [{
            name: '',
            value: 1,
            text: '金额'
          }]
        },

        pieTowData: {
          percent: [{
              name: '商家',
              value: 0,
              text: '金额'
            },
            {
              name: '美团',
              value: 0,
              text: '金额'
            }
          ]
        },
        detaileddata: [],
        echartsdate: [],
        createname: [],
        createpoi_charge: [],
        createmt_charge: [],
        queryDate: [
          this.$dateFormat('YYYY-mm-dd', new Date()),
          this.$dateFormat('YYYY-mm-dd', new Date())
        ],
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        isDetailedShow: false,
        loading: true,
        everyDayDataDate: [],
        nodata1: true,
        nodata2: true,
      }
    },
    methods: {
      chartPieOne(pieOneDataReduceFee, pieOneDataCount, pieOneData) {
        const chartDom = document.getElementById('use-times')
        const myChart = echarts.init(chartDom)
        const option = {
          tooltip: {
            trigger: 'item'
          },
          title: {},
          legend: {
            top: '75%',
            data: pieOneData
          },
          series: [{
              name: '占比金额',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '14'
                }
              },
              labelLine: {
                show: false
              },
              center: ['25%', '40%'],
              data: pieOneDataReduceFee
            },
            {
              name: '占比次数',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '10',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              center: ['75%', '40%'],
              data: pieOneDataCount
            }
          ]
        }
        option && myChart.setOption(option)
      },
      chartPieTow(poi_charge, mt_charge) {
        const chartDom = document.getElementById('use-money')
        const myChart = echarts.init(chartDom)
        const option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '75%',
            left: 'center'
          },
          series: [{
            name: '活动金额',
            type: 'pie',
            radius: ['35%', '75%'],
            center: ['50%', '38%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '14'
              }
            },
            labelLine: {
              show: false
            },
            data: [{
                value: poi_charge,
                name: '商家承担'
              },
              {
                value: mt_charge,
                name: '美团承担'
              }
            ]
          }]
        }

        option && myChart.setOption(option)
      },
      baractivity(index) {
        // console.log(this.detaileddata[index].data)
        // console.log(document.getElementById('activity'))
        // var chartDom = document.getElementById("activity" + index);
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: { // 坐标轴指示器，坐标轴触发有效
              type: 'line' // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          legend: {
            data: ['美团', '商家', '次数'],
            bottom: '5%'
          },
          grid: {
            left: '3%',
            right: '4%',
            containLabel: true
          },
          xAxis: [{
            data: this.everyDayDataDate
          }],
          yAxis: [{
              type: 'value',
              name: '金额（/元）'
            },
            {
              name: '次数',
              type: 'value'
            }
          ],
          series: [{
              name: '美团',
              type: 'bar',
              stack: index,
              emphasis: {
                focus: 'series'
              },
              data: this.detaileddata[index].data.mtCharge
            },
            {
              name: '商家',
              type: 'bar',
              stack: index,
              emphasis: {
                focus: 'series'
              },
              data: this.detaileddata[index].data.poiCharge
            },
            {
              name: '次数',
              type: 'line',
              stack: index,
              emphasis: {
                focus: 'series'
              },
              data: this.detaileddata[index].data.count
            }
          ]
        }
        // console.log('activity' + index)

        this.$nextTick(function() {
          var chartDom = document.getElementById('activity' + index)
          // var chartDom = this.$refs['activity' + index][0]
          var myChart = echarts.init(chartDom)
          option && myChart.setOption(option)
        })
      },
      async getdata() {
        const {
          data: res
        } = await this.$http.get('/user/activity/getData', {
          params: {
            s_id: this.s_id,
            startDate: this.queryDate[0],
            endDate: this.queryDate[1]
          }
        })
        this.loading = false

        // console.log(res)

        const pieOneDataCount = []
        const pieOneDataReduceFee = []
        const pieOneData = []
        const data = res.data.data
        const everyDayData = res.data.everyDayData




        data.forEach(function(item, index) {
          pieOneDataCount.push({
            name: item.remark,
            value: item.count,
            text: '次数'
          })
          pieOneDataReduceFee.push({
            name: item.remark,
            value: item.count,
            text: '金额'
          })
          pieOneData.push(item.remark)
        })

        this.chartPieTow(res.data.poi_charge, res.data.mt_charge)
        console.log(res.data.poi_charge, res.data.mt_charge)
        if ((res.data.poi_charge == 0 && res.data.mt_charge == 0)) {
          this.nodata1 = false
          console.log(this.nodata1)
        } else {
          this.nodata1 = true
          console.log(this.nodata1)
        }
        this.chartPieOne(pieOneDataReduceFee, pieOneDataCount, pieOneData)
        console.log(pieOneDataReduceFee, pieOneDataCount, pieOneData)
        if ((pieOneDataReduceFee.length == 0 && pieOneDataCount.length == 0 && pieOneData.length == 0)) {
          this.nodata2 = false
          console.log(this.nodata2)
        } else {
          this.nodata2 = true
          console.log(this.nodata2)
        }

        // for (var i in res.data.everyDayData.data) {
        // 	date = res.data.everyDayData.date
        // 	poidetailed = res.data.everyDayData.data[i].data.poiCharge
        // 	mtdetailed = res.data.everyDayData.data[i].data.mtCharge
        // 	countetailed = res.data.everyDayData.data[i].data.count
        // 	this.detailed_charts(i, date, name, poidetailed, mtdetailed, countetailed)
        // 	// console.log(i)
        // }



        this.everyDayDataDate = res.data.everyDayData.date
        this.detaileddata = res.data.everyDayData.data
        this.isDetailedShow = true
        for (const i in res.data.everyDayData.data) {
          this.baractivity(i)
        }


        console.log(this.detaileddata)
        // console.log(this.nodata1)
        // console.log(this.nodata2)

      },
      selectDateChange(e) {
        this.loading = true
        this.nodata1 = true
        this.nodata2 = true
        // console.log(e)
        if (e == undefined) {
          e = 7
        }
        if (e !== -1) {
          if (typeof e === 'number') {
            var end = new Date();            var month = end.getMonth() + 1;            var month = month > 10 ? month : "0" + month;            end.setTime(end.getTime() - 23 * 60 * 60 * 1000);
            if (e < 2) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', start)]
            } else {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
            }
          }
          this.getdata()
        } else {
          this.$refs.daterange.focus()
        }
      },
    },
    mounted() {
      this.selectDateChange()
    }
  }
</script>

<style scoped>
  body {
    background: #F7F8FA
  }

  .container {}

  .page-title {
    font-size: 18px;
  }

  .page-tab {
    margin-top: 20px
  }

  .page-body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .page-body-item-title {
    margin-top: 23px;
    font-size: 14px;
    font-weight: bold;
    color: #3F4156;
    opacity: 1;
  }

  .page-body-chart-box {
    width: 100%;
    height: 450px;
    background: #fff;
    margin-top: 20px;
  }

  .page-body-chart-box #use-times,
  .page-body-chart-box #use-money {
    width: 100%;
    height: 400px;
  }

  .page-body-chart-box .use-titles {
    width: 100%;
    height: 100%;
    font-size: 14px;
  }

  .page-body-chart-box .use-titles-item {
    line-height: 50px;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .page-body-chart-box #use-bar-items {
    width: 100%;
    height: 400px;
  }
</style>
