<template>
  <div class="clearfix" style="overflow-y: hidden;" v-loading="loading">

    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
      <span class="clearfix-title">评价管理</span><span class="clearfix-Subtitle">管理客户评价</span>
    </div>

    <div class="score">
      <span class="clearfix-title">提升店铺评分</span>

      <div style="text-align: right;" v-if="orecastScoredata.total_num>=5">
        <li class="score-fs"><span
            style="font-size: 14px;font-weight: 400;color: #3F4156;">当前</span>{{orecastScoredata.now_score}}</li>
        <li class="score-zf" v-if="orecastScoredata.now_score<5">
          今日需{{orecastScoredata.need_num}}个5星好评提升至{{orecastScoredata.next_score}}分</li>
        <li class="score-zf" v-if="orecastScoredata.now_score>=5">店铺评分优秀，请继续保持！</li>
      </div>

      <div style="text-align: right;" v-else>
        <li class="score-zf">需{{orecastScoredata.need_num}}条评论才可以显示评分</li>
      </div>
    </div>

    <div class="routejump">
      <div class="jump-item">
        <li class="jump-name">回复率</li>
        <div style="display: flex;align-items: baseline;">
          <li class="jump-number">{{StateData.replyRate}}<span>%</span></li>
          <li class="route" @click="replyrouter()">自动回评 <i class="el-icon-arrow-right"></i></li>
        </div>
      </div>
      <el-divider direction="vertical" style="height: 40px;"></el-divider>
      <div class="jump-item">
        <li class="jump-name">评价率</li>
        <div style="display: flex;align-items: baseline;">
          <li class="jump-number">{{StateData.commentRate}}<span>%</span></li>
          <!-- <li class="route" @click="UrgeCommentrouter()">催好评 <i class="el-icon-arrow-right"></i></li> -->
        </div>
      </div>
      <el-divider direction="vertical" style="height: 40px;"></el-divider>
      <div class="jump-item">
        <li class="jump-name">近30天评价数</li>
        <div style="display: flex;align-items: baseline;">
          <li class="jump-number">{{StateData.commentCount}}<span>条</span></li>
          <li class="route" @click="hotKeywordsrouter()">热点分析 <i class="el-icon-arrow-right"></i></li>
        </div>
      </div>
    </div>

    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick1">
        <el-tab-pane label="全部" name="0"></el-tab-pane>
        <el-tab-pane label="未回复" name="2"></el-tab-pane>
        <el-tab-pane label="已回复" name="1"></el-tab-pane>
      </el-tabs>

      <div class="reply-header" style="margin-right: 20px;">
        <div class="reply" style="margin-left: 20px;">
          <div style="display: flex;align-items: center;">

            <el-tooltip popper-class="popoverBackB" placement="top" trigger="hover" content="没有可勾选的评论"
              :disabled="this.ischeckbox!='0'">

              <el-checkbox style="margin: 0 10px;" v-model="checkAll" :disabled="this.ischeckbox==0"
                @change="handleCheckAllChange()">

              </el-checkbox>
            </el-tooltip>

            <el-button style="margin-left: 35px;" type="primary" size="small" @click="replyEditdialog()"
              :disabled="this.ischeckbox==0">
              <el-tooltip popper-class="popoverBackB" placement="top" trigger="hover" content="没有可回复的评价"
                :disabled="this.ischeckbox!='0'">
                <li>批量回复</li>
              </el-tooltip>
            </el-button>

            <el-button size="small" @click="intelligenceDialogVisible = true" :disabled="this.ischeckbox==0">
              <el-tooltip class="item" effect="dark" content="没有可回复的评价" placement="top"
                :disabled="this.ischeckbox!='0'">
                <li style="display: flex;align-items: center;line-height: 0;">
                  智能回评
                  <span>
                    <el-tooltip popper-class="popoverBackB" placement="top" trigger="hover" content="使用系统对应类型模板随机回复">
                      <!-- <i class="el-icon-warning-outline" ></i> -->
                      <img src="../../assets/img/wh.png" alt="" style="height: 14px;margin-left: 5px;">
                    </el-tooltip>
                  </span>

                </li>
              </el-tooltip>
            </el-button>

          </div>
        </div>
        <div class="screen" style="padding-left: 0;">

          <el-date-picker size="small" format="yyyy-MM-dd" style="margin-right: 20px;" value-format="yyyy-MM-dd"
            v-model="queryDate" type="daterange" :clearable="false" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" ref="daterange" :picker-options="pickerOptions" @change="customdateChange">
          </el-date-picker>

          <el-select size="small" v-model="starvalue" placeholder="评价星级" @change="starkey(starvalue)">
            <el-option v-for="item in staroptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-select v-model="contentvalue" placeholder="有无内容" @change="ces(value)">
            <el-option v-for="item in contentoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->

        </div>
      </div>
      <div class="reply-content" v-loading="loading" v-if="this.CommentData!=''">
        <div class="reply-item" v-for="(item, index) in CommentData" :key="index">
          <div class="reply-score">
            <div style="display: flex;">
              <div>
                <el-checkbox v-if="item.reply_status!==1" v-model="item.checked"
                  @change="checkboxchange(item.checked,index,item.comment_id)"
                  style="position: absolute;width: 35px;text-align: center;">
                </el-checkbox>
              </div>
              <div class="reply-star">
                评星 <el-rate v-model="item.food_comment_score" disabled text-color="#ff9900"></el-rate>
              </div>
              <!-- <div class="reply-branch">
                <span>总分{{item.food_comment_score}}</span><span>口味{{item.food_comment_score}}</span><span>包装{{item.food_comment_score}}</span><span>配送{{item.food_comment_score}}</span>
              </div> -->
            </div>
            <div class="reply-time">
              {{item.comment_time}}
            </div>
          </div>
          <div class="reply-text" v-if="item.comment_content===''">
            该用户没有填写评价
          </div>
          <div class="reply-text" v-else>
            {{ item.comment_content }}
          </div>
          <div class="business-text" v-if="item.e_reply_content != null">
            <li class="business-time">商家回复：{{item.create_time|formatDate}}</li>
            <li class="business-content">{{item.e_reply_content}}</li>
          </div>
          <transition name="el-fade-in-linear">
            <div class="reply-button" v-if="item.reply_status!==1&&isarr!=0">
              <el-button type="primary" size="small" @click="replyEditdialog(1,item.comment_id)">回复评价</el-button>
            </div>
          </transition>
        </div>

        <div style="display: flex;justify-content: space-between;margin-right: 20px;">
          <span
            style="font-size: 12px;font-weight: 400;color: #666666;opacity: 1;margin-left: 20px;line-height: 32px;">当前显示{{this.total}}条评价数据</span>
          <el-pagination v-if="this.total!=0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            background :current-page="queryInfo.page" :page-size="queryInfo.limit" layout="prev, pager, next"
            :total="total"></el-pagination>
        </div>
      </div>

      <div v-else style="text-align: center;margin-top: 20px;">
        <img src="../../assets/img/null.png" alt="">
        <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
      </div>

    </div>
    <!-- 回复评价对话框 -->
    <el-dialog title="评价回复" :visible.sync="replyDialogVisible" @close="replyDialogClosed" width="500px">
      <el-form :model="replyForm" :rules="replyFormRules" ref="replyFormRef">
        <el-form-item prop="content" style="margin-bottom: 0;">
          <el-input :rows="6" placeholder="填写回复内容" maxlength="300" type="textarea" v-model="replyForm.content">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="replyDialogVisible = false" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button type="primary" @click="replyevaluate" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">提交回复</el-button>
      </span>
    </el-dialog>
    <!-- 智能回评 -->
    <el-dialog title="智能回复" :visible.sync="intelligenceDialogVisible" width="500px">
      <div>
        系统将对所有未回复的评价使用系统对应的模板
        进行回复，是否确定？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="intelligenceDialogVisible = false" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button type="primary" @click="intelligence()" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import store from '@/store'

  // 时间戳转换
  import {
    formatDate
  } from '../../assets/utils.js'

  export default {
    data() {
      return {
        // s_id: store.state.s_id,
        datesvalue: '',
        checkAll: false,
        checkedItem: '',
        checked: false,
        ischecked: true,
        activeName: '0',
        ratevalue: 5,
        CommentData: [],
        queryInfo: {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          type: 0,
          page: 1,
          limit: 10,
          score: 0,
          status: 0,
          is_null: 0,
          startDate: '',
          endDate: ''
        },
        total: 0,
        // 星级下拉
        staroptions: [{
          value: 0,
          label: '全部'
        }, {
          value: 5,
          label: '好评'
        }, {
          value: 3,
          label: '中评'
        }, {
          value: 1,
          label: '差评'
        }],
        // 内容下拉
        contentoptions: [{
          value: 0,
          label: '是'
        }, {
          value: 1,
          label: '否'
        }],
        // 下拉默认显示
        starvalue: '评价星级',
        contentvalue: '有无内容',
        // 复选框默认
        checkedId: '',
        // 回复评价对话框
        replyDialogVisible: false,
        checkedArrys: [],
        // 回复表单
        replyForm: {
          content: ''
        },
        // 回复表单校验规则
        replyFormRules: {
          content: [{
              required: true,
              message: '请输入回复内容',
              trigger: 'blur'
            },
            {
              max: 300,
              message: '请输入1到300个字符',
              trigger: 'blur'
            }
          ]
        },
        // 回复评价ID数组
        comment_id: [],
        orecastScoredata: [],
        reply_status: '',
        intelligenceDialogVisible: false,
        StateData: {
          replyRate: 0,
          commentRate: 0,
          commentCount: 0
        },
        isarr: '1',
        queryDate: [
          this.$dateFormat('YYYY-mm-dd', new Date()),
          this.$dateFormat('YYYY-mm-dd', new Date())
        ],
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        loading: true,
        ischeckbox: '',
        loading: false
      }
    },
    created() {
      this.loading = true
      this.startData()
      this.getCommentList()
      this.getforecastScore()
      this.queryState()
    },
    methods: {
      handleClick1(tab, event) {
        this.queryInfo.status = tab.name
        // console.log(this.queryInfo.status)
        this.getCommentList()
      },
      startData() {
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
        this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', new Date())]
        this.queryInfo.startDate = this.queryDate[0]
        this.queryInfo.endDate = this.queryDate[1]

        // console.log(this.queryDate)
      },
      customdateChange() {
        this.queryInfo.startDate = this.queryDate[0]
        this.queryInfo.endDate = this.queryDate[1]
        this.getCommentList()
      },
      async getforecastScore() {
        const {
          data: res
        } = await this.$http.get('/user/Commentlist/next_good_comment_num?s_id=' + JSON.parse(sessionStorage.getItem(
          's_id')))
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.orecastScoredata = res.data
        this.loading = false
        // console.log(res)
      },
      checkboxchange(a, b, str) {
        // console.log(a, b)
        if (a == true) {
          this.comment_id.push(this.CommentData[b].comment_id)
          this.isarr = '0'
        } else {
          var index = this.comment_id.indexOf(str)
          this.comment_id.splice(index, 1)
          // console.log(this.comment_id)
          if (this.comment_id.length === 0) {
            this.isarr = '1'
          }
        }
      },
      handleCheckAllChange() {
        if (this.checkAll) {
          this.isarr = '0'
          console.log(this.CommentData)
          for (const i in this.CommentData) {
            // this.reply_status = this.CommentData[i].reply_status
            if (this.CommentData[i].reply_status == 0) {
              this.CommentData[i].checked = true
              this.comment_id.push(this.CommentData[i].comment_id)
            }
          }
          // console.log(this.comment_id)
        } else {
          this.isarr = '1'
          // console.log('取消全选')
          for (const i in this.CommentData) {
            this.CommentData[i].checked = false
            this.comment_id.push(this.CommentData[i].comment_id)
          }
          // console.log(this.CommentData[i].checked)
          this.comment_id = []
          this.checked = false
        }
        // console.log(this.checkAll)
        console.log(this.comment_id)
      },
      handleClick(tab, event) {
        this.checkAll = false
        this.comment_id = []
        this.isarr = '1'
        // console.log(tab, event)
        this.queryInfo.status = this.activeName
        this.getCommentList()
        // console.log(this.queryInfo.status)
      },
      starkey(value) {
        this.queryInfo.score = value
        // console.log(this.queryInfo.score)
        this.getCommentList()
      },
      async getCommentList() {
        this.loading = true
        this.checkAll = false
        const {
          data: res
        } = await this.$http.get('user/commentlist/log_data', {
          params: this.queryInfo
        })

        this.CommentData = res.data
        this.total = res.count
        this.loading = false
        // console.log(res)

        // var a = res.data.indexOf('已回复')
        // console.log(a)
        var yhflenght = []

        for (var i in res.data) {
          if (res.data[i].reply_status != 1) {
            yhflenght.push(true)
          }
        }

        // console.log(yhflenght)

        this.ischeckbox = yhflenght.length

        // console.log(this.ischeckbox)
      },
      // 回复评价对话框
      replyDialogClosed() {
        this.$refs.replyFormRef.resetFields()
        this.comment_id = []
      },
      replyEditdialog(e, id) {
        if (e === 1) {
          this.replyDialogVisible = true
          this.comment_id.push(id)
        } else {
          if (this.comment_id != '') {
            this.replyDialogVisible = true
          } else {
            this.$message.error('请选择需要回复的评价!')
          }
        }
      },
      // 确定回复
      replyevaluate() {
        // console.log(this.comment_id)
        this.$refs.replyFormRef.validate(async valid => {
          if (!valid) return
          const {
            data: res
          } = await this.$http.post('/user/commentlist/reply_data/', {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
            comment_id: this.comment_id,
            content: this.replyForm.content
          })
          if (res.code != '1') {
            return this.$confirm(res.msg, '系统提示', {
              confirmButtonText: '确定',
              confirmButtonClass: 'btnFalse',
              center: true,
              callback: action => {
                if (action === 'confirm') {
                  this.checkAll = ''
                  for (const i in this.CommentData) {
                    this.CommentData[i].checked = false
                  }
                  this.comment_id = []
                  this.replyDialogVisible = false
                  // console.log(1)
                } else {
                  this.checked = ''
                  for (const i in this.CommentData) {
                    this.CommentData[i].checked = false
                  }
                  this.comment_id = []
                  this.replyDialogVisible = false
                  // console.log(2)
                }
              }
            })
            // this.$message.error(res.msg)
          }
          this.$message.success(res.msg)
          this.replyDialogVisible = false
          this.getCommentList()
          // console.log(res)
        })
      },
      // 分页功能
      handleSizeChange(newSize) {
        this.queryInfo.limit = newSize
        this.getCommentList()
        // console.log(newSize)
        this.bottom()
      },
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage
        this.getCommentList()
        // console.log(newPage)
        this.bottom()
      },
      bottom() {
        $('body').scrollTop(
          $('.bottom').offset().top - $(window).height()
        )
      },
      async intelligence() {
        const {
          data: res
        } = await this.$http.post('/user/commentlist/voluntarily', {
          s_id: JSON.parse(sessionStorage.getItem('s_id'))
        })
        console.log(res)
        if (res.code != 0) {
          this.$message.success(res.msg)
          this.intelligenceDialogVisible = false
        } else {
          this.$message.error(res.msg)
          this.intelligenceDialogVisible = false
        }
        this.getCommentList()
      },
      async queryState() {
        const {
          data: res
        } = await this.$http.get('/user/commentlist/queryState', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
          }
        })
        this.StateData = res.data
        console.log(res)
      },
      replyrouter() {
        this.$router.push({
          path: '/commentlist/reply'
        })
      },
      UrgeCommentrouter() {
        this.$router.push({
          path: '/commentlist/UrgeComment'
        })
      },
      hotKeywordsrouter() {
        this.$router.push({
          path: '/commentlist/hotKeywords'
        })
      }
    },
    filters: {
      formatDate(time) {
        time = time * 1000
        const date = new Date(time)
        return formatDate(date, 'yyyy-MM-dd')
      }
    }
  }
</script>

<style scoped>
  .score {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E9EAF2;
    margin-left: 20px;
    margin-right: 20px;
  }

  .score-fs {
    font-size: 24px;
    font-weight: bold;
    color: #F89800;
    opacity: 1;
    ;
  }

  .score-zf {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .reply {
    display: flex;
    align-items: center;
  }

  .reply span {
    /* margin-left: 5px; */
    font-size: 14px;
    font-weight: 400;
    color: #858692;
  }

  .reply-header {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E9EAF2;
  }

  .reply-content {
    width: 100%;
  }

  .reply-item {
    min-height: 200px;
    border-bottom: 1px solid #E9EAF2;
    margin: 20px 20px 20px 20px;
    position: relative;
  }

  .reply-score {
    display: flex;
    justify-content: space-between;
  }

  .reply-star {
    font-size: 12px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
    display: flex;
    align-items: center;
    margin-left: 72px;
  }

  .reply-branch {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    opacity: 1;
    margin-left: 20px;
  }

  .reply-branch span {
    margin-right: 10px;
  }

  .reply-time {
    font-size: 14px;
    font-weight: 400;
    color: #A2A4B3;
    opacity: 1;
  }

  .reply-text {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
    margin-left: 72px;
    margin-top: 10px;
  }

  .business-text {
    margin-left: 70px;
    margin-top: 20px;
    background: #F7F8FA;
    opacity: 1;
    border-radius: 2px;
    padding: 20px;
  }

  .business-time {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
  }

  .business-content {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
    margin-top: 10px;
  }

  .content-item {
    margin-bottom: 20px;
    border: 1px solid #E9EAF2;
    border-radius: 2px;
  }

  .content-item-header {
    height: 60px;
    background: #F7F8FA;
    padding: 0 20px;
  }

  .grade {
    display: inline-flex;
  }

  .grade span {
    font-size: 14px;
    font-family: Segoe UI;
    font-weight: 400;
    color: #3F4156;
  }

  .grade .el-rate {
    margin-left: 10px;
  }

  .content-item-header-score {
    display: initial;
    margin-left: 20px;
  }

  .content-item-header-score span {
    font-weight: 400;
    color: #3F4156;
    font-size: 14px;
    margin-right: 10px;
  }

  .item-header-time {
    font-size: 14px;
    font-weight: 400;
    color: #A2A4B3;
  }

  .item-content {
    display: grid;
  }

  .control-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #3F4156;
    margin: 20px 20px;
  }

  .control-reply {
    width: 95%;
    background: #F7F8FA;
    border-radius: 2px;
    margin: auto;
    margin-bottom: 20px;
  }

  .reply-button-div {
    width: 100%;
    text-align: right;
  }

  .reply-button {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .routejump {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 10px solid #F7F8FA;
  }

  .jump-name {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    margin-bottom: 10px;
  }

  .jump-number {
    font-size: 24px;
    font-weight: bold;
    color: #3F4156;
    margin-right: 10px;
  }

  .jump-number span {
    font-size: 14px;
    font-weight: bold;
    color: #3F4156;
  }

  .route {
    font-size: 12px;
    font-weight: 400;
    color: #858692;
    cursor: pointer;
    opacity: 1;
  }

  .route:hover {
    color: #F89800;
  }

  .el-divider--vertical {
    height: 40px;
    margin: 0 90px;
  }
</style>
