<template>
  <div v-loading="loading">
    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
      <span class="clearfix-title">IM热词分析</span>
      <span class="clearfix-Subtitle">发现消费者需求，找到问题，找到市场机会，从而制定对应的营销方案。</span>
    </div>
    <div v-if="showKeywordSettings==false">
      <!--会话列表条件-->
      <div style="padding-left: 20px;padding-right: 20px;display: flex;align-items: center;margin-top: 20px;">

        <el-radio-group v-model="dateOption" size="small" fill="#585A6E" text-color="#FFFFFF"
          @change="selectDateChange">
          <el-radio-button :label="0">今日</el-radio-button>
          <el-radio-button :label="1">昨日</el-radio-button>
          <el-radio-button :label="7">近7天</el-radio-button>
          <el-radio-button :label="30">近30天</el-radio-button>
          <el-radio-button :label="-1">自定义</el-radio-button>
        </el-radio-group>

        <el-date-picker v-if="dateOption===-1" class="mar-l10" style="height: 32px;width: 280px" type="daterange"
          align="right" unlink-panels v-model="queryDate" start-placeholder="开始日期" end-placeholder="结束日期"
          ref="daterange" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="selectDateChange">
        </el-date-picker>

      </div>

      <div style="padding: 20px;">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <li style="font-size: 16px;font-weight: bold;color: #3F4156;opacity: 1;margin-bottom: 20px;">热点分布排名</li>

          <li style="font-size: 14px;font-weight: 400;color: #A2A4B3;opacity: 1;">共{{queryInfo.total}}条数据</li>
        </div>

        <el-table :data="tableData" :stripe="true">
          <el-table-column prop="num_rate" label="排名问题" align="left"></el-table-column>
          <el-table-column prop="tags" label="咨询热度榜" align="left"></el-table-column>
          <el-table-column prop="num" label="咨询量" align="right"></el-table-column>
          <el-table-column prop="percent" label="咨询量占比" align="right"></el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
          :current-page="queryInfo.page" :page-size="queryInfo.limit" :total="queryInfo.total" layout="prev, pager, next"
          style="margin-top: 20px;text-align: right;"></el-pagination>
      </div>

      <div v-if="this.cydata!=''" style="border-top: 10px solid rgb(247, 248, 250);">
        <div style="padding: 20px;">
          <div style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;">回复关键词</div>
          <div>
            <div id="canvas-container" style="text-align: center;" v-if="this.cydata!=''">
              <canvas id="canvas" width="800px" height="600px"></canvas>
            </div>
            <div style="text-align: center;" v-if="this.cydata==''">
              <img src="../../assets/img/null.png" alt="">
              <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!--会话详情弹窗-->
    <el-dialog title="会话记录" :visible.sync="showSessionLogDetail" width="500px">
      <div style="border: solid 1px #ccc;height: 400px;width: calc(100% - 40px);overflow: auto;"
        class="center-column pad20 scrollbar color666 mar-b30 size13">
        <div class="max-w center-column" v-for="item in currentSessionLog">
          <div style="background-color: #E1E1E1;padding: 5px 20px" class="radius5 size10 mar-b20">{{ item.add_time }}
          </div>
          <div class="max-w flex mar-b20" v-if="item.msg_type===2">
            <div class="center size10 white radius50" style="height: 40px;width: 40px;background-color: #AAA">
              顾客
            </div>
            <div class="msg-box msg-box-left mar-l10">{{ item.msg_content }}</div>
          </div>
          <div class="max-w flex mar-b20" style="justify-content: flex-end" v-if="item.msg_type===1">
            <div class="msg-box msg-box-right mar-r10">{{ item.msg_content }}</div>
            <div class="center size10 white radius50" style="height: 40px;width: 40px;background-color: #AAA;">
              商家
            </div>
          </div>
        </div>
      </div>

      <div style="width: calc(100% - 40px);position: absolute;bottom: -20px;left: 0;z-index: 1"
        class="mar-t10 box-shadow-aaa size15 pad20 back-white center-vertical">
        订单信息<img src="../../assets/img/waht.svg" style="height: 18px;width: 18px;" class="mar-l5"
          @mouseover="showOrderDetail=true" @mouseout="showOrderDetail=false" />
      </div>

      <div class="order-box white mar-t20" v-if="showOrderDetail">
        <div class="size15">订单信息</div>
        <div style="border: solid 1px #858692;" class="mar-t20">
          <div style="background-color: #858692;padding: 10px 20px;">
            订单编号：{{ orderDetail.orderId }}
            <div class="float-r center-vertical"><img src="../../assets/img/success.png" class="mar-r5"
                style="height: 18px;width: 18px;" />
              {{
                orderDetail.status === 2 ? '新订单' : orderDetail.status === 4 ? '已接单' : orderDetail.status === 8 ? '订单完成' : orderDetail.status === 9 ? '订单取消' : '未知'
              }}
            </div>
          </div>

          <div class="pad20">
            <div class="size15">商品信息 <span class="mar-l10 weight400"><span class="mar-l10">{{
                orderDetail.detailCount
              }}种商品</span><span class="mar-l10">共5件</span></span></div>

            <div class="mar-t20">
              <div class=" size10 mar-t10" style="height: 25px" v-for="item in orderDetail.detail">
                <div class="float-l show-only-one-line" style="max-width: 60%">
                  {{ item.food_name + (item.food_property ? '-（' + item.food_property + '）' : '') }}
                </div>
                <div class="float-r">¥{{ item.price }}</div>
                <div class="float-r mar-r10">x{{ item.quantity }}</div>
              </div>
            </div>

            <div class="mar-t20">
              <div class=" size10 mar-t10" style="height: 25px"
                v-for="item in orderDetail.poiReceiveDetail.actOrderChargeByPoi">
                <div class="float-l show-only-one-line" style="max-width: 80%">{{ item.comment }}</div>
                <div class="float-r">¥{{ item.moneyCent }}</div>
              </div>
            </div>
            <div class="size15 mar-t20">订单信息</div>
            <div>
              <div class=" size10 mar-t20" style="height: 25px">
                <div class="float-l">下单时间</div>
                <div class="float-r">{{ orderDetail.orderConfirmTime }}</div>
              </div>
            </div>

            <div style="border-top: solid 2px #858692;display: flex;justify-content: flex-end"
              class="pad10 center-vertical">
              <div class="size12">优惠信息<span style="color: #F76C6C;" class="mar-l5">¥{{
                  orderDetail.poiReceiveDetail.actOrderChargeByPoiSum
                }}</span></div>
              <div class="size12 mar-l10">实付金额<span
                  class="mar-l5 size15">¥{{ orderDetail.poiReceiveDetail.onlinePayment }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  export default {
    name: 'SessionLog',
    data() {
      return {
        used: [
          '你好',
          '在吗',
          '味道',
          '好吃'
        ],
        queryInfo: {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          type: 0,
          page: 1,
          limit: 10,
          count: 0
        },
        page: 1,
        pageSize: 10,
        totalCount: 0,
        keyword: undefined,
        msgSource: 0,
        dateRange: [],
        tableData: [],
        loading: true,
        showKeywordSettings: false,
        switch1: false,
        msgOption: 0,
        sId: sessionStorage.getItem('s_id'),
        keywordList: [],
        keywordListLoading: true,
        keywordPage: 1,
        keywordPageSize: 5,
        keywordTotalCount: 0,
        autoReply: [0, 0, 0],
        showSessionLogDetail: false,
        showOrderDetail: false,
        currentSessionPage: 1,
        currentSessionPageSize: 10,
        currentSessionLog: [],
        orderDetail: {},
        imSettings: {
          overtimeConfig: {}
        },
        editKeywordBox: false,
        dateOption: 30,
        queryDate: '',
        keywordFrom: {
          id: undefined,
          msgOption: 2,
          keyword: undefined,
          content: undefined
        },
        oldSendContent: undefined,
        Number_one: [],
        Number_two: [],
        Number_three: [],
        Number_four: [],
        Number_five: [],
        Number_six: [],
        Number_seven: [],
        Number_eight: [],
        Number_nine: [],
        Number_ten: [],
        isShow: true,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        timeData: [new Date(), new Date()],
        cydata: [],
        loading:false
      }
    },
    methods: {
      words() {
        this.$nextTick(function() {
          var canvas = document.getElementById('canvas')
          var options = eval({
            "list": this.cydata, //或者[['各位观众',45],['词云', 21],['来啦!!!',13]],只要格式满足这样都可以
            "gridSize": 6, // 密集程度 数字越小越密集
            "weightFactor": 1, // 字体大小=原始大小*weightFactor
            "maxFontSize": 100, //最大字号
            "minFontSize": 10, //最小字号
            "fontWeight": 'normal', //字体粗细
            "fontFamily": 'Times, serif', // 字体
            "color": 'random-light', // 字体颜色 'random-dark' 或者 'random-light'
          });
          WordCloud(canvas, options);
        })
      },
      handleSizeChange(newSize) {
        this.queryInfo.limit = newSize
        this.gethot_tags_lists()
      },
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage
        this.gethot_tags_lists()
      },
      selectDateChange(e) {

        // console.log(e)
        if (e == undefined) {
          e = 30
        }

        if (e !== -1) {
          if (typeof e === 'number') {
            const end = new Date()
            if (e < 2) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', start)]
            } else {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
            }
          }
          this.startDate = this.queryDate[0]
          this.endDate = this.queryDate[1]
          this.getIMSettings()
          this.gethot_tags_lists()
          this.gethot_tags_array()
        } else {
          this.$refs.daterange.focus()
        }
        // console.log(this.queryDate)

        // console.log(e)
        // if(e==undefined){
        //   e = 30
        // }
        // if (e !== -1) {
        //   if (typeof e === 'number') {
        //     const end = new Date()
        //     if (e == 1 && e == 2) {
        //       const start = new Date()
        //       start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
        //       this.queryDate = [start, start]
        //     } else {
        //       const start = new Date()
        //       start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
        //       this.queryDate = [start, end]
        //     }
        //     this.getIMSettings()
        //     this.gethot_tags_lists()
        //     this.gethot_tags_array()
        //   }
        // } else {
        //   this.$refs.daterange.focus()
        // }
        // console.log(this.queryDate)
        // // this.gethot_tags_array()
      },
      async gethot_tags_lists() {
        const {
          data: res
        } = await this.$http.get('/user/Commenthot/hot_tags_lists', {
          params: {
            s_id: this.sId,
            type: 2,
            page: this.queryInfo.page,
            size: this.queryInfo.limit,
            startDate: this.startDate,
            endDate: this.endDate
          }
        })
        this.queryInfo.total = res.count
        this.tableData = res.data
        console.log( this.queryInfo.total)
      },
      async hot_tags_array() {
        const {
          data: res
        } = await this.$http.get('/user/Commenthot/hot_tags_array', {
          params: {
            s_id: this.sId,
            type: 2,
            page: 1,
            size: 10,
            startDate: this.startDate,
            endDate: this.endDate
          }
        })
        this.Number_one = res.data.slice(0, 1)
        this.Number_two = res.data.slice(1, 5)
        this.Number_three = res.data.slice(5, 15)
        this.Number_four = res.data.slice(15, 30)
        this.Number_five = res.data.slice(30, 50)
        this.Number_six = res.data.slice(50, 75)
        this.Number_seven = res.data.slice(75, 99)
        this.Number_eight = res.data.slice(99, 119)
        this.Number_nine = res.data.slice(119, 149)
        this.Number_ten = res.data.slice(149, 200)
        // console.log(res)
      },
      showAutoReplyKeyword(next) {

        this.keywordListLoading = true
        if (next === true) {
          this.showKeywordSettings = true
        } else {
          this.showKeywordSettings = !this.showKeywordSettings
        }
        // if (this.showKeywordSettings)
        // this.getKeywordList()
      },
      getKeywordList() {
        this.$api.get('/user/im/keyword_list', {
          s_id: this.sId,
          type: this.msgOption,
          page: this.keywordPage,
          limit: this.keywordPageSize
        }).then(res => {
          this.keywordList = res.data
          this.keywordListLoading = false
        })
      },
      getIMSettings() {
        this.$api.get('/user/im/settings', {
          s_id: this.sId
        }).then(res => {
          if (!res.overtimeConfig.overtime) res.overtimeConfig.overtime = 1
          this.imSettings = res
          // console.log(res)
        })
      },
      keywordListPageChange(e) {
        this.keywordPage = e
        this.showAutoReplyKeyword(true)
      },
      getSessionLogDetail(e) {
        this.currentSessionLog = []
        this.showSessionLogDetail = true
        this.$api.get('user/im/log_data', {
          page: this.currentSessionPage,
          limit: this.currentSessionPageSize,
          s_id: this.sId,
          order_id: e.order_id
        }).then(res => {
          this.currentSessionLog = res
        })
        this.getOrderDetail(e.order_id)
      },
      getOrderDetail(orderId) {
        this.$api.get('/user/order/info', {
          order_id: orderId
        }).then(res => {
          res.orderId = orderId
          res.quantity = 0
          for (const detail of res.detail) {
            res.quantity += detail.quantity
          }
          this.orderDetail = res
        })
      },
      openOrCloseSettings(e) {

        // console.log(e.status)

        if (!e.content) {
          e.status = false
          // console.log(e)
          this.$message({
            message: '请输入要回复的内容！',
            type: 'error'
          })
          return
        }
        if (e.status == 1) {
          this.$confirm('是否立即启用该功能?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            confirmButtonClass: 'btnFalse'
          }).then(() => {
            this.$api.post('/user/im/settings_edit', {
              s_id: this.sId,
              field: e.statusStr ? 'orderList' : 'overtimeConfig',
              order_status: e.order_status,
              event: 'status',
              value: e.status
            }, true).then(res => {
              this.getIMSettings()
              this.$message({
                message: res.msg,
                type: 'success'
              })
            })

          }).catch(() => {
            this.getIMSettings()
          });
        } else {
          this.$api.post('/user/im/settings_edit', {
            s_id: this.sId,
            field: e.statusStr ? 'orderList' : 'overtimeConfig',
            order_status: e.order_status,
            event: 'status',
            value: e.status
          }, true).then(res => {
            this.getIMSettings()
            this.$message({
              message: res.msg,
              type: 'success'
            })
          })

        }


      },
      sendContentChange(e) {
        if (this.oldSendContent !== e.content) {
          this.$api.post('/user/im/settings_edit', {
            s_id: this.sId,
            field: e.statusStr ? 'orderList' : 'overtimeConfig',
            order_status: e.order_status,
            event: 'content',
            value: e.content
          }, true).then(res => {
            this.getIMSettings()
            this.$message({
              message: res.msg,
              type: 'success'
            })
          })
        }
      },
      keywordOrOvertimeChange(e, keyword) {
        this.$api.post('/user/im/settings_edit', {
          s_id: this.sId,
          field: keyword ? 'keyword' : 'overtimeConfig',
          event: keyword ? 'status' : 'overtime',
          value: keyword ? e.status : e.overtime
        }, true).then(res => {
          this.getIMSettings()
          this.$message({
            message: res.msg,
            type: 'success'
          })
        })
      },
      openKeywordEdit(e) {
        this.keywordFrom = e.id ? {
          id: e.id,
          msgOption: e.type,
          keyword: e.keyword,
          content: e.content
        } : {
          msgOption: 2,
          keyword: undefined,
          content: undefined
        }
        this.editKeywordBox = true
      },
      keywordEdit() {
        this.$api.post('/user/im/keyword_edit', {
          id: this.keywordFrom.id,
          s_id: this.sId,
          type: this.keywordFrom.msgOption,
          keyword: this.keywordFrom.keyword,
          content: this.keywordFrom.content
        }, true).then(res => {
          this.editKeywordBox = false
          this.getKeywordList()
          this.$message({
            message: res.msg,
            type: 'success'
          })
        })
      },
      delKeyword(e) {
        this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          this.$api.post('/user/im/keyword_delete', {
            id: e.id,
            s_id: this.sId
          }, true).then(res => {
            this.getKeywordList()
            this.$message({
              message: res.msg,
              type: 'success'
            })
          })
        })
      },
      async gethot_tags_array() {
        const {
          data: res
        } = await this.$http.post('/user/Commenthot/hot_tags_array', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          type: 2,
          startDate: this.startDate,
          endDate: this.endDate,
          comment_rate: this.comment_rate
        })

        var cydata = []
        var weightFactor = 0


        if (res.data != '') {

          for (var i = 0; i < 1; i++) {
            if (res && res.data[i]) {
              cydata.push([
                res.data[i].tags,
                100
              ])
            }
          }

          for (var i = 1; i < 5; i++) {
            if (res && res.data[i]) {
              cydata.push([
                res.data[i].tags,
                70
              ])
            }
          }

          for (var i = 5; i < 10; i++) {
            if (res && res.data[i]) {
              cydata.push([
                res.data[i].tags,
                40
              ])
            }
          }

          for (var i = 10; i < 15; i++) {
            if (res && res.data[i]) {
              cydata.push([
                res.data[i].tags,
                20
              ])
            }
          }

          for (var i = 15; i < 20; i++) {
            if (res && res.data[i]) {
              cydata.push([
                res.data[i].tags,
                10
              ])
            }
          }
        }
        this.cydata = cydata
        if (this.cydata != '') {
          this.words()
        }


  this.loading=false

        // console.log(this.cydata)
      }
    },
    created() {
      this.loading = true
      this.selectDateChange()
    }
  }
</script>

<style scoped>
  .order-code-hover:hover {
    color: #F89800;
  }

  .settings {
    margin-bottom: 10px;
    height: 0px;
    width: 100%;
    background-color: white;
  }

  .settings-open {
    animation: settings-open 0.5s;
    animation-fill-mode: forwards;
  }

  .settings-close {
    animation: settings-close 0.5s;
    animation-fill-mode: forwards;
  }

  @keyframes settings-open {
    0% {
      height: 0px;
    }

    100% {
      height: 700px;
    }
  }

  @keyframes settings-close {
    0% {
      height: 700px;
    }

    100% {
      height: 0px;
    }
  }

  .msg-box {
    position: relative;
    max-width: 320px;
    color: #373737;
    padding: 10px;
    background: #E7E7E7;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .msg-box-left::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 6px solid #E7E7E7;
    position: absolute;
    left: -6px;
    top: 10px;
  }

  .msg-box-right::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid #E7E7E7;
    position: absolute;
    right: -6px;
    top: 10px;
  }

  .order-box {
    padding: 20px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    background-color: #3F4156;
    width: 400px;
    z-index: 2;
  }

  .order-box::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-top: 6px solid #3F4156;
    position: absolute;
    bottom: -10px;
    left: 60px;
  }
</style>
