import Vue from 'vue'
import Vuex from 'vuex'

import router from './router'

// 公用属性库
import store from './store/index.js'

// 图标库
import './assets/el-icon/iconfont.css'
// ElementUI
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css';

// echarts
/* import echarts from 'echarts' */

import Js2WordCloud from 'js2wordcloud'



import './assets/css/theme/index.css'

import App from './App.vue'

// 样式文件
import './assets/style.css'

// 公用样式
import '../public/public.css'

import axios from 'axios'


// 测试工具
// import vConsole from '@/assets/vconsole.js'

Vue.use(Vuex)

/* Vue.prototype.$echarts = echarts */

Vue.use(ElementUI)

// 热词分析图
// import './assets/miaov_style.css'
// import './assets/miaov.js'
/// api
// axios.defaults.baseURL = '/api'// https://demo.kkdou.vip/'
// axios.defaults.baseURL = 'https://wwwpdemo.kkdou.vip/'
axios.defaults.baseURL = "https://wwwp-api1.kkdou.vip/"

// axios.defaults.withCredentials = true
// application/x-www-form-urlencoded
axios.defaults.headers = {
  'X-Requested-With': 'XMLHttpRequest'
}
// axios.defaults.headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  // config.headers.Type = 'web'
  //  config.headers.token = window.sessionStorage.getItem('token')
  return config
})

var codestatus = ''
axios.interceptors.response.use(response => {
//   console.log(response.data.code)
//   console.log(response.status)
  console.log(response)
//   codestatus = response.data.code

// console.log(response.status)

  // response.status = 500
  var msgdata = {
    '对象':'后端',
    '状态码':response.status,
    '路径':response.config.url
  }
  console.log(msgdata)
  if (response.status != 200) {
    $http.get('program/wechat/webbug',msgdata)


    Vue.prototype.$confirm('服务器错误', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    }).then(() => {
      console.log('确定')
    }).catch(() => {
      console.log('取消')
    });
  }

  if (response.data.code == 403) {
    Vue.prototype.$confirm(response.data.msg, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    }).then(() => {
        router.replace({
              path: '/index'
            })
    }).catch(() => {
        router.replace({
              path: '/index'
            })
    });
  }

  // if (response.data.code !=1) {
  //   console.log(response.data.msg)
  //   Vue.prototype.$message.error(response.data.msg)
  // }

  // response.data.code = 401
  switch (response.data.code) {
    case 401:
      Vue.prototype.$confirm('登录失效，点击按钮返回授权登录页面', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        window.parent.postMessage({
        	action: 'isvJumpToSalePage',
        }, '*');
      }).catch(() => {
        window.parent.postMessage({
        	action: 'isvJumpToSalePage',
        }, '*');
      });
      break
    default:
      console.log(response)
  }

  return response
}, error => {
  return Promise.reject(error.response.data) // 返回接口返回的错误信息
})

// 页面标题
router.beforeEach((to, from, next) => {
  console.log(codestatus)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param detail
 */
const get = (url, params, detail = false) => {
  return new Promise((resolve, reject) => {
    axios.get(url, {
        params: params
      })
      .then(res => {
        resolve(detail ? res.data : res.data.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param detail
 */
const post = (url, params, detail = false) => {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(res => {
        resolve(detail ? res.data : res.data.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

const dateFormat = (fmt, date) => {
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(),
    'm+': (date.getMonth() + 1).toString(),
    'd+': date.getDate().toString(),
    'H+': date.getHours().toString(),
    'M+': date.getMinutes().toString(),
    'S+': date.getSeconds().toString()
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    }
  }
  return fmt
}

/**
 * 限制输入框只能输入指定位数的浮点类型
 * @param el
 * @param ele
 * @param binding
 * @param vnode
 * @returns {handle}
 */
function onInput(el, ele, binding, vnode) {
  function handle() {
    let val = ele.value
    // modifiers为修饰符对象，传入了float，则其float属性为true
    if (binding.modifiers.float) {
      // 清除"数字"和"."以外的字符
      val = val.replace(/[^\d.]/g, '')
      // 只保留第一个“点”号, 清除多余的
      const idx = val.indexOf('.')
      if (!(idx === -1 || idx === val.length - 1)) {
        val = val.substr(0, idx) + '.' + val.substr(idx + 1).replace(/\./g, '')
      }
      // 第一个字符如果是.号，则补充前缀0
      val = val.replace(/^\./g, '0.')
      if (typeof binding.value !== 'undefined') {
        // 期望保留的最大小数位数
        let pointKeep = 0
        if (typeof binding.value === 'string' ||
          typeof binding.value === 'number') {
          pointKeep = parseInt(binding.value)
        }
        if (!isNaN(pointKeep)) {
          if (!Number.isInteger(pointKeep) ||
            pointKeep < 0) {
            pointKeep = 0
          }
          const str = '^(\\d+)\\.(\\d\{' + pointKeep + '}).*$'
          const reg = new RegExp(str)
          if (pointKeep === 0) {
            // 不需要小数点
            val = val.replace(reg, '$1')
          } else {
            // 通过正则保留小数点后指定的位数
            val = val.replace(reg, '$1.$2')
          }
        }
      } else {
        val = ele.value.replace(/[^\d]/g, '')
      }
      ele.value = val
    }
  }
  return handle
}

const numberInput = {
  bind(el, binding, vnode) {
    const ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
    ele.addEventListener('input', onInput(el, ele, binding, vnode), false)
  }
}
Vue.directive('number-input', numberInput)

Vue.prototype.$dateFormat = dateFormat
Vue.prototype.$api = {
  get,
  post
}
Vue.prototype.$http = axios
Vue.use(ElementUI)
Vue.config.productionTip = false

window.onerror = async function(errorMessage, scriptURI, lineNo, columnNo, error) {
	var msgdata = {
		'异常信息':errorMessage,
		'异常文件路径':scriptURI,
		'异常行号':lineNo,
		'异常列号':columnNo,
		'异常堆栈信息':error
	}
	console.log(msgdata)
  const {data:res} = await this.$http.get('program/wechat/webbug',msgdata)

	console.log('异常信息: ' + errorMessage); // 异常信息
	console.log('异常文件路径: ' + scriptURI); // 异常文件路径
	console.log('异常行号: ' + lineNo); // 异常行号
	console.log('异常列号: ' + columnNo); // 异常列号
	console.log('异常堆栈信息: ' + error); // 异常堆栈信息
};
new Vue({
  router,
  store, // 使用store
  render: h => h(App)
}).$mount('#app')
