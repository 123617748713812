<template>
  <div v-loading="loading">

    <div class="clearfix">
      <div class="header-title">
        <span class="clearfix-title">菜品成本管理</span><span class="clearfix-Subtitle">开启后可针对单个菜品规格成本进行单独设置。</span>
        <div style="float: right; padding: 3px 0;color: #F89800;display: flex;align-items: center;">
          <span style="font-size: 14px;font-weight: 400;color: #585A6E;opacity: 1;margin-right: 6px;">高级设置</span>
          <!-- <el-tooltip popper-class="popoverBackB" placement="bottom" trigger="hover" content="开启后默认使用精准核算法"> -->
          <el-switch inactive-value="0" active-value="1" v-model="value" active-color="#FFD161" @change="postcost"
            inactive-color="#858692">
          </el-switch>
          <!-- </el-tooltip> -->
        </div>
      </div>

      <div class="Collapse" v-if="this.value == 0" style="padding: 20px;">
        <el-alert title="当前页面默认为系统估算法设置" type="info"
          style="margin-bottom: 20px;background: #F7F8FA;font-size: 14px;font-weight: 400;color: #A2A4B3;opacity: 1;border-radius: 2px;">
        </el-alert>

        <el-form ref="form" v-model="costform">
          <el-form-item>
            <li class="form-title">餐盒成本：</li>
            <el-input prefix-icon="el-icon-warning-outline" style="width: 200px;" size="small" type="number"
              v-model="costform.box_cost" @change="boxprice">
            </el-input>
            <li class="form-subtitle">默认订单中每个菜品都包含一份餐盒成本</li>
          </el-form-item>
          <el-form-item>
            <li class="form-title">餐具成本：</li>
            <el-input prefix-icon="el-icon-warning-outline" type="number" style="width: 200px;" size="small" 
              v-model="costform.tableware_cost" @change="tablewareprice"></el-input>
            <!-- <li class="form-subtitle">70%的订单为1双餐具，20%的的订单为2双餐具，10%的的订单为3份餐具。</li> -->
          </el-form-item>
          <el-form-item>
            <li class="form-title">菜品成本：</li>
            <el-input prefix-icon="el-icon-warning" style="width: 200px;" size="small" type="number" v-model="costform.food_cost"
              @change="foodpricepercent"></el-input>
            <li class="form-subtitle">默认使用售价的百分比作为菜品成本</li>
          </el-form-item>
        </el-form>
      </div>

      <div class="Collapse" v-if="this.value == 1">
        <a @click="Refresh" href="#" class="Refresh">
          <i class="el-icon-refresh"></i>更新菜品库
        </a>
        <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
          <el-tab-pane label="全部菜品" name="0">

            <div class="classification" v-if="activeName==0">
              <div class="classification-content">
                <a href="#">

                  <li style="list-style-type: square;font-size: 14px;font-weight: 400;color: #3F4156;opacity: 1;"
                    v-for="(item,index) in classification" :key="index" @click="categary(index)"
                    :class="{hoveractive:categaryIndex==index}">
                    {{item.categary_name}}<span style="margin-left: 5px;">({{item.number}})</span>
                  </li>

                </a>
              </div>
            </div>

            <div class="CostShopList" v-if="classification.length>0">
              <!-- {{classification[categaryIndex].foodlists}} -->
              <div v-for="(item,index) in classification[categaryIndex].foodlists" :key="index"
                class="list-content back">
                <el-row :gutter="0" class="table-header">
                  <el-col :span="8" style="text-align: left;padding-left: 20px;">商品</el-col>
                  <el-col :span="2">规格</el-col>
                  <el-col :span="2" style="text-align: right;">售价</el-col>
                  <el-col :span="4">菜品成本</el-col>
                  <el-col :span="4">餐盒成本</el-col>
                  <el-col :span="4">餐具成本</el-col>
                </el-row>
                <el-row :gutter="0" class="shop-content">
                  <el-col :span="8" class="content-name">
                    <div class="img-item">
                      <div class="img-tp block">
                        <el-image :src="item.picture">
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                      </div>
                      <div class="img-title-item" v-if="item.is_sold_out === 1">
                        <span class="img-title">已下架</span>
                      </div>
                    </div>
                    <div class="shopname">
                      <!-- {{item.name}} -->
                      {{item.name}}
                      <li
                        style="height: 24px;background: #F3F5F8;opacity: 1;border-radius: 2px;padding: 0 5px;line-height: 24px;    width: fit-content;"
                        v-if="item.is_sold_out === 1">
                        <span style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;">已下架</span>
                      </li>

                      <li
                        style="height: 24px;background: #F3F5F8;opacity: 1;border-radius: 2px;padding: 0 5px;line-height: 24px;    width: fit-content;"
                        v-else>
                        <span style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;">已上架</span>
                      </li>

                    </div>

                  </el-col>
                  <el-col :span="2">
                    <div class="demo-input-suffix"
                      v-for="(item1,index) in classification[categaryIndex].foodlists[index].skus" :key="index">
                      {{item1.spec}}
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="demo-input-suffix" style="text-align: right;"
                      v-for="(item1,index) in classification[categaryIndex].foodlists[index].skus" :key="index">
                      ￥{{item1.price}}
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix"
                      v-for="(item1,index) in classification[categaryIndex].foodlists[index].skus" :key="index">
                      <el-input v-model.number="item1.food_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix"
                      v-for="(item1,index) in classification[categaryIndex].foodlists[index].skus" :key="index">
                      <el-input v-model.number="item1.box_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix"
                      v-for="(item1,index) in classification[categaryIndex].foodlists[index].skus" :key="index">
                      <!-- <el-input v-model="item1.tableware_cost" placeholder="请输入内容"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input> -->
                      <el-input v-model.number="item1.tableware_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>

                    </div>
                  </el-col>

                </el-row>
              </div>
              <div v-if="classification[categaryIndex].foodlists.length==0" style="text-align: center;">
                <img src="../../assets/img/null.png" alt="">
                <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
              </div>
            </div>

          </el-tab-pane>
          <el-tab-pane label="已上架已设置" name="1">
            <div class="CostShopList" v-if="classification.length>0">
              <div v-for="(item,index) in shelves_not_set" :key="index" v-if="item.is_set===1&&item.is_sold_out===0"
                class="list-content">
                <el-row :gutter="0" class="table-header">
                  <el-col :span="8" style="text-align: left;padding-left: 20px;">商品</el-col>
                  <el-col :span="2">规格</el-col>
                  <el-col :span="2" style="text-align: right;">售价</el-col>
                  <el-col :span="4">菜品成本</el-col>
                  <el-col :span="4">餐盒成本 </el-col>
                  <el-col :span="4">餐具成本</el-col>
                </el-row>
                <el-row :gutter="0" class="shop-content">
                  <el-col :span="8" class="content-name">
                    <div class="img-item">
                      <div class="img-tp block">
                        <el-image :src="item.picture">
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                      </div>
                      <div class="img-title-item" v-if="item.is_sold_out === 1">
                        <span class="img-title">已下架</span>
                      </div>
                    </div>
                    <div class="shopname">
                      <!-- {{item.name}} -->
                      {{item.name}}
                      <li
                        style="height: 24px;background: #F3F5F8;opacity: 1;border-radius: 2px;padding: 0 5px;line-height: 24px;    width: fit-content;"
                        v-if="item.is_sold_out === 1">
                        <span style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;">已下架</span>
                      </li>

                      <li
                        style="height: 24px;background: #F3F5F8;opacity: 1;border-radius: 2px;padding: 0 5px;line-height: 24px;    width: fit-content;"
                        v-else>
                        <span style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;">已上架</span>
                      </li>

                    </div>

                  </el-col>
                  <el-col :span="2">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      {{item1.spec}}
                    </div>
                  </el-col>
                  <el-col :span="2" style="text-align: right;">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      ￥{{item1.price}}
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      <el-input v-model.number="item1.food_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">

                      <el-input v-model.number="item1.box_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>

                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      <el-input v-model.number="item1.tableware_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>
                    </div>
                  </el-col>

                </el-row>
              </div>
            </div>
            <div v-if="classification.length==0" style="text-align: center;">
              <img src="../../assets/img/null.png" alt="">
              <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
            </div>
          </el-tab-pane>

          <el-tab-pane label="未上架已设置" name="2">
            <div class="CostShopList" v-if="classification.length>0">
              <div v-for="(item,index) in shelves_not_set" :key="index" v-if="item.is_set===1&&item.is_sold_out===1"
                class="list-content">
                <el-row :gutter="0" class="table-header">
                  <el-col :span="8" style="text-align: left;padding-left: 20px;">商品</el-col>
                  <el-col :span="2">规格</el-col>
                  <el-col :span="2" style="text-align: right;">售价</el-col>
                  <el-col :span="4">菜品成本</el-col>
                  <el-col :span="4">餐盒成本 </el-col>
                  <el-col :span="4">餐具成本</el-col>
                </el-row>
                <el-row :gutter="0" class="shop-content">
                  <el-col :span="8" class="content-name">
                    <div class="img-item">
                      <div class="img-tp block">
                        <el-image :src="item.picture">
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                      </div>
                      <div class="img-title-item" v-if="item.is_sold_out === 1">
                        <span class="img-title">已下架</span>
                      </div>
                    </div>
                    <div class="shopname">
                      <!-- {{item.name}} -->
                      {{item.name}}
                      <li
                        style="height: 24px;background: #F3F5F8;opacity: 1;border-radius: 2px;padding: 0 5px;line-height: 24px;    width: fit-content;"
                        v-if="item.is_sold_out === 1">
                        <span style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;">已下架</span>
                      </li>

                      <li
                        style="height: 24px;background: #F3F5F8;opacity: 1;border-radius: 2px;padding: 0 5px;line-height: 24px;    width: fit-content;"
                        v-else>
                        <span style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;">已上架</span>
                      </li>

                    </div>

                  </el-col>
                  <el-col :span="2">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      {{item1.spec}}
                    </div>
                  </el-col>
                  <el-col :span="2" style="text-align: right;">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      ￥{{item1.price}}
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">

                      <el-input v-model.number="item1.food_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>

                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      <el-input v-model.number="item1.box_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      <!-- <el-input v-model="item1.tableware_cost" placeholder="请输入内容"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input> -->
                      <el-input v-model.number="item1.tableware_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div v-if="this.length==0" style="text-align: center;">
              <img src="../../assets/img/null.png" alt="">
              <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
            </div>
          </el-tab-pane>

          <el-tab-pane label="已上架未设置" name="3">
            <div class="CostShopList" v-if="classification.length>0">
              <div v-for="(item,index) in shelves_not_set" :key="index" v-if="item.is_set===0&&item.is_sold_out===0"
                class="list-content">
                <el-row :gutter="0" class="table-header">
                  <el-col :span="8" style="text-align: left;padding-left: 20px;">商品</el-col>
                  <el-col :span="2">规格</el-col>
                  <el-col :span="2" style="text-align: right;">售价</el-col>
                  <el-col :span="4">菜品成本</el-col>
                  <el-col :span="4">餐盒成本 </el-col>
                  <el-col :span="4">餐具成本</el-col>
                </el-row>
                <el-row :gutter="0" class="shop-content">
                  <el-col :span="8" class="content-name">
                    <div class="img-item">
                      <div class="img-tp block">
                        <el-image :src="item.picture">
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                      </div>
                      <div class="img-title-item" v-if="item.is_sold_out === 1">
                        <span class="img-title">已下架</span>
                      </div>
                    </div>
                    <div class="shopname">
                      <!-- {{item.name}} -->
                      {{item.name}}
                      <li
                        style="height: 24px;background: #F3F5F8;opacity: 1;border-radius: 2px;padding: 0 5px;line-height: 24px;    width: fit-content;"
                        v-if="item.is_sold_out === 1">
                        <span style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;">已下架</span>
                      </li>

                      <li
                        style="height: 24px;background: #F3F5F8;opacity: 1;border-radius: 2px;padding: 0 5px;line-height: 24px;    width: fit-content;"
                        v-else>
                        <span style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;">已上架</span>
                      </li>

                    </div>

                  </el-col>
                  <el-col :span="2">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      {{item1.spec}}
                    </div>
                  </el-col>
                  <el-col :span="2" style="text-align: right;">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      ￥{{item1.price}}
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      <el-input v-model.number="item1.food_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      <el-input v-model.number="item1.box_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="demo-input-suffix" v-for="(item1,index) in shelves_not_set[index].skus" :key="index">
                      <!-- <el-input v-model="item1.tableware_cost" placeholder="请输入内容"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input> -->
                      <el-input v-model.number="item1.tableware_cost" placeholder="请输入内容" size="small" type="number"
                        @change="foodprice(item.tag_name,item.name,item1.spec,item.mt_spu_id,item1.mt_sku_id,item1.food_cost,item1.box_cost,item1.tableware_cost)">
                      </el-input>
                    </div>
                  </el-col>

                </el-row>

              </div>
            </div>
            <div v-if="this.length==0" style="text-align: center;">
              <img src="../../assets/img/null.png" alt="">
              <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 历史订单成本对话框 -->
    <el-dialog :title="'同步'+this.name+'历史订单成本'" :visible.sync="boxDialogVisible" @close="boxDialogClosed" width="500px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="时间">
          <el-date-picker v-model="form.start_date" type="date" size="small" placeholder="选择起始日期">
          </el-date-picker>
          <el-date-picker v-model="form.end_date" type="date" size="small" placeholder="选择结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="同步方式">
          <el-radio-group v-model="form.radio" @change="radiotype(form.radio)">
            <el-radio :label="0">仅新增</el-radio>
            <el-radio :label="1">全覆盖</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div style="font-size: 10px;font-weight: 400;line-height: 18px;color: #A2A4B3;">
        提示：
        <li style="text-indent:30px;">1. 应用于某个时间范围内同步历史订单已设置的餐盒成本进行覆盖</li>
        <li style="text-indent:30px;">2. 应用于某个时间范围内同步历史订单未设置的餐盒成本进行新增</li>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="boxDialogVisible = false" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">
          取 消</el-button>
        <el-button @click="posthistory()" type="primary" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="'同步'+this.name+'历史订单成本'" :visible.sync="edithistory" @close="edithistoryClosed" width="500px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="时间" style="width: 200px;">
          <el-date-picker v-model="form.start_date" type="date" placeholder="选择起始日期" size="small">
          </el-date-picker>
          <el-date-picker v-model="form.end_date" type="date" placeholder="选择结束日期" size="small">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="菜品规格">
          <el-select v-model="form.select" placeholder="请选择" size="small" @change="pushskuid(form.select)">
            <el-option v-for="(item,index) in skus" :label="item.spec" v-model="item.mt_sku_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="同步方式">
          <el-radio-group v-model="form.radio" @change="radiotype(form.radio)">
            <el-radio :label="0">仅新增</el-radio>
            <el-radio :label="1">全覆盖</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div style="font-size: 10px;font-weight: 400;line-height: 18px;color: #A2A4B3;">
        提示：
        <li style="text-indent:30px;">1. 应用于某个时间范围内同步历史订单已设置的餐盒成本进行覆盖</li>
        <li style="text-indent:30px;">2. 应用于某个时间范围内同步历史订单未设置的餐盒成本进行新增</li>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="edithistory = false" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">取 消
        </el-button>
        <el-button @click="posthistory()" type="primary" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        value: '',
        loading: false,
        costform: [],
        activeName: '0',
        classification: [],
        shopcontent: [],
        categaryIndex: 0,
        input: '',
        gjvalue: '',
        boxDialogVisible: false,
        name: '测试',
        form: {
          mt_sku_id: '',
          start_date: '',
          end_date: '',
          select: '',
          radio: 0
        },
        type: '',
        edithistory: false,
        skus: [],
        mt_sku_id: '',
        mt_spu_id: '',
        tag_name: '',
        name: '',
        spec: '',
        food_cost: '',
        box_cost: '',
        tableware_cost: '',
        shelves_not_set: [],
        length: 0
      }
    },
    created() {
      this.loading = true
      this.getCostlist()
      this.getCostsetList()
    },
    methods: {
      async getCostsetList() {
        const {
          data: res
        } = await this.$http.get('/user/goods/shop_sys_cost_get?s_id=' + JSON.parse(sessionStorage.getItem('s_id')))
        if (res.code !== 1) {
          return this.$message.error(res.msg)
        }
        // console.log(res)

        // console.log(this.value)

        this.value = res.data.setting_enable + ''

        // console.log(this.value)

        this.costform = res.data

        this.loading = false
      },
      postcost() {

        // console.log(this.value)

        if (this.value === 1) {
          this.value === '1'
          this.get()
        } else {
          this.value === '0'
          this.get()
        }
      },
      async get() {
        const {
          data: res
        } = await this.$http.post('/user/goods/shop_sys_cost_set', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          box_cost: this.costform.box_cost,
          tableware_cost: this.costform.tableware_cost,
          food_cost: this.costform.food_cost,
          setting_enable: this.value
        })
        // console.log(res)
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)

      },
      handleClick(tabsId) {
        console.log(tabsId)
        this.length = ''
        for (var i in this.shelves_not_set) {
          // console.log(this.shelves_not_set[i].is_sold_out)
          if (tabsId == 1) {
            if (this.shelves_not_set[i].is_sold_out === 0 && this.shelves_not_set[i].is_set === 1) {
              this.length = this.shelves_not_set.length
            }
          }
          if (tabsId == 2) {
            if (this.shelves_not_set[i].is_sold_out === 1 && this.shelves_not_set[i].is_set === 1) {
              this.length = this.shelves_not_set.length
            }
          }
          if (tabsId == 3) {
            if (this.shelves_not_set[i].is_sold_out === 0 && this.shelves_not_set[i].is_set === 0) {
              this.length = this.shelves_not_set.length
            }
          }
          // console.log(this.shelves_not_set.is_sold_out,this.is_set)
        }

        // console.log(this.length)
      },
      async getCostlist() {
        const {
          data: res
        } = await this.$http.get('/user/goods/cost_lists?s_id=' + JSON.parse(sessionStorage.getItem('s_id')))

        this.classification = res.data.lists

        for (let i in this.classification) {

          for (let j in this.classification[i].foodlists) {
            this.shelves_not_set.push(this.classification[i].foodlists[j])
          }
        }

        console.log(this.classification)
        console.log(this.shelves_not_set)
      },
      async postsinglemenu() {
        const {
          data: res
        } = await this.$http.post('/user/goods/modify_cost_one', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          mt_spu_id: this.mt_spu_id,
          mt_sku_id: this.mt_sku_id,
          food_cost: this.food_cost,
          box_cost: this.box_cost,
          tableware_cost: this.tableware_cost,
          tag_name: this.tag_name,
          food_name: this.name,
          spec: this.spec
        })
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.getCostlist()
        this.shelves_not_set = []
      },
      categary(i) {
        this.categaryIndex = i
      },
      Refresh() {
        this.getCostlist()
      },
      boxprice() {
        this.get()
      },
      tablewareprice() {
        this.get()
      },
      foodpricepercent() {
        this.get()
      },
      foodprice(tag_name, name, spec, mt_spu_id, mt_sku_id, food_cost, box_cost, tableware_cost) {
        this.tag_name = tag_name
        this.name = name
        this.spec = spec
        this.mt_spu_id = mt_spu_id
        this.mt_sku_id = mt_sku_id
        this.food_cost = food_cost
        this.box_cost = box_cost
        this.tableware_cost = tableware_cost
        this.postsinglemenu()
      },
      namehistory(type) {
        if (type === 3) {
          this.name = '餐盒'
          this.type = type
        }
        if (type === 2) {
          this.name = '餐具'
          this.type = type
        }
        if (type === 1) {
          this.name = '菜品'
          this.type = type
        }
        this.boxDialogVisible = true
      },
      namehistory1(type, index) {
        if (type === 3) {
          this.name = '餐盒'
          this.type = type
        }
        if (type === 2) {
          this.name = '餐具'
          this.type = type
        }
        if (type === 1) {
          this.name = '菜品'
          this.type = type
        }

        this.skus = this.classification[this.categaryIndex].foodlists[index].skus

        this.edithistory = true
      },
      radiotype(radioid) {
        this.form.radio = radioid
      },
      async posthistory() {
        const {
          data: res
        } = await this.$http.post('/user/cost/mod_history', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          mt_sku_id: this.form.mt_sku_id,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
          type: this.type,
          is_update: this.form.radio
        })
        if (res.code !== 1) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.boxDialogVisible = false
      },
      boxDialogClosed() {},
      edithistoryClosed() {},
      pushskuid(sku_id) {
        this.form.mt_sku_id = sku_id
      }
    }
  }
</script>

<style scoped>
  .form-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    color: #56576A;
    margin-bottom: 10px;
  }

  .form-subtitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #A2A4B3;
    margin-top: 10px;
  }

  .el-tabs {
    height: 100%;
  }

  .classification {
    border: 1px solid #E9EAF2;
    border-radius: 2px;
    margin: auto;
    display: flex;
    margin-top: 20px;
  }

  .classification-content {
    width: 100%;
    padding: 15px;
  }

  .classification-content li {
    width: 25%;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #3F4156;
    line-height: 19px;
    float: left;
    margin: 5px 0px;
  }

  .CostShopList {
    margin: auto;
    margin-top: 20px;
  }

  .CostShopList-title {
    background-color: #F7F8FA;
  }

  .CostShopList-bottom {
    height: 60px;
    line-height: 60px;
    text-align: right;
    padding-right: 25px;
    border-top: 1px solid #E9EAF2;
  }

  .img-item {
    width: 150px;
    height: 100px;
    position: relative;
    float: left;
    margin-right: 10px;
  }

  .img-tp {
    width: 150px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #f5f7fa;
  }

  .img-tp img {
    width: 100%;
    height: 100%;
  }

  .img-title-item {
    background: #000;
    opacity: 0.6;
    color: #fff;
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    text-align: center;
  }

  .img-title {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
  }

  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    border-radius: 4px;
  }

  .bg-purple-dark {
    background: #99a9bf;
  }

  .bg-purple {
    background: #d3dce6;
  }

  .bg-purple-light {
    background: #e5e9f2;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .demo-input-suffix {
    margin: 5px;
  }

  .shop-content {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    text-align: center;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .hoveractive {
    color: #F89800 !important;
  }

  .table-header {
    margin-bottom: 0;
    height: 40px;
    line-height: 40px;
    background: #F7F8FA;
    text-align: center;
    font-size: 14px;
    color: #858692;
    border-bottom: 1px solid #E9EAF2;
  }

  /*  .back:hover {
    background-color: #F7F8FA;
  } */

  .table-header div {
    font-weight: bold;
  }

  .table-content {
    text-align: center;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .content-name {
    text-align: left;
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .shopname {
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    color: #3F4156;
  }

  .list-content {
    margin-bottom: 20px;
    border: 1px solid #E9EAF2;
    border-radius: 2px;
  }

  .Refresh {
    position: relative;
    top: 30px;
    left: 87%;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #3F4156;
    z-index: 1;
  }

  .input-tm {
    border: 1px;
    width: 100px;
    height: 28px;
    font-size: 12px;
    outline-color: #EEA128;
    text-align: center;
  }
</style>
