<template>
  <div v-loading="loading">
    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
      <span class="clearfix-title">当前店铺满减</span>
      <span class="clearfix-Subtitle" v-if="home==0">可根据凑单金额维度查看近30天订单菜品评估是否存在凑单问题</span>
      <span class="clearfix-Subtitle" v-if="home==1">{{this.reduce_type_name}}凑单订单信息</span>
    </div>

    <div class="assess-content" v-if="home==0">
      <div class="content-item" v-if="order_estimate!=''" v-for="(item,index) in order_estimate">
        <div class="content-header">
          <div class="content-title">{{item.remark}}</div>
          <div class="content-tag" @click="clickSee(item.remark)"><img src="../../assets/img/see.png" alt="">查看被凑单订单
          </div>
        </div>
        <div class="content">
          <div class="order-item">
            <div>
              <li class="order-title">店铺实收
                <el-tooltip popper-class="popoverBackB" effect="dark" content="统计时间内，包含所有费用的实际收入"
                  placement="top">
                  <img src="../../assets/img/wh.png" alt="">
                </el-tooltip>
              </li>
              <li class="order-money"><span>{{item.income}}</span>元</li>
            </div>
          </div>
          <div class="order-item">
            <div>
              <li class="order-title">有效订单
                <el-tooltip popper-class="popoverBackB" effect="dark"
                  content="统计时间内，已接单且未被取消（包含用户取消和商家取消）的订单数" placement="top">
                  <img src="../../assets/img/wh.png" alt="">
                </el-tooltip>
              </li>
              <li class="order-money"><span>{{item.order_num}}</span>单</li>
            </div>
          </div>
          <div class="order-item">
            <div>
              <li class="order-title">实收客单价
                <el-tooltip popper-class="popoverBackB" effect="dark" content="统计时间内，平均每单实际收取顾客的价格"
                  placement="top">
                  <img src="../../assets/img/wh.png" alt="">
                </el-tooltip>
              </li>
              <li class="order-money"><span>{{item.uni_order_price}}</span>元</li>
            </div>
          </div>
          <div class="order-item">
            <div>
              <li class="order-title">营销力度<el-tooltip popper-class="popoverBackB" effect="dark"
                  content="满减优惠总和÷顾客实付总和" placement="top">
                  <span class="icon iconfont" style="margin-left: 3px;">&#xe682;</span>
                </el-tooltip>
              </li>
              <li class="order-money"><span>{{item.marketing_efforts}}</span>%</li>
            </div>
          </div>
          <div class="order-item">
            <div>
              <li class="order-title">折后客单价<el-tooltip popper-class="popoverBackB" effect="dark"
                  content="（订单金额（订单原价）总和 - 满减优惠总和） ÷ 有效订单数" placement="top">
                  <span class="icon iconfont" style="margin-left: 3px;">&#xe682;</span>
                </el-tooltip>
              </li>
              <li class="order-money"><span>{{item.uni_discount_price}}</span>元</li>
            </div>
          </div>
          <div class="order-item">
            <div>
              <li class="order-title">订单占比<el-tooltip popper-class="popoverBackB" effect="dark"
                  content="统计时间内，全部已完成的订单中，使用此档满减活动的订单占比" placement="top">
                  <span class="icon iconfont" style="margin-left: 3px;">&#xe682;</span>
                </el-tooltip>
              </li>
              <li class="order-money"><span>{{item.order_percent}}</span>%</li>
            </div>
          </div>
        </div>
      </div>
      <div v-if="order_estimate==''" style="    text-align: center;">
        <img src="../../assets/img/null.png" alt="">
        <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
      </div>
    </div>

    <div v-if="home==1">
      <div class="assessment-header">
        <el-select v-model="value1" placeholder="请选择满减类型" size="small" style="width: 200px;margin-right: 10px;"
          @change="remarkChange">

          <el-option v-for="item in options1" :key="item.remark" :label="item.remark" :value="item.remark">
          </el-option>

        </el-select>
        <el-select v-model="value2" placeholder="按实收金额(从低到高)" size="small" style="width: 200px;margin-right: 10px;"
          @change="remarkChange">
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-select v-model="value3" placeholder="今日" size="small" style="width: 120px;margin-right: 10px;"
          @change="remarkChange">
          <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-date-picker style="margin-left: 20px;" size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
          v-model="datesvalue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          @change="customdateChange"

          :picker-options="pickerOptions"

          >
        </el-date-picker>



      </div>

      <div class="table">
        <div style="margin-bottom: 20px;border: 1px solid #E9EAF2;" v-for="(item,index) in reduce_order" :key="index">
          <el-row :gutter="0" class="table-header">
            <el-col :span="12" class="number" style="font-weight: 400;"><span
                class="weight900">{{item.ctime}}</span>订单号:{{item.order_id}}
            </el-col>
            <el-col :span="12">
              <li v-if="item.status==8" class="state"><img src="../../assets/img/state.png" alt=""><span>订单完成</span>
              </li>
              <li v-if="item.status==1" class="state"><span>用户已提交订单</span></li>
              <li v-if="item.status==2" class="state"><span>订单已推送</span></li>
              <li v-if="item.status==4" class="state"><span>商家已确认</span></li>
              <li v-if="item.status==6" class="state"><span>已配送</span></li>
              <li v-if="item.status==9" class="state"><span>已取消</span></li>
              <li v-if="item.status==7" class="state"><span>有退款</span></li>
              <li v-if="item.status==5" class="state"><span>全额退款</span></li>
            </el-col>
          </el-row>
          <el-row :gutter="0" style="height: 50px;line-height: 50px;">
            <el-col :span="20" class="table-title">商品信息<span>{{item.goods_type_num}}种商品 共{{item.goods_num}}件</span>
            </el-col>
          </el-row>
          <el-row :gutter="0" v-for="(item1,index) in item.result" :key="index"
            style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;height: 30px;line-height: 30px;">
            <el-col :span="20" style="text-align: left;padding-left: 30px;">{{item1.food_name}}</el-col>
            <el-col :span="2">
              ×{{item1.quantity}}
            </el-col>
            <el-col :span="2">
              ￥{{item1.price}}
            </el-col>
          </el-row>

          <div class="mar-l25 mar-b20" style="margin-top: 20px;">
            <div class="clearfix size12" style="max-width: 60%">
              <div style="padding: 6px 14px;border: solid 1px #F76C6C;color: #F76C6C"
                class="radius2 float-l mar-r10 mar-t5" v-for="(item1,index) in item.activity" :key="index">{{item1}}
              </div>
            </div>
          </div>

          <div class="table-price">
            <li class="price">凑单金额: <span>{{item.total}}</span>元</li>
            <li class="price">实付金额: <span>{{item.actual_pay}}</span>元</li>
            <li class="price">本单收入: <span>{{item.income}}</span>元</li>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        home: '0',
        order_estimate: [],
        reduce_order: [],
        options1: [],
        options2: [{
          value: '0',
          label: '按实收金额由大到小'
        }, {
          value: '1',
          label: '实收金额由小到大'
        }],
        options3: [{
          value: '0',
          label: '今日'
        }, {
          value: '1',
          label: '昨日'
        }, {
          value: '7',
          label: '最近7天'
        }, {
          value: '30',
          label: '最近30天'
        }],
        value1: '',
        value2: '',
        value3: '',
        datesvalue: '',
        reduce_type_name: '',
        startDate: '',
        endDate: '',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        loading:false
      }
    },
    methods: {
      // 满减列表
      async getOrderdata() {
        const {
          data: res
        } = await this.$http.get('/user/reduce/order_estimate?s_id=' + JSON.parse(sessionStorage.getItem('s_id')))
        console.log(res)
        this.order_estimate = res.data.list
        this.loading = false
      },
      // 满减分类
      async gettype() {
        const {
          data: res
        } = await this.$http.get('/user/reduce/reduce_type', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
          }
        })
        // console.log(res)
        this.options1 = res.data
        this.loading = false
      },
      // 订单列表
      async getreduce_order() {
        const {
          data: res
        } = await this.$http.get('/user/reduce/reduce_order', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
            reduce_type_name: this.reduce_type_name,
            sort_type: this.value2,
            date: this.value3,
            startDate: this.startDate,
            endDate: this.endDate
          }
        })
        this.reduce_order = res.data.lists
        this.loading = false
      },

      clickSee(name) {
        this.home = '1'
        this.value1 = name
        this.reduce_type_name = name
        this.gettype()
        this.getreduce_order()
      },
      remarkChange() {
        this.startDate = ''
        this.endDate = ''
        this.reduce_type_name= ''
        this.reduce_type_name = this.value1
        // console.log(this.reduce_type_name)
        this.getreduce_order()
      },
      customdateChange() {
        this.startDate = this.datesvalue[0]
        this.endDate = this.datesvalue[1]
        this.getreduce_order()
        // console.log(this.startDate, this.endDate)
      }
    },
    created() {
      this.loading = true
      this.getOrderdata()
    }
  }
</script>

<style scoped>
  .assess-content {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-item {
    width: 100%;
    height: 190px;
    border: 1px solid #E9EAF2;
    opacity: 1;
    border-radius: 2px;
    margin-bottom: 20px;
  }

  .content-header {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #F7F8FA;
  }

  .content-title {
    float: left;
    font-size: 20px;
    font-weight: bold;
    color: #3F4156;
    opacity: 1;
  }

  .content-tag {
    float: right;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
    display: flex;
    align-items: center;
    cursor: pointer
  }

  .content {
    height: 130px;
    display: flex;
    justify-content: space-around;
  }

  .order-item {
    align-items: center;
    width: 160px;
    display: flex;
    justify-content: center;
  }

  .order-item div {
    width: 100%;
    height: 50%;
    border-right: 1px solid #E9EAF2
  }

  .order-item:last-child div {
    border: none
  }

  .order-title {
    font-size: 14px;
    font-weight: 400;
    color: #858692;
    opacity: 1;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
        justify-content: center;
  }

  .order-money {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #3F4156;
    opacity: 1;
    text-align: center;
  }

  .order-money span {
    font-size: 24px;
  }

  .assessment-header {
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
  }

  .table {
    margin-top: 20px;
  }

  .table-header {
    height: 60px;
    line-height: 60px;
    background: #F7F8FA;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    color: #858692;
  }

  .table-header div {
    font-weight: bold;
  }

  .number {
    text-align: left;
    padding: 0 30px;
    font-size: 14px;
    font-weight: 400;
    color: #4A4A4A;
  }

  .number span {
    font-size: 14px;
    font-weight: bold;
    color: #3F4156;
    margin-right: 10px;
  }

  .state {
    font-size: 14px;
    font-weight: bold;
    color: #3F4156;
    text-align: right;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .table-title {
    text-align: left;
    padding-left: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #3F4156;
    opacity: 1;
  }

  .table-title span {
    font-size: 14px;
    font-weight: 400;
    color: #F89800;
    opacity: 1;
    padding-left: 1;
    padding-left: 10px;
  }

  .table-price {
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eeeeee;
  }

  .price {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
  }

  .price span {
    font-size: 24px;
    font-weight: 400;
    color: #3F4156;
  }
</style>
