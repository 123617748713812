<template>
<div>
  <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
    <span class="clearfix-title">更新日志</span>
  </div>
  <div class="page-body" >
    <div class="block" style="padding: 30px 0">
      <el-timeline>
        <div v-for="(item,index) in newsList" :key="index">
          <el-timeline-item :timestamp="item.create_time|formatDate" placement="top">
            <el-card  class="box-card">
              <div  v-html="item.post_content"></div>
            </el-card>
          </el-timeline-item>
        </div>
      </el-timeline>
    </div>
  </div>

</div>
</template>

<script>

import { formatDate } from '../assets/utils.js'

export default {
  name: 'ActivityChart',
  data () {
    return {
      newsList: [

      ]
    }
  },
  methods: {
    async getdata () {
      const {
        data: res
      } = await this.$http.get('/api/index/Articles')
      // console.log(res.data.articles)
      this.newsList = res.data.articles
      // console.log(this.newsList)
      // this.detaileddata
    }
  },
  mounted () {
    this.getdata()
  },
  filters: {
    formatDate (time) {
      time = time * 1000
      const date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd')
    }
  }
}
</script>

<style scoped>

</style>
