<template>

  <div style="
    height: 100%;
">
    <div class="header" style="">
      <div style="display: flex;align-items: center;position: absolute;top: 20px;left: 20px;">
        <div style="width: 62px;height: 62px;border-radius: 50%;position: relative;"
          :class="{viptxy:storeinfo.isVip==1,viptxn:storeinfo.isVip==0}">
          <img :src="storeinfo.pic_url" alt="" @click="toapzRouter"
            style="width: 100%;height: 100%;border-radius: 50%;z-index: 1;position: absolute;">
          <img v-if="storeinfo.isVip==1" src="../assets/img/index/vip-y.png" alt=""
            style="position: absolute;right: -6px;top: -6px;">
          <img v-if="storeinfo.isVip==0" src="../assets/img/index/vip-n.png" alt=""
            style="position: absolute;right: -6px;top: -6px;">
        </div>
        <div style="margin-left: 22px;">
          <div
            style="font-size: 20px;font-weight: bold;color: #3F4156;opacity: 1;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 280px;">
            {{storeinfo.s_name}}
          </div>
          <div
            style="height: 28px;font-size: 14px;font-weight: 400;color: #858692;opacity: 1;display: flex;align-items: center;margin-top: 9px;font-size: 14px;font-weight: 400;color: #858692;opacity: 1;">
            <div style="font-weight: bold;color: #3F4156;margin-right: 4px;">
              <span v-if="storeinfo.isVip==0">初级版</span><span v-if="storeinfo.isVip==1">高级版</span>
            </div>
            <div>
              <span style="margin-right: 4px;">有效期</span>
              {{storeinfo.vip_end_time}}
            </div>
            <div style="margin-left: 12px;">
                  <el-button type="primary" size="small" @click="xfRouter">续费 <i class="el-icon-arrow-right"></i></el-button>
                </div>
          </div>
        </div>
      </div>

      <div style="border-right: 1px solid #E9EAF2;height: 40px;position: absolute;left: 406px;margin-top: 28px;">
      </div>

      <div style="position: absolute;left: 437px;display: flex;align-items: center;margin-top: 20px;">
        <div style="margin-right: 34px;cursor: pointer;" @click="phonerouter()">
          <div style="font-size: 20px;font-weight: bold;color: #3F4156;opacity: 1;">
            <span v-if="storeinfo.isPhone==1">手机已绑定</span>
            <span v-if="storeinfo.isPhone!=1">手机号未绑定</span>
          </div>
          <div style="font-size: 12px;font-weight: 400;color: #858692;opacity: 1;margin-top: 9px;">避免错过重要消息<i
              style="margin-left: 4px;" class="el-icon-arrow-right"></i></div>
        </div>
        <div style="margin-right: 34px;cursor: pointer;" @click="wxrouter()">
          <div style="font-size: 20px;font-weight: bold;color: #3F4156;opacity: 1;">
            <span v-if="storeinfo.isWechat==1">微信已绑定</span>
            <span v-if="storeinfo.isWechat!=1">微信未绑定</span>
          </div>
          <div style="font-size: 12px;font-weight: 400;color: #858692;opacity: 1;margin-top: 9px;">及时接收重要信息<i
              style="margin-left: 4px;" class="el-icon-arrow-right"></i></div>
        </div>
        <div @click="dxrouter()" style="cursor: pointer;">
          <div style="font-size: 20px;font-weight: bold;color: #3F4156;opacity: 1;color: #F76C6C;">
            {{storeinfo.smsBalance}}条
          </div>
          <div style="font-size: 12px;font-weight: 400;color: #858692;opacity: 1;margin-top: 9px;">剩余短信<i
              style="margin-left: 4px;" class="el-icon-arrow-right"></i></div>
        </div>
      </div>

      <el-popover placement="bottom" trigger="hover">
        <div
          style="height: 150px;width: 150px;display: flex;align-items: center;justify-content: center;margin: auto;padding: 10px;">
          <img :src="storeinfo.kfImg" alt="" style="width: 100%;height: 100%;margin: auto;">
        </div>

        <div slot="reference" style="position: absolute;left: 808px;align-items: center;margin-top: 11px;">
          <div style="width: 50px;height: 50px;border-radius: 50%;margin: auto;">
            <img src="../assets/img/index/kf.png" alt="" style="width: 50px;height: 50px;border-radius: 50%;">
          </div>
          <div style="font-size: 14px;font-weight: 400;color: #3F4156;opacity: 1;">
            专属客服
          </div>
        </div>
      </el-popover>
      <div style="width: 330px;height: 100%;position: absolute;right: 0;top: 0px;right: 0px;">
        <div style="position: relative;">
          <img src="../assets/img/index/head-back.png" style="width: 100%;" alt="">
          <!-- <div
            style="position: absolute;font-size: 20px;font-weight: bold;color: #AB5700;opacity: 1;z-index: 1;top: 20px;left: 36px;">
            绑定微信和添加客服</br>
            各送100条短信
          </div> -->
        </div>
      </div>
    </div>
    <div style="display: flex;margin: auto;width: 1200px;margin-top: 10px;height: 85%;">

      <div style="width: 890px;height: 100%;">
        <div style="height: 40px;background: #FFF8E1;opacity: 1;position: relative;display: flex;align-items: center;">
          <img src="../assets/img/index/gb.png"
            style="width: 60px;height: 48px;position: absolute;top: -10px;left: 12px;" alt="">
          <div style="font-size: 14px;font-weight: 400;color: #FF7102;opacity: 1;margin-left: 79px;">
            电脑端功能陆续开放中如需体验更多功能请前往移动端</div>
        </div>

        <div style="margin-top: 10px;background: #FFFFFF;padding: 0 20px;height: 100%;">


          <div v-for="(item,index) in functiondata" :key="index" v-if="storeinfo.isVip==0">
            <div style="display: flex;align-items: center;height: 60px;">
              <div style="font-size: 20px;font-weight: bold;color: #3F4156;opacity: 1;">{{item.name}}</div>
              <div style="font-size: 14px;font-weight: 500;color: #858692;opacity: 1;margin-left: 4px;">
                {{item.description}}
              </div>
            </div>
            <!-- <div style="display: grid;grid-template-columns: 205px 205px 205px 205px;grid-column-gap: 10px;grid-row-gap: 10px;"> -->
            <el-row :gutter="24" style="width: 100%;">
              <el-col :span="6" v-for="(item1,index) in item.children" :key="index" :class="{Jmargintop:index<4}" style="margin-top:14px;width: 25%;float: left;">
                <div style="display: flex;align-items: center;height: 70px;cursor: pointer;"
                  @click="funRouter(item1.path,item.id,item1.is_vip)">
                  <div>
                    <img :src="item1.icon" alt="" style="width: 50px;height: 50px;">
                  </div>
                  <div style="margin-left: 5px;">
                    <div
                      style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;display: flex;align-items: center;">
                      {{item1.name}}
                      <div style="width: 26px;height: 17px;margin-left: 4px;" v-if="item1.is_vip==1"><img
                          src="../assets/img/index/menuvip.png" style="width: 26px;height: 17px;" alt=""></div>
                    </div>
                    <div
                      style="font-size: 12px;font-weight: 400;color: #858692;opacity: 1;margin-top: 4px;height: 17px;display: flex;align-items: center;">
                      {{item1.description}}
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <!-- </div> -->
          </div>

          <div v-for="(item,index) in functiondata" :key="index" v-if="storeinfo.isVip==1">
            <div style="display: flex;align-items: center;height: 60px;">
              <div style="font-size: 20px;font-weight: bold;color: #3F4156;opacity: 1;">{{item.name}}</div>
              <div style="font-size: 14px;font-weight: 500;color: #858692;opacity: 1;margin-left: 4px;">
                {{item.description}}
              </div>
            </div>
            <el-row :gutter="24" style="width: 100%;">
              <el-col :span="6" v-for="(item1,index) in item.children" :key="index" :class="{Jmargintop:index<4}" style="margin-top:14px;width: 25%;float: left;">
                <div style="display: flex;align-items: center;height: 70px;cursor: pointer;"
                  @click="funRouter(item1.path,item.id,item1.is_vip)">
                  <div>
                    <img :src="item1.icon" alt="" style="width: 50px;height: 50px;">
                  </div>
                  <div style="margin-left: 5px;">
                    <div
                      style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;display: flex;align-items: center;">
                      {{item1.name}}
<!--                      <div style="width: 26px;height: 17px;margin-left: 4px;" v-if="item1.is_vip==0"><img
                          src="../assets/img/index/menuvip.png" style="width: 26px;height: 17px;" alt=""></div> -->
                    </div>
                    <div
                      style="font-size: 12px;font-weight: 400;color: #858692;opacity: 1;margin-top: 4px;height: 17px;display: flex;align-items: center;">
                      {{item1.description}}
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>


        </div>
      </div>

      <div style="width: 300px;margin-left: 10px;height: max-content;">
        <div style="background-color: #FFFFFF;padding: 20px;">
          <div style="border: 1px solid #F5F6FA;">
            <div
              style="height: 46px;display: flex;align-items: center;background: #F5F6FA;font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;padding-left: 12px;">
              评论数据</div>
            <div style="height: 68px;display: flex;align-items: center;justify-content: space-around;">
              <div style="text-align: center;">
                <div>当前评分</div>
                <div style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;margin-top: 5px;"><span
                    style="font-weight: bold;">{{Commendata.now_score}}</span>分</div>
              </div>
              <div style="text-align: center;">
                <div>回复率</div>
                <div style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;margin-top: 5px;"><span
                    style="font-weight: bold;">{{Statedata.replyRate}}</span>%</div>
              </div>
              <div style="text-align: center;">
                <div>评价率</div>
                <div style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;margin-top: 5px;"><span
                    style="font-weight: bold;">{{Statedata.commentRate}}</span>%</div>
              </div>
            </div>
          </div>
          <div style="border: 1px solid #F5F6FA;margin-top: 10px;">
            <div
              style="height: 46px;display: flex;align-items: center;background: #F5F6FA;font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;padding-left: 12px;">
              营业数据</div>
            <div style="height: 68px;display: flex;align-items: center;justify-content: space-around;">
              <div style="text-align: center;">
                <div>今日成本</div>
                <div style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;margin-top: 5px;"><span
                    style="font-weight: bold;">{{orderdata.total_cost}}</span></div>
              </div>
              <div style="text-align: center;">
                <div>今日毛利</div>
                <div style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;margin-top: 5px;"><span
                    style="font-weight: bold;">{{orderdata.gross_profit}}</span></div>
              </div>
              <div style="text-align: center;">
                <div>今日收入</div>
                <div style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;margin-top: 5px;"><span
                    style="font-weight: bold;">{{orderdata.order_total_income}}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 76px;margin-top: 10px;">
          <img src="../assets/img/index/lbgg.png" alt="">
        </div>
      </div>
    </div>

    <el-dialog title="请选择店铺" :visible.sync="choiceDialogVisible" :show-close="false" width="500px">
      <div>
        {{ s_id }}
        <el-radio-group v-model="radio">
          <el-radio v-for="(item,index) in shopList" :label="item.id">{{item.user_name}}</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="choice" style="padding: 0 18px;height: 36px;font-size: 14px;">确 定
        </el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="isvipDialogVisible" :show-close="false" width="500px">
      <div>
        升级为高级版解锁全部权益！
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" style="padding: 0 18px;height: 36px;font-size: 14px;">我再想想
        </el-button>
        <el-button type="primary" @click="vippopup" style="padding: 0 18px;height: 36px;font-size: 14px;">前往升级
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import store from '@/store'

  export default {
    data() {
      return {
        choiceDialogVisible: false,
        isvipDialogVisible:false,
        shopList: [],
        radio: '',
        s_id: store.state.s_id,
        switchvalue: '1',
        functiondata: [],
        userinfolist: [],
        storeinfo: [],
        i: 0,
        Statedata: [],
        Commendata: [],
        orderdata: []
      }
    },
    created() {
      if (window.sessionStorage.getItem('type') == 1) {
        this.getqueryInfo()

      } else {
        if (window.sessionStorage.getItem('s_id') != '') {
          // 获取店铺列表
          this.getShoplist()
        }
      }
      // 获取功能列表
      this.getFunctionList()
    },
    methods: {
      cancel(){
        this.isvipDialogVisible = false
      },
      vippopup(){
        this.isvipDialogVisible = false
        window.parent.postMessage({
        	action: 'isvJumpToSalePage',
        }, '*');
      },
      toapzRouter() {
        this.i = ++this.i

        if (this.i == 3) {
          this.i = 0
          this.$message.success('即将跳转到测试环境')
          window.location.href = 'https://wpdemo.kkdou.vip/#/ad_login?code=' + sessionStorage.getItem('code');
        }
      },
      jsdsq() {
        // this.i = 0
        clearInterval(this.monitorBindIntervalId)
        console.log(this.i)
      },
      async getqueryState() {
        const {
          data: res
        } = await this.$http.get('/user/commentlist/queryState', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
          }
        })
        console.log(res)
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.Statedata = res.data
        console.log(this.Statedata)
      },
      async getCommen() {
        const {
          data: res
        } = await this.$http.get('/user/Commentlist/next_good_comment_num', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
          }
        })
        console.log(res)
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.Commendata = res.data
        console.log(this.Commendata)
      },
      async getorder() {
        const {
          data: res
        } = await this.$http.get('/user/order/order_gross_profit_total', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
            date: 0,
          }
        })
        console.log(res)
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.orderdata = res.data.now
        console.log(this.orderdata)
      },
      // 获取店铺列表
      async getShoplist() {
        const {
          data: res
        } = await this.$http.get('/user/shop/get_shop_list_data', {
          params: {
            shop_type: 2,
            token: 0,
            status: 0
          }
        })
        if(res.code!=1){
          return this.$message.error(res.msg)
        }
        this.shopList = res.data
        if (res.data.length > 1) {
          this.choiceDialogVisible = true
        } else {
          window.sessionStorage.setItem('s_id', JSON.stringify(res.data[0].id))
          this.$store.commit('increment', this.radio)
          this.choiceDialogVisible = false
          // 获取店铺信息
        }
        this.getqueryInfo()
      },
      choice() {
        if (this.radio != '') {
          window.sessionStorage.setItem('s_id', JSON.stringify(this.radio))
          this.choiceDialogVisible = false
          // 获取店铺信息
          this.getqueryInfo()
        } else {
          return this.$message.error('请选择店铺!')
        }
      },
      wxrouter() {
        this.$router.push({
          path: '/user/NotifyAccountManagement?type=1'
        })
      },
      phonerouter() {
        this.$router.push({
          path: '/user/NotifyAccountManagement?type=2'
        })
      },
      dxrouter() {
        this.$router.push({
          path: '/recharge/Message'
        })
      },
      xfRouter() {
        console.log(1)
        window.parent.postMessage({
        	action: 'isvJumpToSalePage',
        }, '*');
        console.log(2)
      },
      // 获取功能列表
      async getFunctionList() {
        const {
          data: res
        } = await this.$http.get('/user/authaction/menus')
        this.functiondata = res.data
        console.log(this.functiondata)
        this.getqueryState()
        this.getCommen()
        this.getorder()
      },

      async getqueryInfo() {
        const {
          data: res
        } = await this.$http.get('/user/shop/queryInfo', {
          params: {
            s_id: sessionStorage.getItem('s_id')
          }
        })
        this.storeinfo = res.data

        console.log(res.data)
      },
      // 点击切换全部功能
      switchall() {
        this.switchvalue = !this.switchvalue
      },
      // 点击功能页面跳转
      funRouter(url, i, is_vip) {
        // console.log(is_vip)
        if (this.storeinfo.isVip==0&&is_vip != 0) {
          return this.isvipDialogVisible = true
        }
        this.$router.push({
          path_id: i,
          path: url
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .homeBox {
    height: initial;
    background-color: #F7F8FA;
  }

  .dome-info {
    width: 100%;
    height: 333px;
    background-image: url(../assets/img/index-header.png);
    background-size: 100% 333px;
    margin-bottom: 10px;
    position: relative;
  }

  .touxiang {
    height: 96px;
    width: 96px;
    border-radius: 50%;
    display: block;
  }

  .user-info {
    display: flex;
    position: relative;
    top: 45px;
    margin-left: 100px;
  }

  .info {
    display: block;
    margin: auto 0;
    margin-left: 10px;
  }

  .info li {
    margin-bottom: 5px;
  }

  .into-name {
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
  }

  .info-tag {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.6;
  }

  .info-tag img {
    margin-right: 5px;
  }

  .el-col:nth-last-child(1) {
    // border-radius: 4px;
    margin-bottom: 0 !important;
  }

  // .el-col:nth-last-child(2) {
  //   // border-radius: 4px;
  //   margin-bottom: 0 !important;
  // }

  // .el-col:nth-last-child(3) {
  //   // border-radius: 4px;
  //   margin-bottom: 0 !important;
  // }

  // .el-col:nth-last-child(4) {
  //   // border-radius: 4px;
  //   margin-bottom: 0 !important;
  // }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    display: flex;
    text-align: center;
    padding-top: 51px;
    padding-left: 81px;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .info-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  .info-txt {
    margin-left: 10px;
  }

  .info-txt-title {
    font-size: 18px;
  }

  .info-txt-tag {
    font-size: 12px;
  }

  .text {
    font-size: 14px;
    padding: 12px;
  }

  .card-item-title {
    font-size: 14px;
  }

  .card-item-title {
    font-size: 14px;
  }

  .dome-ord {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .icon-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-img img {
    width: 100%;
    height: 100%;
  }

  .Nav-item {
    width: -webkit-fill-available;
    height: 120px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 2px;
    bottom: 0px;
    margin: auto;
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    margin: 24px;
  }

  .Nav-tag {
    margin-left: 10px;
  }

  .Nav-content {
    display: flex;
    align-items: center;
    cursor: pointer
  }

  .Nav-title {
    font-size: 18px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .Nav-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .ord-item {
    width: 180px;
    height: 154px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ord-text {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .number-text {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .number-text span {
    font-size: 38px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .ord-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #868692;
    opacity: 1;
  }

  .ord-subtitle span {
    color: #FFD161;
  }

  .dome-function {
    /*display: grid;
    grid-template-columns: 289px 289px 289px 289px;
    justify-content: center;*/
    width: 1154px;
    background-color: #FFFFFF;
    padding: 0px 24px;

  }

  .function-item {
    border-radius: 2px;
    position: relative;
    height: 180px;
    width: 287px;
    opacity: 1;
    border: 1px solid #E9EAF2;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
    margin-bottom: -1px;
    float: left;
  }

  .hover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .function-item:hover {
    border: 1px solid #E2B780 !important;
    border-radius: 2px;
    z-index: 99;
  }

  .vip {
    background-image: url(../assets/img/vip.png);
    background-position: right top;
    background-repeat: no-repeat;
  }

  .item-title {
    font-size: 14px;
    margin-top: 14px;
    font-weight: bold;
    color: #3F4156;
    opacity: 1;
  }

  .item-subtitle {
    margin-top: 3px;
    font-size: 12px;
    font-weight: 400;
    color: #868692;
    opacity: 1;
  }

  /* 新样式 */
  .header {
    width: 1200px;
    height: 95px;
    background-color: #FFFFFF;
    margin: auto;
    position: relative;
    display: flex;
    // align-items: center;

  }

  .viptxy {
    border: 1px solid #F5C164;
  }

  .viptxn {
    border: 1px solid #B9B9B9;
  }

  .el-row {
    margin: 0 !important;
  }
  .Jmargintop {
    margin-top: 0 !important;
  }
</style>
