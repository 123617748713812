<template>
  <div v-loading="loading">
    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
      <span class="clearfix-title">IM自动回复设置</span>
      <span class="clearfix-Subtitle">建立与顾客实时沟通场景，在不同场景均可在线沟通，有效维护客情，提升下单转化及降低差评。</span>
    </div>
    <!--个人设置展开页-->
    <div v-if="showKeywordSettings==true" style="padding: 0 20px;width: auto;margin-top: 20px;"
      :class="showKeywordSettings?'settings scrollbar settings-open':'settings scrollbar settings-close'">
      <p style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;"> 订单状态场景回复设置</p>
      <!--自动化回复选项-->
      <!--      <div class="label-text">
        <el-checkbox v-model="imSettings.overtimeConfig.status" @change="openOrCloseSettings(imSettings.overtimeConfig)"
          :true-label="1" :false-label="0"></el-checkbox>
        回复时间超过
        <el-select v-model="imSettings.overtimeConfig.overtime" placeholder="请选择"
          style="width: 60px;height: 32px;line-height: 32px;" size="mini"
          @change="keywordOrOvertimeChange(imSettings.overtimeConfig,false)">
          <el-option label="1" :value="1" />
          <el-option label="2" :value="2" />
          <el-option label="3" :value="3" />
          <el-option label="4" :value="4" />
          <el-option label="5" :value="5" />
        </el-select>
        分钟时自动回复的内容<img src="../../assets/img/XiaoLian.svg" width="18" height="18" class="mar-l10">
        <br />

        <el-input class="mar-t10" style="height: 32px;" size="small"
          :placeholder="'请输入回复时间超过'+imSettings.overtimeConfig.overtime+'分钟时回复的内容'"
          v-model="imSettings.overtimeConfig.content" @focus="oldSendContent = imSettings.overtimeConfig.content"
          @blur="sendContentChange(imSettings.overtimeConfig)" />
      </div>
 -->
      <div v-for="item in imSettings.orderList" class="mar-b20">
        <el-checkbox v-model="item.status" @change="openOrCloseSettings(item)" :true-label="1" :false-label="0">
          {{ item.statusStr }}时发送
        </el-checkbox>
        <br />
        <el-input class="mar-t10" style="height: 32px;" placeholder="请输入发送内容" v-model="item.content"
          @change="sendContentChange(item)" @focus="oldSendContent = item.content" size="small" />
      </div>

      <el-checkbox v-model="imSettings.is_keyword" :true-label="1" :false-label="0"
        @change="keywordOrOvertimeChange({status:imSettings.is_keyword},true)">
        当顾客消息中包含指定关键字时，自动回复的内容
      </el-checkbox>


      <br />
      <!--关键字回复列表条件-->
      <div class="mar-t20">
        <el-button type="primary" size="small" @click="openKeywordEdit">添加关键字回复</el-button>
        <div class="float-r">
          <span class="size15 color666">匹配方式：</span>
          <el-select placeholder="请选择" size="small" v-model="msgOption" @change="showAutoReplyKeyword(true)">
            <el-option v-for="item in [{value:0,label:'全部'},{value:2,label:'包含任意其一关键字'},{value:1,label:'必须包含所有关键字'}]"
              :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!--关键字回复列表-->
      <el-table class="mar-t20 radius2" v-loading="keywordListLoading" :stripe="true"
        :header-cell-style="{background:'#F7F8FA',color:'#666','font-weight': 'bold','font-size':'14px',}"
        :cell-style="{color:'#565A6E'}" :data="keywordList" style="width: 100%;border: solid 1px #ccc">
        <el-table-column type="index" label="序号" align="left">
        </el-table-column>
        <el-table-column label="关键字" prop="keyword" align="left">
        </el-table-column>
        <el-table-column label="匹配方式" prop="type" align="left">
          <template slot-scope="scope">
            <span>{{ scope.row.type === 1 ? '必须包含所有关键字' : '包含任意其一关键字' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="回复内容" prop="content" align="left">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <a @click="openKeywordEdit(scope.row)" class="cursor-pointer colorF89800">编辑</a>
            <el-divider direction="vertical"></el-divider>
            <a @click="delKeyword(scope.row)" style="color: #F76C6C;" class="cursor-pointer">删除</a>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination class="float-r mar-t35" @size-change="handleSizeChange" @current-change="poorCommentWarnPageChange"
        background :current-page="keywordPage" :page-size="keywordPageSize" layout="prev, pager, next"
        :total="keywordTotalCount"></el-pagination>

      <!--编辑关键字回复列表弹窗-->
      <el-dialog title="回复条件" :visible.sync="editKeywordBox" width="500px">
        <el-form :model="keywordFrom" label-width="90px">
          <el-form-item label="关键词：">
            <el-input autocomplete="off" size="small" style="width: 370px;" v-model="keywordFrom.keyword">
            </el-input>
            <div style="margin-top: 10px;display: flex;align-items: center;">
              <div @click="keywordFrom.keyword = item" v-for="(item,index) in used"
                style="cursor:  pointer;margin-right: 3px;height: 24px;background: #FFD161;opacity: 1;border-radius: 2px;float: left;line-height: 24px;padding: 0 10px;">
                {{item}}
              </div>
              <span style="font-size: 12px;font-weight: 400;color: #585A6E;opacity: 1;">常用关键字</span>
            </div>
          </el-form-item>
          <el-form-item label="匹配方式：">
            <el-select placeholder="请选择" size="small" v-model="keywordFrom.msgOption" style="width: 200px;float: left;">
              <el-option v-for="item in [{value:2,label:'包含任意其一关键字'},{value:1,label:'必须包含所有关键字'}]" :key="item.value"
                :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="回复内容：">
            <el-input type="textarea" autocomplete="off" rows="5" maxlength="300" size="small" show-word-limit
              style="width: 370px;" v-model="keywordFrom.content"></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="editKeywordBox = false" size="small" style="padding: 0 18px;
    height: 36px;
    font-size: 14px;">取 消</el-button>
          <el-button type="primary" @click="keywordEdit" size="small" style="padding: 0 18px;
    height: 36px;
    font-size: 14px;">保 存</el-button>
        </div>
      </el-dialog>
    </div>

    <!--会话详情弹窗-->
    <el-dialog title="会话记录" :visible.sync="showSessionLogDetail" width="500px">
      <div style="border: solid 1px #ccc;height: 400px;width: calc(100% - 40px);overflow: auto;"
        class="center-column pad20 scrollbar color666 mar-b30 size13">
        <div class="max-w center-column" v-for="item in currentSessionLog">
          <div style="background-color: #E1E1E1;padding: 5px 20px" class="radius5 size10 mar-b20">{{ item.add_time }}
          </div>
          <div class="max-w flex mar-b20" v-if="item.msg_type===2">
            <div class="center size10 white radius50" style="height: 40px;width: 40px;background-color: #AAA">
              顾客
            </div>
            <div class="msg-box msg-box-left mar-l10">{{ item.msg_content }}</div>
          </div>
          <div class="max-w flex mar-b20" style="justify-content: flex-end" v-if="item.msg_type===1">
            <div class="msg-box msg-box-right mar-r10">{{ item.msg_content }}</div>
            <div class="center size10 white radius50" style="height: 40px;width: 40px;background-color: #AAA;">
              商家
            </div>
          </div>
        </div>
      </div>

      <div style="width: calc(100% - 40px);position: absolute;bottom: -20px;left: 0;z-index: 1"
        class="mar-t10 box-shadow-aaa size15 pad20 back-white center-vertical">
        订单信息<img src="../../assets/img/waht.svg" style="height: 18px;width: 18px;" class="mar-l5"
          @mouseover="showOrderDetail=true" @mouseout="showOrderDetail=false" />
      </div>

      <div class="order-box white mar-t20" v-if="showOrderDetail">
        <div class="size15">订单信息</div>
        <div style="border: solid 1px #858692;" class="mar-t20">
          <div style="background-color: #858692;padding: 10px 20px;">
            订单编号：{{ orderDetail.orderId }}
            <div class="float-r center-vertical"><img src="../../assets/img/success.png" class="mar-r5"
                style="height: 18px;width: 18px;" />
              {{
                orderDetail.status === 2 ? '新订单' : orderDetail.status === 4 ? '已接单' : orderDetail.status === 8 ? '订单完成' : orderDetail.status === 9 ? '订单取消' : '未知'
              }}
            </div>
          </div>

          <div class="pad20">
            <div class="size15">商品信息 <span class="mar-l10 weight400"><span class="mar-l10">{{
                orderDetail.detailCount
              }}种商品</span><span class="mar-l10">共5件</span></span></div>

            <div class="mar-t20">
              <div class=" size10 mar-t10" style="height: 25px" v-for="item in orderDetail.detail">
                <div class="float-l show-only-one-line" style="max-width: 60%">
                  {{ item.food_name + (item.food_property ? '-（' + item.food_property + '）' : '') }}
                </div>
                <div class="float-r">¥{{ item.price }}</div>
                <div class="float-r mar-r10">x{{ item.quantity }}</div>
              </div>
            </div>

            <div class="mar-t20">
              <div class=" size10 mar-t10" style="height: 25px"
                v-for="item in orderDetail.poiReceiveDetail.actOrderChargeByPoi">
                <div class="float-l show-only-one-line" style="max-width: 80%">{{ item.comment }}</div>
                <div class="float-r">¥{{ item.moneyCent }}</div>
              </div>
            </div>
            <div class="size15 mar-t20">订单信息</div>
            <div>
              <div class=" size10 mar-t20" style="height: 25px">
                <div class="float-l">下单时间</div>
                <div class="float-r">{{ orderDetail.orderConfirmTime }}</div>
              </div>
            </div>

            <div style="border-top: solid 2px #858692;display: flex;justify-content: flex-end"
              class="pad10 center-vertical">
              <div class="size12">优惠信息<span style="color: #F76C6C;" class="mar-l5">¥{{
                  orderDetail.poiReceiveDetail.actOrderChargeByPoiSum
                }}</span></div>
              <div class="size12 mar-l10">实付金额<span
                  class="mar-l5 size15">¥{{ orderDetail.poiReceiveDetail.onlinePayment }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  export default {
    name: 'SessionLog',
    data() {
      return {
        used: [
          '你好',
          '在吗',
          '味道',
          '好吃'
        ],
        queryInfo: {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          type: 0,
          page: 1,
          limit: 10,
          count: 0
        },
        page: 1,
        pageSize: 10,
        totalCount: 0,
        keyword: undefined,
        msgSource: 0,
        dateRange: [],
        // tableData: [],
        loading: true,
        showKeywordSettings: true,
        switch1: false,
        msgOption: 0,
        sId: sessionStorage.getItem('s_id'),
        keywordList: [],
        keywordListLoading: true,
        keywordPage: 1,
        keywordPageSize: 5,
        keywordTotalCount: 0,
        autoReply: [0, 0, 0],
        showSessionLogDetail: false,
        showOrderDetail: false,
        currentSessionPage: 1,
        currentSessionPageSize: 10,
        currentSessionLog: [],
        orderDetail: {},
        imSettings: {
          overtimeConfig: {}
        },
        editKeywordBox: false,
        dateOption: 0,
        queryDate: '',
        keywordFrom: {
          id: undefined,
          msgOption: 2,
          keyword: undefined,
          content: undefined
        },
        oldSendContent: undefined,
        Number_one: [],
        Number_two: [],
        Number_three: [],
        Number_four: [],
        Number_five: [],
        Number_six: [],
        Number_seven: [],
        Number_eight: [],
        Number_nine: [],
        Number_ten: [],
        isShow: true,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        timeData: [new Date(), new Date()],
        cydata: []
      }
    },
    methods: {
      handleSizeChange(newSize) {
        this.queryInfo.limit = newSize
        this.getKeywordList()
        // console.log(newSize)
        // this.bottom()
      },
      poorCommentWarnPageChange(e) {
        // console.log(e)
        this.keywordPage = e
        this.getKeywordList()
      },
      selectDateChange(e) {
        if (e !== -1) {
          if (typeof e === 'number') {
            const end = new Date()
            if (e == 1 && e == 2) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [start, start]
            } else {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [start, end]
            }
            // this.gethot_tags_lists()
          }
        } else {
          this.$refs.daterange.focus()
        }
      },
      // async gethot_tags_lists() {
      //   const {
      //     data: res
      //   } = await this.$http.get('/user/Commenthot/hot_tags_lists', {
      //     params: {
      //       s_id: this.sId,
      //       type: 2,
      //       page: this.queryInfo.page,
      //       size: this.queryInfo.limit,
      //       startDate: this.startDate,
      //       endDate: this.endDate
      //     }
      //   })
      //   this.queryInfo.total = res.data.total
      //   this.tableData = res.data
      //   if (res) {

      //   }
      //   this.isShow = res
      // },
      showAutoReplyKeyword(next) {
        this.keywordListLoading = true
        if (next === true) {
          this.showKeywordSettings = true
        } else {
          this.showKeywordSettings = !this.showKeywordSettings
        }
        // if (this.showKeywordSettings)
        this.getKeywordList()
      },
      getKeywordList() {
        this.$api.get('/user/im/keyword_list', {
          s_id: this.sId,
          type: this.msgOption,
          page: this.keywordPage,
          limit: this.keywordPageSize
        }).then(res => {
          console.log(res)
          this.keywordTotalCount = res.total
          this.keywordList = res.data
          console.log(this.keywordTotalCount)
          this.keywordListLoading = false
          this.loading = false
        })
      },
      getIMSettings() {
        this.$api.get('/user/im/settings', {
          s_id: this.sId
        }).then(res => {
          if (!res.overtimeConfig.overtime) res.overtimeConfig.overtime = 1
          this.imSettings = res
          console.log(res)
        })
      },
      keywordListPageChange(e) {
        this.keywordPage = e
        this.showAutoReplyKeyword(true)
      },
      getSessionLogDetail(e) {
        this.currentSessionLog = []
        this.showSessionLogDetail = true
        this.$api.get('user/im/log_data', {
          page: this.currentSessionPage,
          limit: this.currentSessionPageSize,
          s_id: this.sId,
          order_id: e.order_id
        }).then(res => {
          this.currentSessionLog = res
        })
        this.getOrderDetail(e.order_id)
      },
      getOrderDetail(orderId) {
        this.$api.get('/user/order/info', {
          order_id: orderId
        }).then(res => {
          res.orderId = orderId
          res.quantity = 0
          for (const detail of res.detail) {
            res.quantity += detail.quantity
          }
          this.orderDetail = res
        })
      },
      openOrCloseSettings(e) {

        // console.log(e.status)

        if (!e.content) {
          e.status = false
          // console.log(e)
          this.$message({
            message: '请输入要回复的内容！',
            type: 'error'
          })
          return
        }
        if (e.status == 1) {
          this.$confirm('是否立即启用该功能?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            confirmButtonClass: 'btnFalse btnPadd',
            cancelButtonClass: 'btnPadd'
          }).then(() => {
            this.$api.post('/user/im/settings_edit', {
              s_id: this.sId,
              field: e.statusStr ? 'orderList' : 'overtimeConfig',
              order_status: e.order_status,
              event: 'status',
              value: e.status
            }, true).then(res => {
              this.getIMSettings()
              this.$message({
                message: res.msg,
                type: 'success'
              })
            })

          }).catch(() => {
            this.getIMSettings()
          });
        } else {
          this.$api.post('/user/im/settings_edit', {
            s_id: this.sId,
            field: e.statusStr ? 'orderList' : 'overtimeConfig',
            order_status: e.order_status,
            event: 'status',
            value: e.status
          }, true).then(res => {
            this.getIMSettings()
            this.$message({
              message: res.msg,
              type: 'success'
            })
          })

        }
      },
      sendContentChange(e) {

        if(e.content!=''){
          this.$confirm('本次修改将立即生效，是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            confirmButtonClass: 'btnFalse btnPadd',
            cancelButtonClass: 'btnPadd'
          }).then(() => {

            this.$api.post('/user/im/settings_edit', {
              s_id: this.sId,
              field: e.statusStr ? 'orderList' : 'overtimeConfig',
              order_status: e.order_status,
              event: 'content',
              value: e.content
            }, true).then(res => {
              this.getIMSettings()
              this.$message({
                message: res.msg,
                type: 'success'
              })
            })

          }).catch(() => {
            this.getIMSettings()
          });


        }else{
          this.$api.post('/user/im/settings_edit', {
            s_id: this.sId,
            field: e.statusStr ? 'orderList' : 'overtimeConfig',
            order_status: e.order_status,
            event: 'content',
            value: e.content
          }, true).then(res => {
            this.getIMSettings()
            this.$message({
              message: res.msg,
              type: 'success'
            })
          })

        }

        // if (this.oldSendContent !== e.content) {


        //   this.$confirm('是否立即启用该功能?', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     confirmButtonClass: 'btnFalse btnPadd',
        //     cancelButtonClass: 'btnPadd'
        //   }).then(() => {

        //     this.$api.post('/user/im/settings_edit', {
        //       s_id: this.sId,
        //       field: e.statusStr ? 'orderList' : 'overtimeConfig',
        //       order_status: e.order_status,
        //       event: 'content',
        //       value: e.content
        //     }, true).then(res => {
        //       this.getIMSettings()
        //       this.$message({
        //         message: res.msg,
        //         type: 'success'
        //       })
        //     })

        //   }).catch(() => {
        //     this.getIMSettings()
        //   });



        // }
      },
      keywordOrOvertimeChange(e, keyword) {
        console.log(e,keyword)
        if(e.status==1){
          this.$confirm('是否立即启用该功能?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            confirmButtonClass: 'btnFalse btnPadd',
            cancelButtonClass: 'btnPadd'
          }).then(() => {

            this.$api.post('/user/im/settings_edit', {
              s_id: this.sId,
              field: keyword ? 'keyword' : 'overtimeConfig',
              event: keyword ? 'status' : 'overtime',
              value: keyword ? e.status : e.overtime
            }, true).then(res => {

              if(res.code!=1){
                this.getIMSettings()
                return this.$message({
                  message: res.msg,
                  type: 'error'
                })
              }
              this.getIMSettings()
              this.$message({
                message: res.msg,
                type: 'success'
              })


              // this.getIMSettings()
              // this.$message({
              //   message: res.msg,
              //   type: 'success'
              // })
            })

          }).catch(() => {
            this.getIMSettings()
          });
        }else{
          this.$api.post('/user/im/settings_edit', {
            s_id: this.sId,
            field: keyword ? 'keyword' : 'overtimeConfig',
            event: keyword ? 'status' : 'overtime',
            value: keyword ? e.status : e.overtime
          }, true).then(res => {
            if(res.code!=1){
              this.getIMSettings()
              return this.$message({
                message: res.msg,
                type: 'error'
              })
            }
            this.getIMSettings()
            this.$message({
              message: res.msg,
              type: 'success'
            })
          })
        }




        // this.$api.post('/user/im/settings_edit', {
        //   s_id: this.sId,
        //   field: keyword ? 'keyword' : 'overtimeConfig',
        //   event: keyword ? 'status' : 'overtime',
        //   value: keyword ? e.status : e.overtime
        // }, true).then(res => {
        //   this.getIMSettings()
        //   this.$message({
        //     message: res.msg,
        //     type: 'success'
        //   })
        // })
      },
      openKeywordEdit(e) {
        this.keywordFrom = e.id ? {
          id: e.id,
          msgOption: e.type,
          keyword: e.keyword,
          content: e.content
        } : {
          msgOption: 2,
          keyword: undefined,
          content: undefined
        }
        this.editKeywordBox = true
      },
      keywordEdit() {
        this.$api.post('/user/im/keyword_edit', {
          id: this.keywordFrom.id,
          s_id: this.sId,
          type: this.keywordFrom.msgOption,
          keyword: this.keywordFrom.keyword,
          content: this.keywordFrom.content
        }, true).then(res => {
          this.editKeywordBox = false
          this.getKeywordList()
          this.$message({
            message: res.msg,
            type: 'success'
          })
        })
      },
      delKeyword(e) {
        this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'btnFalse btnPadd',
          cancelButtonClass: 'btnPadd'
        }).then(() => {
          this.$api.post('/user/im/keyword_delete', {
            id: e.id,
            s_id: this.sId
          }, true).then(res => {
            this.getKeywordList()
            this.$message({
              message: res.msg,
              type: 'success'
            }).el - radio - group
          })
        })
      },
    },
    created() {
      this.loading = true
      this.getIMSettings()
      // this.gethot_tags_lists()
      this.getKeywordList()
    }
  }
</script>

<style scoped>
  .order-code-hover:hover {
    color: #F89800;
  }

  .settings {
    margin-bottom: 10px;
    height: 0px;
    width: 100%;
    background-color: white;
  }

  .settings-open {
    animation: settings-open 0.5s;
    animation-fill-mode: forwards;
  }

  .settings-close {
    animation: settings-close 0.5s;
    animation-fill-mode: forwards;
  }

  @keyframes settings-open {
    0% {
      height: 0px;
    }

    100% {
      height: 700px;
    }
  }

  @keyframes settings-close {
    0% {
      height: 700px;
    }

    100% {
      height: 0px;
    }
  }

  .msg-box {
    position: relative;
    max-width: 320px;
    color: #373737;
    padding: 10px;
    background: #E7E7E7;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .msg-box-left::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 6px solid #E7E7E7;
    position: absolute;
    left: -6px;
    top: 10px;
  }

  .msg-box-right::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid #E7E7E7;
    position: absolute;
    right: -6px;
    top: 10px;
  }

  .order-box {
    padding: 20px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    background-color: #3F4156;
    width: 400px;
    z-index: 2;
  }

  .order-box::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-top: 6px solid #3F4156;
    position: absolute;
    bottom: -10px;
    left: 60px;
  }
</style>
