<template>
  <div style="height: 100%;">
    <div class="header-title">
      <span class="clearfix-title">菜品排序</span><span class="clearfix-Subtitle">根据销量由高到低排序</span>
      <div style="float: right; padding: 3px 0;color: #F89800;align-items: center;display: flex;">
        开启智能排序
        <el-switch style="margin-left: 10px;" inactive-value="0" active-value="1" v-model="switchvalue"
          active-color="#FFD161" @change="activeswitch" inactive-color="#858692">
        </el-switch>
      </div>
    </div>
    <el-collapse-transition>
      <div v-if="this.switchvalue == 1">
        <el-form label-width="150px">
          <el-form-item label="开启时间：">
            <el-time-select style=" width: 200px;" v-model="enable_time" :picker-options="{
                start: '00:30',
                step: '01:00',
                end: '23:30'
              }" placeholder="选择时间:" @change="istime(enable_time)" size="small">
            </el-time-select>
          </el-form-item>

          <el-form-item label="排序条件：">
            <el-select v-model="typevalue" placeholder="请选择" @change="istypevalue(typevalue)" size="small">
              <el-option v-for="item in type" :key="item.type" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="不参与调整分组：">
            <li style="font-size: 12px;font-weight: 400;color: #3F4156;">选择不参与调整排序的菜品(可多选)</li>
            <div style="display: grid;grid-template-columns: 150px 150px 150px;margin-left: 150px;">

              <el-checkbox v-for="(item,index) in not_sorts_names" v-model="item.selected" :label="item" :key="index"
                @change="checkboxChange(item.name,item.selected)">
                {{item.name}}
              </el-checkbox>

              <span @click="clicksorts()"
                style="font-size: 14px;font-weight: 400;color: #56576A;opacity: 1;margin-left: 20px;cursor:  pointer">
                <i class="el-icon-circle-plus-outline" style="color: #FFD161;"></i>添加分组
              </span>
            </div>
          </el-form-item>

          <el-form-item label="不参与调整商品：">
            <li style="font-size: 12px;font-weight: 400;color: #3F4156;">选择不参与调整排序的菜品(可多选)</li>
            <div style="margin-left: 150px;display: grid;grid-template-columns: 80px 80px 80px 80px 80px;">

              <div class="block" style="margin-left: 0;" v-for="(item,index) in mt_spu_ids" :key="index"
                @click="clickSpu(item.name,item.checkAll)">
                <div style="width: 36px;height: 36px;margin: auto;position: relative" class="mar-t10">
                  <img :src="circleUrl" class="max-w max-h radius50">
                  <div class="wechat">
                  </div>
                </div>
                <li style="font-size: 14px;font-weight: 400;color: #56576A;opacity: 1;">{{item.name}}</li>
              </div>
              <span @click="clickshop()"
                style="font-size: 14px;font-weight: 400;color: #56576A;opacity: 1;cursor:  pointer;margin: auto;">
                <i class="el-icon-circle-plus-outline" style="color: #FFD161;"></i>添加商品
              </span>
            </div>
          </el-form-item>


          <el-form-item style="margin-left: 150px;">
            <el-button type="primary" @click="onSubmit" size="small">保存并开启</el-button>
            <el-button @click="cancel" size="small">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-collapse-transition>

    <div
      style="height: 60px;line-height: 60px;padding-left: 20px;padding-right: 20px;border-bottom: 1px solid #EEEEEE;">
      <el-button type="primary" size="small">一键全部排序</el-button>
    </div>

    <div>
      <div class="view-left">
        <div class="view-left-header">
          <el-button size="small">销量从高到低排序</el-button>
        </div>
        <div class="view-left-title">
          <div class="view-left-item" :class="{active1:categaryIndex==index}" v-for="(item,index) in categarydata"
            @click="categary(index,item.name)" :key="index">
            <li>{{item.name}}</li>
          </div>
        </div>
      </div>
      <div class="view-right">
        <div class="view-right-header">
          <el-button size="small">销量从高到低排序</el-button>
        </div>
        <div class="view-right-table">
          <el-table :data="tableData" style="width: 100%;" :stripe="true">
            <el-table-column type="index" label="排名" align="center" width="100px">
            </el-table-column>
            <el-table-column label="菜品">
              <template slot-scope="scope">
                <div style="display: flex;align-items: center;">
                  <div style="width: 64px;height: 64px;">
                    <img :src="scope.row.picture" alt="" width="64px" height="64px">
                  </div>
                  <div style="margin-left: 10px;">
                    <li style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;">{{scope.row.name}}</li>
                    <li style="font-size: 14px;font-weight: 400;color: #A2A4B3;opacity: 1;"><span>销量
                        {{scope.row.sale_num}}</span><span>月售 {{scope.row.m_sale_num}}</span><span>库存
                        {{scope.row.stock}}</span></li>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="金额" align="center" width="100px">
              <template slot-scope="scope">
                ￥{{scope.row.price}}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
            :current-page="queryInfo.page" :page-size="queryInfo.limit" layout="prev, pager, next, total"
            :total="queryInfo.total" class="page"></el-pagination>
        </div>
      </div>
    </div>
    </el-tabs>

    <el-dialog title="分类选择" :visible.sync="sortsDialogVisible" @close="sortsDialogClosed" width="500px">
      <div style="display: flex;height: 300px;border-top: 1px solid #A2A4B3;">
        <div style="width: 150px;height: 100%;border-right: 1px solid #A2A4B3;" class="scroll-list">
          <el-checkbox style="height: 40px;line-height: 40px;" v-for="(item,index) in categarydata"
            v-model="item.checkList" :label="item" :key="index" @change="changecategary(item.name,item.checkList)">
            {{item.name}}
          </el-checkbox>
        </div>
        <div style="width: 340px;margin-top: 10px;" class="scroll-list">
          <div style="display: flex;align-items: center;width: 300px;margin-left: 20px;margin-bottom: 5px;"
            v-for="(item,index) in tableData" :key="index">
            <div style="width: 64px;height: 64px;">
              <img :src="item.picture" alt="" width="64px" height="64px">
            </div>
            <div style="margin-left: 10px;">
              <div style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;">{{item.name}}</div>
              <li style="font-size: 14px;font-weight: 400;color: #A2A4B3;opacity: 1;">
                <span>销量 {{item.sale_num}}</span>
              </li>
            </div>
          </div>

        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="sortsDialogVisible = false" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button @click="sevecheckbox" type="primary" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="商品选择" :visible.sync="shopDialogVisible" @close="shopDialogClosed" width="500px">
      <div style="display: flex;height: 300px;border-top: 1px solid #A2A4B3;">
        <div style="width: 150px;height: 100%;border-right: 1px solid #A2A4B3;" class="scroll-list">
          <div class="view-left-item" :class="{active1:categaryIndex==index}" v-for="(item,index) in categarydata"
            @click="categary(index,item.name)" :key="index">
            <li>{{item.name}}</li>
          </div>
        </div>
        <div style="width: 340px;margin-top: 10px;" class="scroll-list">

          <div style="display: flex;align-items: center;width: 300px;margin-left: 20px;margin-bottom: 5px;"
            v-for="(item,index) in tableData" :key="index">
            <el-checkbox style="margin-right: 10px;" v-model="item.checkAll"
              @change="changeshop(item.name,item.checkAll)"></el-checkbox>
            <div style="width: 64px;height: 64px;">
              <img :src="item.picture" alt="" width="64px" height="64px">
            </div>
            <div style="margin-left: 10px;">
              <div style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;">{{item.name}}</div>
              <li style="font-size: 14px;font-weight: 400;color: #A2A4B3;opacity: 1;">
                <span>销量 {{item.sale_num}}</span>
              </li>
            </div>
          </div>

        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="shopDialogVisible = false" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button @click="seveShpp" type="primary" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        switchvalue: '',
        isCollapse: false,
        pageTitle: '菜品毛利排行',
        datesvalue: '',
        checkList: false,
        selectvalue: '',
        typevalue: '',
        enable_time: '',
        value2: '',
        isSpu: false,
        not_sorts_names: [],
        not_sorts_name: [],
        mt_spu_ids: [],
        // 提交的商品id数组
        mt_spu_id: [],
        type: [{
          value: '1',
          label: '按照7天销量从高到低'
        }, {
          value: '2',
          label: '按照30天销量从高到低'
        }, {
          value: '3',
          label: '按照7天销量从低到高'
        }, {
          value: '4',
          label: '按照30天销量从低到高'
        }],
        sorts_names: '',
        postsorts_names: [],
        postmt_spu_ids: [],
        inputVisible: false,
        inputValue: '',
        activeName: 'first',
        circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
        squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
        sizeList: ["large"],
        categaryIndex: 0,
        categaryname: [],
        categarydata: [],
        tableData: [],
        queryInfo: {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          type: 0,
          page: 1,
          limit: 10,
          score: 0,
          status: 0,
          is_null: 0,
          total: 100
        },
        sortsDialogVisible: false,
        shopDialogVisible: false
      }
    },
    methods: {
      errorHandler() {
        return true
      },
      activeswitch() {
        if (this.switchvalue == '1') {

          var name = []
          var id = []
          for (var i in this.not_sorts_names) {
            name.push(this.not_sorts_names[i].name)
          }
          for (var i in this.mt_spu_ids) {
            id.push(this.mt_spu_ids[i].name)
          }
          this.not_sorts_name = name
          this.mt_spu_id = id
          // console.log(this.mt_spu_id)
          // console.log(this.not_sorts_name)
        } else {
          // console.log(0)
        }
        // console.log(this.switchvalue)
        this.getsorts_set()
      },
      onSubmit() {
        this.switchvalue = 0
        this.postsorts_set()
      },
      cancel() {
        this.switchvalue = 0
      },
      handleClose(tag) {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      },
      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },
      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          this.dynamicTags.push(inputValue);
        }
        this.inputVisible = false;
        this.inputValue = '';
      },
      // 分页功能
      handleSizeChange(newSize) {
        this.queryInfo.limit = newSize
      },
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage
      },
      checkboxChange(name, selected) {
        if (selected === true) {
          this.not_sorts_name.push(name)
        } else {
          this.not_sorts_name.pop(name)
        }

      },
      changecategary(name, selected) {
        if (selected === true) {
          this.not_sorts_name.push(name + "")
          this.not_sorts_names.push({
            name: name,
            selected: true
          })
        } else {
          this.not_sorts_name.pop(name - "")
        }

        var id = this.not_sorts_name
        const s = new Set();
        id.forEach(x => s.add(x));
        for (let i of s) {
          // console.log(i);
        }
        this.not_sorts_name = Array.from(s)
        // console.log(Array.from(s))
        // console.log(this.not_sorts_name)
      },

      changeshop(name, checkAll) {
        if (checkAll === true) {
          this.mt_spu_id.push(name)
        }
      },
      sevecheckbox() {
        this.postsorts_names.push(this.not_sorts_names.concat(this.not_sorts_name))
        // console.log(this.postsorts_names)
        this.sortsDialogVisible = false

        var arr = this.not_sorts_names
        var result = [];
        var obj = {};
        for (var i = 0; i < arr.length; i++) {
          if (!obj[arr[i].name]) {
            result.push(arr[i]);
            obj[arr[i].name] = true;
          }
        }
        this.postsorts_names = result
        this.not_sorts_names = result

      },
      seveShpp() {
        this.postmt_spu_ids.push(this.mt_spu_ids.concat(this.mt_spu_id))
        // console.log(this.postmt_spu_ids)
        this.shopDialogVisible = false

        var arr = this.mt_spu_ids
        var result = [];
        var obj = {};
        for (var i = 0; i < arr.length; i++) {
          if (!obj[arr[i].name]) {
            result.push(arr[i]);
            obj[arr[i].name] = true;
          }
        }
        this.postmt_spu_ids = result
        this.mt_spu_ids = result
      },
      clickSpu(name,checkAll) {
        if (checkAll === true) {
          this.postmt_spu_ids.push(name)
        } else {
          this.postmt_spu_ids.pop(name)
        }
        // console.log(this.postmt_spu_ids)
      },
      clicksorts() {
        this.sortsDialogVisible = true
      },
      clickshop() {
        this.shopDialogVisible = true
      },
      sortsDialogClosed() {},
      shopDialogClosed() {},
      async getdishSortsLists() {
        const {
          data: res
        } = await this.$http.get('/user/sorts/dishSortsLists', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
            type: 4,
          }
        })

        // console.log(res)
        if (this.categarydata == '') {
          for (var name in res.data.categary_lists) {
            this.categarydata.push({
              name: name,
              checkList: false
            })
          }
        }
        // console.log(this.categarydata)
        if (this.categaryname == '') {
          this.categaryname = this.categarydata[0].name
        }

        for (var i in res.data.categary_lists) {
          for (var j in res.data.categary_lists[i]) {
            res.data.categary_lists[i]. [j].checkAll = false
          }
        }
        // console.log(res.data.categary_lists)
        this.tableData = res.data.categary_lists[this.categaryname]

        res.data.categary_lists[this.categaryname][i]
        // console.log(this.tableData)
        // console.log(this.categarydata)
      },
      istime(enable_time) {
        this.enable_time = enable_time
      },
      istypevalue(typevalue) {
        this.typevalue = typevalue
      },
      deleshopcf() {
        const s = new Set();
        this.postmt_spu_ids[0].forEach(x => s.add(x));
        // Set结构不会添加重复的值
        for (let i of s) {
          // console.log(i);
        }
        this.postmt_spu_ids = Array.from(s)
      },
      deleshop1cf() {
        const s = new Set();
        this.mt_spu_ids.forEach(x => s.add(x));
        // Set结构不会添加重复的值
        for (let i of s) {
          // console.log(i);
        }
        this.mt_spu_ids = Array.from(s)
      },
      async getsorts_set() {
        const {
          data: res
        } = await this.$http.get('/user/sorts/get_sorts_set', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
          }
        })
        // console.log(res)
        this.typevalue = res.data.type + ''
        this.enable_time = res.data.enable_time + ''
        this.mt_spu_ids = []
        this.not_sorts_names = []

        for (var i in res.data.not_sorts_names) {
          this.not_sorts_names.push({
            selected: true,
            name: res.data.not_sorts_names[i]
          })
        }

        for (var i in res.data.mt_spu_ids) {
          this.mt_spu_ids.push({
            checkAll: true,
            name: res.data.mt_spu_ids[i]
          })
        }

        // console.log(this.mt_spu_ids,this.not_sorts_names)



        // this.form = res.data

      },
      async postsorts_set() {
        const {
          data: res
        } = await this.$http.post('/user/sorts/sorts_set', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          type: this.typevalue,
          enable_time: this.enable_time,
          not_sorts_names: this.not_sorts_name,
          mt_spu_ids: this.mt_spu_id
        })
        // console.log(res)
      },
      categary(index, name) {
        this.categaryIndex = index
        this.categaryname = name
        this.getdishSortsLists()
      },
    },
    created() {
      this.getdishSortsLists()
      this.getsorts_set()
    }
  }
</script>


<style scoped>
  .screen {
    height: 60px;
    line-height: 60px;
  }

  .el-input {
    width: 150px;
  }

  .table-header {
    height: 60px;
    line-height: 60px;
    background: #F7F8FA;
    font-weight: bold;
    font-size: 14px;
    color: #858692;
  }

  .el-tag+.el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

  .shop-img {
    margin-right: 20px;
  }

  .block {
    margin-left: 150px;
    width: 60px;
    text-align: center;
    line-height: 20px;
  }

  .view-left {
    width: 250px;
    height: 100%;
    float: left;
    border-right: 1px solid #E4E7ED;
  }

  .view-left-title {
    width: 100%;
  }

  .view-left-header {
    height: 60px;
    line-height: 60px;
    padding-left: 20px;
  }

  .view-left-item {
    height: 40px;
    line-height: 40px;
    width: 250px;
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
  }

  .view-left-item li {
    margin-left: 20px;
  }

  .active1 {
    background-color: #E9EAF2;
  }

  .view-right {
    width: 719px;
    height: 100%;
    float: right;
    margin-right: 20px;
  }

  .view-right-header {
    height: 60px;
    width: 100%;
    line-height: 60px;
    text-align: right;
    padding-right: 20px;
  }

  .view-right-table {
    margin-left: 20px;
  }



  .grid-left {
    width: 250px;
    height: 100%;
    border-right: 1px solid #A2A4B3;
    float: left;
    margin-top: 7px;
  }

  .grid-right {
    width: 730px;
    height: 100%;
    margin: auto;
    float: right;
  }

  .wechat {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    overflow: hidden;
    background-image: url(../../assets/img/wechat-selected.png);
    background-size: 100%;
  }

  .wechat img {
    height: 100%;
    width: 100%;
  }

  .scroll-list {
    word-break: break-all;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: inherit;
    padding: 0 0.1rem;
    margin-bottom: -.2rem;
    overflow: -moz-scrollbars-none;
    overflow: -moz-scrollbars-none;
  }

  .scroll-list::-webkit-scrollbar {
    display: none;
  }
</style>
