<template>
  <div>
    <div class="header-title"
      style="border-bottom: 10px solid #F7F8FA;display: flex;align-items: center;justify-content: space-between;">
      <div>
        <span v-if="!currentDishesId" class="clearfix-title">菜品毛利排行</span>
        <span v-else class="clearfix-title">{{shoptitle}}</span>
        <span v-if="!currentDishesId" class="clearfix-Subtitle">根据菜品成本计算时间范围内单个菜品的毛利。</span>
      </div>
      <el-button style="top: 0px;right: 30px" type="danger" size="small" v-if="currentDishesId" @click="isclick()">返回
      </el-button>
    </div>
    <div class="flex relative color3F4156 pad-l20 pad-r20" style="margin-top: 20px;">
      <el-radio-group v-model="dateOption" size="small" fill="#585A6E" text-color="#FFFFFF" @change="selectDateChange">
        <el-radio-button :label="0">今日</el-radio-button>
        <el-radio-button :label="1">昨日</el-radio-button>
        <el-radio-button :label="7">近7天</el-radio-button>
        <el-radio-button :label="30">近30天</el-radio-button>
        <el-radio-button :label="-1">自定义</el-radio-button>
      </el-radio-group>
      <el-date-picker v-if="dateOption===-1" class="mar-l10" style="height: 32px;width: 280px" type="daterange"
        align="right" unlink-panels v-model="queryDate" ref="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
        value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="selectDateChange">
      </el-date-picker>


    </div>

    <div class="pad20">
      <el-table :stripe="true" v-if="!currentDishesId" class="default-border radius2"
        :header-cell-style="{background:'#F7F8FA',color:'#666','font-weight': 'bold','font-size':'14px'}"
        :cell-style="{'font-size':'13px',color:'#565A6E'}" :data="grossPage.data" v-loading="loading">
        <el-table-column type="index" label="排名" width="50" :index="table_index" align="left"></el-table-column>
        <el-table-column label="菜品" align="left">
          <template slot-scope="scope">
            <div class="" style="font-weight: bold;">{{ scope.row.foodName }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="售价" width="150" align="right">
          <template slot-scope="scope">
            <span>￥{{scope.row.price}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="foodSalesVolume" label="销量" width="100" align="right">
        </el-table-column>
        <el-table-column label="销售额" width="120" align="right" :render-header="renderHeader1">
          <template slot-scope="scope">
            <span style="margin-right: 22px;">￥{{scope.row.foodSales}}</span>
          </template>
        </el-table-column>
        <el-table-column label="预估毛利" width="120" align="right" :render-header="renderHeader2">
          <template slot-scope="scope">
            <span style="margin-right: 22px;">￥{{scope.row.foodProfitForecast}}</span>
          </template>
        </el-table-column>
        <el-table-column label="毛利率" width="120" align="right" :render-header="renderHeader3">
          <template slot-scope="scope">
            <span style="margin-right: 22px;">{{scope.row.foodProfitRate}}%</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <span @click="detailPage.page=1;getDishesDetail(scope.row)" style="color: #F89800;">查看</span>
            <!-- <el-button type="primary"  size="small">查看</el-button> -->
          </template>
        </el-table-column>
      </el-table>
<div v-else>
      <div v-if="detailPage.data!=''" :class="['default-border',index==index?'mar-b20':'','radius2']"  v-for="(item,index) in detailPage.data">
        <div class="size14 default-back center-vertical pad-l20 color3F4156" style="height: 60px;">
          <span class="weight900">{{ item.order_time }}</span><span class="mar-l20" style="color: #4A4A4A">订单编号
            {{ item.order_id }}</span>
        </div>

        <div class="pad20">

          <div class="size14 mar-b10">
            <span class="weight900">商品信息</span><span class="colorF89800 mar-l20"> {{ item.good_type_num }}种商品
              <span>共{{ item.good_num }}件</span></span>
            <!--            <div class="float-r color3F4156">-->
            <!--              <span class="mar-r30">x9</span>-->
            <!--              <span class="mar-l30 mar-r5">¥{{ item.order_total }}</span>-->
            <!--            </div>-->
          </div>

          <div class="size14 mar-b10 color3F4156 clearfix" v-for="it in item.skus">
            <div class="show-only-one-line float-l size10" style="max-width: 60%">
              {{ it.food_name }}
            </div>
            <div class="mar-l30 float-r mar-r5">¥{{ it.sale_price }}</div>
            <div class="mar-r30 float-r">x{{ it.sale_num }}</div>
          </div>

          <div class="clearfix size12" style="max-width: 60%">
            <div style="padding: 6px 14px;border: solid 1px #F76C6C;color: #F76C6C" v-for="it in item.activity"
              class="radius2 float-l mar-r10 mar-t5">{{ it }}
            </div>
          </div>

          <hr class="mar-t20" />

          <div class="size14 mar-t20 center-vertical">
            <div class="center-vertical color999" style="width: 33.33%">本单收入 ：<span
                class="size20 weight900 color3F4156">{{ item.order_total }}</span>元
            </div>
            <div class="center color999" style="width: 33.33%">本单成本 ：<span
                class="size20 weight900 color3F4156">{{ item.total_cost }}</span>元
            </div>
            <div class="center-flex-end color999" style="width: 33.33%">本单毛利 ：<span
                class="size20 weight900 color3F4156">{{ item.gross_profit }}</span>元
            </div>
          </div>

        </div>
      </div>

      <div v-if="detailPage.data==''" style="text-align: center;">
        <img src="../../assets/img/null.png" alt="">
        <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
      </div>

</div>
    </div>


    <el-pagination background layout="prev, pager, next, total" :total="this.grossPage.total"
      :page-limit="currentDishesId?detailPage.limit:grossPage.limit"
      :current-page="currentDishesId?detailPage.page:grossPage.page" @current-change="pageChange"
      class="float-r mar-t15 pad-r20">
    </el-pagination>

  </div>
</template>

<script>
  import * as echarts from 'echarts'
  import whsrc from '../../assets/img/wh.png'

  export default {
    data() {
      return {
        sId: sessionStorage.getItem('s_id'),
        pageTitle: '菜品毛利排行',
        dateOption: 7,
        queryDate: [
          this.$dateFormat('YYYY-mm-dd', new Date()),
          this.$dateFormat('YYYY-mm-dd', new Date())
        ],
        grossPage: {
          page: 1,
          limit: 20,
          total: 0,
          data: []
        },
        detailPage: {
          page: 1,
          limit: 10,
          total: 0,
          data: []
        },
        currentDishesId: undefined,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        timeData: [new Date(), new Date()],
        loading: true,
        shoptitle: '',
        row:[]
      }
    },
    methods: {
      renderHeader1(h, {
        column,
        $index
      }) {
        // var src =
        // 这里在最外层插入一个div标签
        return h('div', [ // h即为cerateElement的简写
          // 在div里面插入span
          h('span', {
            // 表示内容
            domProps: {
              innerHTML: column.label
            },
            attrs: {
              style: 'margin-right:4px',
            },
          }),
          h('el-tooltip', {
              // 表示属性
              attrs: {
                effect: 'dark',
                content: '统计周期内有效订单的总计金额，包含菜品销售额、餐盒销售额、配送费收入，其中菜品销售额包含活动补贴',
                placement: 'top',
                'popper-class': 'popoverBackB'
              }
            },
            [h('img', {
              // 表示内容

              attrs: {
                src: whsrc,
              },

            }), ]
          )
        ])
      },
      renderHeader2(h, {
        column,
        $index
      }) {
        // var src =
        // 这里在最外层插入一个div标签
        return h('div', [ // h即为cerateElement的简写
          // 在div里面插入span
          h('span', {
            // 表示内容
            domProps: {
              innerHTML: column.label
            },
            attrs: {
              style: 'margin-right:4px',
            },
          }),
          h('el-tooltip', {
              // 表示属性
              attrs: {
                effect: 'dark',
                content: '在指定时间范围内的菜品毛利 ÷菜品实收金额',
                placement: 'top',
                'popper-class': 'popoverBackB'
              }
            },
            [h('img', {
              // 表示内容

              attrs: {
                src: whsrc,
              },

            }), ]
          )
        ])
      },
      renderHeader3(h, {
        column,
        $index
      }) {
        // var src =
        // 这里在最外层插入一个div标签
        return h('div', [ // h即为cerateElement的简写
          // 在div里面插入span
          h('span', {
            // 表示内容
            domProps: {
              innerHTML: column.label
            },
            attrs: {
              style: 'margin-right:4px',
            },
          }),
          h('el-tooltip', {
              // 表示属性
              attrs: {
                effect: 'dark',
                content: '在指定时间范围内，根据菜品成本及销量计算菜品毛利',
                placement: 'top',
                'popper-class': 'popoverBackB'
              }
            },
            [h('img', {
              // 表示内容
              attrs: {
                src: whsrc,
              },
            }), ]
          )
        ])
      },
      isclick() {
        this.currentDishesId = undefined
        this.getGrossprofitList()
      },
      table_index(index) {
        return (this.grossPage.page - 1) * this.grossPage.limit + index + 1
      },
      selectDateChange(e) {
        console.log(this.currentDishesId)
        // console.log(e)
        if (e == undefined) {
          e = 7
          var end = new Date();            var month = end.getMonth() + 1;            var month = month > 10 ? month : "0" + month;            end.setTime(end.getTime() - 23 * 60 * 60 * 1000);
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
          this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
          // console.log(this.queryDate)
        }
        if (e !== -1) {
          this.loading = true
          if (typeof e === 'number') {
            var end = new Date();            var month = end.getMonth() + 1;            var month = month > 10 ? month : "0" + month;            end.setTime(end.getTime() - 23 * 60 * 60 * 1000);
            if (e < 2) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', start)]
            } else {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
            }
          }
          this.getGrossprofitList()
          if(this.currentDishesId!=undefined){
            this.getDishesDetail(this.row)
          }
        } else {
          this.$refs.daterange.focus()
        }
      },
      pageChange(e) {
        if (this.currentDishesId) {
          this.detailPage.page = e
          this.getDishesDetail()
        } else {
          this.grossPage.page = e
          this.getGrossprofitList()
        }
      },
      async getGrossprofitList() {
        this.loading = true
        const {
          data: res
        } = await this.$http.get('/user/order/unit_gross_profit', {
          params: {
            s_id: this.sId,
            startDate: this.queryDate[0],
            endDate: this.queryDate[1],
            page: this.currentDishesId ? this.detailPage.page : this.grossPage.page,
            limit: this.currentDishesId ? this.detailPage.limit : this.grossPage.limit
          }
        })
        this.loading = false
        this.grossPage.total = res.count

        this.grossPage.data = res.data

        // console.log(res)
      },
      getDishesDetail(e) {
        // console.log(e)
        this.row = e
        this.shoptitle = e.foodName
        this.currentDishesId = e.mt_sku_id
        this.$api.get('/user/order/order_gross_profit', {
          s_id: this.sId,
          page: this.detailPage.page,
          limit: this.detailPage.limit,
          startDate: this.queryDate[0],
          endDate: this.queryDate[1],
          mt_sku_id: e.mt_sku_id
        }).then(res => {
          this.detailPage.data = res.lists
          this.grossPage.total = res.total
          console.log(res)
        })
      },
      chartPieOne() {
        const chartDom = document.getElementById('use-times')
        const myChart = echarts.init(chartDom)
        const option = {
          tooltip: {
            trigger: 'item'
          },
          title: {},
          legend: {
            top: '75%',
            data: this.pieOneData.pieOneData
          },
          toolbox: {
            show: true,
            feature: {
              mark: {
                show: true
              },
              dataView: {
                show: true,
                readOnly: false
              },
              restore: {
                show: true
              },
              saveAsImage: {
                show: true
              }
            }
          },
          series: [{
              name: '占比金额',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '14'
                }
              },
              labelLine: {
                show: false
              },
              center: ['25%', '40%'],
              data: this.pieOneData.pieOneDataReduceFee
            },
            {
              name: '占比次数',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '10',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              center: ['75%', '40%'],
              data: this.pieOneData.pieOneDataCount
            }
          ]
        }

        option && myChart.setOption(option)
      },
      chartPieTow() {
        const chartDom = document.getElementById('use-money')
        const myChart = echarts.init(chartDom)
        const option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '75%',
            left: 'center'
          },
          series: [{
            name: '活动金额',
            type: 'pie',
            radius: ['30%', '60%'],
            center: ['50%', '38%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '14'
              }
            },
            labelLine: {
              show: false
            },
            data: [{
                value: this.pieOneData.poiCharge,
                name: '商家承担'
              },
              {
                value: this.pieOneData.mtCharge,
                name: '美团承担'
              }
            ]
          }]
        }

        option && myChart.setOption(option)
      }
    },
    created() {
      this.selectDateChange()

    }
  }
</script>

<style scoped>
  .date-option {
    height: 32px;
    width: 70px;
  }
</style>
