<template>
  <div style="background-color: #F7F8FA;">

    <!-- 店铺列表 -->
    <!-- <el-dialog title="请选择店铺" :visible.sync="choiceDialogVisible" :show-close="false" :before-close="shophandleClose"
      width="500px">
      <div>
        {{ s_id }}
        <el-radio-group v-model="radio">
          <el-radio v-for="(item,index) in shopList" :label="item.id">{{item.user_name}}</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="choice">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
  import store from '@/store'

  export default {
    data() {
      return {
        // choiceDialogVisible: false,
        // shopList: [],
        // radio: '',
        // s_id: store.state.s_id

      }
    },
    created() {
      // this.getShoplist()
    },
    methods: {
      // // 获取店铺列表
      // async getShoplist() {
      //   const {
      //     data: res
      //   } = await this.$http.get('/user/shop/get_shop_list_data?shop_type=2')
      //   if (res.data.length > 0) {
      //     this.choiceDialogVisible = true
      //     if (res.data.length = 1) {
      //       console.log(res.data[0].id)
      //       // sessionStorage.setItem('s_id', JSON.stringify(res.data[0].id))
      //       this.radio = res.data[0].id
      //       this.choiceDialogVisible = false
      //       this.choice()
      //     }
      //   } else {
      //     this.choiceDialogVisible = false
      //   }
      //   // if(JSON.parse(sessionStorage.getItem('s_id')) === this.radio ){
      //   //   console.log('已存在')
      //   //   this.choiceDialogVisible = false
      //   // }
      //   // if(JSON.parse(sessionStorage.getItem('s_id')) === '' ){
      //   //   this.choiceDialogVisible = true
      //   // }
      //   this.shopList = res.data
      //   // console.log(this.shopList)
      // },
      // choice() {
      //   if (this.radio != '') {
      //     // console.log(this.s_id)
      //     sessionStorage.setItem('s_id', JSON.stringify(this.radio))
      //     this.$store.commit('increment', this.radio)
      //     // console.log(this.s_id)
      //     this.choiceDialogVisible = false
      //   } else {
      //     return this.$message.error('请选择店铺!')
      //   }
      // },
      // shophandleClose(done) {}
    }
  }
</script>

<style scoped>
  .dome-info {
    width: 100%;
    height: 333px;
    background-image: url(../assets/img/index-header.png);
    background-size: 100% 333px;
  }

  .touxiang {
    height: 72px;
    width: 72px;
    border-radius: 50%;
    display: block;
  }

  .user-info {
    display: flex;
    position: relative;
    top: 45px;
    margin-left: 100px;
  }

  .info {
    display: block;
    margin-top: 20px;
    margin-left: 10px;
  }

  .info li {
    margin-bottom: 5px;
  }

  .into-name {
    font-size: 24px;
  }

  .into-tag {
    font-size: 12px;
  }

  .el-col {
    border-radius: 4px;
  }


  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    display: flex;
    text-align: center;
    padding-top: 51px;
    padding-left: 81px;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .info-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  .info-txt {
    margin-left: 10px;
  }

  .info-txt-title {
    font-size: 18px;
  }

  .info-txt-tag {
    font-size: 12px;
  }

  .text {
    font-size: 14px;
    padding: 12px;
  }

  .card-item-title {
    font-size: 14px;
  }

  .card-item-title {
    font-size: 14px;
  }

  .dome-ord {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .icon-img {
    width: 50px;
    height: 50px;
    background: #CFE6FB;
    border-radius: 50%;
    opacity: 0.39;
    text-align: center;
    line-height: 50px;
  }

  .Nav-tag {
    margin-left: 10px;
  }

  .Nav-content {
    display: flex;
    align-items: center;
  }

  .Nav-title {
    font-size: 18px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .Nav-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .ord-item {
    width: 180px;
    height: 154px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ord-text {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .number-text {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .number-text span {
    font-size: 38px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .ord-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #868692;
    opacity: 1;
  }

  .ord-subtitle span {
    color: #FFD161;
  }
</style>
