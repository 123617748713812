<template>
  <div v-loading="loading">
    <div class="header-title" style="height: auto;padding: 0;">
      <span class="clearfix-title" style="padding-left: 20px;
    padding-right: 20px;">我想要预测的满减</span><span class="clearfix-Subtitle">根据近30天有效满减订单预测满减盈亏变化</span>
      <div style="float: right; padding: 3px 0;padding-left: 20px;
    padding-right: 20px;color: #3F4156;;align-items: center;display: flex;cursor:  pointer" @click="trueReduce()">
        修改满减<i class="el-icon-arrow-right"></i>
      </div>

      <div style="height: 45px;background: #FFFFFF;opacity: 1;border-top: 1px solid #E9EAF2;">
        <div v-for="(item,index) in Orderdata" :key="index" v-if="item.remark!='无满减活动'"
          style="float: left;height: 28px;border: 1px solid #F76C6C;opacity: 1;border-radius: 2px;display: flex;align-items: center;width: fit-content;padding: 0 8px;color: #F76C6C;margin: 10px 0px 10px 10px;">
          {{item.remark}}
        </div>
      </div>
    </div>

    <div
      style="padding-left: 20px;padding-right: 20px;height: 60px;line-height: 60px;border-bottom: 1px solid #E9EAF2;">
      <el-button type="primary" size="small" @click="StartTesting">开始检测</el-button>
    </div>

    <div style="padding-left: 20px;padding-right: 20px;">
      <div>
        <div class="content-tis">
          <span>近30天有{{order_total_num}}笔有效满减订单（不含折扣订单），最终预测结果替代参考。</span>

          <!-- <span>
            与当前店铺面见对比
            <el-switch style="margin-left: 10px;" inactive-value="0" active-value="1" v-model="switchvalue"
              active-color="#FFD161" inactive-color="#858692" @change="ChangeSwitch">
            </el-switch>
          </span> -->

        </div>

        <div class="grid-content">

          <div class="grid-item" style="background: #F1F9FF;">
            <div class="grid-text">
              <li>预测顾客实付</li>
              <li style="white-space:nowrap"><span class="grid-textspan">{{Totaldata.actual_pay}}</span>元</li>
              <li v-if="switchvalue==1">较原活动


                <span style="font-size: 14px;font-weight: bold;" class="colorF76C6C"
                  v-if="Totaldata.compare.actual_pay>=0">
                  <span v-if="Totaldata.compare.actual_pay>=0">+</span>{{Totaldata.compare.actual_pay}}元</span>

                <span style="font-size: 14px;font-weight: bold;" class="color63D29D"
                  v-if="Totaldata.compare.actual_pay<0">
                  {{Totaldata.compare.actual_pay}}元</span>


              </li>

            </div>
            <img style="width: 80px;height: 80px;margin-right: 20px;" src="../../assets/img/tag-pay.png" alt="">
          </div>

          <div class="grid-item" style="background: #FFEBEA;">
            <div class="grid-text">
              <li style="display: flex;align-items: center;line-height: 0;">预测营销力度
                <el-tooltip popper-class="popoverBackB" class="item" effect="dark" content="满减优惠总和÷顾客实付总和"
                  placement="top">
                  <img src="../../assets/img/wh.png" alt="" style="margin-left: 4px;">
                </el-tooltip>
              </li>
              <li><span class="grid-textspan">{{Totaldata.marketing_efforts}}</span>%</li>
              <li v-if="switchvalue==1">较原活动

                <span style="font-size: 14px;font-weight: bold;" class="colorF76C6C"
                  v-if="Totaldata.compare.marketing_efforts>=0">
                  <span
                    v-if="Totaldata.compare.marketing_efforts>=0">+</span>{{Totaldata.compare.marketing_efforts}}%</span>

                <span style="font-size: 14px;font-weight: bold;" class="color63D29D"
                  v-if="Totaldata.compare.marketing_efforts<0">
                  {{Totaldata.compare.marketing_efforts}}%</span>


              </li>
            </div>
            <img style="width: 80px;height: 80px;margin-right: 20px;" src="../../assets/img/tag-efforts.png" alt="">
          </div>

          <div class="grid-item" style="background: #FFF4EB;">
            <div class="grid-text">
              <li style="display: flex;align-items: center;line-height: 0;">折后客单价
                <el-tooltip popper-class="popoverBackB" class="item" effect="dark"
                  content="（订单金额（订单原价）总和 - 满减优惠总和） ÷ 有效订单数。" placement="top">
                  <img src="../../assets/img/wh.png" alt="" style="margin-left: 4px;">
                </el-tooltip>
              </li>
              <li><span class="grid-textspan">{{Totaldata.uni_discount_price}}</span>元</li>
              <li v-if="switchvalue==1">较原活动

                <span style="font-size: 14px;font-weight: bold;" class="colorF76C6C"
                  v-if="Totaldata.compare.uni_discount_price>=0">
                  <span
                    v-if="Totaldata.compare.uni_discount_price>=0">+</span>{{Totaldata.compare.uni_discount_price}}元</span>

                <span style="font-size: 14px;font-weight: bold;" class="color63D29D"
                  v-if="Totaldata.compare.uni_discount_price<0">
                  {{Totaldata.compare.uni_discount_price}}元</span>

              </li>


            </div>
            <img style="width: 80px;height: 80px;margin-right: 20px;" src="../../assets/img/tag-price.png" alt="">
          </div>
        </div>


      </div>

      <div class="result">
        <div class="assess-content" v-if="this.result==true">
          <div class="assess-tis">
            <li style="font-size: 18px;font-weight: bold;color: #3F4156;opacity: 1;">检测结果</li>
            <li style="font-size: 12px;font-weight: 400;color: #3F4156;">上次预测时间：{{atime}}
            </li>
          </div>

          <div class="content-item" v-loading="keywordListLoading" v-for="(item,index) in Orderdata" :key="index">
            <div class="content-header">
              <div style="display: flex;align-items:flex-end;">
                <div class="content-title">{{item.remark}}</div>
                <div class="content-tag">
                  <li v-if="item.compare.remark!=''&item.compare.remark!='无满减活动'">原{{item.compare.remark}}</li>
                  <li v-if="item.compare.remark=='无满减活动'">原{{item.compare.remark}}</li>
                </div>
              </div>
            </div>

            <div class="content">
              <div class="order-item">
                <div>
                  <li class="order-title">顾客实付
                    <el-tooltip popper-class="popoverBackB" class="item" effect="dark" content="统计时间内，包含所有费用的实际收入"
                      placement="top-start">
                      <img src="../../assets/img/wh.png" alt="" style="margin-left: 4px;">
                    </el-tooltip>
                  </li>
                  <li class="order-money"><span>{{item.actual_pay}}</span>元</li>
                  <li class="original" v-if="switchvalue==1">较原活动
                    <span v-if="item.compare.actual_pay>=0"><span>+</span>{{item.compare.actual_pay}}元</span>
                    <span v-if="item.compare.actual_pay<0" style="color: #63D29D;">{{item.compare.actual_pay}}元</span>
                  </li>
                </div>
              </div>
              <div class="order-item">
                <div>
                  <li class="order-title">有效订单
                    <el-tooltip popper-class="popoverBackB" class="item" effect="dark"
                      content="统计时间内，已接单且未被取消（包含用户取消和商家取消）的订单数" placement="top-start">
                      <img src="../../assets/img/wh.png" alt="" style="margin-left: 4px;">
                    </el-tooltip>
                  </li>
                  <li class="order-money"><span>{{item.order_num}}</span>单</li>
                  <li class="original" v-if="switchvalue==1">较原活动
                    <span v-if="item.compare.order_num>=0"><span>+</span>{{item.compare.order_num}}单</span>
                    <span v-if="item.compare.order_num<0" style="color: #63D29D;">{{item.compare.order_num}}单</span>
                  </li>
                </div>
              </div>
              <div class="order-item">
                <div>
                  <li class="order-title">实收客单价
                    <el-tooltip popper-class="popoverBackB" class="item" effect="dark" content="统计时间内，平均每单实际收取顾客的价格"
                      placement="top-start">
                      <img src="../../assets/img/wh.png" alt="" style="margin-left: 4px;">
                    </el-tooltip>
                  </li>
                  <li class="order-money"><span>{{item.uni_order_price}}</span>元</li>
                  <li class="original" v-if="switchvalue==1">较原活动
                    <span v-if="item.compare.uni_order_price>=0"><span>+</span>{{item.compare.uni_order_price}}元</span>
                    <span v-if="item.compare.uni_order_price<0"
                      style="color: #63D29D;">{{item.compare.uni_order_price}}元</span>
                  </li>
                </div>
              </div>
              <div class="order-item">
                <div>
                  <li class="order-title">营销力度
                    <el-tooltip popper-class="popoverBackB" class="item" effect="dark" content="满减优惠总和÷顾客实付总和"
                      placement="top-start">
                      <img src="../../assets/img/wh.png" alt="" style="margin-left: 4px;">
                    </el-tooltip>
                  </li>
                  <li class="order-money"><span>{{item.marketing_efforts}}</span>%</li>
                  <li class="original" v-if="switchvalue==1">较原活动
                    <span
                      v-if="item.compare.marketing_efforts>=0"><span>+</span>{{item.compare.marketing_efforts}}%</span>
                    <span v-if="item.compare.marketing_efforts<0"
                      style="color: #63D29D;">{{item.compare.marketing_efforts}}%</span>
                  </li>
                </div>
              </div>
              <div class="order-item">
                <div>
                  <li class="order-title">折后客单价
                    <el-tooltip popper-class="popoverBackB" class="item" effect="dark"
                      content="（订单金额（订单原价）总和 - 满减优惠总和） ÷ 有效订单数。" placement="top-start">
                      <img src="../../assets/img/wh.png" alt="" style="margin-left: 4px;">
                    </el-tooltip>
                  </li>
                  <li class="order-money"><span>{{item.uni_discount_price}}</span>元</li>
                  <li class="original" v-if="switchvalue==1">较原活动
                    <span
                      v-if="item.compare.uni_discount_price>=0"><span>+</span>{{item.compare.uni_discount_price}}元</span>
                    <span v-if="item.compare.uni_discount_price<0"
                      style="color: #63D29D;">{{item.compare.uni_discount_price}}元</span>
                  </li>
                </div>
              </div>
              <div class="order-item">
                <div>
                  <li class="order-title">订单占比
                    <el-tooltip popper-class="popoverBackB" class="item" effect="dark"
                      content="统计时间内，全部已完成的订单中，使用此档满减活动的订单占比" placement="top-start">
                      <img src="../../assets/img/wh.png" alt="" style="margin-left: 4px;">
                    </el-tooltip>
                  </li>
                  <li class="order-money"><span>{{item.order_percent}}</span>%</li>
                  <li class="original" v-if="switchvalue==1">较原活动
                    <span v-if="item.compare.order_percent>=0"><span>+</span>{{item.compare.order_percent}}%</span>
                    <span v-if="item.compare.order_percent<0"
                      style="color: #63D29D;">{{item.compare.order_percent}}%</span>
                  </li>
                </div>
              </div>
            </div>
          </div>

          <div v-if="Orderdata==''" style="text-align: center;">
            <img src="../../assets/img/null.png" alt="">
            <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
          </div>
        </div>

      </div>

    </div>
    <el-dialog title="修改满减活动" :visible.sync="editDialogVisible" @close="editDialogClosed()" width="500px">
      <el-form :model="dynamicValidateForm" ref="dynamicValidateFormRef" style="text-align: center;display: table;margin: auto;">
        <el-form-item label=" " v-for="(item,index) in Reducedata" :key="index">
          <li>
            满
            <el-input style="width: 100px" v-model.number="item.full" class="mar-rl15" size="small">
              <template slot="suffix">
                <span class="mar-r10">元</span>
              </template>
            </el-input>
            减
            <el-input style="width: 100px" v-model.number="item.reduce" class="mar-rl15" size="small">
              <template slot="suffix">
                <span class="mar-r10">元</span>
              </template>
            </el-input>
            <span style="cursor:  pointer" @click="deleteGear(item.id)">删除</span>
          </li>
        </el-form-item>

        <el-form-item label=" " v-for="(domain, index) in dynamicValidateForm.domains" :key="domain.key"
          v-if="Reducedata.length<'5'" :prop="'domains.' + index + '.value'">
          <li>
            满
            <el-input style="width: 100px" type="number" v-model.number="domain.full" class="mar-rl15" size="small">
              <template slot="suffix">
                <span class="mar-r10">元</span>
              </template>
            </el-input>
            减
            <el-input style="width: 100px" type="number" v-model.number="domain.reduce" class="mar-rl15" size="small">
              <template slot="suffix">
                <span class="mar-r10">元</span>
              </template>
            </el-input>
            <span @click.prevent="removeDomain(domain)"
              style="font-size: 14px;font-weight: 400;color: #56576A;opacity: 1;cursor:  pointer;">删除</span>
          </li>
        </el-form-item>
        <el-form-item>
          <li
            style="width: fit-content;font-size: 12px;font-weight: bold;color: #56576A;opacity: 1;display: flex;cursor:  pointer;align-items: center;"
            v-if="this.Reducedata.length + this.dynamicValidateForm.domains.length<5" @click="addDomain"><img
              src="../../assets/img/add.svg" style="width: 18px;height: 18px;margin-right: 4px;" alt="">
            添加一档满减<span style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;">（最多5个）</span>
          </li>
        </el-form-item>

      </el-form>



      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button @click="openDialog()" type="primary" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">开始检测</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        result: true,
        switchvalue: '0',
        editDialogVisible: false,
        Orderdata: [],
        Totaldata: [],
        Reducedata: [],
        domains: [],
        dynamicValidateForm: {
          domains: [{
            full: '',
            reduce: '',
          }],
          full: '',
          reduce: '',
        },
        atime: '',
        order_total_num: '',
        keywordListLoading: true,
      }
    },
    methods: {
      // 修改满减
      editDialogClosed() {
        // console.log()
      },
      StartTesting() {
        this.keywordListLoading = true
        this.result = true
        this.getOrderdata()
      },
      ChangeSwitch() {
        if (this.result == false) {
          this.switchvalue = 0
          this.$message.info('请检测后再进行对比!')
        }
        // console.log(this.switchvalue)
      },
      cancel() {
        this.editDialogVisible = false
        // console.log(this.dynamicValidateForm)
        // 如果点击取消放空
        this.dynamicValidateForm = {
          domains: [{
            full: '',
            reduce: '',
          }],
          full: '',
          reduce: ''
        }
        // console.log(this.dynamicValidateForm)

      },
      async getOrderdata() {
        const {
          data: res
        } = await this.$http.get('/user/reduce/order_forecast?s_id=' + JSON.parse(sessionStorage.getItem('s_id')))
        this.Orderdata = res.data.lists
        this.Totaldata = res.data.total
        this.atime = res.data.atime
        this.order_total_num = res.data.order_total_num
        console.log(res)
        this.switchvalue = 1
        // this.result == true
        this.keywordListLoading = false
        this.loading = false
      },
      openDialog() {
        this.editDialogVisible = false

        this.domains = this.dynamicValidateForm.domains
        var data = []
        for (let i in this.Reducedata) {
          if (!this.Reducedata[i].full || !this.Reducedata[i].reduce) continue;
          data.push(this.Reducedata[i])
        }







        for (let i in this.dynamicValidateForm.domains) {
          if (!this.dynamicValidateForm.domains[i].full || !this.dynamicValidateForm.domains[i].reduce) {
            this.dynamicValidateForm.domains.splice(i, 1)
          }
        }

        console.log(data)
        console.log(this.domains)
        console.log(this.domains. [0])

        if (this.domains.length != 0) {
          if (this.domains. [0].full != '' && this.domains. [0].reduce != '') {
            var domains1 = []
            domains1.push(this.domains. [0])
            this.domains = domains1
            console.log(this.domains)
          }
        } else {
          var domains = []
          domains.push(data)
          this.domains = domains. [0]
          console.log(this.domains)
        }

        console.log(this.domains)
        this.postOrderdata()
      },
      async postOrderdata() {
        const {
          data: res
        } = await this.$http.post('/user/reduce/updateReduce', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          reduces: this.domains
        })
        console.log(res)
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.keywordListLoading = true
        this.domains = []
        this.dynamicValidateForm.domains = []
        this.getOrderdata()
        this.getReduce()
      },
      trueReduce() {
        this.editDialogVisible = true
      },
      async getReduce() {
        const {
          data: res
        } = await this.$http.get('/user/reduce/getReduce?s_id=' + JSON.parse(sessionStorage.getItem('s_id')))
        this.Reducedata = res.data
        if (this.Reducedata != '') {
          this.result = true
          this.getOrderdata()
        }
        // console.log(this.Reducedata)

      },
      async addReduce() {
        const {
          data: res
        } = await this.$http.post('/user/reduce/updateReduce')
      },
      async deleteGear(id) {
        if (this.Reducedata.length > 1) {
          const {
            data: res
          } = await this.$http.post('/user/reduce/delReduce', {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
            id: id
          })
          if (res.code != 1) {
            this.$message.error(res.msg)
          }
          this.$message.success(res.msg)
          this.getReduce()
        } else {
          this.$message.error('必须保留一条数据!')
        }
      },
      removeDomain(item) {
        var index = this.dynamicValidateForm.domains.indexOf(item)
        if (index !== -1) {
          this.dynamicValidateForm.domains.splice(index, 1)
        }
      },
      addDomain() {
        var length = this.Reducedata.length + this.dynamicValidateForm.domains.length
        if (length < 5) {
          this.dynamicValidateForm.domains.push({});
        } else {
          this.$message.error('最多只可以添加五条数据!')
        }
      }
    },
    created() {
      this.loading = true
      this.getOrderdata()
      this.getReduce()
      // console.log(this.Reducedata)
    }
  }
</script>

<style scoped>
  .content-tis {
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .grid-content {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .grid-item {
    width: 300px;
    height: 155px;
    opacity: 1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: inherit;
  }

  .grid-text {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
    margin-left: 50px;
  }

  .grid-textspan {
    font-size: 38px;
  }

  .assess-content {
    margin-top: 40px;
  }

  .assess-tis {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .content-item {
    width: 100%;
    border: 1px solid #E9EAF2;
    opacity: 1;
    border-radius: 2px;
    margin-bottom: 20px;
  }

  .content-header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background: #F7F8FA;
  }

  .content-title {
    float: left;
    margin-left: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #3F4156;
    opacity: 1;
    padding: 0;
  }

  .content-tag {
    float: right;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
    text-decoration: line-through
  }

  .content {
    display: flex;
    justify-content: space-around;
    padding: 40px 0;
    align-items: baseline;
  }

  .order-item {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .order-title {
    font-size: 14px;
    font-weight: 400;
    color: #858692;
    opacity: 1;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  .order-money {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #3F4156;
    opacity: 1;
  }

  .order-money span {
    font-size: 24px;
  }

  .original {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
    margin-top: 5px;
  }

  .original span {
    color: #F76C6C;
  }


  .colorF76C6C {
    color: #F76C6C;
  }

  .color63D29D {
    color: #63D29D;
  }
</style>
