<template>
  <div v-loading="loading">
    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
      <span class="clearfix-title">订单毛利</span>
      <span class="clearfix-Subtitle">设置好各项成本能直观查看每个订单所产生的毛利</span>
    </div>
    <div class="color3F4156 pad-l20 pad-r20"
      style="padding: 20px;border-bottom: 1px solid #F7F8FA;display: flex;align-items: center;">

      <el-radio-group v-model="dateOption" size="small" fill="#585A6E" text-color="#FFFFFF" @change="selectDateChange">
        <el-radio-button :label="0">今日</el-radio-button>
        <el-radio-button :label="1">昨日</el-radio-button>
        <el-radio-button :label="7">近7天</el-radio-button>
        <el-radio-button :label="30">近30天</el-radio-button>
        <el-radio-button :label="-1">自定义</el-radio-button>
      </el-radio-group>

      <el-date-picker style="margin-left: 20px;" v-if="dateOption===-1" size="small" format="yyyy-MM-dd"
        value-format="yyyy-MM-dd" v-model="queryDate" type="daterange" range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期" ref="daterange" :picker-options="pickerOptions" @change="selectDateChange">
      </el-date-picker>
    </div>

    <div class="card pad-l20 pad-r20" v-loading="loading">
      <div class="card-item">
        <div style="padding: 20px;">
          <li class="card-title">总订单实收</li>
          <li class="card-money"><span>{{ grossProfitTotal.now.order_total_income }}</span>元</li>
          <li class="card-bottom"
            v-if="grossProfitTotal.now.order_total_income>grossProfitTotal.compare.order_total_income">
            {{ dateOption===0?'比前一天':dateOption===1?'比前一天':dateOption===7?'比前七天':dateOption===30?'比前三十天':'环比'}}上升<span
              class="decline">+{{ grossProfitTotal.change.order_total_income }}元</span>
          </li>
          <li class="card-bottom"
            v-if="grossProfitTotal.now.order_total_income<grossProfitTotal.compare.order_total_income">
            {{ dateOption===0?'比前一天':dateOption===1?'比前一天':dateOption===7?'比前七天':dateOption===30?'比前三十天':'环比'}}下降<span
              class="rise">{{ grossProfitTotal.change.order_total_income }}元</span>
          </li>
          <li class="card-bottom"
            v-if="grossProfitTotal.now.order_total_income===grossProfitTotal.compare.order_total_income">
            {{ dateOption===0?'比前一天':dateOption===1?'比前一天':dateOption===7?'比前七天':dateOption===30?'比前三十天':'环比'}}持平
          </li>
        </div>
      </div>

      <div class="card-item" style="margin-right: 10px;margin-left: 10px;">
        <div style="padding: 20px;">
          <li class="card-title" style="display: flex;align-items: center;">总订单成本
            <el-tooltip popper-class="popoverBackB" class="item" effect="dark" content="总订单成本=餐盒成本+餐具成本+菜品成本+包装袋成本+赠品成本"
              placement="top">
              <span style="height: 18px;"><img src="../../assets/img/wh.png" slot="reference"
                  style="margin-left: 4px;"></span>
            </el-tooltip>
          </li>
          <li class="card-money"><span>{{ grossProfitTotal.now.total_cost }}</span>元</li>
          <li class="card-bottom" v-if="grossProfitTotal.now.total_cost>grossProfitTotal.compare.total_cost">
            {{ dateOption===0?'比前一天':dateOption===1?'比前一天':dateOption===7?'比前七天':dateOption===30?'比前三十天':'环比'}}上升<span
              class="decline">+{{ grossProfitTotal.change.total_cost }}元</span>
          </li>
          <li class="card-bottom" v-if="grossProfitTotal.now.total_cost<grossProfitTotal.compare.total_cost">
            {{ dateOption===0?'比前一天':dateOption===1?'比前一天':dateOption===7?'比前七天':dateOption===30?'比前三十天':'环比'}}下降<span
              class="rise">{{ grossProfitTotal.change.total_cost }}元</span>
          </li>
          <li class="card-bottom" v-if="grossProfitTotal.now.total_cost===grossProfitTotal.compare.total_cost">
            {{ dateOption===0?'比前一天':dateOption===1?'比前一天':dateOption===7?'比前七天':dateOption===30?'比前三十天':'环比'}}持平
          </li>
        </div>
      </div>

      <div class="card-item">
        <div style="padding: 20px;">
          <li class="card-title">总订单毛利</li>
          <li class="card-money"><span>{{ grossProfitTotal.now.gross_profit }}</span>元</li>
          <li class="card-bottom" v-if="grossProfitTotal.now.gross_profit>grossProfitTotal.compare.gross_profit">
            {{ dateOption===0?'比前一天':dateOption===1?'比前一天':dateOption===7?'比前七天':dateOption===30?'比前三十天':'环比'}}上升<span
              class="decline">+{{ grossProfitTotal.change.gross_profit }}元</span>
          </li>
          <li class="card-bottom" v-if="grossProfitTotal.now.gross_profit<grossProfitTotal.compare.gross_profit">
            {{ dateOption===0?'比前一天':dateOption===1?'比前一天':dateOption===7?'比前七天':dateOption===30?'比前三十天':'环比'}}下降<span
              class="rise">{{ grossProfitTotal.change.gross_profit }}元</span>
          </li>
          <li class="card-bottom" v-if="grossProfitTotal.now.gross_profit===grossProfitTotal.compare.gross_profit">
            {{ dateOption===0?'比前一天':dateOption===1?'比前一天':dateOption===7?'比前七天':dateOption===30?'比前三十天':'环比'}}持平
          </li>
        </div>
      </div>
    </div>

    <div>

      <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px;"
        class="pad-l20 pad-r20">
        <p class="page-title size14 weight400">菜品成本汇总图</p>
        <span style="font-size: 14px;font-weight: 400;color: #A2A4B3;opacity: 1;cursor:  pointer"
          @click="allCostroute()">查看更多<i class="el-icon-arrow-right" style="margin-left: 5px;"></i></span>
      </div>

      <div class="radius2" v-loading="loading">
        <div class="page-body-item">
          <div class="page-body-item-content">
            <div class="page-body-chart-box">
              <el-row>
                <el-col :span="24">
                  <div id="allCostcheart" style="width: 100%;height: 320px;">

                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>


    <el-table style="width: 950px;margin: auto;" :stripe="true" class="default-border radius2"
      :header-cell-style="{background:'#F7F8FA',color:'#666','font-weight': 'bold','font-size':'14px'}"
      :cell-style="{'font-size':'13px',color:'#565A6E'}" :data="tableData" v-loading="loading">
      <el-table-column label="流水号" width="80" align="left">
        <template slot-scope="scope">
          #{{scope.row.daySeq}}
        </template>
      </el-table-column>
      <el-table-column label="订单号" align="left">
        <template slot-scope="scope">
          <el-tooltip popper-class="popoverBackB" placement="top" trigger="hover"
            :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }">
            <div slot="content" style="width: 540px;">
              <div style="height: 45px;line-height: 45px;border-bottom: 1px solid #EEEEEE;;">
                <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">订单编号：{{scope.row.order_id}}</li>
              </div>
              <div style="margin-top: 10px;">
                <li style="font-size: 20px;font-weight: bold;color: #FFFFFF;opacity: 1;">{{scope.row.recipientName}}
                </li>
                <li style="font-size: 12px;font-weight: 400;color: #BABCCC;opacity: 1;"><i
                    class="el-icon-info"></i>为保证服务体验，您在拨打或者接听隐私号电话时，可能会被录音</li>
                <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;"><span
                    style="font-size: 14px;font-weight: 400;color: #BABCCC;opacity: 1;">顾客地址</span>{{scope.row.recipient_address}}
                </li>
              </div>
              <div style="margin-top: 10px;">
                <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">备餐状态</li>
              </div>
              <div style="margin-top: 10px;">
                <div style="display: flex;justify-content: space-between;">
                  <div>
                    <span style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">商品信息</span>
                    <span
                      style="font-size: 12px;font-weight: 400;color: #FFFFFF;opacity: 1;">{{scope.row.good_type_num}}种商品，共{{scope.row.good_num}}件，本单预计收入￥{{scope.row.order_total}}</span>
                  </div>
                  <div>
                    <li @click="isshow=!isshow">展开商品信息 <i class="el-icon-arrow-down"></i></li>
                  </div>
                </div>
                <li style="font-size: 12px;font-weight: 400;color: #BABCCC;opacity: 1;">{{scope.row.order_time}} 下单</li>

                <div v-if="isshow==true">

                  <li v-for="(item,index) in scope.row.skus" :key="index"
                    style="font-size: 12px;font-weight: 400;color: #FFFFFF;opacity: 1;">{{item.food_name}} <span
                      style="font-size: 12px;font-weight: 400;color: #BABCCC;opacity: 1;margin-left: 10px;">￥{{item.price}}</span>
                  </li>

                  <div style="margin-top: 10px;">
                    <div v-for="(item,index) in scope.row.activity"
                      style="height: 28px;border: 1px solid #FFFFFF;opacity: 1;border-radius: 2px;float: left;line-height: 28px;padding: 0 10px;">
                      {{item}}
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div>{{ scope.row.order_id }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <!--      <el-table-column label="菜品售价" width="130" align="right">
        <template slot-scope="scope">
          <div>{{ scope.row.food_price }}</div>
        </template>
      </el-table-column>
      <el-table-column label="活动成本" width="130" align="right">
        <template slot-scope="scope">
          <div>{{ scope.row.activity_cost }}</div>
        </template>
      </el-table-column> -->
      <el-table-column label="本单收入" width="130" align="right">
        <template slot-scope="scope">
          <el-tooltip popper-class="popoverBackB" placement="bottom-start" trigger="hover">
            <div slot="content">
              <div style="display: flex;align-items: center;justify-content: center;">

                <div
                  style="width: 76px;height: 56px;border: 1px solid #FFFFFF;opacity: 1;border-radius: 2px;display: flex;align-items: center;justify-content: center;">
                  <div>
                    <li style="font-size: 20px;font-weight: 400;color: #FFFFFF;opacity: 1;">
                      {{ scope.row.order_total }}<span style="font-size: 12px;">元</span>
                    </li>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">本单收入</li>
                  </div>
                </div>
                =
                <div
                  style="width: 76px;height: 56px;border: 1px solid #FFFFFF;opacity: 1;border-radius: 2px;display: flex;align-items: center;justify-content: center;">
                  <div>
                    <li style="font-size: 20px;font-weight: 400;color: #FFFFFF;opacity: 1;">
                      {{scope.row.food_price}}<span style="font-size: 12px;">元</span>
                    </li>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">菜品售价</li>
                  </div>
                </div>
                -
                <div
                  style="width: 76px;height: 56px;border: 1px solid #FFFFFF;opacity: 1;border-radius: 2px;display: flex;align-items: center;justify-content: center;">
                  <div>
                    <li style="font-size: 20px;font-weight: 400;color: #FFFFFF;opacity: 1;">{{scope.row.bale_cost}}<span
                        style="font-size: 12px;">元</span></li>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">打包</li>
                  </div>
                </div>
                -
                <div
                  style="width: 76px;height: 56px;border: 1px solid #FFFFFF;opacity: 1;border-radius: 2px;display: flex;align-items: center;justify-content: center;">
                  <div>
                    <li style="font-size: 20px;font-weight: 400;color: #FFFFFF;opacity: 1;">
                      {{scope.row.logisticsFee}}<span style="font-size: 12px;">元</span>
                    </li>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">配送</li>
                  </div>
                </div>
                -
                <div
                  style="width: 76px;height: 56px;border: 1px solid #FFFFFF;opacity: 1;border-radius: 2px;display: flex;align-items: center;justify-content: center;">
                  <div>
                    <li style="font-size: 20px;font-weight: 400;color: #FFFFFF;opacity: 1;">
                      {{scope.row.activity_cost}}<span style="font-size: 12px;">元</span>
                    </li>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">活动成本</li>
                  </div>
                </div>
                -
                <div
                  style="width: 76px;height: 56px;border: 1px solid #FFFFFF;opacity: 1;border-radius: 2px;display: flex;align-items: center;justify-content: center;">
                  <div>
                    <li style="font-size: 20px;font-weight: 400;color: #FFFFFF;opacity: 1;">
                      {{scope.row.foodShareFeeChargeByPoi}}<span style="font-size: 12px;">元</span>
                    </li>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">平台抽成</li>
                  </div>
                </div>

              </div>
            </div>
            <div>￥{{ scope.row.order_total }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="本单成本" width="130" align="right">
        <template slot-scope="scope">
          <el-tooltip popper-class="popoverBackB" placement="bottom-start" trigger="hover">
            <div slot="content">
              <div style="display: flex;align-items: center;justify-content: center;">

                <div
                  style="width: 76px;height: 56px;border: 1px solid #FFFFFF;opacity: 1;border-radius: 2px;display: flex;align-items: center;justify-content: center;">
                  <div>
                    <li style="font-size: 20px;font-weight: 400;color: #FFFFFF;opacity: 1;">
                      {{scope.row.total_cost}}<span style="font-size: 12px;">元</span>
                    </li>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">本单成本</li>
                  </div>
                </div>
                =
                <div
                  style="width: 76px;height: 56px;border: 1px solid #FFFFFF;opacity: 1;border-radius: 2px;display: flex;align-items: center;justify-content: center;">
                  <div v-if="scope.row.box_cost!=''">
                    <li style="font-size: 20px;font-weight: 400;color: #FFFFFF;opacity: 1;">{{scope.row.box_cost}}<span
                        style="font-size: 12px;">元</span></li>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">餐盒成本</li>
                  </div>
                  <div v-else>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;" @click="food_costroute">未设置
                    </li>
                  </div>
                </div>
                -
                <div
                  style="width: 76px;height: 56px;border: 1px solid #FFFFFF;opacity: 1;border-radius: 2px;display: flex;align-items: center;justify-content: center;">
                  <div v-if="scope.row.tableware_cost!=''">
                    <li style="font-size: 20px;font-weight: 400;color: #FFFFFF;opacity: 1;">
                      {{scope.row.tableware_cost}}<span style="font-size: 12px;">元</span>
                    </li>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">餐具成本</li>
                  </div>
                  <div v-else>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;" @click="food_costroute">未设置
                    </li>
                  </div>
                </div>
                -
                <div
                  style="width: 76px;height: 56px;border: 1px solid #FFFFFF;opacity: 1;border-radius: 2px;display: flex;align-items: center;justify-content: center;">
                  <div v-if="scope.row.food_cost!=''">
                    <li style="font-size: 20px;font-weight: 400;color: #FFFFFF;opacity: 1;">{{scope.row.food_cost}}<span
                        style="font-size: 12px;">元</span></li>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;">菜品成本</li>
                  </div>
                  <div v-else>
                    <li style="font-size: 14px;font-weight: 400;color: #FFFFFF;opacity: 1;" @click="food_costroute">未设置
                    </li>
                  </div>
                </div>

              </div>
            </div>

            <div class=" show-edit">

              <div class="max-wh" v-if="scope.row.total_cost">￥{{ scope.row.total_cost }}
                <img @click="food_costroute()" src="../../assets/img/edit.svg" class="edit-icon">
              </div>

              <div @click="food_costroute()" class="center max-wh colorF3F4156 cursor-pointer" v-else>未设置</div>

            </div>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column label="包装袋成本" width="130" align="right">
        <template slot-scope="scope">
          <div class="center show-edit">
            <div class="max-wh" v-if="scope.row.pack_cost">￥{{ scope.row.pack_cost }}
              <img @click="packing_costroute()" src="../../assets/img/edit.svg" class="edit-icon">
            </div>
            <div @click="packing_costroute()" class="max-wh colorF3F4156 cursor-pointer" v-else>未包含</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="赠品成本" width="130" align="right">
        <template slot-scope="scope">
          <div class="center show-edit">
            <div class="max-wh" v-if="scope.row.gift_cost">￥{{ scope.row.gift_cost }}
              <img @click="giftroute()" src="../../assets/img/edit.svg" class="edit-icon">
            </div>
            <div @click="giftroute()" class=" max-wh colorF3F4156 cursor-pointer" v-else>未包含</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="本单毛利" width="130" align="right">
        <template slot-scope="scope">
          <div>￥{{ scope.row.gross_profit }}</div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
      :current-page="query.page" :page-size="query.size" layout="prev, pager, next, total" :total="query.total"
      class="page" v-if="query.total>0"></el-pagination>



    <el-dialog :show-close="false" :visible.sync="showGoToNewPageTip" width="400px">

      <div class="size20 color3F4156 center-vertical"><img src="../../assets/img/question.png"
          style="height: 35px;width: 35px;margin-right: 5px">是否确认跳转？</div>
      <div class="mar-t10 mar-l30 pad-lr5 size14 color56576A">是否确认跳转至{{routename}}页面？</div>

      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="showGoToNewPageTip = false">取消跳转</el-button>
        <el-button size="medium" type="primary" @click="routerurl">确认跳转</el-button>
      </span>

    </el-dialog>

    <el-dialog :title="costForm.title" width="500px" :visible.sync="costForm.dialogVisible">
      <el-form :model="costForm" class="cost-form" label-position="right" label-width="100px">
        <el-form-item label="成本算法：">
          <el-radio-group v-model="costForm.algorithm">
            <el-radio :label="0">系统预估算法</el-radio>
            <el-radio :label="1">精确核算法</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="规格：" v-if="costForm.algorithm===1">
          {{ costForm.title }}
        </el-form-item>
        <div style="height: 15px" v-if="costForm.algorithm===0"></div>
        <el-form-item label="餐盒成本：">
          <el-input v-model="costForm.lunchBoxCost" prefix-icon="el-icon-warning-outline" clearable autocomplete="off"
            style="width: 200px;" size="small" v-number-input.float="2" maxlength="10">
          </el-input><br>
          <div class="size12 cost-form-tip"><span v-if="costForm.algorithm===0">默认订单中每个菜品都包含一份餐盒成本</span></div>
        </el-form-item>
        <el-form-item label="餐具成本：">
          <el-input v-model="costForm.tablewareCost" prefix-icon="el-icon-warning-outline" clearable autocomplete="off"
            style="width: 200px;" size="small" v-number-input.float="2" maxlength="10">
          </el-input>
          <div class="size12 cost-form-tip"><span v-if="costForm.algorithm===0">70%的订单为1双餐具，20%的的订单为2双餐具，10%的的订单为3份
              餐具。</span></div>
        </el-form-item>
        <el-form-item label="菜品成本：">
          <el-input v-model="costForm.dishCost" prefix-icon="el-icon-warning" clearable autocomplete="off"
            style="width: 200px;" size="small" v-number-input.float="2" maxlength="10">
          </el-input>
          <div class="size12 cost-form-tip"><span v-if="costForm.algorithm===0">默认使用售价的百分比作为菜品成本</span></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="costForm.dialogVisible=false" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button type="primary" @click="saveCostSettings" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import * as echarts from 'echarts'
  export default {
    data() {
      return {
        isshow: false,
        routename: '',
        sId: sessionStorage.getItem('s_id'),
        loading: true,
        dateOption: 30,
        datesvalue: '',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        queryDate: [
          this.$dateFormat('YYYY-mm-dd', new Date()),
          this.$dateFormat('YYYY-mm-dd', new Date())
        ],
        query: {
          page: 1,
          size: 10,
          total: 0,
        },
        tableData: [],
        grossProfitTotal: {
          now: {},
          compare: {}
        },
        showGoToNewPageTip: false,
        costForm: {
          title: undefined,
          dialogVisible: false,
          algorithm: 0,
          lunchBoxCost: undefined,
          tablewareCost: undefined,
          dishCost: undefined
        },
        costFormRules: {
          lunchBoxCost: [{
            required: true,
            message: '请输入餐盒成本',
            trigger: 'blur'
          }],
          tablewareCost: [{
            required: true,
            message: '请输入餐具成本',
            trigger: 'blur'
          }],
          dishCost: [{
            required: true,
            message: '请输入菜品成本',
            trigger: 'blur'
          }],
          algorithm: [{
            required: true,
            message: '请选择成本算法',
            trigger: 'blur'
          }],

          box_costNum: '',
          food_costNum: '',
          tableware_costNum: '',

          box_costMoney: '',
          food_costMoney: '',
          tableware_costMoney: '',
        }
      }
    },
    methods: {
      allCostchearts(moneydata, numdata) {
        // console.log(moneydata,numdata)
        const chartDom = document.getElementById('allCostcheart')
        const myChart = echarts.init(chartDom)
        const backcolor = ['#F8B500', '#935FED', '#5F77ED', '#5FA6ED', '#92D154', '#54D175', '#54C5D1']
        const option = {
          tooltip: {
            trigger: 'item',
          },
          title: [{
              text: '成本数量占比',
              left: '19%',
            },
            {
              text: '成本金额占比',
              left: '69%',
            }
          ],
          legend: {
            top: '85%',
          },
          series: [{
              name: '数量',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '10',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              center: ['25%', '50%'],
              data: numdata,
              color: backcolor
            },
            {
              name: '金额',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '10',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              center: ['75%', '50%'],
              data: moneydata,
              color: backcolor
            }
          ]
        }

        option && myChart.setOption(option)


      },
      pageChange(e) {
        this.query.page = e
      },
      handleSizeChange(newSize) {
        this.query.limit = newSize
        this.getProfitList()
      },
      handleCurrentChange(newPage) {
        this.query.page = newPage
        this.getProfitList()
      },
      selectDateChange(e) {
        // console.log(e)
        if (e == undefined) {
          var end = new Date();            var month = end.getMonth() + 1;            var month = month > 10 ? month : "0" + month;            end.setTime(end.getTime() - 23 * 60 * 60 * 1000);
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
          // console.log(this.queryDate)
        }
        if (e !== -1) {
          this.loading = true
          if (typeof e === 'number') {
            var end = new Date();            var month = end.getMonth() + 1;            var month = month > 10 ? month : "0" + month;            end.setTime(end.getTime() - 23 * 60 * 60 * 1000);
            if (e < 2) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', start)]
            } else {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
            }
          }
          this.getcost_summary()
          this.orderGrossProfitTotal()
          this.getProfitList()
        } else {
          this.$refs.daterange.focus()
        }
      },
      async getcost_summary() {
        const {
          data: res
        } = await this.$http.post('/user/cost/items_cost_summary', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          startDate: this.queryDate[0],
          endDate: this.queryDate[1]
        })
        var moneydata = []
        var numdata = []

        for (var i in res.data) {
          moneydata.push({
              value: res.data[i].money,
              name: res.data[i].name
            }),
            numdata.push({
              value: res.data[i].num,
              name: res.data[i].name
            })
        }

        this.box_costNum = res.data.box_cost.num
        this.food_costNum = res.data.food_cost.num
        this.tableware_costNum = res.data.tableware_cost.num

        this.box_costMoney = res.data.box_cost.money
        this.food_costMoney = res.data.food_cost.money
        this.tableware_costMoney = res.data.tableware_cost.money
        console.log(this.box_costNum, this.food_costNum, this.tableware_costNum)
        console.log(this.box_costMoney, this.food_costMoney, this.tableware_costMoney)

        console.log(moneydata)
        console.log(numdata)


        this.allCostchearts(moneydata, numdata)
        // console.log(res)
      },
      async orderGrossProfitTotal() {
        const {
          data: res
        } = await this.$http.get('/user/order/order_gross_profit_total', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem(
              's_id')),
            startDate: this.queryDate[0],
            endDate: this.queryDate[1]
          }
        })

        res.data.now.total_cost = parseFloat(res.data.now.total_cost)
        res.data.now.activity_cost = parseFloat(res.data.now.activity_cost)
        res.data.now.order_total = parseFloat(res.data.now.order_total)
        res.data.now.gross_profit = parseFloat(res.data.now.gross_profit)
        res.data.compare.total_cost = parseFloat(res.data.compare.total_cost)
        res.data.compare.activity_cost = parseFloat(res.data.compare.activity_cost)
        res.data.compare.order_total = parseFloat(res.data.compare.order_total)
        res.data.compare.gross_profit = parseFloat(res.data.compare.gross_profit)

        const tootal = res.data.now.total_cost - res.data.compare.total_cost
        const activety = res.data.now.activity_cost - res.data.compare.activity_cost
        const order = res.data.now.order_total - res.data.compare.order_total
        const gross = res.data.now.gross_profit - res.data.compare.gross_profit

        res.data.now.total_cost_sum = (tootal < 0 ? tootal : '+' + tootal)
        res.data.now.activity_cost_sum = (activety < 0 ? activety : '+' + activety)
        res.data.now.order_total_sum = (order < 0 ? order : '+' + order)
        res.data.now.gross_profit_sum = (gross < 0 ? gross : '+' + gross)
        this.grossProfitTotal = res.data
      },
      async getProfitList() {
        this.loading = true
        const {
          data: res
        } = await this.$http.get('/user/order/order_gross_profit', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem(
              's_id')),
            page: this.query.page,
            size: this.query.size,
            startDate: this.queryDate[0],
            endDate: this.queryDate[1]
          }
        })
        this.query.total = res.data.total
        this.tableData = res.data.lists
        this.loading = false
        console.log(res)
      },

      async saveCostSettings() {
        const {
          data: res
        } = await this.$http.get(this.costForm.algorithm === 1 ? '/user/goods/modify_cost_one' :
          '/user/goods/shop_sys_cost_set', {
            params: {
              s_id: JSON.parse(sessionStorage.getItem(
                's_id')),
              mt_sku_id: this.costForm.mt_sku_id,
              food_cost: this.costForm.dishCost,
              box_cost: this.costForm.lunchBoxCost,
              tableware_cost: this.costForm.tablewareCost,
              food_name: this.costForm.title
            }
          })
        this.costForm.dialogVisible = false
        this.getProfitList()
      },
      allCostroute() {
        this.$router.push({
          path: '/order/allCost'
        })
      },
      food_costroute() {
        this.routename = '菜品成本'
        this.showGoToNewPageTip = true
      },
      packing_costroute() {
        this.routename = '包装袋成本'
        this.showGoToNewPageTip = true
      },
      giftroute() {
        this.routename = '赠品成本'
        this.showGoToNewPageTip = true
      },
      routerurl() {
        if (this.routename == '菜品成本') {
          this.$router.push({
            path: '/manage/food_cost'
          })
        }
        if (this.routename == '包装袋成本') {
          this.$router.push({
            path: '/manage/packing_cost'
          })
        }
        if (this.routename == '赠品成本') {
          this.$router.push({
            path: '/orders/gift'
          })
        }
      },
      routecost() {
        this.$router.push({
          path: '/manage/food_cost'
        })
      }
    },
    mounted() {
      this.selectDateChange()
    }
  }
</script>

<style>
  #activitySummary {
    height: 371px;
  }

  .date-option {
    height: 32px;
    width: 70px;
  }

  .screen {
    padding: 10px 0;
    display: flex;
    border-bottom: 1px solid #E9EAF2;
  }

  .button-time {
    margin-right: 20px;
    display: flex;
  }

  .button-item {
    width: 70px;
    height: 32px;
    line-height: 32px;
    color: #3F4156;
    text-align: center;
    border: 1px solid #E9EAF2;
    border-radius: 3px;
    font-size: 12px;
  }

  .active {
    color: #FFFFFF;
    background-color: #3F4156;
  }

  .card-item {
    border-radius: 4px;
    width: 33%;
    height: 130px;
    background: #FFF4EB;
  }

  .card {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .card-title {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
  }

  .card-money {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
  }

  .card-money span {
    font-size: 24px;
    font-weight: 400;
    color: #3F4156;
  }

  .card-bottom {
    font-size: 14px;
    color: #A2A4B3;
  }

  .rise {
    font-size: 14px;
    font-weight: bold;
    color: #63D29D;
  }

  .decline {
    font-size: 14px;
    font-weight: bold;
    color: #F74444;
  }

  .table {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .teble-content {
    border: 1px solid #E9EAF2;
    border-radius: 2px;
  }

  .table-header {
    height: 60px;
    line-height: 60px;
    background: #F7F8FA;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    color: #858692;
  }

  .table-header div {
    font-weight: bold;
  }

  .number {
    text-align: left;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #4A4A4A;
  }

  .number span {
    font-size: 14px;
    font-weight: bold;
    color: #3F4156;
    margin-right: 10px;
  }

  .table-information {
    height: 50px;
    line-height: 50px;
  }

  .information {
    text-align: left;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
    color: #4A4A4A;
  }

  .information span {
    font-size: 14px;
    font-weight: bold;
    color: #3F4156;
    margin-right: 10px;
  }

  .table-content {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #3F4156;
    height: 30px;
    line-height: 30px;
  }

  .content-title {
    text-align: left;
    padding: 0 20px;
  }

  .table-button {
    padding: 0 30px;
    font-size: 14px;
    font-weight: 400;
    color: #F89800;
    margin: 20px 0;
  }

  .table-tag {
    height: 28px;
    line-height: 28px;
    border: 1px solid #F76C6C;
    border-radius: 4px;
    padding: 0 5px;
    display: initial;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .tag {
    font-size: 12px;
    font-weight: 400;
    color: #F76C6C;
  }

  .tag-content {
    padding: 20px 0;
    /* display: inline-flex; */
  }

  .table-price {
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
  }

  .price {
    font-size: 14px;
    font-weight: 400;
    color: #858692;
  }

  .price span {
    font-size: 24px;
    font-weight: 400;
    color: #3F4156;
  }

  .edit-icon {
    opacity: 0;
    margin-left: 3px;
    height: 18px;
    width: 18px;
    transition: all 200ms;
    cursor: pointer;
    position: absolute;
    z-index: 1;
  }

  .show-edit:hover .edit-icon {
    opacity: 1;
  }

  .cost-form .el-form-item {
    margin-bottom: 0px !important;

  }

  .cost-form-tip {
    color: #A2A4B3;
    margin-left: 100px;
    line-height: 16px;
    margin-bottom: 10px;
    margin-top: 5px;
    min-height: 5px;
  }

  .el-input__prefix {
    color: #56576A !important;
    font-size: 14px !important;
  }

  .page-body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .page-body-item-title {
    margin-top: 23px;
    font-size: 14px;
    font-weight: bold;
    color: #3F4156;
    opacity: 1;
  }

  .page-body-chart-box {
    width: 100%;
    background: #fff;
    margin-top: 20px;
  }

  .page-body-chart-box #use-times,
  .page-body-chart-box #use-money {
    width: 100%;
    height: 400px;
  }

  .page-body-chart-box .use-titles {
    width: 100%;
    height: 100%;
    font-size: 14px;
  }

  .page-body-chart-box .use-titles-item {
    line-height: 50px;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .page-body-chart-box #use-bar-items {
    width: 100%;
    height: 400px;
  }

  .el-popover__reference-wrapper img {
    height: 14px;
    line-height: 14px;
  }
</style>
