<template>
  <div>
    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
      <span class="clearfix-title">赠品成本</span>
      <span class="clearfix-Subtitle">便于用户核对、记录赠品帐务，了解时间范围内赠品成本支出及赠品使用次数情况</span>
    </div>
    <div style="padding: 20px;">
      <el-button type="primary" size="small" @click="addgift">添加赠品成本</el-button>
    </div>
    <div style="padding-left: 20px;padding-right: 20px;">
      <el-table :data="tableData" :stripe="true" style="width: 100%;"
        :header-cell-style="{'padding-left':'20px','padding-right': '20px'}"
        :cell-style="{'padding-left':'20px','padding-right': '20px'}">
        <el-table-column type="index" label="序号" width="120" align="left">
        </el-table-column>
        <el-table-column prop="name" label="赠品" align="left">
        </el-table-column>
        <el-table-column label="赠品成本" align="right" class-name="column-right">
          <template slot-scope="scope">
            ￥{{scope.row.price}}
          </template>
        </el-table-column>
        <el-table-column label="生效时间" width="300px" align="left" class-name="column-left">
          <template slot-scope="scope">

            <div v-if="scope.row.status==0">
              {{scope.row.startDate}} 至 {{scope.row.endDate}}
            </div>

            <div v-else>
              生效中
            </div>

          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div style="display: none;">
              <el-button size="small" v-if="scope.row.status==1">关闭</el-button>
              <el-button type="primary" size="small" v-if="scope.row.status==0">开启</el-button>
            </div>
            <span style="font-size: 14px;font-weight: 400;color: #F89800;opacity: 1;cursor:  pointer"
              @click="editgift(scope.row.id,scope.row.name,scope.row.price,scope.row.status,scope.row.startDate,scope.row.endDate)">
              修改</span>
            <span style="width: 16px;text-align: center;">
              <el-divider direction="vertical"></el-divider>
            </span>

            <span style="font-size: 14px;font-weight: 400;color: #F76C6C;opacity: 1;cursor:  pointer"
              @click="delegift(scope.row.id)">
              删除</span>
          </template>
        </el-table-column>
      </el-table>


      <el-pagination background layout="prev, pager, next, total" :total="totalCount"
        @current-change="poorCommentWarnPageChange" style="text-align: right;margin: 21px 0;">
      </el-pagination>


    </div>
    <div style="height: 60px;display: flex;align-items: center;border-top: 10px solid rgb(247, 248, 250);">

      <div class="flex relative color3F4156 pad-l20 pad-r20">
        <el-radio-group v-model="dateOption" size="small" fill="#585A6E" text-color="#FFFFFF"
          @change="selectDateChange">
          <el-radio-button :label="0">今日</el-radio-button>
          <el-radio-button :label="1">昨日</el-radio-button>
          <el-radio-button :label="7">近7天</el-radio-button>
          <el-radio-button :label="30">近30天</el-radio-button>
          <el-radio-button :label="-1">自定义</el-radio-button>
        </el-radio-group>
        <el-date-picker v-if="dateOption===-1" class="mar-l10" style="height: 32px;width: 280px" type="daterange"
          align="right" unlink-panels v-model="queryDate" start-placeholder="开始日期" end-placeholder="结束日期"
          format="yyyy-MM-dd" value-format="yyyy-MM-dd" ref="daterange" :picker-options="pickerOptions"
          @change="selectDateChange">
        </el-date-picker>
      </div>

    </div>

    <div>
      <div class="page-body">
        <div class="page-body-item">
          <div class="page-body-item-title" style="margin-top: 5px;">赠品汇总图</div>
          <div class="page-body-item-content">
            <div class="center-column" v-show="!isNotnull">
              <img src="../../assets/img/null.png" class="nodata">
              <div class="mar-t20 color666 size15">暂无数据</div>
            </div>
            <div class="page-body-chart-box" v-show="isNotnull">
              <el-row>
                <el-col :span="24">
                  <div class="use-titles">
                    <el-row>
                      <el-col :span="12">
                        <div class="use-titles-item">赠品金额占比</div>
                      </el-col>
                      <el-col :span="12">
                        <div class="use-titles-item">赠品数量占比</div>
                      </el-col>
                    </el-row>
                  </div>
                  <div id="use-constsnumber">
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isDetailedShow">
        <div class="page-body">
          <div class="page-body-item">
            <div class="page-body-item-title">各项赠品明细图
              <li style="font-size: 14px;font-weight: 400;color: #3F4156;opacity: 1;">已选时间：{{this.queryDate[0]}} 至
                {{this.queryDate[1]}} 数据</li>
            </div>

            <div class="page-body-item-content" v-for="(item,index) in detaileddata" :key="index">
              <div class="page-body-chart-box">
                <el-row>
                  <el-col :span="24">
                    <div class="use-titles">
                      <el-row>
                        <el-col :span="24">
                          <div class="use-titles-item"
                            style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;">
                            {{item.name}}
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                    <div :id="'activity'+index" :ref="'activity'+index" style="width: 100%;height: 400px;">
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="page-body">
        <div class="page-body-item">
          <div class="page-body-item-title">订单明细
            <li style="font-size: 14px;font-weight: 400;color: #3F4156;opacity: 1;">已选时间：{{this.queryDate[0]}} 至
              {{this.queryDate[1]}} 数据</li>
          </div>
          <div class="page-body-item-content">
            <div class="page-body-chart-box" style="height: inherit;">
              <el-row>
                <el-col :span="24">
                  <div>
                    <el-table :data="tableData1" :stripe="true" style="width: 100%;">
                      <el-table-column prop="order_id" label="订单号" width="180px">
                      </el-table-column>
                      <el-table-column label="顾客信息">
                        <template slot-scope="scope">
                          {{scope.row.recipientName}}+{{scope.row.recipientPhone}}
                        </template>
                      </el-table-column>
                      <el-table-column label="订单状态" width="100px">
                        <template slot-scope="scope">
                          <div v-if="scope.row.status==1">
                            已完成
                          </div>
                          <div v-if="scope.row.status==0">
                            未完成
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="utime" label="时间" width="170px">
                      </el-table-column>
                      <el-table-column prop="gift_name" label="赠品" width="50px">
                      </el-table-column>
                      <el-table-column label="订单备注">
                        <template slot-scope="scope">

                          <el-tooltip popper-class="popoverBackB" placement="top" trigger="hover" :content="scope.row.caution">
                            <span >{{scope.row.caution}}</span>
                          </el-tooltip>

                        </template>
                      </el-table-column>
                    </el-table>

                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="赠品成本" :visible.sync="editDialogVisible" @close="editDialogClosed" width="500px"
      custom-class="size20">
      <el-form :model="Editform" :rules="EditformRules" ref="EditformRef" label-width="100px" hide-required-asterisk>
        <el-form-item label="赠品名称:" prop="name">
          <div style="line-height: 0;">
            <el-input v-model="Editform.name" type="text" style="width: 200px;" size="small">
            </el-input>
            <li
              style="font-size: 12px;font-weight: 400;color: #56576A;opacity: 1;margin-top: 5px;margin-bottom: 15px;">
              备注需要包含对应赠品名称才能算记录</li>
          </div>
        </el-form-item>
        <el-form-item label="赠品成本:" prop="price">
          <el-input suffix-icon="el-icon-warning-outline" style="width: 200px;" size="small" clearable
            v-model.number="Editform.price"></el-input>
        </el-form-item>
        <el-form-item label="是否立即生效:" prop="status">
          <el-radio-group v-model="Editform.status" style="margin-top: 12px;">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="生效时间范围:" v-if="Editform.status==0" prop="startDate">
          <el-date-picker v-model="value1" size="small" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="andDateChange">
          </el-date-picker>
          <!--
          <div style="display: flex;">
            <el-date-picker size="small" type="date" placeholder="选择日期" v-model="Editform.startDate"
              style="width: 150px;"></el-date-picker>
            <div style="width: 20px;text-align: center;">-</div>
            <el-date-picker size="small" type="date" placeholder="选择日期" v-model="Editform.endDate"
              style="width: 150px;"></el-date-picker>
          </div> -->

        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false" size="medium"
          style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button type="primary" @click="postgiftEdit()" size="medium"
          style="padding: 0 18px;height: 36px;font-size: 14px;">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    data() {
      return {
        totalCount: 0,
        page: 1, //当前页
        pageSize: 10, //每页大小
        s_id: sessionStorage.getItem('s_id'),
        res: [],
        dateOption: 0,
        tableData: [],
        tableData1: [],
        isNotnull: true,
        isDetailedShow: false, // 展开明细
        activeName: '今日',
        datesvalue: '',
        pieOtion: {},
        pieOneData: {
          gift_money: [],
          gift_count: []
        },
        giftdata: [],
        Editform: {
          id: '',
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          name: '',
          price: '',
          status: 1,
          startDate: '',
          endDate: ''
        },
        value1: '',
        EditformRules: {
          name: [{
            required: true,
            message: '请输入赠品名称',
            trigger: 'blur'
          }],
          price: [{
            required: true,
            message: '请输入成本价格',
            trigger: 'blur'
          }],
          status: [{
            required: true,
            message: '是否立即生效',
            trigger: 'blur'
          }],
          startDate: [{
            required: true,
            message: '请选择起始时间',
            trigger: 'blur'
          }],
          endDate: [{
            required: true,
            message: '请选择结束时间',
            trigger: 'blur'
          }]
        },
        startDate: '',
        endDate: '',
        editDialogVisible: false,
        detaileddata: [],
        queryDate: [
          this.$dateFormat('YYYY-mm-dd', new Date()),
          this.$dateFormat('YYYY-mm-dd', new Date())
        ],
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        loading: true,
        everyDayDataDate: []
      }
    },
    methods: {
      poorCommentWarnPageChange(e) {
        this.page = e
        this.getgiftList()
        // this.getPoorCommentWarnList()
      },
      selectDateChange(e) {
        this.loading = true
        this.isDetailedShow = false
        if (e !== -1) {
          if (typeof e === 'number') {
            var end = new Date();            var month = end.getMonth() + 1;            var month = month > 10 ? month : "0" + month;            end.setTime(end.getTime() - 23 * 60 * 60 * 1000);
            if (e < 2) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', start)]
            } else {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
            }
          }
          this.getEchartsData()
        } else {
          this.$refs.daterange.focus()
        }
      },
      andDateChange(e) {
        this.Editform.startDate = e[0]
        this.Editform.endDate = e[1]
        // console.log(this.Editform.startDate, this.Editform.endDate)
      },
      baractivity(index) {
        // console.log(index)
        var option = {
          color: ['#5470C6', '#91CC75', '#EE6666'],
          tooltip: {
            trigger: 'axis',
            axisPointer: { // 坐标轴指示器，坐标轴触发有效
              type: 'line' // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          legend: {
            data: ['金额', '次数'],
            bottom: '5%'
          },
          grid: {
            left: '3%',
            right: '4%',
            containLabel: true
          },
          xAxis: [{
            data: this.everyDayDataDate
          }],
          yAxis: [{
              type: 'value',
              name: '金额（/元）'
            },
            {
              name: '次数（/次）',
              type: 'value'

            }
          ],
          series: [{
              name: '金额',
              type: 'bar',
              stack: index,
              emphasis: {
                focus: 'series'
              },
              color: '#92D154',
              data: this.detaileddata[index].money
            },
            {
              name: '次数',
              type: 'line',
              stack: index,
              emphasis: {
                focus: 'series'
              },
              color: '#F8B500',
              data: this.detaileddata[index].count
            }
          ]
        }
        this.$nextTick(function() {
          var chartDom = this.$refs['activity' + index][0]
          var myChart = echarts.init(chartDom)
          myChart.setOption(option)
        })
      },
      async getgiftList() {
        const {
          data: res
        } = await this.$http.get('/user/gift/giftList', {
          params: {
            s_id: this.s_id,
            page: this.page,
            size: this.pageSize,
          }
        })

        if (res.code != 1) {
          this.$message.error(res.msg)
        }
        this.tableData = res.data.data
        console.log(res)
        this.totalCount = res.data.total
      },
      async getgiftorderList() {
        // 赠品明细记录查询
        const {
          data: res
        } = await this.$http.get('/user/gift/ordersData', {
          params: {
            s_id: this.s_id,
            startDate: this.queryDate[0],
            endDate: this.queryDate[1]
          }
        })
        this.tableData1 = res.data
      },
      // 添加赠品成本
      addgift() {
        this.editDialogVisible = true
        this.Editform.id = ''
        this.Editform.name = ''
        this.Editform.price = ''
        this.Editform.status = 1
        this.Editform.startDate = ''
        this.Editform.endDate = ''
      },
      // 修改赠品成本
      async editgift(id, name, price, status, startDate, endDate) {
        this.editDialogVisible = true
        this.Editform.id = id
        this.Editform.name = name
        this.Editform.price = price
        this.Editform.status = status
        if (this.Editform.status == '') {
          this.Editform.startDate = ''
        }
        this.Editform.startDate = startDate
        this.Editform.endDate = endDate
      },
      delegift(id) {
        this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'btnFalse btnPadd',
          cancelButtonClass: 'btnPadd'
        }).then(() => {
          this.$api.post('/user/Gift/giftDelete', {
            id: id,
            s_id: JSON.parse(sessionStorage.getItem('s_id'))
          }, true).then(res => {
            this.$message({
              type: res.code === 0 ? 'error' : 'success',
              message: res.msg
            })
            this.getgiftList()
          })
        })
      },
      postgiftEdit() {
        this.$refs.EditformRef.validate(async valid => {
          if (!valid) return
          // 发起修改模板信息的数据请求
          const {
            data: res
          } = await this.$http.post('/user/gift/giftEdit', {
            id: this.Editform.id,
            s_id: this.Editform.s_id,
            name: this.Editform.name,
            price: this.Editform.price,
            status: this.Editform.status,
            startDate: this.Editform.startDate,
            endDate: this.Editform.endDate
          })
          if (res.code != 1) {
            return this.$message.error(res.msg)
          }
          this.editDialogVisible = false
          this.getgiftList()
          this.$message.success(res.msg)
        })
      },
      editDialogClosed() {
        this.$refs.EditformRef.resetFields()
      },
      async getEchartsData() {
        this.isNotnull = true
        const {
          data: res
        } = await this.$http.get('/user/gift/echartsData', {
          params: {
            s_id: this.Editform.s_id,
            startDate: this.queryDate[0],
            endDate: this.queryDate[1]
          }
        })
        this.getgiftorderList()

        if (JSON.stringify(res.data.gift_name) == '[]') {
          this.isDetailedShow = false
          this.isNotnull = false
        } else {
          this.isNotnull = true

          const pieOneDataCount = []
          const pieOneDataReduceFee = []
          const chartSeriesData = res.data.gift_name

          chartSeriesData.forEach(function(item, index) {
            pieOneDataCount.push({
              name: item,
              value: res.data.gift_count[index],
              text: '次数'
            })
            pieOneDataReduceFee.push({
              name: item,
              value: res.data.gift_money[index],
              text: '金额'
            })
          })

          const chartDom = document.getElementById('use-constsnumber')
          const myChart = echarts.init(chartDom)
          const option = {
            tooltip: {
              trigger: 'item'
            },
            title: {},
            legend: {
              top: '75%',
              data: res.data.gift_name
            },
            color: ['#F8B500', '#935FED', '#5F77ED', '#5F77ED', '#5FA6ED', '#92D154', '#54D175', '#54C5D1'],
            toolbox: {
              show: true,
              feature: {
                mark: {
                  show: true
                },
                dataView: {
                  show: true,
                  readOnly: false
                },
                restore: {
                  show: true
                },
                saveAsImage: {
                  show: true
                }
              }
            },
            series: [{
                name: '金额',
                type: 'pie',
                radius: ['30%', '60%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '14'
                  }
                },
                labelLine: {
                  show: false
                },
                center: ['25%', '40%'],
                data: pieOneDataReduceFee
              },
              {
                name: '次数',
                type: 'pie',
                radius: ['30%', '60%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '10',
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                center: ['75%', '40%'],
                data: pieOneDataCount
              }
            ]
          }

          option && myChart.setOption(option)

          if (JSON.stringify(res.data.everyDayData) != '[]') {
            this.isDetailedShow = true
            this.everyDayDataDate = res.data.everyDayData.date
            this.detaileddata = res.data.everyDayData.data
            // 各个赠品不为空
            for (const i in res.data.everyDayData.data) {
              this.baractivity(i)
            }
          }
        }
      }
    },
    mounted() {
      this.selectDateChange()
      this.getgiftList()
      this.getgiftorderList()
      // this.chartPieOne()
      // this.postgiftEdit()
      // this.getechartsData()
    }
  }
</script>

<style scoped>
  .page-body-chart-box #use-constsnumber,
  .page-body-chart-box #use-money {
    width: 100%;
    height: 280px;
  }

  .size20 {
    font-size: 20px;
    font-weight: bold;
    color: #4A4A4A;
    opacity: 1;
  }
</style>
