<template>
  <div v-loading="loading">

    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
      <span class="clearfix-title">菜品组合列表</span>
      <span class="clearfix-Subtitle">统计菜品组合出现次数以做店铺推新菜品组合参考</span>
    </div>

    <div class="flex relative color3F4156 pad-l20 pad-r20" style="margin-top: 20px;">
      <el-radio-group v-model="dateOption" size="small" fill="#585A6E" text-color="#FFFFFF" @change="selectDateChange">
        <el-radio-button :label="0">今日</el-radio-button>
        <el-radio-button :label="1">昨日</el-radio-button>
        <el-radio-button :label="7">近7天</el-radio-button>
        <el-radio-button :label="30">近30天</el-radio-button>
        <el-radio-button :label="-1">自定义</el-radio-button>
      </el-radio-group>
      <el-date-picker v-if="dateOption===-1" class="mar-l10" style="height: 32px;width: 280px" type="daterange"
        align="right" unlink-panels v-model="queryDate" start-placeholder="开始日期" end-placeholder="结束日期"
        format="yyyy-MM-dd" value-format="yyyy-MM-dd" ref="daterange" :picker-options="pickerOptions"
        @change="selectDateChange">
      </el-date-picker>
    </div>

    <div style="padding:0 20px;">
      <el-tabs v-model="activeNum" style="padding-bottom: 20px;padding-left: 0px;" @tab-click="handleClick">
        <el-tab-pane label="两个菜品组合" name="2"></el-tab-pane>
        <el-tab-pane label="三个菜品组合" name="3"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" :stripe="true" style="width: 100%" ref="tables" v-loading="loading">
        <el-table-column type="expand" width="-1">
          <template slot-scope="scope">
            <div :id="'activity'+scope.row.item_sub_code" :ref="'activity'+scope.row.item_sub_code"
              style="width: 100%;height: 300px;">
            </div>
          </template>
        </el-table-column>
        <el-table-column type="index" label="排名" width="50" align="left" :index="table_index">
        </el-table-column>
        <el-table-column label="菜品组合" align="left">
          <template slot-scope="scope">
            <li v-for="(item,index) in scope.row.item_sub_name" :key="index" style="font-weight: 700">{{item.food_name}}
            </li>
          </template>

        </el-table-column>
        <el-table-column prop="item_sub_num" label="组合数量" :render-header="renderHeader1" width="100" align="right">
          <template slot-scope="scope">
            <span style="margin-right: 24px;">{{scope.row.item_sub_num}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="出现次数" :render-header="renderHeader2" width="100" align="right">
          <template slot-scope="scope">
            <span style="margin-right: 24px;">{{scope.row.count}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">

          <template slot-scope="scope">
            <span style="font-size: 14px;font-weight: 400;color: #F89800;opacity: 1;cursor:  pointer"
              @click="rows(scope.row)">查看趋势</span>
            <!-- <span v-else style="font-size: 14px;font-weight: 400;color: #A2A4B3;opacity: 1;cursor: not-allowed;">查看趋势<i
                class="el-icon-arrow-right"></i></span> -->
          </template>

        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
        :current-page="queryInfo.page" :page-size="queryInfo.limit" layout="prev, pager, next, total"
        :total="queryInfo.total" class="page"></el-pagination>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  import whsrc from '../../assets/img/wh.png'


  export default {
    data() {
      return {
        switchvalue: '',
        isCollapse: false,
        pageTitle: '菜品毛利排行',
        activeName: '今日',
        datesvalue: '',
        selectvalue: '',
        activeNum: '2',
        form: {
          least: '',
          most: ''
        },
        tableData: [],
        queryInfo: {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          type: 0,
          page: 1,
          limit: 10,
          score: 0,
          status: 0,
          is_null: 0,
          total: 0
        },
        queryDate: [
          this.$dateFormat('YYYY-mm-dd', new Date()),
          this.$dateFormat('YYYY-mm-dd', new Date())
        ],
        startDate: '',
        dateOption: 7,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        endDate: '',
        time: [],
        food_name: [],
        count: [],
        food_data: [],
        loading: true
      }
    },
    methods: {
      handleClick(tab, event) {
        this.activeNum = tab.name
        this.getFoodGropNum()
      },
      table_index(index) {
        return (this.queryInfo.page - 1) * this.queryInfo.limit + index + 1
      },
      activeswitch() {
        // console.log(this.switchvalue)
      },
      onSubmit() {
        this.switchvalue = 0
      },
      cancel() {
        this.switchvalue = 0
      },
      selectDateChange(e) {
        // console.log(e)
        if (e == undefined) {
          e = 7
        }
        this.queryInfo.page = 1
        if (e !== -1) {
          if (typeof e === 'number') {
            var end = new Date();
            var month = end.getMonth() + 1;
            var month = month > 10 ? month : "0" + month;
            end.setTime(end.getTime() - 23 * 60 * 60 * 1000);
            if (e < 2) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', start)]
            } else {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
              console.log(this.queryDate)
            }
          }
          this.getFoodGropNum()
        } else {
          this.$refs.daterange.focus()
        }
      },
      // 分页功能
      handleSizeChange(newSize) {
        this.queryInfo.limit = newSize
        this.getFoodGropNum()
      },
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage
        this.getFoodGropNum()
      },
      async rows(row) {
        let $table = this.$refs.tables;
        this.tableData.map((item) => {
          if (row.id != item.id) {
            $table.toggleRowExpansion(item, false)
          }
        })
        $table.toggleRowExpansion(row)
        this.currentIndex = row.id

        // this.$refs.tables.toggleRowExpansion(row)

        // console.log(this.$refs.tables)

        const {
          data: res
        } = await this.$http.get('/user/order/getFoodGropList', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
            startDate: this.queryDate[0],
            endDate: this.queryDate[1],
            item_sub_code: row.item_sub_code
          }
        })

        this.food_data = []
        this.time = res.data.date
        for (var i in res.data.list) {
          this.food_name.push(res.data.list[i].food_name)
          this.count.push(res.data.list[i].count)
          this.food_data.push({
            name: res.data.list[i].food_name,
            type: 'line',
            stack: '总量',
            data: res.data.list[i].count
          })
        }
        // console.log(res)
        // console.log(this.count)
        this.baractivity(row.item_sub_code)
      },
      baractivity(id) {
        var option = {
          title: {
            text: '折线图堆叠'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: this.food_name
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.time
          },
          yAxis: {
            type: 'value'
          },
          series: this.food_data
        }
        this.$nextTick(function() {
          var chartDom = this.$refs['activity' + id]
          var myChart = echarts.init(chartDom)
          myChart.setOption(option)
        })
      },
      renderHeader1(h, {
        column,
        $index
      }) {
        // var src =
        // 这里在最外层插入一个div标签
        return h('div', [ // h即为cerateElement的简写
          // 在div里面插入span
          h('span', {
            // 表示内容
            domProps: {
              innerHTML: column.label
            },
            attrs: {
              style: 'margin-right:4px',
            },
          }),
          h('el-tooltip', {
              // 表示属性
              attrs: {
                effect: 'dark',
                content: '所选时段订单中同时包含该菜品组合的订单数',
                placement: 'top',
                'popper-class': 'popoverBackB'
              }
            },
            [h('img', {
              // 表示内容

              attrs: {
                src: whsrc,
              },

            }), ]
          )
        ])
      },
      renderHeader2(h, {
        column,
        $index
      }) {
        // var src =
        // 这里在最外层插入一个div标签
        return h('div', [ // h即为cerateElement的简写
          // 在div里面插入span
          h('span', {
            // 表示内容
            domProps: {
              innerHTML: column.label
            },
            attrs: {
              style: 'margin-right:4px',
            },
          }),
          h('el-tooltip', {
              // 表示属性
              attrs: {
                effect: 'dark',
                content: '所选时段订单中同时出现这个菜品的次数',
                placement: 'top',
                'popper-class': 'popoverBackB'
              }
            },
            [h('img', {
              // 表示内容

              attrs: {
                src: whsrc,
              },

            }), ]
          )
        ])
      },
      async getFoodGropNum() {
        this.loading = true
        const {
          data: res
        } = await this.$http.get('/user/order/getFoodGropNum', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
            startDate: this.queryDate[0],
            endDate: this.queryDate[1],
            num: this.activeNum,
            page: this.queryInfo.page,
            limit: this.queryInfo.limit
          }
        })
        this.loading = false
        var data = []
        for (var i in res.data) {
          data.push({
            count: res.data[i].count,
            item_sub_code: res.data[i].item_sub_code,
            item_sub_name: res.data[i].item_sub_name,
            item_sub_num: res.data[i].item_sub_num,
            id: i
          })
        }
        // console.log(data)
        // console.log(res.data)
        this.tableData = data
        this.queryInfo.total = res.count
      }
    },
    created() {
      this.selectDateChange()
    }
  }
</script>

<style scoped>
  .screen {
    height: 60px;
    line-height: 60px;
  }

  .el-input {
    width: 150px;
  }

  .table-header {
    height: 60px;
    line-height: 60px;
    background: #F7F8FA;
    font-weight: bold;
    font-size: 14px;
    color: #858692;
  }

  .el-table__header-wrapper {
    line-height: 40px;
  }

  .el-table__row {
    height: 60px;
  }
</style>
