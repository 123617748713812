<template>
  <div v-loading="loading">
    <div class="header-title">
      <span class="clearfix-title">包装袋成本记录</span><span class="clearfix-Subtitle">根据订单原价匹配包装袋规格并记录其成本和汇总</span>
    </div>
    <div class=" pad-l20 pad-r20">
      <el-button type="primary" class="radius2 mar-t10"
        @click="editPackageBagCostBox=true;packageBagForm={title:packageBagForm.title}" size="small">添加包装袋成本
      </el-button>
    </div>

    <el-dialog :title="packageBagForm.title" :visible.sync="editPackageBagCostBox" width="500px">

      <el-form :model="packageBagForm" :rules="packageBagFormRules" ref="packageBagForm" hide-required-asterisk
        label-width="120px">
        <el-form-item label="包装名称 ：" prop="name">
          <el-input autocomplete="off" class="width250" placeholder="请输入包装名称" maxlength="20" size="small"
            v-model="packageBagForm.name"></el-input>
        </el-form-item>
        <el-form-item label="包装成本 ：" prop="cost">
          <el-input autocomplete="off" class="width250" placeholder="请输入包装成本" maxlength="10" size="small"
             type="number" v-model.number="packageBagForm.cost"></el-input>
        </el-form-item>

        <el-form-item label="订单金额范围 ：" prop="title">
          <div>
            <div class="color56576A size12 mar-t10" style="line-height: 20px;">
              设置金额范围不可重叠，如有超出订单金额范围的，按已设的最大
              订单金额匹配包装袋。
            </div>
            <div class="center-vertical mar-t10">
              <el-col :span="11" class="flex range-input-v range-min-input-v">
                <el-form-item class="center">
                  最小
                  <div class="center radius2 mar-l10 num-input-v">
                    <div style="width: 55px" class="max-h center">
                      <input class="center pad-lr5 color3F4156 border-none" type="number" v-model.number="packageBagForm.minMoney"
                        maxlength="5">
                    </div>
                    <div class="max-h center price-input">元</div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="11" class="flex range-input-v range-max-input-v">
                <el-form-item class="center max-w">
                  <span class="mar-l20">最大</span>
                  <div class="center radius2 mar-l10 num-input-v">
                    <div style="width: 55px" class="max-h center">
                      <input class="center pad-lr5 color3F4156 border-none" type="number" v-model.number="packageBagForm.maxMoney"
                        maxlength="5">
                    </div>
                    <div class="max-h center price-input">元</div>
                  </div>
                </el-form-item>
              </el-col>
            </div>
          </div>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editPackageBagCostBox = false" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button type="primary" @click="editPackageBagCost" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">保 存</el-button>
      </div>
    </el-dialog>

    <div class=" pad-l20 pad-r20">

      <el-table class="radius2 mar-t20" v-loading="loading" show-header :stripe="true"
        :header-cell-style="{background:'#F7F8FA',color:'#666','font-weight': 'bold','font-size':'14px','padding-left':'20px','padding-right': '20px'}"
        :cell-style="{'font-size':'13px',color:'#565A6E','padding-left':'20px','padding-right': '20px'}"
        :data="packageBagQuery.data">

        <el-table-column prop="name" label="包装名称" align="left">
        </el-table-column>

        <el-table-column label="订单原价" align="right">
          <template slot-scope="scope">

            <div v-if="scope.row.min_money>0&&scope.row.max_money>0">
              ￥{{ scope.row.min_money }} - {{ scope.row.max_money }}
            </div>
            <div v-if="scope.row.min_money==0&&scope.row.max_money>0">
              小于￥{{ scope.row.max_money }}
            </div>
            <div v-if="scope.row.min_money>0&&scope.row.max_money==0">
              大于￥{{ scope.row.min_money }}
            </div>


<!--            <div v-if="scope.row.min_money>0&&scope.row.max_money==0">
              ￥{{ scope.row.min_money }} - {{ scope.row.max_money }}
            </div>
 -->

           <!-- <div v-else>
              大于￥{{ scope.row.min_money }}
            </div>

            <div v-if="scope.row.min_money!=0&&scope.row.max_money==0">
              ￥{{ scope.row.min_money }} - {{ scope.row.max_money }}
            </div>
            <div v-else>
              大于￥{{ scope.row.min_money }}
            </div> -->

            <!-- <div v-else>
              大于￥{{ scope.row.min_money }}
            </div> -->

          </template>
        </el-table-column>

        <el-table-column prop="pack_cost" label="包装袋成本" align="right">
          <template slot-scope="scope">
            <div>
              ￥{{ scope.row.pack_cost }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="utime" label="更新时间" align="left">
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <a class="cursor-pointer colorF89800" @click="
             editPackageBagCostBox=true;
             packageBagForm={
               id: scope.row.id,
               name: scope.row.name,
               cost: scope.row.pack_cost,
               minMoney: scope.row.min_money,
               maxMoney: scope.row.max_money,
               title:packageBagForm.title
             };">编辑</a>
            <el-divider direction="vertical"></el-divider>
            <a class="cursor-pointer" style="color: #F76C6C;" @click="delPackageBag(scope.row.id)">删除</a>
          </template>
        </el-table-column>
      </el-table>


      <div class="flex color3F4156 mar-t35">
        <el-radio-group v-model="dateOption" size="small" fill="#585A6E" text-color="#FFFFFF"
          @change="selectDateChange">
          <el-radio-button :label="0">今日</el-radio-button>
          <el-radio-button :label="1">昨日</el-radio-button>
          <el-radio-button :label="7">近7天</el-radio-button>
          <el-radio-button :label="30">近30天</el-radio-button>
          <el-radio-button :label="-1">自定义</el-radio-button>
        </el-radio-group>

        <el-date-picker style="margin-left: 20px;" size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
          v-if="dateOption===-1" unlink-panels v-model="queryDate" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" ref="daterange" @change="selectDateChange">
        </el-date-picker>

      </div>


      <p class="size14 weight-bold relative color3F4156">包装袋成本汇总图</p>

      <div v-if="this.echartslength==true">
        <div class="relative">
          <div class="absolute size14 color3F4156" style="top: 20px;left: 15%;z-index: 1">成本数量占比</div>
          <div class="absolute size14 color3F4156" style="top: 20px;left: 73%;z-index: 1">成本金额占比</div>
        </div>
        <div id="packageBagCostPie"></div>
      </div>

      <div v-if="this.echartslength==false" style="text-align: center;margin-top: 20px;">
        <img src="../../assets/img/null.png" alt="">
        <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
      </div>
    </div>

    <el-dialog :visible.sync="tisDialogVisible" :show-close="false" width="400px">
      <div style="display: flex;">
        <div>
          <img src="../../assets/img/question.png"
            style="height: 35px;width: 35px;margin-right: 5px;margin-right: 20px;">
        </div>
        <div>
          <li>
            <div class="size20 color3F4156 center-vertical">
              提示信息</div>
          </li>
          <li>
            <div class="mar-t10 size14 color56576A">{{tip}}</div>
          </li>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="tisDialogVisible = false" size="small">以后再说</el-button>
        <el-button type="primary" @click="tisview" size="small">前往设置</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import * as echarts from 'echarts'
  export default {
    name: 'PackageBagCost',
    data() {
      return {
        sId: sessionStorage.getItem('s_id'),
        dateOption: 0,
        queryDate: [
          this.$dateFormat('YYYY-mm-dd', new Date()),
          this.$dateFormat('YYYY-mm-dd', new Date())
        ],
        packageBagQuery: {
          page: 1,
          limit: 10,
          total: 0,
          data: []
        },
        packageBagForm: {
          id: undefined,
          name: undefined,
          cost: undefined,
          minMoney: undefined,
          maxMoney: undefined,
          title: '包装成本'
        },
        loading:false,
        packageBagFormRules: {
          name: [{
            required: true,
            message: '请输入包装名称',
            trigger: 'blur'
          }],
          cost: [{
            required: true,
            message: '请输入包装成本',
            trigger: 'blur'
          }],
          minMoney: [{
            required: true,
            message: '请输入最小金额',
            trigger: 'blur'
          }],
          maxMoney: [{
            required: true,
            message: '请输入最大金额',
            trigger: 'blur'
          }],
          title: [{
            required: true,
            message: '',
            trigger: 'blur'
          }]
        },
        loading: false,
        editPackageBagCostBox: false,
        tisDialogVisible: false,
        tip: '',
        echartslength: true
      }
    },
    methods: {
      tisview() {
        this.tisDialogVisible = false
        this.editPackageBagCostBox = true
      },
      selectDateChange(e) {
        if (e !== -1) {
          if (typeof e === 'number') {
            var end = new Date();            var month = end.getMonth() + 1;            var month = month > 10 ? month : "0" + month;            end.setTime(end.getTime() - 23 * 60 * 60 * 1000);

            const nowDate = new Date()
            nowDate.setDate(nowDate.getDate() - e)
            this.queryDate[0] = this.$dateFormat('YYYY-mm-dd', nowDate)
            this.queryDate[1] = this.$dateFormat('YYYY-mm-dd', end)
          }
          // console.log(this.queryDate)
          this.getPackCostSumary()
        }
        if (e == -1) {
          this.$refs.daterange.focus()
        }
      },
      initCostCountPie(saleCount, saleAmount) {
        let myChart = echarts.getInstanceByDom(document.getElementById('packageBagCostPie'))
        if (myChart == null) { // 如果不存在，就进行初始化
          myChart = echarts.init(document.getElementById('packageBagCostPie'))
        }
        myChart.setOption({
          tooltip: {
            trigger: 'item',
            backgroundColor: '#2B3E4C',
            borderColor: '#2B3E4C',
            animation: false,
            animationDurationUpdate: 0,
            formatter: params => {
              return '<div style="position: relative;background-color: #2B3E4C;border-radius: 4px;padding: 0px 40px 13px 10px;color: white;"><div style="font-size: 14px;">' +
                params.data.name + '</div><div style="font-size: 24px;font-weight: bold;margin-top:10px;">' +
                params.data.value +
                '</div><div style="display: block;width: 0;height: 0;border-top: 15px solid transparent;border-bottom: 15px solid transparent;border-right: 20px solid #2B3E4C;position: absolute;left: -25px;top: 36%;"></div></div>'
            },
            position: (point, params, dom, rect, size) => {
              // 固定在顶部
              return [point[0] + 30, point[1] - 50]
            }
          },
          legend: {
            top: '90%',
            left: 'center',
            itemWidth: 12,
            itemHeight: 12,
            itemGap: 25,
            icon: 'rect'
          },
          series: [{
              type: 'pie',
              radius: ['30%', '60%'],
              center: ['20%', '50%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 0,
                borderColor: '#fff',
                borderWidth: 5
              },
              label: {
                show: false,
                position: 'center'
              },
              labelLine: {
                show: false
              },
              data: saleCount,
              color: ['#F8B500', '#54C5D1', '#54D175', '#92D154', '#5FA6ED']
            },
            {
              type: 'pie',
              radius: ['30%', '60%'],
              center: ['78%', '50%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 0,
                borderColor: '#fff',
                borderWidth: 5
              },
              label: {
                show: false,
                position: 'center'
              },
              labelLine: {
                show: false
              },
              data: saleAmount,
              color: ['#F8B500', '#54C5D1', '#54D175', '#92D154', '#5FA6ED']
            }
          ]
        })
      },
      async getPackageBagCostList() {
        const {
          data: res
        } = await this.$http.get('/user/packing/packingLists', {
          params: {
            s_id: this.sId,
            page: this.packageBagQuery.page,
            size: this.packageBagQuery.limit
          }
        })
        // console.log(res)
        this.packageBagQuery.data = res.data.lists
        this.loading = false
      },
      editPackageBagCost() {

        this.$refs.packageBagForm.validate((valid) => {
          if (valid) {
            if (this.packageBagForm.minMoney == undefined && this.packageBagForm.maxMoney == undefined) {
              return this.$message.error('请填写金额')
            }
            this.$api.post('/user/packing/updatePackingCost', {
              s_id: this.sId,
              id: this.packageBagForm.id,
              name: this.packageBagForm.name,
              pack_cost: this.packageBagForm.cost,
              min_money: this.packageBagForm.minMoney,
              max_money: this.packageBagForm.maxMoney
            }, true).then(res => {
              this.getPackageBagCostList()
              this.editPackageBagCostBox = false
              this.$message({
                message: res.msg,
                type: res.code === 1 ? 'success' : 'error'
              })
            })
          }
        })



      },
      async delPackageBag(e) {
        this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'btnFalse btnPadd',
          cancelButtonClass: 'btnPadd'
        }).then(() => {
          this.$api.post('/user/packing/delPacking', {
            s_id: this.sId,
            ids: e
          }, true).then(res => {
            this.getPackageBagCostList()
            this.$message({
              message: res.msg,
              type: res.code === 1 ? 'success' : 'error'
            })
          })
        })
      },
      async getPackCostSumary() {
        this.$api.post('/user/packing/pack_cost_sumary', {
          s_id: this.sId,
          startDate: this.queryDate[0],
          endDate: this.queryDate[1],
        }).then(res => {
          if (res.tip != '') {
            this.tip = res.tip
            this.tisDialogVisible = true
          }
          const saleCount = []
          const saleAmount = []
          if (res.data) {
            this.echartslength = true
            console.log(res.data)
            for (const re of res.data) {
              saleCount.push({
                name: re.pack_name,
                value: re.num
              })
              saleAmount.push({
                name: re.pack_name,
                value: re.num
              })
            }
            this.initCostCountPie(saleCount, saleAmount)
          }else{
            this.echartslength = false
          }
        })
      },
      backtop() {
        var timer = setInterval(() => {
          const osTop = document.documentElement.scrollTop || document.body.scrollTop
          const ispeed = Math.floor(-osTop / 5)
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
          if (osTop === 0) {
            clearInterval(timer)
          }
        }, 30)
      }
    },
    mounted() {
      this.loading = true
      this.getPackageBagCostList()
      this.getPackCostSumary()
    },
    created() {
      this.getPackCostSumary()
    }
  }
</script>

<style>
  #packageBagCostPie {
    height: 350px;
  }

  .num-input-v {
    width: 85px;
    height: 32px;
    border: solid 1px #E9EAF2;
  }

  .num-input-v input {
    width: 80%;
  }

  .price-input {
    width: 30px;
    border-left: solid 1px #E9EAF2;
  }

  .el-form-item__error {
    margin-left: 100px !important;
  }

  .range-max-input-v .el-form-item__error {
    margin-left: 0 !important;
    padding-left: 55px;
  }

  .range-input-v .el-form-item__content {
    width: 100%;
    display: flex;
  }

  .range-min-input-v .el-form-item__error {
    margin-left: 35px !important;
  }
</style>
