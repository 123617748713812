<template>
  <div class="homeBox">

    <el-container direction="vertical" style="margin: auto;">
      <el-container>
        <el-aside style="position: fixed;width: 200px;">
          <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" background-color="#3F4156"
            :default-openeds="openeds1" :default-active="$route.path" text-color="#BABCCC" active-text-color="#EEA128"
            unique-opened router>
            <div class="aside-header">
              <li style="justify-content: space-evenly;">
                <el-popover placement="left" trigger="hover">
                  <div style="width: 150px;text-align: center;height: 150px;">
                    <img :src="storeinfo.kfImg" style="width: 150px;height: 150px;">
                  </div>
                  <span slot="reference">
                    <i class="el-icon-user" style="margin-right: 3px;"></i>客服
                  </span>
                </el-popover>
                <!-- <span style="cursor:  pointer" @click="wxrouter">微信</span> -->
                <span style="cursor: pointer;" @click="dxrouter()"><i class="el-icon-message"
                    style="margin-right: 3px;"></i>短信<i class="el-icon-arrow-right"></i></span>
              </li>
            </div>
            <div class="aside-userinfo" @click="indexrouter()">
              <img src="../assets/img/wmdp.png" alt="">
            </div>
            <el-submenu :index="item.id + ''" v-for="item in menulist" :key="item.id">
              <template slot="title">
                <span style="display: flex;align-items: center;"><img :src="item.icon"
                    style="margin-right: 4px;">{{item.name}}</span>
              </template>
              <el-menu-item-group>
                <!-- <el-menu-item :index="subItem.path" :disabled="subItem.is_vip==1" v-for="subItem in item.children" :key="subItem.id"
                  style="hover:color:EEA128 ;" @click="savaNavState(subItem.path,subItem.name,subItem.is_vip)">
                  <i style="font-size: 40px;margin-right: 10px;">·</i>{{subItem.name}}<img v-if="subItem.is_vip==1"
                    src="../assets/img/vipmenu.png" alt="">
                </el-menu-item> -->

               <el-menu-item :index="subItem.path" v-for="subItem in item.children" v-if="storeinfo.isVip==0"
                  :key="subItem.id" style="hover:color:EEA128 ;"
                  @click="savaNavState(subItem.path,subItem.name,subItem.is_vip,subItem)">
                  <i style="font-size: 40px;margin-right: 10px;">·</i>{{subItem.name}}<img v-if="subItem.is_vip==1"
                    style="margin-left: 8px;" src="../assets/img/vipmenu.png" alt="">
                </el-menu-item>

                <el-menu-item :index="subItem.path" v-for="subItem in item.children" v-if="storeinfo.isVip==1"
                   :key="subItem.id" style="hover:color:EEA128 ;"
                   @click="savaNavState(subItem.path,subItem.name,subItem.is_vip,subItem)">
                   <i style="font-size: 40px;margin-right: 10px;">·</i>{{subItem.name}}
                 </el-menu-item>

                <!-- <el-menu-item v-if="is_vip==1" v-for="subItem in item.children" :key="subItem.id"
                  style="hover:color:EEA128 ;" @click="savaNavState(subItem.path,subItem.name,subItem.is_vip,subItem)">
                  <i style="font-size: 40px;margin-right: 10px;">·</i>{{subItem.name}}<img v-if="subItem.is_vip==1"
                    style="margin-left: 8px;" src="../assets/img/vipmenu.png" alt="">
                </el-menu-item> -->

              </el-menu-item-group>
            </el-submenu>
            <!-- <el-menu-item index="/updateLog" style="hover:color:EEA128 ;">
              更新日志
            </el-menu-item> -->
          </el-menu>

        </el-aside>
        <el-container style="width: 1190px;margin-left: 210px;">
          <el-header>
            <div style="display: flex;">
              <div style="display: flex;align-items: center;text-align: left;cursor:  pointer;line-height: 20px;"
                @click="wxrouter()">
                <img src="../assets/img/WeChat.png" alt="">
                <div style="margin-left: 10px;">
                  <li style="font-size: 14px;font-weight: 400;color: #222222;opacity: 1;" v-if="storeinfo.isWechat!=1">
                    微信:未绑定</li>
                  <li style="font-size: 14px;font-weight: 400;color: #222222;opacity: 1;" v-else>微信:已绑定</li>
                  <li style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;">及时接收重要信息</li>
                </div>
              </div>
              <div style="width: 100px;text-align: center;">
                <el-divider direction="vertical"></el-divider>
              </div>
              <div style="display: flex;align-items: center;text-align: left;cursor:  pointer;line-height: 20px;"
                @click="phonerouter()">
                <img src="../assets/img/phone.png" alt="">
                <div style="margin-left: 10px;">
                  <li style="font-size: 14px;font-weight: 400;color: #222222;opacity: 1;" v-if="storeinfo.isPhone!=1">
                    手机号:未绑定</li>
                  <li style="font-size: 14px;font-weight: 400;color: #222222;opacity: 1;" v-else>手机号:已绑定</li>
                  <li style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;">避免错过重要消息，影响使用效果<i
                      class="el-icon-arrow-right"></i></li>
                </div>
              </div>
            </div>

            <div
              style="display: flex;align-items: center;text-align: left;float: right;cursor:  pointer;line-height: 20px;">

              <div style="margin-left: 10px;">
                <li style="font-size: 14px;font-weight: 400;color: #222222;opacity: 1;">店铺名称:{{storeinfo.s_name}}</li>
                <li style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;" v-if="storeinfo.isVip==0">
                  <span style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;">
                    <img src="../assets/img/viptag-n.png" alt="" style="margin-right: 8px;">初级会员</span>
                </li>

                <li style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;" v-if="storeinfo.isVip==1">
                  <span style="font-size: 12px;font-weight: 400;color: #E1B47B;opacity: 1;">
                    <img src="../assets/img/viptag-y.png" alt="" style="margin-right: 8px;">高级会员</span>
                  <el-divider direction="vertical"></el-divider>有效期至：{{storeinfo.vip_end_time}}
                </li>
                <li style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;" v-if="storeinfo.isVip==2">
                  <span style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;">
                    会员已过期
                  </span>
                </li>
              </div>
            </div>

          </el-header>
          <el-main>
            <router-view ref="home-router">
            </router-view>
          </el-main>
          <el-footer>
            <li
              style="font-size: 14px;font-weight: bold;color: #222222;opacity: 1;text-align: left;margin-top: 20px;height: 30px;line-height: 30px;">
              以上功能是否对你有帮助：</li>
            <div class="footer-icon">

              <div class="footer-item" @click="postzan">
                <img src="../assets/img/fabulous.png" alt="" style="width: 40px;height: 40px;" v-if="user_count<1">
                <img src="../assets/img/e-fabulous.png" alt="" v-else>
                <li class="fabulous" v-if="user_count<1">很赞，鼓励一下</li>
                <li class="fabulous" v-else>已赞，感谢你的鼓励</li>
                <li class="number">{{zan}}</li>
              </div>

              <div class="footer-item" @click="addDialogVisible = true">
                <img src="../assets/img/feedback.png">
                <li class="fabulous">我要反馈</li>
                <li class="number"> </li>
              </div>
            </div>

          </el-footer>
        </el-container>

      </el-container>
    </el-container>

    <!-- 建议反馈 -->
    <el-dialog title="建议反馈" :visible.sync="addDialogVisible" width="500px" @close="addDialogClosed">
      <!--对话框内容-->
      <el-form :model="proposalForm" :rules="proposalFormRules" ref="proposalFormRef" label-width="80px">

        <el-form-item prop="content">
          <el-input :rows="5" placeholder="想要反馈的内容" type="textarea" v-model="proposalForm.content" maxlength="100"
            show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="proposalForm.phone" placeholder="联系手机号"></el-input>
        </el-form-item>
      </el-form>
      <!--对话框操作-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">
          取 消</el-button>
        <el-button type="primary" @click="feedback" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">确
          定</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
  import bus from '../utils/bus.js'

  export default {
    data() {
      return {
        // 使用store
        openeds1: [''],
        menulist: [],
        isCollapse: false,
        activePath: '',
        PathName: '',
        visible: false,
        html: '<img src="">',
        storeinfo: [],
        addDialogVisible: false,
        proposalForm: {
          phone: '',
          content: ''
        },
        proposalFormRules: {
          phone: [{
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }],
          content: [{
              required: true,
              message: '请输入内容',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 300,
              message: '请输入3到7个字符',
              trigger: 'blur'
            }
          ]
        },
        zan: '',
        user_count: '',
        is_vip:''
      }
    },
    created() {
      this.openeds1.push(this.$route.path + '')
      this.getMenuList()
      this.getqueryInfo()
      this.getZan()
      this.activePath = window.sessionStorage.getItem('activePath')
      this.PathName = window.sessionStorage.getItem('PathName')
    },
    methods: {
      sx() {
        location.reload()
      },
      ces2() {
        this.$router.push('/commentlist/user/NotifyAccountManager?type=2')
      },
      async getMenuList() {
        const {
          data: res
        } = await this.$http.get('user/authaction/menus')

        this.menulist = res.data
        console.log(this.menulist)
      },
      async getZan(activePath) {
        const {
          data: res
        } = await this.$http.get('/user/useraction/getZan', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
            user_id: this.storeinfo.user_id,
            path: this.$route.path
          }
        })
        this.zan = res.data.count
        this.user_count = res.data.user_count
        // console.log(this.zan)
      },
      async postzan() {
        console.log(this.$route.path)
        const {
          data: res
        } = await this.$http.post('/user/useraction/zan', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          user_id: this.storeinfo.user_id,
          name: this.PathName,
          path: this.$route.path
        })
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.getZan()
      },
      async feedback() {
        const {
          data: res
        } = await this.$http.post('/user/useraction/feedback', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          user_id: this.storeinfo.user_id,
          name: this.PathName,
          path: this.activePath,
          mobile: this.proposalForm.phone,
          content: this.proposalForm.content
        })
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.addDialogVisible = false
      },
      savaNavState(activePath, PathName, is_vip, subItem) {
        console.log(this.$refs)
        this.is_vip = is_vip
        console.log(subItem)
        // if (is_vip != 0) {
        //   return this.$message.error('你还不是会员，请开通会员!')
        // }
        this.activePath = activePath
        this.PathName = PathName
        window.sessionStorage.setItem('activePath', activePath)
        window.sessionStorage.setItem('PathName', PathName)
        this.getZan()
      },
      handleOpen(key, keyPath) {},
      handleClose(key, keyPath) {},
      // 获取店铺信息
      async getqueryInfo() {
        const {
          data: res
        } = await this.$http.get('/user/shop/queryInfo', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id'))
          }
        })
        this.storeinfo = res.data
        console.log('成功')
      },
      wxrouter(is_vip) {
        this.is_vip = 0
        console.log(this.is_vip)
        this.$router.push({
          path: '/user/NotifyAccountManagement?type=1'
        })
      },
      phonerouter(is_vip) {
        this.is_vip = 0
        console.log(this.is_vip)
        this.$router.push({
          path: '/user/NotifyAccountManagement?type=2'
        })
      },
      dxrouter() {
        this.$router.push({
          path: '/recharge/Message'
        })
      },
      indexrouter() {
        this.$router.push({
          path: '/index'
        })
      },
      addDialogClosed() {
        this.$refs.proposalFormRef.resetFields()
      },
      testA () {
        console.log('由A组件调用')
      },
    },
    mounted() {
      bus.$on('getqueryInfo', this.getqueryInfo)
    }
  }
</script>

<style scoped>
  .homeBox {
    height: 100%;
    background-color: #F7F8FA;
  }

  .home-container {
    width: 100%;
    height: 100%;
  }

  .el-menu-item {
    height: 45px !important;
    line-height: 45px !important;
  }

  .el-menu-item:hover {
    background-color: rgba(162, 164, 179, 0.20) !important;
    z-index: 9999;
  }

  .el-menu-item-group__title {
    padding: 0px !important;
  }

  .el-header {
    height: 70px !important;
    margin-bottom: 10px;
    background-color: #FFFFFF;
    color: #333;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    top: 0;
    width: 990px;
    z-index: 3;
    border-bottom: 10px solid #F7F8FA;

  }

  .el-footer {
    height: 160px !important;
    margin-bottom: 10px;
  }

  .footer-icon {
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    width: 500px;
  }

  .el-header,
  .el-footer {
    background-color: #FFFFFF;
    color: #333;
    text-align: center;
  }

  .el-aside {
    background-color: #3F4156;
    color: #D2D2D2;
    text-align: center;
    height: 100%;
    z-index: 10;
  }

  .aside-header {
    width: 100%;
    font-size: 12px;
    line-height: 60px;
    height: 60px;
    border-bottom: 2px solid #BEBDBD;
    text-align: center;
  }

  .aside-header span {
    font-size: 14px;
    font-weight: 400;
    color: #D2D2D2;
    opacity: 1;
    margin: 0 3px;
  }

  .aside-userinfo {
    margin: 25px 0;
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer
  }

  .userinfo-logo {
    height: 72px;
    width: 72px;
    border-radius: 50%;
    margin-top: 41px;
  }

  .aside-userinfo li {
    margin: 5px 0;
  }

  .aside-userinfo i {
    margin-right: 5px;
    color: #CF9236;
    font-size: 14px;
  }

  .el-main {
    background-color: #FFFFFF;
    color: #333;
    overflow-y: overlay;
    margin-bottom: 10px;
    margin-top: 70px;
    padding: 0 0 20px 0px;

  }

  .el-container {
    height: 100%;
    width: 1200px;
  }

  body>.el-container {
    margin-bottom: 40px;
    height: 100%;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

  .el-menu {
    border-right: none;
    text-align: left;
  }

  .main-topimg {
    height: 200px;
  }

  ::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  ::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  .fabulous {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
  }

  .number {
    font-size: 14px;
    font-weight: 400;
    color: #3F4156;
    opacity: 1;
    padding-left: 0;
    text-align: center;
  }

  .footer-item {
    cursor: pointer;
    width: 50%;
    padding: 10px;
  }
</style>
