<template>
  <div>
    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
      <span class="clearfix-title">{{ type === '1' ? '微信' : '手机' }}号管理</span>
      <span class="clearfix-Subtitle">{{ type === '1' ? '绑定即可接收重要提醒' : '绑定即可接收重要提醒' }}</span>
    </div>

    <!--添加按钮与提示-->
    <div class="pad-l20 pad-r20" style="padding-top: 20px;">
      <el-button size="small" icon="el-icon-plus" type="primary" @click="bindAccount">添加{{
          type === '1' ? '微信' : '手机'
        }}号
      </el-button>
      <span class="add-tips">最多添加5个</span>
    </div>
    <!--绑定信息列表-->
    <div class="mar-t20 pad-l20 pad-r20">
      <el-table :stripe="true" v-if="tableData&&tableData.length" show-header
        :header-cell-style="{background:'#F7F8FA',color:'#666','font-weight': 'bold','font-size':'15px'}"
        :cell-style="{color:'#565A6E'}" :data="tableData" class="radius2">
        <el-table-column type="index" label="编号" width="80" prop="id">
        </el-table-column>
        <el-table-column :label="type==='1'?'微信号':'手机号'" width="180" align="left">

          <template slot-scope="scope">
            <div v-if="type==='1'" style="display: flex;align-items: center;">
              <el-avatar size="small" :src="scope.row.avatar"></el-avatar>
              <span style="margin-left: 5px;">{{ scope.row.nickname }}</span>
            </div>
            <div v-else @click="editBind(scope.row)">{{ scope.row.account }}</div>
          </template>

        </el-table-column>
        <el-table-column prop="content" label="提醒内容" align="left">
        </el-table-column>
        <el-table-column prop="utime" label="添加时间" width="160" align="left">
        </el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <a style="color: #F89800;" @click="postnotifyAccountEdit(scope.row)" class="cursor-pointer">编辑</a>
            <el-divider direction="vertical"></el-divider>
            <a @click="unbindAccount(scope.row)" class="cursor-pointer" style="color: #F76C6C;">删除</a>
          </template>
        </el-table-column>
      </el-table>

      <!--绑定信息列表无数据时显示-->
      <div v-else class="center-column">
        <img src="../../assets/img/null.png" class="nodata">
        <div class="mar-t20 color666 size15">暂无数据</div>
      </div>
    </div>

    <!--绑定手机或微信弹窗-->
    <el-dialog :title="type==='1'?'绑定微信号':'绑定手机号'" :visible.sync="bindAccountBox" width="500px"
      @close="bindAccountBoxClose">
      <div>
        <div>
          <div v-if="type==='1'" style="text-align: center;">避免错过重要消息影响使用效果</div>
          <div v-else style="text-align: left;margin-bottom: 10px;">避免错过重要消息影响使用效果</div>

          <div class="center-column" v-if="type==='1'">
            <div style="color: #f56d6f">免费提醒</div>
            <div style="border: 1px solid #E9EAF2;border-radius: 2px;margin-top: 15px;">
              <img v-if="!bind.pic_url" src="../../assets/img/loading.gif" class="qrcode">
              <el-image v-if="bind.pic_url" :src="bind.pic_url" class="qrcode"></el-image>
            </div>
            <div style="margin-top: 8px;font-size: 14px;font-weight: 400;color: #3F4156;opacity: 1;">扫一扫绑定微信</div>
          </div>

          <div style="color: #f56d6f;text-align: right;" v-else>
            <el-form :model="bind" ref="bind">
              <el-form-item label="手机号">
                <el-input style="width: 200px;" placeholder="输入手机号" v-model="bind.account" size="small"></el-input>
              </el-form-item>
              <el-form-item label="验证码" style="display: flex;align-items: center;">
                <el-input placeholder="请输入验证码" v-model="bind.captcha" size="small" style="width: 100px;height: 32px;display: flex;align-items: center;"></el-input>
                <span>
                  <el-button @click.prevent="getCode()" style="margin-left: 10px;display: flex;align-items: center;width: 90px;height: 32px;background: #FFFFFF;border: 1px solid #F89800;opacity: 1;border-radius: 2px;" size="small" :disabled="!show">
                    <span v-show="show" style="font-size: 12px;font-weight: 400;color: #F89800;opacity: 1;">获取验证码</span>
                    <span v-show="!show">重新获取({{count}})</span>
                  </el-button>
                </span>
              </el-form-item>
            </el-form>
            <el-button @click="bindAccountBox=false" size="small"
              style="padding: 0 18px;height: 36px;font-size: 14px;">取消</el-button>
            <el-button @click="bindPhone('bind')" type="primary" size="small"
              style="padding: 0 18px;height: 36px;font-size: 14px;">保存</el-button>

            <!-- <el-button type="primary"></el-button> -->
          </div>

        </div>
      </div>

    </el-dialog>

    <!--编辑提醒内容-->
    <el-dialog title="编辑提醒内容" :visible.sync="editReminderContent" width="500px">
      <div>

        <div style="height: 60px;display: flex;align-items: center;border-top: 1px solid #E9EAF2;">
          <div style="display: flex;align-items: center;">
            <li style="font-size: 14px;font-weight: 400;color: #56576A;opacity: 1;width: 100px;"><span
                v-if="this.nickname!=undefined">微信号</span><span v-if="this.account!=undefined">手机号</span></li><span
              style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;">{{nickname}} {{account}}</span>
          </div>
        </div>


        <div style="border-top: 1px solid #E9EAF2;" v-for="(item,index) in isopendata" :key="index">
          <div style="height: 60px;display: flex;align-items: center;justify-content: space-between;">
            <div style="display: flex;align-items: center;">
              <li style="font-size: 14px;font-weight: 400;color: #56576A;opacity: 1;width: 100px;">{{item.title}}</li>
              <span
                style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{item.describe}}</span>
            </div>
            <div style="width: 200px;text-align: right;">
              <el-switch v-model="item.switch" active-color="#fed06a" @change="changeswitch(item)"></el-switch>
            </div>
          </div>
          <div style="height: 60px;display: flex;align-items: center;justify-content: space-between;">
            <div style="display: flex;align-items: center;">
              <li style="font-size: 14px;font-weight: 400;color: #56576A;opacity: 1;width: 100px;" v-if="item.type!=3">
                提醒时间</li>
              <li style="font-size: 14px;font-weight: 400;color: #56576A;opacity: 1;width: 100px;" v-if="item.type==3">
                订单毛利低于</li>
              <span style="font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;"></span>
            </div>
            <div style="width: 200px;text-align: right;" v-if="item.type!=3">
              <el-time-select style="width: 100%;" v-model="item.recieve_time" :editable="false" :clearable="false"
                @change="isopen(item.title,item)" :picker-options="{
                    start: '00:30',
                    step: '01:00',
                    end: '23:30'
                  }" placeholder="请选择时间" size="small">
              </el-time-select>
            </div>
            <div v-if="item.type==3">
              <el-input v-model="item.profit" prefix-icon="el-icon-warning-outline" clearable autocomplete="off"
                @change="isopen(item.title,item)" style="width: 200px;" size="small" v-number-input.float="2"
                maxlength="10">
              </el-input>
            </div>
          </div>
        </div>

      </div>
    </el-dialog>

  </div>
</template>

<script>
  import bus from '../../utils/bus.js'

  export default {
    name: 'NotifyAccountManger',
    data() {
      return {
        isopendata: [],
        show: 'false',
        count: '',
        nickname: '',
        account: '',
        openvalue: false,
        opentime: '09:00',
        sId: sessionStorage.getItem('s_id'),
        type: this.$route.query.type,
        tableData: undefined,
        editReminderContent: false,
        bindAccountBox: false,
        bind: {
          pic_url: undefined,
          account: undefined,
          captcha: undefined,
        },
        bindRules: {
          account: [{
              required: true,
              message: '请输入手机号码',
              trigger: 'blur'
            },
            {
              min: 11,
              max: 11,
              message: '请输入11位手机号码',
              trigger: 'blur'
            },
            {
              pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
              message: '请输入正确的手机号码'
            }
          ],
          captcha:[{
              required: true,
              message: '请输入验证码',
              trigger: 'blur'
            },
            {
              min: 4,
              max: 4,
              message: '请输入4位验证码',
              trigger: 'blur'
            }
          ],
        },
        accountId: ''
      }
    },
    methods: {
      async getCode() {
        //axios请求
        const {data:res} = await this.$http.post('/user/index/getCode', {
          mobile:this.bind.account
        })
        if(res.code!=1){
          return this.$message({
            type: 'error',
            message: res.msg
          });
        }
        this.$message.success(res.msg);
        // 验证码倒计时
        if (!this.timer) {
          this.count = 60;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
      },
      isopen(title, item) {
        this.$confirm('对' + title + '的设置是否立即生效', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
          this.changeswitch(item)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      async changeswitch(item) {
        var enable = ''
        if (item.switch == true) {
          enable = 1
        } else {
          enable = 0
        }

        const {
          data: res
        } = await this.$http.post('/user/remind/setNotice', {
          s_id: this.sId,
          id: this.accountId,
          type: item.type,
          enable: enable,
          recieve_time: item.recieve_time,
          profit: item.profit
        })
        if (res.code != 1) {
          item.switch = false
          console.log(item.switch)
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        console.log(item.switch)
      },
      getNotifyAccountList() {
        this.tableData = undefined
        this.$api.post('/user/user/notify_account_list', {
          type: this.type,
          s_id: this.sId
        }).then(res => {
          const tableData = []
          const type = Number(this.type)
          for (const re of res) {
            if (type === re.type) {
              tableData.push(re)
            }
          }
          this.tableData = tableData
          bus.$emit('getqueryInfo')
          // console.log(this.tableData)
        })
      },
      bindAccount() {
        if (this.tableData.length >= 5) {
          return this.$message({
            message: '最多添加5个',
            type: 'error'
          })
        }
        this.bind.account = undefined
        this.bind.pic_url = undefined
        this.bindAccountBox = true
        if (this.type === '1') {
          this.$api.get('/user/user/wechat_bind_data', {
            type: 1,
            s_id: this.sId
          }).then(res => {
            this.bind.pic_url = res.pic_url
            this.monitorBindIntervalId = setInterval(callback => {
              this.monitorBind(res.timestamp)
            }, 2000)
          })
        }
      },
      monitorBind(timestamp) {
        this.$api.get('/user/user/wechat_bind_cron', {
          timestamp,
          type: 1,
          s_id: this.sId
        }, true).then(res => {
          if (res.code === 1 || res.code === -1) {
            this.bindAccountBox = false
            clearInterval(this.monitorBindIntervalId)
            if (res.code === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定'
              })
            }
            this.getNotifyAccountList()
          }
        })
      },
      bindAccountBoxClose() {
        console.log('1')
        if (this.type === '1') {
          clearInterval(this.monitorBindIntervalId)
        }
      },
      bindPhone(formName) {
        console.log(this.type)
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            if(this.type==2){
              const {data:res} = await this.$http.post('/user/index/checkCode',{
                mobile:this.bind.account,
                code:this.bind.captcha
              })
              if(res.code!=1){
                return this.$message.error(res.msg)
              }
              this.$message.success(res.msg)
            }
            this.$api.post('/user/user/notify_account_edit', {
              id: this.bind.id,
              account: this.bind.account,
              type: 2,
              s_id: this.sId
            }, true).then(res => {
              if(res.code!=1){
                return this.$message.error(res.msg)
              }
              this.$message.success(res.msg)
              this.bind = []
              this.bindAccountBox = false
              this.getNotifyAccountList()
            })
          }
        })
      },
      editBind(e) {
        if (this.type === '1') {
          this.editReminderContent = true
        } else {
          this.bindAccountBox = true
          this.bind.account = e.account
          this.bind.id = e.id
        }
      },
      unbindAccount(e) {
        this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'btnFalse btnPadd',
          cancelButtonClass: 'btnPadd'
        }).then(() => {
          this.$api.post('/user/Remind/notifyAccountDelete', {
            id: e.id,
            s_id: this.sId
          }, true).then(res => {
            this.$message({
              type: res.code === 0 ? 'error' : 'success',
              message: res.msg
            })
            this.getNotifyAccountList()
          })
        })
      },
      async postnotifyAccountEdit(item) {
        this.accountId = item.id
        this.editReminderContent = true


        this.nickname = item.nickname
        this.account = item.account

        // console.log(this.account)
        // console.log(this.nickname)
        // console.log(item)
        const {
          data: res
        } = await this.$http.post('/user/remind/getSet', {
          s_id: this.sId,
          id: item.id
        })
        // console.log(res)
        var isopendata = []
        var isswitch = false
        for (var i in res.data) {

          if (res.data[i].enable == 0) {
            isswitch = false
          } else {
            isswitch = true
          }
          if (res.data[i].recieve_time == '') {
            res.data[i].recieve_time = '09:00'
          }
          isopendata.push({
            describe: res.data[i].describe,
            enable: res.data[i].enable,
            recieve_time: res.data[i].recieve_time,
            profit: res.data[i].profit,
            title: res.data[i].title,
            type: res.data[i].type,
            switch: isswitch
          })
        }
        this.isopendata = isopendata
        // console.log(this.isopendata)
      }
    },
    created() {
      this.getNotifyAccountList()
      // this.postnotifyAccountEdit()
    },
    watch: {
      $route(to, from) {
        this.type = this.$route.query.type
        this.getNotifyAccountList()
      }
    }
  }
</script>

<style scoped>
  .el-form-item__content {
    margin: 0 !important;
  }
  .el-form-item {
  	margin-bottom: 11px;
  }
  .el-form-item__content{
  	text-align: left;
  	display: flex;
  	align-items: center;
  }
  .el-dialog__header {
  	padding: 20px 16px;
  }
  .el-dialog__body {
  	padding: 0px 16px 20px 18px !important;
  }
  .form-label {
    display: flex;
    align-items: center;
    width: 100px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #858692;
    opacity: 1;
  }

  .qrcode {
    width: 117px;
    height: 117px;
    margin: 15px;
  }

  .switch-sublabel {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #4A4A4A;
    float: left
  }

  .add-tips {
    margin-left: 20px;
    font-size: 15px;
    color: #666;
  }

  .nodata {
    margin-top: 100px;
    height: 250px;
  }

  hr {
    border: none;
    height: 1px;
    background-color: #e5e5e5;
  }
</style>
