import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        s_id: ''
    },
    mutations: {
        increment(state,s_id) {
            state.s_id=s_id
        }
    }
})

export default store
