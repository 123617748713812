import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login'
import Index from '../components/index.vue'
import Home from '../components/Home'
import Welcome from '../components/Welcome'
import Reply from '../components/comment/Reply.vue'
import Control from '../components/comment/Control.vue'
import Cost from '../components/dishes/Cost.vue'
import ActivityChart from '../components/chart/ActivityChart.vue'
import NotifyAccountManagement from '../components/user/NotifyAccountManagement'
import SessionLog from '../components/session_log/SessionLog'
import Grossprofit from '../components/profit/Grossprofit.vue'
import Anti from '../components/anti/Anti.vue'
import Profitorder from '../components/grossprofit/Profitorder.vue'
import Fixedcost from '../components/cost/Fixedcost.vue'
import Listquery from '../components/combination/Listquery.vue'
import Menusort from '../components/sort/Menusort.vue'
import PackageBagCost from '../components/package_bag_cost/PackageBagCost'
import PoorCommentWarn from '../components/poor_comment_warn/PoorCommentWarn'
import UrgePraise from '../components/praise/urgepraise.vue'
import GiveawayCost from '../components/giveaway_cost/GiveawayCost'
import Variouscosts from '../components/expenditure/Variouscosts.vue'
import Assessment from '../components/discount/assessment.vue'
import Ratingnotice from '../components/score/Ratingnotice.vue'
import Preferentialforecast from '../components/forecast/Preferentialforecast.vue'
import Giftcost from '../components/gift/Giftcost.vue'
import Lossnotice from '../components/notice/Lossnotice.vue'
import Message from '../components/recharge/Message.vue'
import ad_login from '../components/ad_login.vue'
import Analysis from '../components/analysis/analysis.vue'
import sessionSet from '../components/session_log/SessionSet.vue'
import error404 from '../components/404.vue'
import error403 from '../components/403.vue'


import '../assets/css/global.css'
import updateLog from '../components/updateLog'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/login',
    meta: {
      title: '外卖旺铺'
    }
  },
  {
    path: '/ad_login',
    component: ad_login,
    meta: {
      title: '外卖旺铺'
    }
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: '外卖旺铺'
    }
  },
  {
    path: '/index',
    component: Index,
    meta: {
      title: '外卖旺铺'
    }
  },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [{
        path: '/welcome',
        component: Welcome,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/commentlist/reply',
        component: Reply,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/commentlist/Control',
        component: Control,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/manage/food_cost',
        component: Cost,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/manage/activity',
        component: ActivityChart,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/user/NotifyAccountManagement',
        component: NotifyAccountManagement,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/im/index',
        component: SessionLog,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/manage/food_dpit',
        component: Grossprofit,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/goods/checkTogether',
        component: Anti,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/manage/orders_dpit',
        component: Profitorder,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/manage/management_cost',
        component: Fixedcost,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/order/FoodGropList',
        component: Listquery,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/manage/food_sort',
        component: Menusort,
        meta: {
          title: '外卖旺铺'
        }
      }, {
        path: '/manage/packing_cost',
        component: PackageBagCost,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/commentlist/warning',
        component: PoorCommentWarn,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/commentlist/UrgeComment',
        component: UrgePraise,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/giveaway_cost',
        component: GiveawayCost,
        meta: {
          title: '外卖旺铺'
        }
      }, {
        path: '/order/allCost',
        component: Variouscosts,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/goods/fullReductionassessment',
        component: Assessment,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/commentlist/scoreLog',
        component: Ratingnotice,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/goods/fullReductionCheck',
        component: Preferentialforecast,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/orders/gift',
        component: Giftcost,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/notice/loss',
        component: Lossnotice,
        meta: {
          title: '外卖旺铺'
        }
      }, {
        path: '/recharge/Message',
        component: Message,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/updateLog',
        component: updateLog,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/commentlist/hotKeywords',
        component: Analysis,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        path: '/im/setting',
        component: sessionSet,
        meta: {
          title: '外卖旺铺'
        }
      },
      {
        name: '403',
        path: '/403',
        component: error403,
      },
    ]
  },
  {
    name: '404',
    path: '/404',
    component: error404,
  }, {
    path: "*",
    redirect: "/404",
  },

]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
// router.beforeEach((to, from, next) => {
//   // to 将要访问的路径
//   // from 从哪个路径而来
//   // next 是一个函数
//   if (to.path === '/login') return next()
//   const tokenStr = window.sessionStorage.getItem('token')
//   console.log(tokenStr)
//   if (!tokenStr) return next('login')
//   next()
// })
export default router
