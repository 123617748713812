<template>
  <div>
    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
      <span class="clearfix-title">固定成本</span>
      <span class="clearfix-Subtitle">设置店铺每月固定运营支出</span>
    </div>
    <div style="padding: 20px;">

    <el-table :data="this.tableData.list" :stripe="true" style="width: 100%;"
    :header-cell-style="{'height':'60px'}"
    :cell-style="{'height':'60px'}"
    >
      <el-table-column label="成本类型">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center;">
             {{scope.row.name}}
            <el-tooltip popper-class="popoverBackB" class="item" effect="dark" :content="scope.row.describe" placement="right" v-show="scope.row.describe!=null">
              <!-- <span class="icon iconfont" style="margin-left: 3px;">&#xe682;</span>   -->
              <img src="../../assets/img/wh.png" alt="">
            </el-tooltip>

          </div>
        </template>
      </el-table-column>

      <el-table-column label="成本" width="200px" align="center">
        <template slot-scope="scope">
          <el-input style="width: 100px;" size="mini" v-model="scope.row.money" placeholder="请输入金额" @change="editData(scope.row)"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom-money">
      <li>每月总成本 <span>{{tableData.monthMoney}}</span>元</li>
      <li>每日总成本 <span>{{tableData.dayMoney}}</span>元</li>
    </div>
    </div>

  </div>
</template>

<script>
  export default {
    data () {
      return {
        input: '',
        s_id: sessionStorage.getItem('s_id'),
        tableData: []
      }
    },
    methods: {
      async getData () {
        const {
          data: res
        } = await this.$http.get('/user/operating/getData', {
          params: {
            s_id: this.s_id
          }
        })

        this.tableData = res.data
        // console.log(this.tableData)
      },
      async editData (tid) {
        // console.log(tid.tid)
        const {
          data: res
        } = await this.$http.post('/user/operating/editData', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
            tid: tid.tid,
            money: tid.money
          }
        })
        if (res.code != 1) {
          this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.getData()
      }
    },
    mounted () {
      this.getData()
    }
  }
</script>

<style scoped>
.bottom-money {
  margin-top: 20px;
  width: 100%;
  height: 60px;
  border: 1px solid #E9EAF2;
  opacity: 1;
  border-radius: 2px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
}
.bottom-money li {
  font-size: 14px;
  font-weight: 400;
  color: #222222;
  opacity: 1;
}
.bottom-money span {
  font-size: 24px;
}
</style>
