<template>
  <div>
    <!--页面标题-->
    <div class="header-title">
      <span class="clearfix-title">差评提醒</span><span class="clearfix-Subtitle">开启后系统会在第二天指定时间发送差评提醒。</span>
      <div style="float: right;">
        <span class="mar-r10 size15 colorF89800 cursor-pointer" @click="showOrCloseWarnSettings">设置差评提醒</span>
        <el-switch v-model="enableWarn" class="mar-l5" active-color="#fed06a" :active-value="1" :inactive-value="0"
          @change="setWarnOpenOrClose">
        </el-switch>
      </div>
    </div>

    <div v-if="showWarnSettings!==undefined"
      :class="showWarnSettings?'settings scrollbar settings-open':'settings scrollbar settings-close'">

      <el-form ref="ruleForm" class="max-w">
        <el-form-item label="*接收差评时间：">
          <el-time-select :picker-options="{
                    start: '00:30',
                    step: '01:00',
                    end: '23:30'
                  }" v-model="recieveHour" placeholder="选择时间" size="small">
          </el-time-select>
        </el-form-item>
      </el-form>

      <div class="pad-l20 mar-t10">
        <div class="flex">
          <div class="label-text">短信接收人 ：</div>
          <div>
            <div class="label-text size8">通过手机短信通知（最多5个）</div>
            <div></div>
            <div class="mar-t10" style="width: 70%">
              <el-checkbox class="mar-t5" v-model="item.select" v-for="item in warnTels" :true-label="1" :key="item"
                :false-label="0">{{ item.account }}
              </el-checkbox>
            </div>

            <div class="mar-t10">
              <div v-for="(item,index) in newWarnTels" :key="index">
                <el-form :model="item" :rules="newWarnTelsRules" ref="newWarnTels">
                  <el-form-item class="mar0 mar-b20" prop="account">
                    <el-input size="small" placeholder="添加手机号" class="width200" maxlength="11" v-model="item.account">
                    </el-input>
                    <span class="label-text size12 mar-l10 cursor-pointer"
                      @click="newWarnTels.splice(index,1)">删除</span>
                  </el-form-item>
                </el-form>
              </div>

              <div class="flex mar-tb10 center-vertical cursor-pointer"
                v-show="(warnTels.length+newWarnTels.length) < 5">
                <div class="flex" @click="newWarnTels.push({account:''});">
                  <img src="../../assets/img/add.svg" class="wh-20" />
                  <span class="label-text mar-l5">添加手机号<span class="color999 size12">（最多5个）</span></span>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="flex mar-t20">
          <div class="label-text ">微信接收人 ：</div>
          <div style="width: 70%">
            <div class="label-text size8">通过微信公众号通知到您微信（最多5个）</div>

            <div class="clearfix">
              <div style="width: 80px;height: 73px;" :class="['mar-r30','mar-t20','float-l']"
                v-for="item in warnWeChats" :key="item" @click="item.select=(item.select===1?0:1)" >
                <div style="width: 36px;height: 36px;margin-left: 22px;position: relative" class="mar-t10">
                  <img :src="item.avatar" class="max-w max-h radius50">
                  <div class="wechat-selected" v-if="item.select===1">
                    <img src="../../assets/img/wechat-selected.png">
                  </div>
                </div>
                <div class="show-only-one-line size14 color56576A pad-lr5 text-center">
                  {{ item.nickname }}
                </div>
              </div>

              <div style="width: 80px;height: 73px;background-color: #EEE" @click="bindAccount"
                class="mar-r30 mar-t20 float-l center size30 color3F4156">
                +
              </div>
            </div>

            <div class="mar-t35">
              <el-button type="primary" class="mar-r10 radius2" @click="saveSettings(2)">保存并开启</el-button>
              <el-button class="radius2" @click="showWarnSettings=false">取消</el-button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <p style="background-color: #FFF8E1;color: #FF6A00;height: 40px;" class="center-vertical pad-l20 size12">
      收到差评后，商家可以对不合理、不真实的评价进行申诉。申诉成功后，平台会依法处理这条评价。并把这条评价从计分评价中剔除。</p>

    <p class="size14 color3F4156 pad-l20">已发送{{ totalCount }}条差评提醒</p>
    <div style="padding-left: 20px; padding-right: 20px;">
      <el-table class="radius2 mar-t20" v-loading="loading" show-header
        :header-cell-style="{background:'#F7F8FA',color:'#666','font-weight': 'bold','font-size':'14px','text-align':'center'}"
        :cell-style="{'text-align':'center','font-size':'13px',color:'#565A6E'}" :data="poorCommentList">

        <el-table-column prop="id" label="编号">
        </el-table-column>

        <el-table-column prop="contact" label="微信昵称／手机号">
        </el-table-column>
        <el-table-column prop="message" label="提醒内容">
        </el-table-column>
        <el-table-column prop="send_time" label="发送时间">
        </el-table-column>

      </el-table>

      <el-pagination background layout="prev, pager, next, total" :total="totalCount"
        @current-change="poorCommentWarnPageChange" class="float-r mar-t35">
      </el-pagination>
    </div>
    <!--绑定手机或微信弹窗-->
    <el-dialog title="绑定微信号" :visible.sync="bindAccountBox" width="500" @close="bindAccountBoxClose">
      <div class=" mar-t30 mar-b30">
        <div class="center-column">
          <div>避免错过重要消息影响使用效果</div>
          <div class="center-column">
            <div class="mar-t30 box-shadow-aaa pad20 radius5">
              <img v-if="!wechat_qrcode" src="../../assets/img/loading.gif" class="qrcode">
              <el-image v-if="wechat_qrcode" :src="wechat_qrcode" class="qrcode"></el-image>
            </div>
            <div class="mar-t30 mar-b30 size10">扫一扫绑定微信</div>
          </div>
        </div>
      </div>

    </el-dialog>

  </div>
</template>

<script>
  export default {
    name: 'PoorCommentWarn',
    data () {
      return {
        sId: sessionStorage.getItem('s_id'), // 店铺id
        warnType: 1, // 提醒类型
        poorCommentList: [], // 差评列表
        showWarnSettings: undefined, // 显示提醒设置
        loading: false, // 加载中
        page: 1, // 当前页
        pageSize: 10, // 每页大小
        totalCount: 0, // 总记录数
        enableWarn: 0, // 是否打开差评提醒
        recieveHour: '09:00',
        warnWeChats: [],
        warnTels: [],
        newWarnTels: [],
        newWarnTelsRules: {
          account: [{
              required: true,
              message: '请输入手机号码',
              trigger: 'blur'
            },
            {
              min: 11,
              max: 11,
              message: '请输入11位手机号码',
              trigger: 'blur'
            },
            {
              pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
              message: '请输入正确的手机号码'
            }
          ]
        },
        bindAccountBox: false,
        wechat_qrcode: ''
      }
    },
    methods: {
      poorCommentWarnPageChange (e) {
        this.page = e
        this.getPoorCommentWarnList()
      },
      setWarnOpenOrClose (enable) {
        if ((this.warnTels.length + this.warnWeChats.length) < 1) {
          this.showWarnSettings = !this.showWarnSettings
          return
        }
        this.saveSettings(enable)
      },
      getPoorCommentWarnList () {
        this.$api.post('/user/remind/noticeLog', {
          page: this.page,
          size: this.pageSize,
          s_id: this.sId
        }).then(res => {
          this.totalCount = res.total
          this.poorCommentList = res.lists
        })
      },
      getWarnSettings () {
        this.$api.post('/user/remind/getNoticeSetting', {
          type: 1,
          s_id: this.sId
        }).then(res => {
          this.enableWarn = res.enable
          this.recieveHour = res.recieve_time
          this.warnTels = res.mobile
          this.warnWeChats = res.wechat
        })
      },
      saveSettings (enable) {
        this.enableWarn = 1
        let flag = true
        for (let i = 0; i < this.newWarnTels.length; i++) {
          this.$refs.newWarnTels[i].validate((valid) => {
            if (!valid) {
              flag = false
              return flag
            }
          })
        }
        if (flag) {
          const params = {
            s_id: this.sId,
            type: this.warnType,
            enable: enable === 2 ? this.enableWarn : enable
          }
          if (enable === 1 || enable === 2) {
            params.recieve_time = this.recieveHour
            params.mobileIds = []
            params.wechatIds = []
            params.new_mobile = []
            for (const warnTel of this.warnTels) {
              if (warnTel.select === 1) {
                params.mobileIds.push(warnTel.id)
              }
            }
            for (const warnWeChat of this.warnWeChats) {
              if (warnWeChat.select === 1) {
                params.wechatIds.push(warnWeChat.id)
              }
            }
            for (const newWarnTel of this.newWarnTels) {
              params.new_mobile.push(newWarnTel.account)
            }
          }

          this.$api.post('/user/remind/noticeSetting', params, true).then(res => {
            if (res.code === 1) {
              this.enableWarn = enable === 2 ? this.enableWarn : enable
            } else {
              this.enableWarn = 0
            }
            this.$message({
              message: res.msg,
              type: res.code === 1 ? 'success' : 'error'
            })
          })
        }
      },
      bindAccount () {
        if (this.warnWeChats.length >= 5) {
          return this.$message({
            message: '最多只能添加五个微信号',
            type: 'error'
          })
        }
        this.bindAccountBox = true
        this.$api.get('/user/user/wechat_bind_data', {
          type: 1,
          s_id: this.sId
        }).then(res => {
          this.wechat_qrcode = res.pic_url
          this.monitorBindIntervalId = setInterval(callback => {
            this.monitorBind(res.timestamp)
          }, 2000)
        })
      },
      monitorBind (timestamp) {
        this.$api.get('/user/user/wechat_bind_cron', {
          timestamp,
          type: 1,
          s_id: this.sId
        }, true).then(res => {
          if (res.code === 1 || res.code === -1) {
            this.bindAccountBox = false
            clearInterval(this.monitorBindIntervalId)
            if (res.code === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定'
              })
            }
            this.getWarnSettings()
          }
        })
      },
      bindAccountBoxClose () {
        clearInterval(this.monitorBindIntervalId)
      },
      showOrCloseWarnSettings () {
        this.showWarnSettings = !this.showWarnSettings
      }
    },
    created () {
      this.getPoorCommentWarnList()
      this.getWarnSettings()
    }
  }
</script>

<style scoped>
  .settings {
    overflow: auto;
    margin-bottom: 10px;
    height: 0px;
    width: 100%;
    background-color: white;
  }

  .settings-open {
    animation: settings-open 0.5s;
    animation-fill-mode: forwards;
  }

  .settings-close {
    animation: settings-close 0.5s;
    animation-fill-mode: forwards;
  }

  @keyframes settings-open {
    0% {
      height: 0px;
    }

    100% {
      height: 600px;
    }
  }

  @keyframes settings-close {
    0% {
      height: 600px;
    }

    100% {
      height: 0px;
    }
  }

  .wechat-selected {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    overflow: hidden;
  }

  .wechat-selected img {
    height: 100%;
    width: 100%;
  }
</style>
