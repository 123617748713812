<template>
  <div v-loading="loading">
    <!--页面标题-->
    <div class="header-title" style="    border-bottom:none;">
      <span class="clearfix-title">亏损通知</span><span class="clearfix-Subtitle">当实收金额低于预设值，将会进行通知，及时调整减少损失</span>
      <!-- <div style="float: right;">
        <span class="mar-r10 size15 colorF89800 cursor-pointer" @click="openWarnSettings()">设置亏损提醒</span>

        <el-switch :value="enableWarn" class="mar-l5" active-color="#fed06a" :active-value="1" :inactive-value="0"
          @change="setWarnOpenOrClose">
        </el-switch>

      </div> -->
    </div>

    <div>
      <el-form ref="ruleForm" label-width="110px" style="padding:20px;">
        <el-form-item label="订单毛利低于：">
          <el-input v-model="profit" prefix-icon="el-icon-warning-outline" clearable autocomplete="off"
            style="width: 200px;" size="small" v-number-input.float="2" maxlength="10">
          </el-input>
        </el-form-item>
        <el-form-item label="短信接收人：">
          <div class="size8">通过手机短信通知（最多5个）</div>
          <div style="display: flex;">
            <div v-for="(item,index) in warnTels" @click="item.select=(item.select===1?0:1)" v-model="item.select"
              class="checkeBox" @mouseenter="enter(index)" @mouseleave="leave(index)">

              <div style="width: inherit;position: absolute;" v-show="item.seen" id="others">
                <i class="el-icon-error"
                  style="position: absolute;right: -7px;top: -7px;font-size: 16px;color: #BABCCC;background-color: #FFFFFF;z-index: 121;"
                  @click="deletephone(index,item.id)"></i>
              </div>

              <div :class="{phoneselected:item.select===1}" class="checkphone" :key="index">
                {{ item.account }}
              </div>

            </div>
            <div class="flex center-vertical cursor-pointer" v-show="warnTels.length <= 5">
              <div class="flex" @click="addPhone">
                <img src="../../assets/img/add.svg" class="wh-20" />
                <span class="label-text mar-l5" style="font-size: 14px;font-weight: 400;color: #F89800;opacity: 1;">添加手机号</span>
              </div>
            </div>
          </div>
          <div class="mar-t10">

          </div>
        </el-form-item>

        <el-form-item label="微信接收人：">
          <div class="size8">通过微信公众号通知到您微信（最多5个）</div>
          <div style="align-items: baseline;align-items: center;display: flex;">
            <div @mouseenter="enter1(index)" @mouseleave="leave1(index)" :class="{wechatselected:item.select===1}"
              style="width: 124px;height: 100px;border: 1px solid #E9EAF2;opacity: 1;border-radius: 2px;display: flex;justify-content: center;align-items: center;margin-right: 10px;"
              v-for="(item,index) in warnWeChats" @click="item.select=(item.select===1?0:1)">
              <div style="width: inherit;position: absolute;height: inherit;" v-show="item.seen" id="others">
                <i class="el-icon-error"
                  style="position: absolute;right: -7px;top: -7px;font-size: 16px;color: #BABCCC;background-color: #FFFFFF;z-index: 111;"
                  @click="deletephone1(index,item.id)"></i>
              </div>
              <div>
                <div style="width: 36px;height: 36px;margin: auto;;position: relative" class="mar-t10">
                  <img :src="item.avatar" class="max-w max-h radius50">
                </div>
                <div class="show-only-one-line size14 color56576A pad-lr5 text-center"
                  style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100px;">
                  {{ item.nickname }}
                </div>
              </div>
<!--              <div class="wechat-selected" style="margin-left: 1px;" v-if="item.select===1">
              </div> -->
            </div>
            <div v-if="warnWeChats.length<5"
              style="width: 124px; height: 100px; border: 1px solid rgb(233, 234, 242); opacity: 1; border-radius: 2px; display: flex; justify-content: center; align-items: center; margin-right: 10px;"
              @click="bindAccount">
              <span style="font-size: 38px;font-weight: 400;color: #3F4156;opacity: 1;">+</span>
            </div>

          </div>
        </el-form-item>

        <el-form-item label="开启亏损通知：">
          <el-switch v-model="enableWarn" active-color="#fed06a" inactive-color="#858692" @change="setWarnOpenOrClose">
          </el-switch>
        </el-form-item>


        <div style="margin-left: 110px;margin-top: 50px;">
          <el-button type="primary" class="radius2" @click="saveSettings(2)">保存</el-button>
        </div>

      </el-form>


    </div>

    <div style="border-top: 10px solid rgb(247, 248, 250);;">
      <p class="size14 color3F4156 pad-l20 pad-r20">已发送{{ totalCount }}条亏损</p>
      <div class="pad-l20 pad-r20">


        <el-table class="radius2 mar-t20" v-loading="loading" show-header :stripe="true"
          :header-cell-style="{background:'#F7F8FA',color:'#666','font-weight': 'bold','font-size':'14px','height':'60px'}"
          :cell-style="{'font-size':'13px','color':'#565A6E','height':'60px'}" :data="poorCommentList">
          <el-table-column label="订单编号" width="200px" align="left">
            <template slot-scope="scope">
              <el-popover placement="bottom" width="400" trigger="hover" el-popover popper-class="popoverBackB"
                :visible-arrow="false">
                <div class="popover-content">
                  <div class="popover-order_id">
                    <li>订单编号：{{scope.row.order_id}}</li>
                    <li v-if="scope.row.order_data.status==1">用户已提交订单</li>
                    <li v-if="scope.row.order_data.status==2">可推送到App方平台也可推送到商家</li>
                    <li v-if="scope.row.order_data.status==4">商家已确认</li>
                    <li v-if="scope.row.order_data.status==6">已配送</li>
                    <li v-if="scope.row.order_data.status==8">已完成</li>
                    <li v-if="scope.row.order_data.status==9">已取消</li>
                    <li v-if="scope.row.order_data.status==7">有退款</li>
                    <li v-if="scope.row.order_data.status==5">全额退款</li>
                  </div>
                  <div class="order_title">
                    <li>商品信息<span class="order_span">{{scope.row.order_data.goods_type_num}}种商品
                        共{{scope.row.order_data.goods_num}}件</span></li>
                  </div>
                  <div>
                    <div class="order_title" v-for="(item,index) in scope.row.order_data.result.data.detail">
                      <li>{{item.food_name}}<span class="order_span">×{{item.quantity}}</span></li><span
                        class="order_span">￥{{item.price}}</span>
                    </div>
                  </div>


                  <div style="margin-top: 10px;" v-if="scope.row.order_data.result.data.activity.length>0">
                    <div class="order_discount" v-for="(item,index) in scope.row.order_data.result.data.activity">
                      {{item.remark}}
                    </div>
                  </div>
                </div>
                <span slot="reference">{{scope.row.order_id}}</span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="通知方式" width="180px" align="left">
            <template slot-scope="scope">
              <div>
                <div style="text-align: left;">
                  <li v-if="scope.row.send_wechat==1"
                    style="font-size: 14px;font-weight: 400;color: #3F4156;opacity: 1;">
                    <img src="../../assets/img/wx-icon.png" alt=""
                      style="width:12px;height: 12px;margin-right: 5px;">微信通知
                  </li>
                  <li v-if="scope.row.send_mobile==1"
                    style="font-size: 14px;font-weight: 400;color: #3F4156;opacity: 1;">
                    <img src="../../assets/img/min-phone.png" alt=""
                      style="width:12px;height: 12px;margin-right: 5px;">短信通知
                  </li>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="sendTime" label="发送时间" width="200px" align="left">
          </el-table-column>
          <el-table-column label="本单收入" align="right">
            <template slot-scope="scope">
              <span>￥{{scope.row.total_price}}</span>
            </template>
          </el-table-column>
          <el-table-column label="本单成本" align="right">
            <template slot-scope="scope">
              <span>￥{{scope.row.total_cost}}</span>
            </template>
          </el-table-column>
          <el-table-column label="预估毛利(元)" align="right">
            <template slot-scope="scope">
              <span v-if="scope.row.gross_profit>=0" class="Znumber">+{{scope.row.gross_profit}}</span>
              <span v-if="scope.row.gross_profit<0" class="Fnumber">{{scope.row.gross_profit}}</span>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination background layout="prev, pager, next, total" :total="totalCount"
          @current-change="poorCommentWarnPageChange" class="float-r mar-t35">
        </el-pagination>
      </div>
    </div>

    <!--绑定手机或微信弹窗-->
    <el-dialog title="绑定微信号" :visible.sync="bindAccountBox" width="400px" @close="bindAccountBoxClose">
      <div>
        <div class="center-column">
          <div style="font-size: 14px;font-weight: 400;color: #3F4156;opacity: 1;">避免错过重要消息影响使用效果</div>
          <div style="font-size: 14px;font-weight: 400;color: #F76C6C;opacity: 1;margin-bottom: 10px;">免费提醒</div>

          <div class="center-column">
            <div style="border: 1px solid #E9EAF2;border-radius: 2px;margin-top: 15px;">
              <img v-if="!wechat_qrcode" src="../../assets/img/loading.gif" class="qrcode">
              <el-image v-if="wechat_qrcode" :src="wechat_qrcode" class="qrcode"></el-image>
            </div>
            <div style="margin-top: 8px;font-size: 14px;font-weight: 400;color: #3F4156;opacity: 1;">扫一扫绑定微信</div>
          </div>

        </div>
      </div>
    </el-dialog>
    <!--绑定手机或微信弹窗-->
    <el-dialog title="添加手机号" :visible.sync="addPhonedialog" width="500px"
      @close="addPhonedialogClose">
      <div>
        <div>
          <div style="text-align: left;margin-bottom: 10px;">避免错过重要消息影响使用效果</div>
          <div style="color: #f56d6f;text-align: right;">
            <el-form :model="bind" ref="bind">
              <el-form-item label="手机号">
                <el-input style="width: 200px;" placeholder="输入手机号" v-model="bind.account" size="small"></el-input>
              </el-form-item>
              <el-form-item label="验证码" style="display: flex;align-items: center;">
                <el-input placeholder="请输入验证码" v-model="bind.captcha" size="small" style="width: 100px;height: 32px;display: flex;align-items: center;"></el-input>
                <span>
                  <el-button @click.prevent="getCode()" :class="{yzm:isyzm==1}" style="margin-left: 10px;width: 90px;display: flex;align-items: center;padding: 0;justify-content: center;height: 32px;" size="small" :disabled="!show">
                    <span v-show="show" style="font-size: 12px;font-weight: 400;color: #F89800;opacity: 1;">获取验证码</span>
                    <span v-show="!show" style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;">重新获取({{count}})</span>
                  </el-button>
                </span>
              </el-form-item>
            </el-form>
            <el-button @click="bindAccountBox=false" size="small"
              style="padding: 0 18px;height: 36px;font-size: 14px;">取消</el-button>
            <el-button @click="bindPhone('bind')" type="primary" size="small"
              style="padding: 0 18px;height: 36px;font-size: 14px;">保存</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import bus from '../../utils/bus.js'

  export default {
    name: 'PoorCommentWarn',
    data() {
      return {
        bind: {
          pic_url: undefined,
          account: undefined,
          captcha: undefined,
        },
        isyzm:1,
        show: 'false',
        count: '',
        sId: sessionStorage.getItem('s_id'), //店铺id
        seen: false,
        seen1: false,
        addPhonedialog: false,
        warnType: [3], //提醒类型
        poorCommentList: [], //亏损列表
        showWarnSettings: false, //显示提醒设置
        loading: false, //加载中
        page: 1, //当前页
        pageSize: 10, //每页大小
        totalCount: 0, //总记录数
        enableWarn: false, //是否打开差评提醒
        profit: undefined,
        warnWeChats: [],
        warnTels: [],
        newWarnTels: [],
        newWarnTelsRules: {
          account: [{
              required: true,
              message: '请输入手机号码',
              trigger: 'blur'
            },
            {
              min: 11,
              max: 11,
              message: '请输入11位手机号码',
              trigger: 'blur'
            },
            {
              pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
              message: '请输入正确的手机号码'
            }
          ]
        },
        bindAccountBox: false,
        wechat_qrcode: '',
        type: '',
        enable: ''
      }
    },
    methods: {
      addPhone(){
        this.addPhonedialog = true
        console.log('1')
      },
      async getCode() {
        //axios请求
        const {data:res} = await this.$http.post('/user/index/getCode', {
          mobile:this.bind.account
        })
        if(res.code!=1){
          return this.$message({
            type: 'error',
            message: res.msg
          });
        }
        this.$message.success(res.msg);
        // 验证码倒计时
        if (!this.timer) {
          this.isyzm = 0
          this.count = 60;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
      },
      bindPhone(formName) {
        console.log(this.type)
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
              const {data:res} = await this.$http.post('/user/index/checkCode',{
                mobile:this.bind.account,
                code:this.bind.captcha
              })
              if(res.code!=1){
                return this.$message.error(res.msg)
              }
              this.$message.success(res.msg)
            this.$api.post('/user/user/notify_account_edit', {
              id: this.bind.id,
              account: this.bind.account,
              type: 2,
              s_id: this.sId
            }, true).then(res => {
              if(res.code!=1){
                return this.$message.error(res.msg)
              }
              this.$message.success(res.msg)
              this.bind = []
              this.addPhonedialog = false
              this.getWarnSettings()
            })
          }
        })
      },
      addPhonedialogClose () {
        // clearInterval(this.monitorBindIntervalId)
        console.log(1)
        this.bind = {}
      },
      async deletephone(index, id) {

        this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'btnFalse'
        }).then(async () => {
          const {
            data: res
          } = await this.$http.post('/user/user/notify_account_delete', {
            id: id,
            s_id: this.sId
          })
          if (res.code != 1) {
            return this.$message.error(res.msg)
          }
          this.$message.success(res.msg)
          bus.$emit('getqueryInfo')
          this.warnTels.splice(index, 1)
          this.getWarnSettings()
        })
      },
      async deletephone1(index, id) {

        this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'btnFalse'
        }).then(async () => {
          const {
            data: res
          } = await this.$http.post('/user/user/notify_account_delete', {
            id: id,
            s_id: this.sId
          })
          if (res.code != 1) {
            return this.$message.error(res.msg)
          }
          this.$message.success(res.msg)
          bus.$emit('getqueryInfo')
          this.warnTels.splice(index, 1)
          this.getWarnSettings()
        })
      },
      enter(index) {
        this.warnTels[index].seen = true
        // this.seen = true
        // console.log(this.warnTels)
        // console.log(index)
      },
      leave(index) {
        this.warnTels[index].seen = false
        // console.log(index)
      },
      enter1(index) {
        this.warnWeChats[index].seen = true
        // console.log(index)
      },
      leave1(index) {
        this.warnWeChats[index].seen = false
        // console.log(index)
      },
      openWarnSettings() {
        // console.log(this.showWarnSettings)
        this.showWarnSettings = !this.showWarnSettings
        if (this.showWarnSettings) {
          this.getWarnSettings()
        }
      },
      poorCommentWarnPageChange(e) {
        this.page = e
        this.getPoorCommentWarnList()
      },
      setWarnOpenOrClose(enable) {
        console.log(this.enableWarn)
        if (this.enableWarn == true) {
          this.enable = 1
        } else {
          this.enable = 0
        }
        // this.showWarnSettings = !this.showWarnSettings
        // console.log(enable)
        // console.log(this.enableWarn)
        // if (this.enableWarn === 1) {
        //   this.enable = '0'
        //   console.log(this.enable)
        // } else {
        //   this.enableWarn = 1
        //   this.enable = '1'
        // this.showWarnSettings = true
        // }

      },
      getPoorCommentWarnList() {
        this.$api.post('/user/Sendmsg/lists', {
          page: this.page,
          type: 3,
          size: this.pageSize,
          s_id: this.sId,
        }).then(res => {
          // console.log(res)
          this.totalCount = res.total
          this.poorCommentList = res.lists
          // console.log(this.poorCommentList)
          this.loading = false
        })
      },
      getWarnSettings() {
        this.$api.post('/user/remind/getNoticeSetting', {
          type: 3,
          s_id: this.sId
        }).then(res => {
          console.log(res)
          this.newWarnTels = []
          if (res.enable == 1) {
            this.showWarnSettings = true
            this.enableWarn = true
            this.enable = 1
          } else {
            this.enableWarn = false
          }


          this.enable = res.enable
          console.log(this.enable)

          if (res.profit == '') {
            this.profit = '0'
          }

          this.profit = res.profit

          var warnTels = []
          for (var i in res.mobile) {
            warnTels.push({
              account: res.mobile[i].account,
              id: res.mobile[i].id,
              select: res.mobile[i].select,
              utime: res.mobile[i].utime,
              seen: false
            })
          }
          this.warnTels = warnTels

          var warnWeChats = []
          for (var i in res.wechat) {
            warnWeChats.push({
              avatar: res.wechat[i].avatar,
              id: res.wechat[i].id,
              nickname: res.wechat[i].nickname,
              select: res.wechat[i].select,
              utime: res.wechat[i].utime,
              seen: false
            })
          }
          this.warnWeChats = warnWeChats

          // console.log(this.warnWeChats)
          // console.log(res)
        })
      },
      saveSettings() {
        // console.log(enable)
        let flag = true
        for (let i = 0; i < this.newWarnTels.length; i++) {
          this.$refs['newWarnTels'][i].validate((valid) => {
            if (!valid) {
              flag = false
              return flag
            }
          })
        }

        if (flag) {
          let params = {
            s_id: this.sId,
            type: this.warnType,
            enable: this.enable
          }

          params.profit = this.profit
          params.mobileIds = []
          params.wechatIds = []
          params.new_mobile = []
          for (let warnTel of this.warnTels) {
            if (warnTel.select === 1) {
              params.mobileIds.push(warnTel.id)
            }
          }
          for (let warnWeChat of this.warnWeChats) {
            if (warnWeChat.select === 1) {
              params.wechatIds.push(warnWeChat.id)
            }
          }
          for (let newWarnTel of this.newWarnTels) {
            params.new_mobile.push(newWarnTel.account)
          }

          this.$api.post('/user/remind/noticeSetting', params, true).then(res => {
            console.log(this.$refs)
            if (res.code === 1) {
              this.enableWarn = this.enable
              this.getWarnSettings()
              this.showWarnSettings = false
              bus.$emit('getqueryInfo')
              this.newWarnTels = []
            }
            this.$message({
              message: res.msg,
              type: res.code === 1 ? 'success' : 'error'
            })
          })
        }
      },
      bindAccount() {
        if (this.warnWeChats.length >= 5) {
          return this.$message({
            message: '最多只能添加五个微信号',
            type: 'error'
          })
        }
        this.bindAccountBox = true
        this.$api.get('/user/user/wechat_bind_data', {
          type: 3,
          s_id: this.sId
        }).then(res => {
          this.wechat_qrcode = res.pic_url
          this.monitorBindIntervalId = setInterval(callback => {
            this.monitorBind(res.timestamp)
          }, 2000)
        })
      },
      monitorBind(timestamp) {
        this.$api.get('/user/user/wechat_bind_cron', {
          timestamp,
          type: 1,
          s_id: this.sId
        }, true).then(res => {
          if (res.code === 1 || res.code === -1) {
            this.bindAccountBox = false
            clearInterval(this.monitorBindIntervalId)
            if (res.code === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定'
              })
            }
            this.getWarnSettings()
          }
        })
      },
      bindAccountBoxClose() {
        clearInterval(this.monitorBindIntervalId)
      }
    },
    created() {
      this.loading = true
      this.getPoorCommentWarnList()
      this.getWarnSettings()
    }
  }
</script>

<style scoped>
  .Znumber {
    color: #F76C6C;
    font-weight: bold;
  }

  .Fnumber {
    color: #63D29D;
    font-weight: bold;
  }

  .settings {
    overflow: auto;
    margin-bottom: 10px;
    height: 0px;
    width: 100%;
    background-color: white;
  }

  .settings-open {
    animation: settings-open 0.5s;
    animation-fill-mode: forwards;
  }

  .settings-close {
    animation: settings-close 0.5s;
    animation-fill-mode: forwards;
  }

  @keyframes settings-open {
    0% {
      height: 0px;
    }

    100% {
      height: 600px;
    }
  }

  @keyframes settings-close {
    0% {
      height: 600px;
    }

    100% {
      height: 0px;
    }
  }


  .yzm {
    display: flex;align-items: center;width: 90px;height: 32px;background: #FFFFFF;border: 1px solid #F89800;opacity: 1;border-radius: 2px;
  }
  .checkeBox {
    height: 45px;
    width: 125px;
    border: 1px solid #E9EAF2;
    margin-right: 10px;
    border-radius: 2px;
  }

  .checkphone {
    position: absolute;
    width: inherit;
    height: inherit;
    text-align: center;
    line-height: 45px;
  }

  .phoneselected {
    height: 45px;
    border: 1px solid #F89800;
    opacity: 1;
    border-radius: 2px;
    width: inherit;
    background-image: url(../../assets/img/xz.png);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: bottom right;
    width: 125px;
    position: relative;
    right: 1px;
    bottom: 1px;
  }

  .popover-content {
    color: #FFFFFF;
    padding: 10px;
    opacity: 1;
  }

  .popover-order_id {
    height: 45px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EEEEEE;
  }

  .popover-order_info {
    display: flex;
    justify-content: space-between;
  }

  .order_title {
    font-size: 14px;
    font-weight: bold;
    opacity: 1;
    display: flex;
    justify-content: space-between;
  }

  .order_span {
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 10px;
  }

  .order_discount {
    height: 30px;
    line-height: 30px;
    border: 1px solid #FFFFFF;
    opacity: 1;
    border-radius: 2px;
    padding: 0 10px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
  }
</style>
