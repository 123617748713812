<template>
  <div class="container" v-loading="loading">
    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;">
      <span class="clearfix-title">各项成本支出</span>
      <span class="clearfix-Subtitle">查看、核对时间范围内所有订单的菜品成本、餐盒成本、餐盒成本，包装袋成本，赠品成本。</span>
    </div>
    <div class="flex relative color3F4156 pad-l20 pad-r20" style="margin-top: 20px;">
      <el-radio-group v-model="dateOption" size="small" fill="#585A6E" text-color="#FFFFFF" @change="selectDateChange"
        v-if="activeName=='0'">
        <el-radio-button :label="0">今日</el-radio-button>
        <el-radio-button :label="1">昨日</el-radio-button>
        <el-radio-button :label="7">近7天</el-radio-button>
        <el-radio-button :label="30">近30天</el-radio-button>
        <el-radio-button :label="-1">自定义</el-radio-button>
      </el-radio-group>
      <el-date-picker v-if="dateOption===-1" class="mar-l10" style="height: 32px;width: 280px" type="daterange"
        align="right" unlink-panels v-model="queryDate" ref="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
        value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="selectDateChange">
      </el-date-picker>
    </div>

<div v-if="isshow==1">

    <div class="page-body">
      <div class="page-body-item">
        <div class="page-body-item-title">成本汇总图</div>
        <div class="page-body-item-content">
          <div class="page-body-chart-box" style="height: 350px">
            <el-row>
              <el-col :span="24">
                <!-- <div class="use-titles">
                  <el-row>
                    <el-col :span="12">
                      <div class="use-titles-item">成本数量占比</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="use-titles-item">成本金额占比</div>
                    </el-col>
                  </el-row>
                </div> -->
                <div id="use-constsnumber" style="width: 100%;height: 320px;">
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>


    <div class="page-body-item"
      style="padding: 20px;">
      <div class="page-body-item-title">各项活动明细图
        <li>已选时间：{{this.queryDate[0]}} 至 {{this.queryDate[1]}} 数据</li>
      </div>
      <div class="page-body-item-content" v-for="(item,index) in detaileddata" :key="index">
        <div class="page-body-chart-box">
          <el-row>
            <el-col :span="24">
              <div class="use-titles">
                <el-row>
                  <el-col :span="24">
                    <div class="use-titles-item" style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;">
                      {{item.name}}
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div :id="'activity'+index" :ref="'activity'+index" style="width: 100%;height: 400px;">
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

    </div>
</div>

    <div class="page-body" style="text-align: center;"
      v-if="isshow==0">
      <img src="../../assets/img/null.png" alt="">
      <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
    </div>
  </div>

</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: 'Variouscosts',
    data() {
      return {
        s_id: sessionStorage.getItem('s_id'),
        activeName: '0',
        dateRangeValue: '',
        detaileddata: [],
        loading:false,
        datesvalue: '',
        isDetailedShow: false,
        dateOption: 7,
        chartPieObj: null,
        isshow:1,
        pieOtion: {},
        pieOneData: {
          constsnumber: [{
              name: '',
              value: 0,
              text: '次数'
            },
            {
              name: '',
              value: 0,
              text: '次数'
            },
            {
              name: '',
              value: 0,
              text: '次数'
            }
          ],
          money: [{
              // key:'box_cost',
              name: '',
              value: 0,
              text: '金额'
            },
            {
              name: '',
              value: 0,
              text: '金额'
            },
            {
              name: '',
              value: 0,
              text: '金额'
            }
          ]
        },
        date: '',
        startDate: '',
        queryDate: [
          this.$dateFormat('YYYY-mm-dd', new Date()),
          this.$dateFormat('YYYY-mm-dd', new Date())
        ],
        endDate: '',
        // 柱状图数据
        cost_date: [],
        // 餐盒成本
        box_cost_money: [],
        box_cost_num: [],
        // 餐具成本
        tableware_cost_money: [],
        tableware_cost_num: [],
        // 菜品成本
        food_cost_money: [],
        food_cost_num: [],
        // 饼状图数据
        cake_box_cost_money: '',
        cake_food_cost_money: '',
        cake_tableware_cost_money: '',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        box_costNum: '',
        food_costNum: '',
        tableware_costNum: '',

        box_costMoney: '',
        food_costMoney: '',
        tableware_costMoney: '',

      }
    },
    methods: {

      selectDateChange(e) {
        this.isDetailedShow = false
        if (e == undefined) {
          e = 7
        }
        if (e !== -1) {
          this.loading = true
          if (typeof e === 'number') {
            var end = new Date();            var month = end.getMonth() + 1;            var month = month > 10 ? month : "0" + month;            end.setTime(end.getTime() - 23 * 60 * 60 * 1000);
            if (e < 2) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', start)]
            } else {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
              this.isDetailedShow = true
            }
            this.getcakedata()
            this.getcolumndata()
          }

        } else {
          this.$refs.daterange.focus()
        }
      },
      // 明细
      async getcolumndata() {
        const {
          data: res
        } = await this.$http.get('/user/cost/items_cost_time_summary', {
          params: {
            s_id: this.s_id,
            startDate: this.queryDate[0],
            endDate: this.queryDate[1]
          }
        })

        this.detaileddata = res.data.dateData
        // console.log(res)
        // console.log(this.detaileddata)
        var date = []
        date = res.data.xtime
        let that = this;
        for (var i in res.data.dateData) {
          // let date = []

          (function(j) {
            let name = []
            let num = []
            let money = []
            name.push(res.data.dateData[j].name)
            for (var time in res.data.dateData[j].list) {
              // console.log(res.data.dateData[j].list[time].ctime)
              num.push(res.data.dateData[j].list[time].num)
              money.push(res.data.dateData[j].list[time].money)
              that.detailedEcharts(j, name, date, num, money)
            }
            // console.log(num)
            // console.log(money)
          })(i)


        }


        this.loading = false


      },
      // 汇总
      async getcakedata() {
        const {
          data: res
        } = await this.$http.get('/user/cost/items_cost_summary', {
          params: {
            s_id: this.s_id,
            startDate: this.queryDate[0],
            endDate: this.queryDate[1]
          }
        })

        console.log(res.data)
        const legend1 = []
        const legend2 = []
        const echartsData = res.data
        const pieOneData = []
        const pieOneDataCount = []
        const pieOneDataReduceFee = []

        this.box_costNum = res.data.box_cost.num
        this.food_costNum = res.data.food_cost.num
        this.tableware_costNum = res.data.tableware_cost.num

        this.box_costMoney = res.data.box_cost.money
        this.food_costMoney = res.data.food_cost.money
        this.tableware_costMoney = res.data.tableware_cost.money

        if(this.box_costNum==0&&this.food_costNum==0&&this.tableware_costNum==0&&this.box_costMoney==0&&this.food_costMoney==0&&this.tableware_costMoney==0){
          this.isshow = 0
        }else{
          this.isshow = 1
        }

        for (const i in echartsData) {
          pieOneDataCount.push({
            name: echartsData[i].name,
            value: echartsData[i].num,
            text: '次数'
          })
          pieOneDataReduceFee.push({
            name: echartsData[i].name,
            value: echartsData[i].money,
            text: '金额'
          })
        }

        console.log(this.box_costNum, this.food_costNum, this.tableware_costNum)
        console.log(this.box_costMoney, this.food_costMoney, this.tableware_costMoney)

        const backcolor = ['#F8B500', '#935FED', '#5F77ED', '#5FA6ED', '#92D154', '#54D175', '#54C5D1']
        const chartDom = document.getElementById('use-constsnumber')
        const myChart = echarts.init(chartDom)
        const option = {
          tooltip: {
            trigger: 'item'
          },
          title: [{
              text: '成本数量占比',
              left: '19%',
            },
            {
              text: '成本金额占比',
              left: '69%',
            }
          ],
          legend: {
            top: '85%',
          },
          // legend: {
          //   top: '75%',
          //   data: pieOneData
          // },
          // legend: {
          //   top: '90%',
          //   left: 'center',
          //   itemWidth: 12,
          //   itemHeight: 12,
          //   itemGap: 25,
          //   icon: 'rect'
          // },
          series: [{
              name: '占比次数',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '10',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              center: ['25%', '50%'],
              data: pieOneDataReduceFee,
              color: backcolor
            },
            {
              name: '占比金额',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '10',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              center: ['75%', '50%'],
              data: pieOneDataCount,
              color: backcolor
            }
          ],

          // series: [{
          //     name: '占比次数',
          //     type: 'pie',
          //     radius: ['30%', '60%'],
          //     avoidLabelOverlap: false,
          //     label: {
          //       show: false,
          //       position: 'center'
          //     },
          //     emphasis: {
          //       label: {
          //         show: true,
          //         fontSize: '14'
          //       }
          //     },
          //     labelLine: {
          //       show: false
          //     },
          //     center: ['25%', '40%'],
          //     data: pieOneDataReduceFee
          //   },
          //   {
          //     name: '占比金额',
          //     type: 'pie',
          //     radius: ['30%', '60%'],
          //     avoidLabelOverlap: false,
          //     label: {
          //       show: false,
          //       position: 'center'
          //     },
          //     emphasis: {
          //       label: {
          //         show: true,
          //         fontSize: '10',
          //         fontWeight: 'bold'
          //       }
          //     },
          //     labelLine: {
          //       show: false
          //     },
          //     center: ['75%', '40%'],
          //     data: pieOneDataCount
          //   }
          // ]
        }

        option && myChart.setOption(option)
      },
      detailedEcharts(index, name, date, num, money) {
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            data: ['次数', '金额']
          },
          xAxis: [{
            type: 'category',
            data: date,
            axisPointer: {
              type: 'shadow'
            }
          }],
          yAxis: [{
              type: 'value',
              name: '次数',

            },
            {
              type: 'value',
              name: '金额',
            }
          ],
          series: [{
              name: '次数',
              type: 'bar',
              data: money
            },
            {
              name: '金额',
              type: 'line',
              data: num
            }
          ]
        }

        this.$nextTick(function() {
          var chartDom = document.getElementById('activity' + index)
          // var chartDom = this.$refs['activity' + index][0]
          var myChart = echarts.init(chartDom)
          option && myChart.setOption(option)
        })
      },
    },
    mounted() {
      this.loading = true
      this.selectDateChange()
    }
  }
</script>

<style scoped>
  body {
    background: #F7F8FA
  }

  .container {}

  .page-title {
    font-size: 18px;
  }

  .page-tab {
    margin-top: 20px
  }
</style>
