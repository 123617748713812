<template>
  <div>
    <!-- 跳转中。。。 -->
  </div>
</template>

<script>
  export default {
    methods:{

      async login(){

        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgb(247 248 250 / 90%);'
        });


        const { data: res } = await this.$http.post('/program/index/app_auth?code=' + this.$route.query.code + '&type=' + this.$route.query.type)
        if (res.code != 1) {
          this.$alert(res.msg, '错误提示', {
            confirmButtonText: '确定'
          })
          return
        }

        window.sessionStorage.setItem('token', res.data.access_token)
        window.sessionStorage.setItem('s_id', res.data.s_id)
        window.sessionStorage.setItem('type', 1)
        this.$router.push('/index')
        loading.close();
      },
      isMobile() {
          let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
          return flag;
         }
    },
    created() {
      // this.$route.params.id
    },
    mounted() {
      window.sessionStorage.setItem('code', this.$route.query.code)
      this.login()
      if (this.isMobile()) {
        console.log(window.location.origin)
            window.location.href= window.location.origin+'/wap/#/pages/ad_login?code='+ this.$route.query.code + '&type=' + this.$route.query.type
            // this.$router.replace('/m_index');
          } else {
            // alert("pc端");
            // this.$router.replace('/pc_index');
          }
    }
  }
</script>

<style>
</style>
