<template>
  <div>
    <div class="header-title" style="display: flex;align-items: center;justify-content: space-between;"
      v-if="!showDetail">
      <li v-if="!isCollapse"><span class="clearfix-title">防凑单检测</span><span
          class="clearfix-Subtitle">通过满减利润金额，了解菜品满减订单是否存在问题。</span></li>
      <li v-if="isCollapse"><span class="clearfix-title">防凑单检测</span><span
          class="clearfix-Subtitle">通过满减利润金额，了解菜品满减订单是否存在问题。</span></li>
      <div style="padding: 3px 0;color: #F89800;">
        <el-button class="zize18" style=" color: #f89800" type="text" v-on:click="Collapse()">高级设置
          <i class="el-icon-arrow-up" v-if="isCollapse"></i>
          <i class="el-icon-arrow-down" v-if="!isCollapse"></i>
        </el-button>
      </div>

    </div>

    <div style="border-bottom: 1px solid #E9EAF2;width: 100%;height: 60px;
    display: flex;
    align-items: center;padding-left: 20px;padding-right: 20px;">
      <el-button type="primary" size="small" v-if="!isCollapse && !showDetail" @click="addDialogVisible = true">
        添加一档满减<span>(</span><span>0</span><span>/</span><span>5</span><span>)</span></el-button>
      <el-button v-if="!isCollapse && !showDetail" size="small">更新历史满减优惠</el-button>
    </div>

    <!-- 高级设置表单 -->
    <div class="Collapse" v-if="Collapse && isCollapse" style="padding: 0 20px;">
      <el-form label-width="160px" :model="seniorForm" :rules="seniorRules" ref="seniorRefsForm">
        <el-form-item label="店铺的保底服务费:" prop="charge">
          <el-input class="width200" clearable v-model="seniorForm.charge" suffix-icon="el-icon-warning-outline"
            size="small">
          </el-input>
        </el-form-item>

        <el-form-item label="店铺的抽点比例:" prop="some">
          <el-input class="width200" clearable v-model="seniorForm.some" suffix-icon="el-icon-warning" size="small">
          </el-input>
        </el-form-item>

        <el-form-item label="新客立减:" prop="customers">
          <el-input class="width200" clearable v-model="seniorForm.customers" suffix-icon="el-icon-warning-outline"
            size="small"></el-input>
        </el-form-item>

        <el-form-item label="减免配送:" prop="reduction">
          <el-input class="width200" clearable v-model="seniorForm.reduction" suffix-icon="el-icon-warning-outline"
            size="small"></el-input>
        </el-form-item>

        <el-form-item label="店内领卷:" prop="roll">
          <el-input class="width200" clearable v-model="seniorForm.roll" suffix-icon="el-icon-warning-outline"
            size="small"></el-input>
        </el-form-item>

        <el-form-item label="收藏有礼:" prop="collection">
          <el-input class="width200" clearable v-model="seniorForm.collection" suffix-icon="el-icon-warning-outline"
            size="small"></el-input>
        </el-form-item>

        <el-form-item label="代金卷:" prop="voucher">
          <el-input class="width200" clearable v-model="seniorForm.voucher" suffix-icon="el-icon-warning-outline"
            size="small"></el-input>
        </el-form-item>

        <el-form-item label="餐盒餐具包装袋成本:" prop="box">
          <el-link href="#" target="_blank" :underline="false"><span class="colorF89800">查看餐盒餐具包装带成本</span><i
              class="el-icon-arrow-right colorF89800 mar-l5"></i></el-link>
        </el-form-item>

        <!-- <el-form-item label="凑单菜品上限:" prop="num">
          <el-input-number v-model="seniorForm.num" @change="handleChange" :min="1" :max="10" size="small">
          </el-input-number>
          <span class="mar-l10">最多允许凑单组合菜品数量</span>
        </el-form-item>

        <el-form-item label="单品凑单次数:" prop="num1">
          <el-input-number v-model="seniorForm.num1" @change="handleChange" :min="1" :max="10" size="small">
          </el-input-number>
          <span class="mar-l10">1个菜品最大能被凑的次数</span>
        </el-form-item> -->

        <!-- <el-form-item label="浮动范围:">
          <el-input class="width113" v-model="seniorForm.range" placeholder="请输入" size="small"></el-input><span
            class="mar-l10">设置菜品凑单</span>
        </el-form-item> -->

        <!-- <el-form-item label="过滤下架菜品:" prop="radio">
          <el-radio-group v-model="seniorForm.radio">
            <el-radio :label="1">考虑</el-radio>
            <el-radio :label="2">不考虑</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <!-- <el-form-item label="排除指定菜品:">
          <span>设置不参与凑单的菜品，如没有可不设置</span>

          <div
            class="label-upload center"
            style="margin-left: 200px"
            @click="picturedialogVisible = true"
          >
            <span class="upload">+</span>
          </div>
        </el-form-item> -->

        <!-- <el-form-item label="单点不配送菜品:">
          <span>添加后凑单组合防凑出现都是单点不配送的菜品</span>

          <div
            class="label-upload center"
            style="margin-left: 200px"
            @click="picturedialogVisible = true"
          >
            <span class="upload">+</span>
          </div>
        </el-form-item> -->

        <el-form-item label="必点指定菜品:">
          <span>根据店铺品类定义设置，如烧烤如需必点口味可添加对应菜品，组合过程中仅需包含其中1个必点商品即可</span>

          <div class="label-upload center" style="margin-left: 200px" @click="picturedialogVisible = true">
            <span class="upload">+</span>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" v-on:click="Collapse()" size="small">保存规则</el-button>
          <el-button v-on:click="Collapse()" size="small">取消</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 主体内容 -->
    <div style="padding-right: 20px;padding-left: 20px;">

      <div class="content mar-t20" :hidden="isCollapse || showDetail" v-for="item in 4" :key="item.id">
        <el-row class="center mar-l15 backF7F8FA pad-tb10" :gutter="0">
          <el-col :span="3" class="mar-l20">
            <span class="size20 page-title">满40减10</span>
          </el-col>

          <el-col :span="17">
            <span class="size12" style="margin-left: -20px">检测时间：2021-3-30</span>
          </el-col>

          <el-col :span="2" class="text-center">
            <span><i class="el-icon-edit"></i>
              <el-button type="text" @click="modifyDialogVisible = true">修改
              </el-button>
            </span>
          </el-col>

          <el-col :span="2" class="text-center mar-r5">
            <span><i class="el-icon-delete"></i>
              <el-button type="text">删除 </el-button>
            </span>
          </el-col>
        </el-row>

        <el-row class="center center-pad flex" :gutter="0">
          <el-col :span="5" v-for="item in 4" class="mar-l20" :key="item.id">
            <span class="size12">最低餐品原价</span><br />
            <span class="size30">40</span><span class="size12">.00</span><span class="size12">元</span>
          </el-col>

          <el-col :span="4">
            <el-button type="primary" @click="showDetail = true" size="small">查看菜品凑单组合</el-button>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 查看菜品对话框 -->
    <div v-if="showDetail">
      <div class="header-title">
        <span class="clearfix-title">满40减10菜品凑单组合</span>
        <div style="float: right; padding: 3px 0;color: #F89800;">
          <el-button type="primary" @click="showDetail = false" size="small">返回</el-button>
        </div>
      </div>

      <div class="card center-vertical">
        <div class="card-item">
          <div style="padding: 15px">
            <li class="card-title">基础成本</li>
            <li class="card-money"><span>0</span>元</li>
          </div>
        </div>

        <span class="mar-rl5 page-title">=</span>

        <div class="card-item">
          <div style="padding: 15px">
            <li class="card-title">
              活动优惠
              <el-tooltip popper-class="popoverBackB" placement="top" trigger="hover" content="活动优惠成本包含：水费/电费/燃气费">
                <img src="../../assets/img/wh.png" alt="">
              </el-tooltip>
            </li>
            <li class="card-money"><span>0</span>元</li>
          </div>
        </div>

        <span class="page-title">+</span>

        <div class="card-item">
          <div style="padding: 15px">
            <li class="card-title">
              平台抽成<el-tooltip popper-class="popoverBackB" placement="top" trigger="hover" content="活动优惠成本包含：水费/电费/燃气费">
                <img src="../../assets/img/wh.png" alt="">
              </el-tooltip>
            </li>
            <li class="card-money"><span>0</span>元</li>
          </div>
        </div>

        <span class="page-title">+</span>

        <div class="card-item">
          <div style="padding: 15px">
            <li class="card-title">
              餐盒/餐具/包装<el-tooltip popper-class="popoverBackB" placement="top" trigger="hover" content="活动优惠成本包含：水费/电费/燃气费">
                <img src="../../assets/img/wh.png" alt="">
              </el-tooltip>
            </li>
            <li class="card-money"><span>0</span>元</li>
          </div>
        </div>
      </div>

      <div class="table" v-for="item in 3" :key="item.id">
        <div>
          <el-row :gutter="0" class="tables-header">
            <el-col :span="12" class="number"><span class="weight900">40元 （含餐盒费共3件菜品）</span></el-col>
            <el-col :span="4" style="font-weight: bold; color: #858692">数量</el-col>
            <el-col :span="4" style="font-weight: bold; color: #858692">价格</el-col>
            <el-col :span="4" style="font-weight: bold; color: #858692">成本</el-col>
          </el-row>

          <div>
            <hr style="margin: 0" />
          </div>

          <el-row :gutter="0" class="table-content" style="padding-top: 20px">
            <el-col class="content-title"><span class="title-span">商品信息</span>5种商品，共5件</el-col>
          </el-row>

          <el-row class="table-content" :gutter="0">
            <el-col :span="12" class="content-title">香软米饭-单点不送</el-col>
            <el-col :span="4">×1</el-col>
            <el-col :span="4">￥2.5</el-col>
            <el-col :span="4">￥2.5</el-col>
          </el-row>

          <el-row class="table-content" :gutter="0">
            <el-col :span="12" class="content-title">金汤原味猪肚鸡汤+娃娃菜+海鲜菇</el-col>
            <el-col :span="4">×1</el-col>
            <el-col :span="4">￥2.5</el-col>
            <el-col :span="4">￥2.5</el-col>
          </el-row>

          <el-row class="table-content" :gutter="0">
            <el-col :span="12" class="content-title" style="padding-bottom: 15px">香软米饭-单点不送</el-col>
            <el-col :span="4">×1</el-col>
            <el-col :span="4">￥2.5</el-col>
            <el-col :span="4">￥2.5</el-col>
          </el-row>
        </div>

        <div class="item backF7F8FA pad-rl20">
          <el-row>
            <el-col :span="12" style="font-size: 14px; color: #858692">原始价格</el-col>
            <el-col :span="12" style="font-size: 14px; color: #3f4156">0元</el-col>
          </el-row>

          <el-row class="mar-tb20">
            <el-col :span="12" style="font-size: 14px; color: #858692">菜品成本</el-col>
            <el-col :span="12" style="font-size: 14px; color: #3f4156">0元</el-col>
          </el-row>

          <el-row>
            <el-col :span="12" style="font-size: 14px; color: #858692">利润低于</el-col>
            <el-col :span="12"><span style="font-size: 20px; color: #3f4156; font-weight: 400">0</span>
              <span>元</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 修改对话框 -->
    <el-dialog title="检测满减防凑单" :visible.sync="modifyDialogVisible" width="500px">
      <el-form :rules="modifyRules" ref="modifyRefsForm" :model="modifyForm" label-width="100px">
        <el-form-item label="检测满减:" prop="calculation">
          <span>满</span>

          <el-input style="width: 120px" v-model="modifyForm.calculation.number1" class="mar-rl15" maxlength="4"
            size="small">
            <template slot="suffix">
              <span class="mar-r10">元</span>
            </template>
          </el-input>
          <span>减</span>

          <el-input style="width: 120px" class="mar-rl15" v-model="modifyForm.calculation.number2" maxlength="4"
            size="small">
            <template slot="suffix">
              <span class="mar-r10">元</span>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item label="利润低于:" prop="profit">
          <el-input class="width150" v-model="modifyForm.profit" maxlength="4" size="small"></el-input>
        </el-form-item>

        <el-form-item label="凑单规则:" prop="field">
          <li>1.凑单菜品上限200个</li>
          <li style="margin-left: 100px;">2.单菜品凑单次数10次</li>
          <li style="margin-left: 100px;">3.不过滤下架菜品</li>
          <li class="colorF89800" style="margin-left: 100px;">修改菜单规则</li>
          <li class="colorccc size12" style="margin-left: 100px;">
            提示:可根据店铺实际情况调整凑单规则。多5个
          </li>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="modifyDialogVisible = false" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button type="primary" @click="modifyDialogVisible = false" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">开始检测</el-button>
      </span>
    </el-dialog>

    <!-- 添加对话框 -->
    <el-dialog title="检测满减防凑单" :visible.sync="addDialogVisible" width="500px" @close="closeForm">
      <el-form :rules="addRules" ref="addRefsForm" :model="addForm" label-width="100px">
        <el-form-item label="检测满减:" prop="calculation">
          <span>满</span>

          <el-input style="width: 120px" v-model="addForm.calculation.number1" class="mar-rl15" maxlength="4"
            size="small">
            <template slot="suffix">
              <span class="mar-r10">元</span>
            </template>
          </el-input>
          <span>减</span>

          <el-input style="width: 120px" class="mar-rl15" v-model="addForm.calculation.number2" maxlength="4"
            size="small">
            <template slot="suffix">
              <span class="mar-r10">元</span>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item label="利润低于:" prop="profit">
          <el-input class="width150" v-model="addForm.profit" maxlength="4" size="small"></el-input>
        </el-form-item>

        <el-form-item label="凑单规则:" prop="field">
          <li>1.凑单菜品上限200个</li>
          <li style="margin-left: 100px;">2.单菜品凑单次数10次</li>
          <li style="margin-left: 100px;">3.不过滤下架菜品</li>
          <li class="colorF89800" style="margin-left: 100px;">修改菜单规则</li>
          <li class="colorccc size12" style="margin-left: 100px;">
            提示:可根据店铺实际情况调整凑单规则。多5个
          </li>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false" style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button type="primary" @click="addDialogVisible = false" style="padding: 0 18px;height: 36px;font-size: 14px;">开始检测</el-button>
      </span>
    </el-dialog>

    <!-- 上传图片对话框 -->

    <el-dialog title="菜品选择" :visible.sync="picturedialogVisible" width="600px">
      <hr style="position: absolute; top: 76px; left: 0px" class="max-w" />

      <div class="flex" style="height: 350px; width: 100%">
        <div class="max-h" style="width: 100px; height: 100%; border-right: 1px solid #ccc">
          <div class="view-left-title max-h" style="position: absolute; left: 0px">
            <div class="view-left-item active1">
              <li style="width: 119px">商品名称1(0)</li>
            </div>
            <div class="view-left-item">
              <li style="width: 119px">商品名称1(0)</li>
            </div>
          </div>
        </div>
        <div class="max-h scrollbar pad10" style="width: calc(100% - 101px)">
          <div class="mar-t10 flex">
            <el-checkbox class="center">
              <div style="height: 67px" class="flex">
                <div class="label-upload center" @click="picturedialogVisible = true">
                  <span class="upload-img">图片</span>
                </div>

                <div class="mar-l5 flex" style="
                    width: calc(100% - 83px);
                    flex-direction: column;
                    justify-content: space-between;
                  ">
                  <div style="
                      color: #3f4156;
                      font-weight: bold;
                      white-space: normal;
                      word-break: break-all;
                    ">
                    养生胡椒猪肚鸡汤+山药猪肚鸡 （含2份米饭+2份时蔬+2份点料)
                  </div>
                  <div style="color: #a2a4b3; font-weight: 400">销量 0</div>
                </div>
              </div>
            </el-checkbox>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="picturedialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="picturedialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        // 高级设置表单标签位置
        labelPosition: 'left',
        // 修改对话框
        modifyDialogVisible: false,
        // 添加对话框
        addDialogVisible: false,
        // 查看对话框
        seeDialogVisible: false,
        // 图片上传对话框
        picturedialogVisible: false,
        // 折叠面板
        isCollapse: false,
        showDetail: false,
        // 高级设置表单数据
        seniorForm: {
          charge: '', // 服务费
          some: '', // 抽点比例
          customers: '', // 新客立减
          reduction: '', // 减免配送费
          roll: '', // 店内领卷
          collection: '', // 收藏有礼
          voucher: '', // 代金卷
          range: '', // 浮动范围
          box: '', // 餐盒餐具
          num: 1, // 菜品上限
          num1: 1, // 凑单次数
          radio: 1 // 过滤选框
        },
        // 修改表单数据
        modifyForm: {
          calculation: {
            number1: '',
            number2: ''
          },
          profit: ''
        },
        // 添加表单数据
        addForm: {
          calculation: {
            number1: '',
            number2: ''
          },
          profit: ''
        },
        // 添加对话框表单验证
        addRules: {
          calculation: [{
            required: true,
            trigger: 'blur'
          }],
          profit: [{
            required: true,
            message: '请输入对应的内容',
            trigger: 'blur'
          }],
          field: [{
            required: true,
            trigger: 'blur'
          }]
        },
        // 修改对话框表单验证
        modifyRules: {
          calculation: [{
            required: true,
            trigger: 'blur'
          }],
          profit: [{
            required: true,
            message: '请输入对应的内容',
            trigger: 'blur'
          }],
          field: [{
            required: true,
            trigger: 'blur'
          }]
        },
        // 高级设置表单验证
        seniorRules: {
          charge: [{
            required: true,
            message: '请输入对应的内容',
            trigger: 'blur'
          }],
          some: [{
            required: true,
            message: '请输入对应的内容',
            trigger: 'blur'
          }],
          customers: [{
            required: true,
            message: '请输入对应的内容',
            trigger: 'blur'
          }],
          reduction: [{
            required: true,
            message: '请输入对应的内容',
            trigger: 'blur'
          }],
          roll: [{
            required: true,
            message: '请输入对应的内容',
            trigger: 'blur'
          }],
          collection: [{
            required: true,
            message: '请输入对应的内容',
            trigger: 'blur'
          }],
          voucher: [{
            required: true,
            message: '请输入对应的内容',
            trigger: 'blur'
          }],
          box: [{
            required: true,
            trigger: 'blur'
          }],
          num: [{
            required: true,
            message: '请输入对应的内容',
            trigger: 'blur'
          }],
          num1: [{
            required: true,
            message: '请输入对应的内容',
            trigger: 'blur'
          }],
          radio: [{
            required: true,
            trigger: 'blur'
          }]
        }
      }
    },
    created() {
      this.getPrevention()
    },
    methods: {
      // 退出添加对话框重置表单
      closeForm() {
        // this.$refs.addRefsForm.resetFields();
      },
      // 高级设置显示隐藏
      async Collapse() {
        this.isCollapse = !this.isCollapse
        // this.$refs.seniorRefsForm.resetFields();
      },

      handleChange(value) {
        // console.log(value)
      },
      // 图片上传
      handleRemove(file, fileList) {
        // console.log(file, fileList)
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },


    }
  }
</script>
<style lang="less" scoped>
  .table {
    display: table;
    height: 100%;
    width: 100%;
    margin-top: 20px;
    border: 1px solid #e9eaf2;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .item {
    text-align: center;
    display: table-cell;
    height: 100%;
    vertical-align: middle; //垂直居中
  }

  .content {
    border: 1px solid #e9eaf2;
    border-radius: 2px;
  }

  .center-pad {
    padding: 30px 15px 30px 0;
  }

  .card-item {
    border-radius: 4px;
    width: 255px;
    height: 120px;
    background: #fff4eb;
  }

  .card {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .card-title {
    font-size: 14px;
    font-weight: 400;
    color: #3f4156;
  }

  .card-money {
    font-size: 14px;
    font-weight: 400;
    color: #3f4156;
  }

  .card-money span {
    font-size: 38px;
    font-weight: 400;
    color: #3f4156;
  }

  .tables-header {
    height: 60px;
    line-height: 60px;
    font-weight: bold;
    background-color: #ffffff;
    text-align: center;
    font-size: 14px;
    color: #858692;
  }

  .table-content {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #3f4156;
    height: 30px;
    line-height: 30px;
  }

  .content-title {
    text-align: left;
    padding: 0 20px;
  }

  .number {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
  }

  .number span {
    font-size: 14px;
    font-weight: bold;
    color: #3f4156;
    padding: 0 20px;
  }

  .title-span {
    font-size: 14px;
    font-weight: bold;
    color: #3f4156;
    margin-right: 15px;
  }

  .label-upload {
    width: 130px;
    height: 80px;
    background: #eeeeee;
    border-radius: 4px;
  }

  .label-upload-img {
    width: 73px;
    height: 67px;
    background: #eaeaea;
  }

  .upload-img {
    width: 28px;
    height: 19px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #858692;
  }

  .upload {
    width: 30px;
    height: 58px;
    font-size: 44px;
    font-weight: 400;
    line-height: 58px;
    color: #3f4156;
  }

  .view-left-item {
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #3f4156;
  }

  .active1 {
    background-color: #e9eaf2;
  }
</style>
