<template>
  <div v-loading="loading">

    <div class="header-title">
      <span class="clearfix-title">评价热点分析</span>
      <span class="clearfix-Subtitle">发现消费者需求，找到问题，找到市场机会，从而制定对应的营销方案。</span>
    </div>

    <div style="height: 60px;display: flex;padding: 0 20px;align-items: center;">

      <el-radio-group style="display: flex;align-items: center;" v-model="dateOption" size="small" fill="#585A6E" text-color="#FFFFFF" @change="selectDateChange">
        <el-radio-button :label="1">昨日</el-radio-button>
        <el-radio-button :label="7">近7天</el-radio-button>
        <el-radio-button :label="30">近30天</el-radio-button>
        <el-radio-button :label="-1">自定义</el-radio-button>
      </el-radio-group>

      <el-date-picker v-if="dateOption===-1" size="small" format="yyyy-MM-dd" style="margin-left: 20px;"
        value-format="yyyy-MM-dd" v-model="queryDate" type="daterange" :clearable="false" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期" ref="daterange" :picker-options="pickerOptions"
        @change="selectDateChange">
      </el-date-picker>
    </div>

    <div>

      <el-tabs v-model="activeName" @tab-click="handleClick1">
        <el-tab-pane label="全部" name="0"></el-tab-pane>
        <el-tab-pane label="好评" name="3"></el-tab-pane>
        <el-tab-pane label="中评" name="2"></el-tab-pane>
        <el-tab-pane label="差评" name="1"></el-tab-pane>
      </el-tabs>
    </div>

    <div>

      <div style="padding: 20px;border-bottom: 10px solid rgb(247, 248, 250);">

        <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 20px;">
          <div style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;">评价热点分析</div>
          <div style="font-size: 14px;font-weight: 400;color: #A2A4B3;opacity: 1;">共{{total}}条数据</div>
        </div>

        <div>
          <el-table :stripe="true" :data="tableData" style="width: 100%">
            <el-table-column label="问题排名" prop="num_rate" align="left"></el-table-column>
            <el-table-column label="评价热度榜" prop="tags" align="left"></el-table-column>
            <el-table-column label="评价量" prop="num" align="right"></el-table-column>
            <el-table-column label="评价占比" width="150" prop="percent" align="right"></el-table-column>
          </el-table>

          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
            :current-page="page" :page-size="limit" :total="total" layout="prev, pager, next"
            style="margin-top: 20px;text-align: right;"></el-pagination>
        </div>

      </div>

      <div style="padding: 20px;">
        <div style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;">评价关键词</div>
        <div>
          <div id="canvas-container" style="text-align: center;" v-if="this.cydata!=''">
            <canvas id="canvas" width="900px" height="400px"></canvas>
          </div>
          <div style="text-align: center;" v-if="this.cydata==''">
            <img src="../../assets/img/null.png" alt="">
            <li style="font-size: 14px;font-weight: 400;color: #2C2B2B;opacity: 1;margin-top: 20px;">暂无数据</li>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        dateOption: 30,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
          }
        },
        queryDate: [
          this.$dateFormat('YYYY-mm-dd', new Date()),
          this.$dateFormat('YYYY-mm-dd', new Date())
        ],
        activeName: '0',
        tableData: [],
        page: 1,
        limit: 5,
        total: 0,
        startDate: '',
        endDate: '',
        comment_rate: '',
        cydata: [],
        loading:false
      }
    },
    methods: {
      selectDateChange(e) {
        // console.log(e)
        if (e == undefined) {
          var end = new Date();            var month = end.getMonth() + 1;            var month = month > 10 ? month : "0" + month;            end.setTime(end.getTime() - 23 * 60 * 60 * 1000);
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
          // console.log(this.queryDate)
        }
        if (e !== -1) {
          this.loading = true
          if (typeof e === 'number') {
            var end = new Date();            var month = end.getMonth() + 1;            var month = month > 10 ? month : "0" + month;            end.setTime(end.getTime() - 23 * 60 * 60 * 1000);
            if (e < 2) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', start)]
            } else {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * e)
              this.queryDate = [this.$dateFormat('YYYY-mm-dd', start), this.$dateFormat('YYYY-mm-dd', end)]
            }
          }
          this.startDate = this.queryDate[0]
          this.endDate = this.queryDate[1]
          this.gethot_tags_lists()
          this.gethot_tags_array()
        } else {
          this.$refs.daterange.focus()
        }
      },
      handleClick1(tab, event) {
        this.comment_rate = tab.name
        // console.log(tab.name)
        this.gethot_tags_lists()
        this.gethot_tags_array()
      },
      handleSizeChange(newSize) {
        this.limit = newSize
        this.gethot_tags_lists()
      },
      handleCurrentChange(newPage) {
        this.page = newPage
        this.gethot_tags_lists()
      },
      words() {

        this.$nextTick(function() {
          var canvas = document.getElementById('canvas')
          var options = eval({
            "list": this.cydata, //或者[['各位观众',45],['词云', 21],['来啦!!!',13]],只要格式满足这样都可以
            "gridSize": 6, // 密集程度 数字越小越密集
            "weightFactor": 1, // 字体大小=原始大小*weightFactor
            "maxFontSize": 100, //最大字号
            "minFontSize": 10, //最小字号
            "fontWeight": 'normal', //字体粗细
            "fontFamily": 'Microsoft YaHei', // 字体
            "color": 'random-light', // 字体颜色 'random-dark' 或者 'random-light'
          });
          WordCloud(canvas, options);
        })


        //生成

      },
      async gethot_tags_lists() {
        const {
          data: res
        } = await this.$http.post('/user/Commenthot/hot_tags_lists', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          type: 1,
          page: this.page,
          size: this.limit,
          startDate: this.startDate,
          endDate: this.endDate,
          comment_rate: this.comment_rate,
          "rotateRatio": 0 // 字体倾斜(旋转)概率，1代表总是倾斜(旋转)
        })

        this.tableData = res.data
        this.total = res.count


      },
      async gethot_tags_array() {
        const {
          data: res
        } = await this.$http.post('/user/Commenthot/hot_tags_array', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          type: 1,
          startDate: this.startDate,
          endDate: this.endDate,
          comment_rate: this.comment_rate
        })
        var cydata = []
        var weightFactor = 0
        // console.log(res.data)
        if (res.data != '') {

          for (var i = 0; i < 1; i++) {
            if (res && res.data[i]) {
              cydata.push([
                res.data[i].tags,
                100
              ])
            }
          }

          for (var i = 1; i < 5; i++) {
            if (res && res.data[i]) {
              cydata.push([
                res.data[i].tags,
                70
              ])
            }
          }

          for (var i = 5; i < 10; i++) {
            if (res && res.data[i]) {
              cydata.push([
                res.data[i].tags,
                40
              ])
            }
          }

          for (var i = 10; i < 15; i++) {
            if (res && res.data[i]) {
              cydata.push([
                res.data[i].tags,
                20
              ])
            }
          }

          for (var i = 15; i < 20; i++) {
            if (res && res.data[i]) {
              cydata.push([
                res.data[i].tags,
                10
              ])
            }
          }
        }

        this.cydata = cydata
        if (this.cydata != '') {
          this.words()
        }
        this.loading = false
      }
    },
    mounted() {
      this.loading = true
      this.selectDateChange()
    }
  }
</script>

<style scoped>
</style>
