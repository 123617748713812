<template>
  <div v-loading="loading">
    <!--页面标题-->
    <div class="header-title">
      <span class="clearfix-title">差评提醒</span><span class="clearfix-Subtitle">次日获取到差评的信息进行通知，便于商家根据实际情况进行回复或申诉。</span>
      <!-- <div style="float: right;">
        <span class="mar-r10 size15 colorF89800 cursor-pointer" @click="openWarnSettings()">
          <span v-if="showWarnSettings==false">展开设置提醒</span>
          <span v-else>收起设置提醒</span>
        </span>
        <el-switch :value="enableWarn" class="mar-l5" active-color="#fed06a" @change="setWarnOpenOrClose">
        </el-switch>
      </div> -->
    </div>

    <div v-show="showWarnSettings==true" style="border-top: 1px solid #e9eaf2;margin-top: -10px;"
      :class="showWarnSettings?'settings scrollbar settings-open':'settings scrollbar settings-close'">

      <div
        style="height: 40px;background: #FFF8E1;opacity: 1;border-radius: 2px;line-height: 40px;padding-left: 30px;margin: 10px 20px;">
        <span
          style="font-size: 12px;font-weight: 400;color: #FF6A00;opacity: 1;">收到差评后，商家可以对不合理、不真实的评价进行申诉。申诉成功后，平台会依法处理这条评价。并把这条评价从计分评价中剔除。</span>
      </div>

      <el-form ref="ruleForm" label-width="135px" style="margin-top: 20px;">
        <el-form-item label="接收差评时间：">
          <el-time-select :picker-options="{
                    start: '00:30',
                    step: '01:00',
                    end: '23:30'
                  }" v-model="recieveHour" placeholder="选择时间" size="small" :clearable="false">
          </el-time-select>
        </el-form-item>
        <el-form-item label="短信接收人：">
          <div class="size8">通过手机短信通知（最多5个）</div>
          <div style="display: flex;">
            <div v-for="(item,index) in warnTels" @click="item.select=(item.select===1?0:1)" v-model="item.select"
              class="checkeBox" @mouseenter="enter(index)" @mouseleave="leave(index)">

              <div style="width: inherit;position: absolute;" v-show="item.seen" id="others">
                <i class="el-icon-error"
                  style="position: absolute;right: -7px;top: -7px;font-size: 16px;color: #BABCCC;background-color: #FFFFFF;z-index: 121;"
                  @click="deletephone(index,item.id)"></i>
              </div>

              <div :class="{phoneselected:item.select===1}" class="checkphone" :key="index">
                {{ item.account }}
              </div>

            </div>
            <div class="flex center-vertical cursor-pointer" v-show="warnTels.length <= 5">
              <div class="flex" @click="addPhone">
                <img src="../../assets/img/add.svg" class="wh-20" />
                <span class="label-text mar-l5" style="font-size: 14px;font-weight: 400;color: #F89800;opacity: 1;">添加手机号</span>
              </div>
            </div>
          </div>
          <div class="mar-t10">

          </div>
        </el-form-item>
        <el-form-item label="微信接收人：">
          <div class="size8">通过微信公众号通知到您微信（最多5个）</div>
          <div style="align-items: baseline;margin-left: :;0px;align-items: center;display: flex;">
            <div @mouseenter="enter1(index)" @mouseleave="leave1(index)" :class="{wechatselected:item.select===1}"
              style="width: 124px;height: 100px;border: 1px solid #E9EAF2;opacity: 1;border-radius: 2px;display: flex;justify-content: center;align-items: center;margin-right: 10px;"
              v-for="(item,index) in warnWeChats" @click="item.select=(item.select===1?0:1)">
              <div style="width: inherit;position: absolute;height: inherit;" v-show="item.seen" id="others">
                <i class="el-icon-error"
                  style="position: absolute;right: -7px;top: -7px;font-size: 16px;color: #BABCCC;background-color: #FFFFFF;z-index: 111;"
                  @click="deletephone1(index,item.id)"></i>
              </div>
              <div>
                <div style="width: 36px;height: 36px;margin: auto;;position: relative" class="mar-t10">
                  <img :src="item.avatar" class="max-w max-h radius50">
                </div>
                <div class="show-only-one-line size14 color56576A pad-lr5 text-center" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100px;">
                  {{ item.nickname }}
                </div>
              </div>
              <!-- <div class="wechat-selected" style="margin-left: 1px;" v-if="item.select===1">
              </div> -->
            </div>
            <div v-if="warnWeChats.length<5"
              style="width: 124px; height: 100px; border: 1px solid rgb(233, 234, 242); opacity: 1; border-radius: 2px; display: flex; justify-content: center; align-items: center; margin-right: 10px;"
              @click="bindAccount">
              <span style="font-size: 38px;font-weight: 400;color: #3F4156;opacity: 1;">+</span>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="开启差评提醒：">
          <el-switch v-model="enableWarn" active-color="#fed06a" inactive-color="#858692" @change="setWarnOpenOrClose">
          </el-switch>
        </el-form-item>

      </el-form>
      <div style="margin-left: 135px;margin-top: 50px;">
        <el-button type="primary" class="radius2" @click="saveSettings()">保存</el-button>
       <!-- <el-button class="radius2" @click="showWarnSettings=false">取消</el-button>
        <el-button class="radius2" @click="showWarnSettings=false">取消</el-button> -->
      </div>
    </div>



    <div v-show="showWarnSettings==false">

      <div class="screen">
        <el-radio-group v-model="activeName" size="small" fill="#585A6E" text-color="#FFFFFF" @change="dateChange">
          <el-radio-button label="近7天"></el-radio-button>
          <el-radio-button label="近30天"></el-radio-button>
          <el-radio-button label="自定义"></el-radio-button>
        </el-radio-group>

        <!-- <el-date-picker style="margin-left: 20px;" size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-if="this.activeName === '自定义'"
        v-model="datesvalue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
        @change="customdateChange">
      </el-date-picker> -->

        <el-date-picker style="margin-left: 20px;" size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
          v-if="activeName=='自定义'" unlink-panels v-model="datesvalue" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" ref="daterange" @change="customdateChange">
        </el-date-picker>

      </div>

      <div style="height: 60px;display: flex;align-items: center;border-top: 1px solid #e9eaf2;">
        <div
          style="height: 40px;background: #FFF8E1;opacity: 1;border-radius: 2px;line-height: 40px;padding-left: 30px;margin: 0 20px;width: 100%;">
          <span
            style="font-size: 12px;font-weight: 400;color: #FF6A00;opacity: 1;">收到差评后，商家可以对不合理、不真实的评价进行申诉。申诉成功后，平台会依法处理这条评价。并把这条评价从计分评价中剔除。</span>
        </div>
      </div>


      <div style="height: 450px;padding-left: 20px;padding-right: 20px;">
        <li style="font-size: 14px;font-weight: bold;color: #3F4156;opacity: 1;margin-top: 20px;">评分变化图表</li>
        <div id="main" style="height: 400px;width: 100%;">

        </div>
      </div>
    </div>
    <!--绑定手机或微信弹窗-->
    <el-dialog title="绑定微信号" :visible.sync="bindAccountBox" width="400px" @close="bindAccountBoxClose">
      <div>
        <div class="center-column">
          <div style="font-size: 14px;font-weight: 400;color: #3F4156;opacity: 1;">避免错过重要消息影响使用效果</div>
          <div style="font-size: 14px;font-weight: 400;color: #F76C6C;opacity: 1;margin-bottom: 10px;">免费提醒</div>
          <div class="center-column">
            <div style="    border: 1px solid rgb(233, 234, 242);border-radius: 2px;margin-top: 15px;">
              <img v-if="!wechat_qrcode" src="../../assets/img/loading.gif" class="qrcode">
              <el-image v-if="wechat_qrcode" :src="wechat_qrcode" class="qrcode"></el-image>
            </div>
            <div style="margin-top: 8px;font-size: 14px;font-weight: 400;color: #3F4156;opacity: 1;">扫一扫绑定微信</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="添加手机号" :visible.sync="addPhonedialog" width="500px"
      @close="addPhonedialogClose">
      <div>
        <div>
          <div style="text-align: left;margin-bottom: 10px;">避免错过重要消息影响使用效果</div>
          <div style="color: #f56d6f;text-align: right;">
            <el-form :model="bind" ref="bind">
              <el-form-item label="手机号">
                <el-input style="width: 200px;" placeholder="输入手机号" v-model="bind.account" size="small"></el-input>
              </el-form-item>
              <el-form-item label="验证码" style="display: flex;align-items: center;">
                <el-input placeholder="请输入验证码" v-model="bind.captcha" size="small" style="width: 100px;height: 32px;display: flex;align-items: center;"></el-input>
                <span>
                  <el-button @click.prevent="getCode()" :class="{yzm:isyzm==1}" style="margin-left: 10px;width: 90px;display: flex;align-items: center;padding: 0;justify-content: center;height: 32px;" size="small" :disabled="!show">
                    <span v-show="show" style="font-size: 12px;font-weight: 400;color: #F89800;opacity: 1;">获取验证码</span>
                    <span v-show="!show" style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;">重新获取({{count}})</span>
                  </el-button>
                </span>
              </el-form-item>
            </el-form>
            <el-button @click="bindAccountBox=false" size="small"
              style="padding: 0 18px;height: 36px;font-size: 14px;">取消</el-button>
            <el-button @click="bindPhone('bind')" type="primary" size="small"
              style="padding: 0 18px;height: 36px;font-size: 14px;">保存</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
import bus from '../../utils/bus.js'

  export default {
    name: 'PoorCommentWarn',
    data() {
      return {
        bind: {
          pic_url: undefined,
          account: undefined,
          captcha: undefined,
        },
        isyzm:1,
        show: 'false',
        count: '',
        sId: sessionStorage.getItem('s_id'), //店铺id
        addPhonedialog:false,
        activeName: '近7天',
        datesvalue: '',
        warnType: '', //提醒类型
        poorCommentList: [], //差评列表
        showWarnSettings: true, //显示提醒设置
        loading: false, //加载中
        page: 1, //当前页
        pageSize: 10, //每页大小
        totalCount: 0, //总记录数
        enableWarn: false, //是否打开差评提醒
        recieveHour: undefined,
        checkList: [],
        warnWeChats: [],
        warnTels: [],
        newWarnTels: [],
        type5: '',
        type1: '',
        newWarnTelsRules: {
          account: [{
              required: true,
              message: '请输入手机号码',
              trigger: 'blur'
            },
            {
              min: 11,
              max: 11,
              message: '请输入11位手机号码',
              trigger: 'blur'
            },
            {
              pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
              message: '请输入正确的手机号码'
            }
          ]
        },
        bindAccountBox: false,
        wechat_qrcode: '',
        startDate: '',
        endDate: '',
        enable: ''
      }
    },
    methods: {
      addPhone(){
        this.addPhonedialog = true
        console.log('1')
      },
      async getCode() {
        //axios请求
        const {data:res} = await this.$http.post('/user/index/getCode', {
          mobile:this.bind.account
        })
        if(res.code!=1){
          return this.$message({
            type: 'error',
            message: res.msg
          });
        }
        this.$message.success(res.msg);
        // 验证码倒计时
        if (!this.timer) {
          this.isyzm = 0
          this.count = 60;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
      },
      bindPhone(formName) {
        console.log(this.type)
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
              const {data:res} = await this.$http.post('/user/index/checkCode',{
                mobile:this.bind.account,
                code:this.bind.captcha
              })
              if(res.code!=1){
                return this.$message.error(res.msg)
              }
              this.$message.success(res.msg)
            this.$api.post('/user/user/notify_account_edit', {
              id: this.bind.id,
              account: this.bind.account,
              type: 2,
              s_id: this.sId
            }, true).then(res => {
              if(res.code!=1){
                return this.$message.error(res.msg)
              }
              this.$message.success(res.msg)
              this.bind = []
              this.addPhonedialog = false
              this.getWarnSettings()
            })
          }
        })
      },
      addPhonedialogClose(){
        this.bind = {}
      },
      typpeclick5(id) {
        if (id === '') {
          this.type5 = '5'
          this.checkList.push(5)
        } else {
          this.type5 = ''
          this.checkList = this.checkList.filter(item => item != 5)

        }
        // console.log(this.type5)
        // console.log(this.checkList)
      },
      typpeclick1(id) {
        if (id === '') {
          this.type1 = '1'
          this.checkList.push(1)
        } else {
          this.type1 = ''
          this.checkList = this.checkList.filter(item => item != 1)
        }
        // console.log(this.type1)
        // console.log(this.checkList)
      },
      async deletephone(index, id) {
        this.$confirm('将删除该账户下所有通知，是否确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'btnFalse btnPadd',
          cancelButtonClass: 'btnPadd'
        }).then(async() => {

          const {data:res} = await this.$http.post('/user/user/notify_account_delete', {
            id: id,
            s_id: this.sId
          })
          if(res.code!=1){
            return this.$message.error(res.msg)
          }
          this.$message.success(res.msg)
          bus.$emit('getqueryInfo')
          this.warnTels.splice(index, 1)
          this.getWarnSettings()
        })
      },
      async deletephone1(index, id) {

        this.$confirm('将删除该账户下所有通知，是否确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'btnFalse btnPadd',
          cancelButtonClass: 'btnPadd'
        }).then(async() => {

          const {data:res} = await this.$http.post('/user/user/notify_account_delete', {
            id: id,
            s_id: this.sId
          })
          if(res.code!=1){
            return this.$message.error(res.msg)
          }
          this.$message.success(res.msg)
          bus.$emit('getqueryInfo')

          this.warnWeChats.splice(index, 1)
          this.getWarnSettings()
        })
      },
      enter(index) {
        this.warnTels[index].seen = true
        // this.seen = true
        // console.log(this.warnTels)
        // console.log(index)
      },
      leave(index) {
        this.warnTels[index].seen = false
        // console.log(index)
      },
      enter1(index) {
        this.warnWeChats[index].seen = true
        // console.log(index)
      },
      leave1(index) {
        this.warnWeChats[index].seen = false
        // console.log(index)
      },
      checkremind() {
        // console.log(this.checkList)

      },
      openWarnSettings() {
        this.showWarnSettings = !this.showWarnSettings
        // if (this.showWarnSettings == false) {
        //   // this.enableWarn = false
        //   // this.getdata()
        // } else {
        //   // this.enableWarn = true
        // }
        // console.log(this.showWarnSettings)
      },
      // poorCommentWarnPageChange(e) {
      //   this.page = e
      //   this.getPoorCommentWarnList()
      // },
      setWarnOpenOrClose(enable) {
        console.log(this.enableWarn)
        if (this.enableWarn == true) {
          this.enable = 1
        } else {
          this.enable = 0
        }
        // this.showWarnSettings = !this.showWarnSettings
        // console.log(enable)
        // console.log(this.enableWarn)
        // if (this.enableWarn === 1) {
        //   this.enable = '0'
        //   console.log(this.enable)
        // } else {
        //   this.enableWarn = 1
        //   this.enable = '1'
        // this.showWarnSettings = true
        // }

      },
      getPoorCommentWarnList() {
        this.$api.post('/user/remind/noticeLog', {
          page: this.page,
          size: this.pageSize,
          s_id: this.sId
        }).then(res => {
          this.totalCount = res.total
          this.poorCommentList = res.lists
        })
      },
      getWarnSettings() {
        this.$api.post('/user/remind/getNoticeSetting', {
          type: 1,
          s_id: this.sId
        }).then(res => {
          console.log(res)
          this.newWarnTels = []
          if (res.enable == 1) {
            this.showWarnSettings = true
            this.enableWarn = true
            this.enable = 1
          }else{
            this.enableWarn = false
          }
          // this.enableWarn = res.enable
          this.profit = res.profit

          if(res.recieve_time==''){
            this.recieveHour = '09:00'
          }else{
            this.recieveHour = res.recieve_time
          }
          bus.$emit('getqueryInfo')

          var check = []
          for (var i in res.select) {
            check.push(res.select[i] + '')
          }

          if (check[0] == 1) {
            this.type1 = '1'
          }
          if (check[0] == 5) {
            this.type5 = '5'
          }
          if (check[1] == 1) {
            this.type1 = '1'
          }
          if (check[1] == 5) {
            this.type5 = '5'
          }

          if (this.type1 != '0' && this.type1 != '') {
            this.checkList.push(this.type1)
          }
          if (this.type5 != '0' && this.type5 != '') {
            this.checkList.push(this.type5)
          }

          // console.log(this.checkList)

          if (this.checkList[0] == '') {
            this.checkList = []
          }
          // console.log(this.checkList)
          var warnTels = []
          for (var i in res.mobile) {
            warnTels.push({
              account: res.mobile[i].account,
              id: res.mobile[i].id,
              select: res.mobile[i].select,
              utime: res.mobile[i].utime,
              seen: false
            })
          }
          this.warnTels = warnTels

          var warnWeChats = []
          for (var i in res.wechat) {
            warnWeChats.push({
              avatar: res.wechat[i].avatar,
              id: res.wechat[i].id,
              nickname: res.wechat[i].nickname,
              select: res.wechat[i].select,
              utime: res.wechat[i].utime,
              seen: false
            })
          }
          this.warnWeChats = warnWeChats

          // console.log(this.warnWeChats)
          // console.log(res)
        })
        this.loading = false
      },


      saveSettings() {
        var check = []
        for (var i in this.checkList) {
          check.push(this.checkList[i] - '')
        }
        let flag = true
        for (let i = 0; i < this.newWarnTels.length; i++) {
          this.$refs['newWarnTels'][i].validate((valid) => {
            if (!valid) {
              flag = false
              return flag
            }
          })
        }
        if (flag) {
          let params = {
            s_id: this.sId,
            type: [1],
            enable: this.enable
          }

          params.recieve_time = this.recieveHour
          params.mobileIds = []
          params.wechatIds = []
          params.new_mobile = []
          for (let warnTel of this.warnTels) {
            if (warnTel.select === 1) {
              params.mobileIds.push(warnTel.id)
            }
          }
          for (let warnWeChat of this.warnWeChats) {
            if (warnWeChat.select === 1) {
              params.wechatIds.push(warnWeChat.id)
            }
          }
          for (let newWarnTel of this.newWarnTels) {
            params.new_mobile.push(newWarnTel.account)
          }

          // console.log(params.enable)

          this.$api.post('/user/remind/noticeSetting', params, true).then(res => {
            console.log(res)
            if (res.code === 1) {
              // console.log(enable)
              // this.enableWarn = enable
              this.checkList = []
              this.getWarnSettings()
              this.newWarnTels = []

              bus.$emit('getqueryInfo')
            }
            this.$message({
              message: res.msg,
              type: res.code === 1 ? 'success' : 'error'
            })
          })
        }
      },

      bindAccount() {
        if (this.warnWeChats.length >= 5) {
          return this.$message({
            message: '最多只能添加五个微信号',
            type: 'error'
          })
        }
        this.bindAccountBox = true
        this.$api.get('/user/user/wechat_bind_data', {
          type: 1,
          s_id: this.sId
        }).then(res => {
          this.wechat_qrcode = res.pic_url
          this.monitorBindIntervalId = setInterval(callback => {
            this.monitorBind(res.timestamp)
            // console.log(res)
          }, 2000)
        })
      },
      monitorBind(timestamp) {
        this.$api.get('/user/user/wechat_bind_cron', {
          timestamp,
          type: 1,
          s_id: this.sId
        }, true).then(res => {
          if (res.code === 1 || res.code === -1) {
            this.bindAccountBox = false
            clearInterval(this.monitorBindIntervalId)
            if (res.code === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定'
              })
            }
            this.getWarnSettings()
            // console.log(res)
          }
        })
      },
      bindAccountBoxClose() {
        clearInterval(this.monitorBindIntervalId)
      },
      activeswitch() {
        // console.log(this.switchvalue)
        if (this.switchvalue == 1) {
          this.getformdata()
        }
        if (this.switchvalue == 0) {
          // this.getdata()
        }
      },
      dateChange() {
        // 昨天
        var day1 = new Date();
        day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
        var s1 = day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate();
        // 今天
        var day2 = new Date();
        day2.setTime(day2.getTime());
        var s2 = day2.getFullYear() + "-" + (day2.getMonth() + 1) + "-" + day2.getDate()
        // 近7天
        var day3 = new Date();
        day3.setTime(day3.getTime() - 168 * 60 * 60 * 1000);
        var s3 = day3.getFullYear() + "-" + (day3.getMonth() + 1) + "-" + day3.getDate();
        // 近30天
        var day4 = new Date();
        day4.setTime(day4.getTime() - 720 * 60 * 60 * 1000);
        var s4 = day4.getFullYear() + "-" + (day4.getMonth() + 1) + "-" + day4.getDate();

        if (this.activeName == '近7天') {
          this.startDate = s3
          this.endDate = s2
        }
        if (this.activeName == '近30天') {
          this.startDate = s4
          this.endDate = s2
        }
        // this.getdata()
      },
      customdateChange() {
        this.startDate = this.datesvalue[0]
        this.endDate = this.datesvalue[1]
        // this.getdata()
      },
      Scorechange() {
        var chartDom = document.getElementById('main');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.Scoredate
          },
          yAxis: {
            type: 'value',
            name: '分数'
          },
          series: [{
            name: '分数',
            type: 'line',
            data: this.Scoredata,
            color: '#F89800'
          }]
        };
        option && myChart.setOption(option);
      },
      async getdata() {
        const {
          data: res
        } = await this.$http.get('/user/Commentlist/scoreLog?s_id=' + JSON.parse(sessionStorage.getItem('s_id')) +
          '&' + 'startDate=' + this.startDate + '&' + 'endDate=' + this.endDate)
        this.Scoredate = res.data.date
        this.Scoredata = res.data.avgPoiScore
        this.Scorechange()
        // console.log(res)
      },

    },
    created() {
      this.loading = true
      this.getWarnSettings()
      this.dateChange()
    }
  }
</script>

<style scoped>
  .settings {
    overflow: auto;
    width: 100%;
    background-color: white;
  }
  .qrcode {
    width: 117px;
    height: 117px;
    margin: 15px;
  }


  .settings-open {
    animation: settings-open 0.5s;
    animation-fill-mode: forwards;
  }

  .settings-close {
    animation: settings-close 0.5s;
    animation-fill-mode: forwards;
  }

  @keyframes settings-open {
    0% {
      height: 0px;
    }
  }

  @keyframes settings-close {
    0% {
      height: 600px;
    }

    100% {
      height: 0px;
    }
  }



  .screen {
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
  }

  .el-input {
    width: 150px;
  }

  .table-header {
    height: 60px;
    line-height: 60px;
    background: #F7F8FA;
    font-weight: bold;
    font-size: 14px;
    color: #858692;
  }

  .block {
    width: 60px;
    text-align: center;
    line-height: 20px;
    margin-right: 20px;
  }

  .button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
