<template>
  <div v-loading="loading">
    <div class="header-title" style="border-bottom: 10px solid #F7F8FA;height: inherit">
      <span class="clearfix-title">自动回复评价</span>
      <span class="clearfix-Subtitle">根据需求在指定时间回复评价，便于协助商家进行日常客情维护。</span>
      <span class="float-r mar-r10  colorF89800 cursor-pointer" style="font-size: 14px;" v-if="false">
        <span v-on:click="Collapse()" style="margin-right: 5px;margin-top: 3px;">回复设置</span>
        <el-switch active-value="1" inactive-value="0" v-model="Reviewvalue" active-color="#FFD161"
          inactive-color="#858692" @change="Review(Reviewvalue)">
        </el-switch>
      </span>

      <div class="Collapse">
        <el-form ref="Collapseform" v-model="Collapseform" style="margin-top: 20px;">
          <el-form-item label="回复时间：">
            <el-time-select v-model="Collapseform.reply_time" :editable="false" :clearable="false" :picker-options="{
                  start: '00:30',
                  step: '01:00',
                  end: '23:30'
                }" placeholder="选择时间:" @change="istime()" size="small">
            </el-time-select>
          </el-form-item>
          <el-form-item label="开启回评：">
            <el-switch active-value="1" inactive-value="0" v-model="Reviewvalue" active-color="#FFD161"
              inactive-color="#858692" @change="Review(Reviewvalue)">
            </el-switch>
            <span></span>
          </el-form-item>
          <el-form-item label="好评回复：" style="display: flex;align-items: center;">
            <el-radio-group v-model="goodradio" @change="goodradio5(goodradio)">
              <el-radio :label="1">系统模板</el-radio>
              <el-radio :label="2">自定义模板</el-radio>
              <el-radio :label="0">不回复</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="中评回复：" style="display: flex;align-items: center;">
            <el-radio-group v-model="middleradio" @change="goodradio3(middleradio)">
              <el-radio :label="1">系统模板</el-radio>
              <el-radio :label="2">自定义模板</el-radio>
              <el-radio :label="0">不回复</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="差评回复：" style="display: flex;align-items: center;">
            <el-radio-group v-model="negativeradio" @change="goodradio1(negativeradio)">
              <el-radio :label="1">系统模板</el-radio>
              <el-radio :label="2">自定义模板</el-radio>
              <el-radio :label="0">不回复</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div>
      <div class="text item" style="width: 100%;">
        <el-tabs v-model="tabsactiveName" @tab-click="tabshandleClick">
          <el-tab-pane label="好评模板" name="5">
            <el-row :gutter="24">
              <el-col :span="18" style="text-align: left;">
                <el-button size="small" type="primary" @click="addDialogVisible = true">添加自定义模板</el-button>
              </el-col>
              <el-col :span="6" style="text-align: right;">
                <el-select size="small" v-model="value" placeholder="系统模板" @change="ces(value)">
                  <el-option v-for="item in viewoptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-table :stripe="true" :data="Templatetdata" :header-cell-style="{'height':'60px'}"
              :cell-style="{'height':'60px'}" style="width: 100%">
              <el-table-column label="编号" width="100" type="index"></el-table-column>
              <el-table-column label="模板类型" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.type==0">系统模板</span>
                  <span v-if="scope.row.type==1">自定义模板</span>
                </template>
              </el-table-column>
              <el-table-column label="模板内容" prop="content"></el-table-column>
              <el-table-column label="操作" width="150" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.type==0">
                    <span>系统模板不支持操作</span>
                  </div>
                  <div v-if="scope.row.type==1">
                    <span style="font-size: 14px;font-weight: 400;color: #F89800;opacity: 1;cursor:  pointer"
                      @click="showEditdialog(scope.row.id)">
                      编辑</span>
                    <span style="width: 16px;text-align: center;">
                      <el-divider direction="vertical"></el-divider>
                    </span>

                    <span style="font-size: 14px;font-weight: 400;color: #F76C6C;opacity: 1;cursor:  pointer"
                      @click="remove(scope.row.id)">
                      删除</span>

                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
              :current-page="queryInfo.page" :page-size="queryInfo.limit" layout="prev, pager, next, total"
              :total="total" style="margin-top: 20px;text-align: right;"></el-pagination>
          </el-tab-pane>
          <el-tab-pane label="中评模板" name="3">
            <el-row :gutter="24">
              <el-col :span="18" style="text-align: left;">
                <el-button size="small" type="primary" @click="addDialogVisible = true">添加自定义模板</el-button>
              </el-col>
              <el-col :span="6" style="text-align: right;">
                <el-select size="small" v-model="value" placeholder="系统模板" @change="ces(value)">
                  <el-option v-for="item in viewoptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-table :stripe="true" :data="Templatetdata" :header-cell-style="{'height':'60px'}"
              :cell-style="{'height':'60px'}" style="width: 100%">
              <el-table-column label="编号" type="index" width="100"></el-table-column>
              <el-table-column label="模板类型" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.type==0">系统模板</span>
                  <span v-if="scope.row.type==1">自定义模板</span>
                </template>
              </el-table-column>
              <el-table-column label="模板内容" prop="content"></el-table-column>
              <el-table-column label="操作" width="150" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.type==0">
                    <span>系统模板不支持操作</span>
                  </div>
                  <div v-if="scope.row.type==1">
                    <span style="font-size: 14px;font-weight: 400;color: #F89800;opacity: 1;cursor:  pointer"
                      @click="showEditdialog(scope.row.id)">
                      编辑</span>
                    <span style="width: 16px;text-align: center;">
                      <el-divider direction="vertical"></el-divider>
                    </span>

                    <span style="font-size: 14px;font-weight: 400;color: #F76C6C;opacity: 1;cursor:  pointer"
                      @click="remove(scope.row.id)">
                      删除</span>

                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
              :current-page="queryInfo.page" :page-size="queryInfo.limit" layout="prev, pager, next, total"
              :total="total" class="page"></el-pagination>
          </el-tab-pane>
          <el-tab-pane label="差评模板" name="1">
            <el-row :gutter="24">
              <el-col :span="18" style="text-align: left;">
                <el-button size="small" type="primary" @click="addDialogVisible = true">添加自定义模板</el-button>
              </el-col>
              <el-col :span="6" style="text-align: right;">
                <el-select size="small" v-model="value" placeholder="系统模板" @change="ces(value)">
                  <el-option v-for="item in viewoptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-table :stripe="true" :data="Templatetdata" :header-cell-style="{'height':'60px'}"
              :cell-style="{'height':'60px'}" style="width: 100%">
              <el-table-column label="编号" type="index" width="100"></el-table-column>
              <el-table-column label="模板类型" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.type==0">系统模板</span>
                  <span v-if="scope.row.type==1">自定义模板</span>
                </template>
              </el-table-column>
              <el-table-column label="模板内容" prop="content"></el-table-column>
              <el-table-column label="操作" width="150" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.type==0">
                    <span>系统模板不支持操作</span>
                  </div>
                  <div v-if="scope.row.type==1">
                    <span style="font-size: 14px;font-weight: 400;color: #F89800;opacity: 1;cursor:  pointer"
                      @click="showEditdialog(scope.row.id)">
                      编辑</span>
                    <span style="width: 16px;text-align: center;">
                      <el-divider direction="vertical"></el-divider>
                    </span>

                    <span style="font-size: 14px;font-weight: 400;color: #F76C6C;opacity: 1;cursor:  pointer"
                      @click="remove(scope.row.id)">
                      删除</span>

                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
              :current-page="queryInfo.page" :page-size="queryInfo.limit" layout="prev, pager, next, total"
              :total="total" class="page"></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 添加自定义模板对话框 -->
    <el-dialog title="添加模板" :visible.sync="addDialogVisible" width="500px" @close="addDialogClosed">
      <!--对话框内容-->
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" hide-required-asterisk>

        <el-form-item label="模板类型:" prop="food_comment_score" style="display: flex;align-items: center;">
          <el-radio-group v-model="addForm.food_comment_score">
            <el-radio :label="5">好评</el-radio>
            <el-radio :label="3">中评</el-radio>
            <el-radio :label="1">差评</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="模板名称:" prop="title">
          <el-input v-model="addForm.title" style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="模板内容:" prop="content">
          <el-input :rows="5" placeholder="填写模板内容" type="textarea" v-model="addForm.content" maxlength="100"
            style="width: 380px" show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <!--对话框操作-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">
          取 消</el-button>
        <el-button type="primary" @click="addview" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">保
          存</el-button>
      </span>
    </el-dialog>
    <!-- 编辑自定义模板对话框 -->
    <el-dialog title="编辑模板:" :visible.sync="editDialogVisible" @close="editDialogClosed" width="500px">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px" hide-required-asterisk>
        <el-form-item label="模板类型:" prop="food_comment_score">
          <el-radio-group v-model="editForm.food_comment_score">
            <el-radio :label="5">好评</el-radio>
            <el-radio :label="3">中评</el-radio>
            <el-radio :label="1">差评</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="模板名称:" prop="title">
          <el-input v-model="editForm.title" style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="模板内容:" prop="content">
          <el-input :rows="5" placeholder="填写模板内容" type="textarea" v-model="editForm.content" maxlength="100"
            style="width: 380px" show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false" size="small"
          style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button type="primary" @click="editview" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">保
          存</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import store from '@/store'

  export default {
    data() {
      return {
        loading:false,
        goodradio: '',
        middleradio: '',
        negativeradio: '',
        Reviewvalue: '',
        // 折叠面板
        isCollapse: false,
        activeNames: [''],
        // tabs默认显示
        tabsactiveName: '5',
        // 表格数据
        Templatetdata: [],
        queryInfo: {
          type: 0,
          page: 1,
          limit: 10,
          score: 5
        },
        total: 0,
        // 搜索下拉框
        viewoptions: [{
          value: 0,
          label: '系统模板'
        }, {
          value: 1,
          label: '自定义模板'
        }],
        value: '系统模板',
        Collapseform: [],
        // 添加模板
        addDialogVisible: false,
        // 添加模板表单
        addForm: {
          food_comment_score: '',
          title: '',
          content: ''
        },
        // 添加模板对话框表单校验
        addFormRules: {
          title: [{
              required: true,
              message: '请输入模板名称',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 10,
              message: '请输入3到7个字符',
              trigger: 'blur'
            }
          ],
          content: [{
              required: true,
              message: '请输入模板内容',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 300,
              message: '请输入3到7个字符',
              trigger: 'blur'
            }
          ],
          food_comment_score: [{
            required: true,
            message: '请选择模板类型',
            trigger: 'blur'
          }]
        },
        addremnant: 300,
        editremnant: 300,
        // 编辑模板
        editDialogVisible: false,
        editForm: {},
        editFormRules: {
          title: [{
              required: true,
              message: '请输入模板名称',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 10,
              message: '请输入3到7个字符',
              trigger: 'blur'
            }
          ],
          content: [{
              required: true,
              message: '请输入模板内容',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 300,
              message: '请输入3到7个字符',
              trigger: 'blur'
            }
          ],
          food_comment_score: [{
            required: true,
            message: '请选择模板类型',
            trigger: 'blur'
          }]
        },
        event: '',
        value1: '',
        editId: '',
        isScores: '',
        s_id: store.state.s_id
      }
    },
    created() {
      this.loading = true
      this.getTemplatetdata()
      this.zdhf()
    },
    methods: {
      ces(value) {
        // console.log(value)
        this.queryInfo.type = value
        this.getTemplatetdata()
      },
      async zdhf() {
        const {
          data: res
        } = await this.$http.get('user/commentlist/config?s_id=' + JSON.parse(sessionStorage.getItem('s_id')))
        if (res.code !== 1) {
          return this.$message.error(res.msg)
        }
        // console.log(res)
        this.Reviewvalue = res.data.isScore + ''
        this.goodradio = res.data.score5
        this.middleradio = res.data.score3
        this.negativeradio = res.data.score1
        // res.data.score3
        // console.log(this.Reviewvalue)
        this.isScores = res.data.isScore
        this.Collapseform = res.data
        // console.log(123)
        if (res.data.isScore === 1) {
          return this.isCollapse = true
        }

        // console.log(res.data.isScore)
        // console.log(this.isScores)
      },
      async istime() {
        this.event = 'reply_time'
        this.value1 = this.Collapseform.reply_time
        this.checkbox()
        // console.log(this.event, this.value1)
      },
      Review(e) {
        // console.log(e)
        this.event = 'isScore'
        if (e === '1') {
          this.value1 = '1'
        }
        if (e === '0') {
          this.value1 = '0'
        }
        this.checkbox()
        // this.value1 = e + ""
        // console.log(this.value1)
      },
      goodradio5(e) {
        this.event = 'score5'
        this.value1 = e + ''
        // console.log(this.value1)
        this.checkbox()
      },
      goodradio3(e) {
        this.event = 'score3'
        this.value1 = e + ''
        // console.log(this.value1)
        this.checkbox()
      },
      goodradio1(e) {
        this.event = 'score1'
        this.value1 = e + ''
        // console.log(this.value1)
        this.checkbox()
      },
      async checkbox() {
        const {
          data: res
        } = await this.$http.post('/user/commentlist/edit/', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          value: this.value1,
          event: this.event
        })
        if (res.code === 1) {
          return this.$message.success(res.msg)
        }
        // console.log(res)
      },
      async Collapse() {
        this.isCollapse = !this.isCollapse
      },
      tabshandleClick(tab, event) {
        this.queryInfo.score = this.tabsactiveName
        this.addForm.food_comment_score = this.tabsactiveName + ''
        // console.log(this.addForm.food_comment_score)
        this.getTemplatetdata()
      },
      handleSizeChange(newSize) {
        this.queryInfo.limit = newSize
        this.getTemplatetdata()
      },
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage
        this.getTemplatetdata()
        // console.log(newPage)
      },
      // 获取评价模板列表
      async getTemplatetdata() {
        const {
          data: res
        } = await this.$http.get('user/commentlist/templatet_data/', {
          params: this.queryInfo
        })
        this.Templatetdata = res.data
        this.total = res.count
        this.loading = false
      },
      // 根据ID删除模板
      async remove(id) {
        var idStr = '[{"id":' + id + '}]'
        JSON.parse(idStr)
        // console.log(id)
        // 弹框询问是否删除数据
        const confirmResult = await this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'btnFalse btnPadd',
          cancelButtonClass: 'btnPadd'
          // type: 'warning',
        }).catch(err => err)
        if (confirmResult !== 'confirm') {
          return
        }
        const {
          data: res
        } = await this.$http.post('/user/commentlist/templatet_my_delete_all/', {
          data: idStr
        })
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.getTemplatetdata()
        // console.log(res)
      },
      // 添加自定义模
      addview() {
        this.$refs.addFormRef.validate(async valid => {
          if (!valid) return
          const {
            data: res
          } = await this.$http.post('user/commentlist/templatet_my_add_data', this.addForm)
          if (res.code !== 1) {
            this.$message.error(res.msg)
          }
          this.$message.success(res.msg)
          this.addDialogVisible = false
          this.getTemplatetdata()
          // console.log(res)
        })
      },
      addDialogClosed() {
        this.$refs.addFormRef.resetFields()
      },
      adddescInput() {
        var txtVal = this.addForm.content.length
        this.addremnant = 300 - txtVal
      },
      editdescInput() {
        var txtVal = this.editForm.content.length
        this.editremnant = 300 - txtVal
      },
      // 编辑模板
      async showEditdialog(id) {
        const {
          data: res
        } = await this.$http.get('/user/commentlist/templatet_my_add/?id=' + id)
        // console.log(res)
        if (res.code !== 1) {
          return this.$message.error()(res.msg)
        }
        this.editDialogVisible = true
        this.editForm = res.data
        this.editId = id
        // console.log(res)
      },
      editview() {
        this.$refs.editFormRef.validate(async valid => {
          if (!valid) return
          // 发起修改模板信息的数据请求
          const {
            data: res
          } = await this.$http.post('user/commentlist/templatet_my_add_data/', {
            id: this.editId,
            food_comment_score: this.editForm.food_comment_score,
            title: this.editForm.title,
            content: this.editForm.content
          })
          if (res.code !== 1) {
            return this.$message.error(res.msg)
          }
          this.editDialogVisible = false
          this.getTemplatetdata()
          this.$message.success(res.msg)
        })
      },
      editDialogClosed() {
        this.$refs.editFormRef.resetFields()
      }
    }
  }
</script>

<style scoped>
  .text {
    font-size: 14px;
  }

  .item {
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both;
  }

  .el-tab-pane {
    color: #585A6E;
  }

  .page {
    margin-top: 20px;
    text-align: right;
  }

  .Collapse {
    width: 100%;
    border-top: 1px solid #e9eaf2;
    padding-right: 20px;
  }

  .el-table {
    color: #858692;
  }

  .el-table__row {
    background: #F7F8FA;

  }

  .el-row {
    height: 70px;
    line-height: 70px;
    border-top: 1px solid #E9EAF2;
  }
</style>
