<template>
  <div>
    <div class="header-title">
      <span class="clearfix-title">催好评提醒</span><span class="clearfix-Subtitle">顾客收到餐后，系统自动发送短信给顾客引导顾客好评。</span>
      <div style="float: right; padding: 3px 0;color: #F89800;align-items: center;display: flex;">
        设置好评提醒
        <el-switch style="margin-left: 10px;" inactive-value="0" active-value="1" v-model="switchvalue"
          active-color="#FFD161" @change="activeswitch" inactive-color="#858692">
        </el-switch>
      </div>
    </div>
    <el-collapse-transition>
      <div v-if="this.switchvalue == 1" style="padding: 20px;">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="顾客过滤:">
            <el-select v-model="form.noSend" placeholder="请选择" size="small">
              <el-option v-for="(item,index) in noSend" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="仅发送老顾客:">
            <el-select v-model="form.isOld" placeholder="请选择" size="small">
              <el-option v-for="(item,index) in isOld" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="防骚扰:">
            <el-switch style="margin-left: 10px;" inactive-value="0" active-value="1" v-model="form.antiHarass"
              @change="ces" active-color="#FFD161" inactive-color="#858692">
            </el-switch><span
              style="font-size: 12px;font-weight: 400;color: #56576A;opacity: 1;">开启后23:00~7:00不发送催评</span>
          </el-form-item>
          <el-form-item label="发送时间:">
            <!-- <el-time-select v-model="form.value" :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:59'
              }" placeholder="选择时间:" @change="istime()" size="small">
            </el-time-select> -->
            <el-select v-model="form.sendTime" placeholder="请选择" size="small">
              <el-option v-for="(item,index) in sendTime" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="短信模板">
            <el-input style="width: 700px;" type="textarea" :rows="4" v-model="form.smsTemplate"></el-input>
            <li
              style="margin-left: 100px;font-size: 12px;font-weight: 400;color: #A2A4B3;opacity: 1;line-height: 16px;">
              提示：为保证短信发送效果，暂不提供自定义短信功能，如有好的短信模板，可联系客服。收费标准为58字/条，最终请以实际扣
              除短信条数为主。</li>
            <li style="margin-left: 100px;font-size: 14px;font-weight: 400;color:#56576A;opacity: 1;cursor:  pointer;display: flex;align-items: center;"
              @click="choiceDialogVisible = true"><i class="el-icon-thumb"
                style="color: #FFD161;margin-right: 5px;"></i>选择模板</li>
          </el-form-item>
        </el-form>
        <div style="margin-left: 100px;">
          <el-button type="primary" @click="onSubmit" size="small">保存并开启</el-button>
          <el-button @click="cancel" size="small">取消</el-button>
        </div>
      </div>
    </el-collapse-transition>

    <el-collapse-transition>
      <div v-if="this.switchvalue == 0" style="padding-left: 20px;padding-right: 20px;">

        <div style="height: 78px;width: 100%;background: #FFF8E1;opacity: 1;border-radius: 2pxdisplay: flex;align-items: center;justify-content: space-between;display: flex;">

          <div style="margin-left: 20px;">
            <li style="font-size: 12px;font-family: Segoe UI;font-weight: 400;color: #FF6A00;opacity: 1;height: 16px;line-height: 16px;">今日仅需27个5星好评可提升至4.6分</li>
            <li style="font-size: 12px;font-weight: 400;color: #3F4156;opacity: 1;"><span style="font-size: 16px;font-weight: 600;color: #3F4156;opacity: 1;">100条</span>剩余短信</li>
          </div>

          <div style="margin-right: 20px;">
            <el-button style="height: 40px;background: #FFD161;"><span style="font-size: 14px;font-weight: 400;color: #222222;opacity: 1;">去充值</span></el-button>
          </div>

        </div>


        <li style="margin: 20px 0;">已发送2条好评提醒</li>
        
        <el-table :data="tableData" :stripe="true" style="width: 100%">

          <el-table-column prop="ctime" label="下单时间">
          </el-table-column>
          <el-table-column label="订单状态">
            <template slot-scope="scope">
              <span v-if="scope.row.sendState==0">未发送</span>
              <span v-if="scope.row.sendState==1">待发送</span>
              <span v-if="scope.row.sendState==2">已发送</span>
            </template>
          </el-table-column>
          <el-table-column label="顾客信息">
            <template slot-scope="scope">
              {{scope.row.recipientPhone}}
            </template>
          </el-table-column>
          <el-table-column prop="orderCompletedTime" label="订单完成时间">
          </el-table-column>
          <el-table-column prop="sendTime" label="发送时间">
          </el-table-column>

        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
          :current-page="queryInfo.page" :page-size="queryInfo.limit" layout="prev, pager, next, total"
          :total="queryInfo.total" class="page"></el-pagination>
      </div>
    </el-collapse-transition>

    <el-dialog title="选择模板" :visible.sync="choiceDialogVisible" @close="choiceDialogClosed" width="500px">
      <div style="width: 100%;height: 100%;border: 1px solid #E9EAF2;">
        <el-radio-group v-model="choiceRadio" style="width: 100%;height: 100%;">
          <el-radio :label="item.id" v-for="(item,index) in TemplateData" :key="index"
            @change="choiceChange(item.template,item.id)">{{item.template}}</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="choiceDialogVisible = false" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">取 消</el-button>
        <el-button @click="choiceDialogVisible = false" type="primary" size="small" style="padding: 0 18px;height: 36px;font-size: 14px;">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        switchvalue: '',
        isCollapse: false,
        pageTitle: '菜品毛利排行',
        activeName: '近7天',
        datesvalue: '',
        selectvalue: '',
        form: {
          noSend: '',
          isOld: '',
          antiHarass: 1,
          sendTime: '',
          smsTemplate: '',
          smsTemplateId: ''
        },
        tableData: [],
        // 过滤顾客
        noSend: [{
          value: '1',
          label: '过滤7天催评过的顾客'
        }, {
          value: '2',
          label: '过滤15天催评过的顾客'
        }, {
          value: '3',
          label: '过滤30天催评过的顾客'
        }, {
          value: '4',
          label: '过滤历史催评过的顾客'
        }],
        // 新老用户
        isOld: [{
            value: '0',
            label: '全部'
          },
          {
            value: '1',
            label: '老用户'
          },
          {
            value: '2',
            label: '新用户'
          }
        ],
        // 发送时间
        sendTime: [{
            value: '1',
            label: '5分钟'
          },
          {
            value: '2',
            label: '10分钟'
          },
          {
            value: '3',
            label: '15分钟'
          }
        ],
        queryInfo: {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          type: 0,
          page: 1,
          limit: 10,
          score: 0,
          status: 0,
          is_null: 0,
          total: 100,
        },
        total: '',
        startDate: '',
        endDate: '',
        choiceDialogVisible: false,
        choiceRadio: '',
        TemplateData: []
      }
    },
    methods: {
      activeswitch() {
        // console.log(this.switchvalue)
        if (this.switchvalue == 1) {
          this.getquerySetting()
          this.choiceclick()
        }
      },
      onSubmit() {
        this.switchvalue = 0
        this.postSetting()
      },
      cancel() {
        this.switchvalue = 0
      },
      // 分页功能
      handleSizeChange(newSize) {
        this.queryInfo.limit = newSize
        this.getSendMsgList()
      },
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage
        this.getSendMsgList()
      },

      ces(i) {
        // console.log(i)
      },
      dateChange() {

        var day2 = new Date();
        day2.setTime(day2.getTime());
        var s2 = day2.getFullYear() + "-" + (day2.getMonth() + 1) + "-" + day2.getDate()

        var day4 = new Date();
        day4.setTime(day4.getTime() - 700 * 60 * 60 * 1000);
        var s4 = day4.getFullYear() + "-" + (day4.getMonth() + 1) + "-" + day4.getDate();

        this.startDate = s4
        this.endDate = s2

        // console.log(this.startDate, this.endDate)

        this.getSendMsgList()

      },
      choiceDialogClosed() {

      },
      choiceChange(template, id) {
        this.form.smsTemplate = template
        this.form.smsTemplateId = id
      },
      async choiceclick() {
        const {
          data: res
        } = await this.$http.get('/user/UrgeComment/querySendMsgTemplateList', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
          }
        })

        this.TemplateData = res.data

        let index = res.data.find(item => {
          return item.id == this.form.smsTemplateId
        })
        if (index) {
          //可以用一个变量接收，也可以用数组接收，这里我用了变量接收
          this.form.smsTemplate = index.template
          // console.log(index.template)
        }

      },
      async getSendMsgList() {
        const {
          data: res
        } = await this.$http.get('/user/UrgeComment/querySendMsgList', {
          params: {
            s_id: JSON.parse(sessionStorage.getItem('s_id')),
            startDate: this.startDate,
            endtDate: this.endDate,
            page: this.queryInfo.page,
            limit: this.queryInfo.limit,
          }
        })
        this.tableData = res.data
        this.queryInfo.total = res.count
        // console.log(res.count)
      },
      async getquerySetting() {
        const {
          data: res
        } = await this.$http.get('/user/UrgeComment/querySetting?s_id=' + JSON.parse(sessionStorage.getItem('s_id')))
        // console.log(res.data)
        this.form.noSend = res.data.noSend.toString()
        this.form.isOld = res.data.isOld.toString()
        this.form.isBad = res.data.isBad.toString()
        this.form.antiHarass = res.data.antiHarass.toString()
        this.form.sendTime = res.data.sendTime.toString()
        this.form.smsTemplateId = res.data.smsTemplateId.toString()
      },
      async postSetting() {
        const {
          data: res
        } = await this.$http.post('/user/UrgeComment/Setting', {
          s_id: JSON.parse(sessionStorage.getItem('s_id')),
          noSend: this.form.noSend,
          isBad: this.form.isBad,
          isOld: this.form.isOld,
          isBad: this.form.isBad,
          antiHarass: this.form.antiHarass,
          sendTime: this.form.sendTime,
          smsTemplateId: this.form.smsTemplateId,
          enable: 1
        })
        // console.log(res)
      }
    },
    created() {
      this.dateChange()
      this.getSendMsgList()
    }
  }
</script>


<style scoped>
  .screen {
    height: 60px;
    line-height: 60px;
  }

  .el-input {
    width: 150px;
  }

  .table-header {
    height: 60px;
    line-height: 60px;
    background: #F7F8FA;
    font-weight: bold;
    font-size: 14px;
    color: #858692;
  }
</style>
