<template>
  <div>
    <!--页面标题-->
    <p class="page-title">添加赠品成本
      <el-switch
        :value="advancedSettings"
        class="float-r mar-l5"
        active-color="#fed06a"
        :active-value="1"
        :inactive-value="0">
      </el-switch>
      <span class="float-r mar-r10 size15 colorF89800 cursor-pointer">高级设置</span>
    </p>

    <el-button type="primary" class="radius2 mar-t10" size="small">添加包装袋成本</el-button>

    <el-table
      class="radius2 mar-t20"
      show-header
      :header-cell-style="{background:'#F7F8FA',color:'#666','font-weight': 'bold','font-size':'14px','text-align':'center'}"
      :cell-style="{'text-align':'center','font-size':'13px',color:'#565A6E'}"
      :data="giveawayCostList">

      <el-table-column
        type="index"
        label="序号">
      </el-table-column>

      <el-table-column
        prop="giveaway"
        label="赠品">
      </el-table-column>

      <el-table-column
        prop="cost"
        label="赠品成本">
      </el-table-column>

      <el-table-column
        prop="takeEffectTime"
        label="生效时间">
      </el-table-column>
      <el-table-column
        label="操作">
        <template slot-scope="scope">
          <el-button type="primary" class="radius2" size="small">开启</el-button>
          <el-button type="primary" class="radius2" size="small">修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="flex color3F4156 mar-t35">
      <el-radio-group v-model="dateOption" size="small" fill="#585A6E" text-color="#FFFFFF" @change="dateOptionsChange">
        <el-radio-button :label="0">今日</el-radio-button>
        <el-radio-button :label="1">昨日</el-radio-button>
        <el-radio-button :label="7">近7天</el-radio-button>
        <el-radio-button :label="30">近30天</el-radio-button>
        <el-radio-button :label="-1">自定义</el-radio-button>
      </el-radio-group>
      <el-date-picker v-if="dateOption===-1" class="mar-l10" style="height: 34px;width: 280px" type="daterange"
                      align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
    </div>

    <p class="page-title mar-t30">赠品汇总图</p>

    <div class="relative">
      <div class="absolute size14 color3F4156" style="top: 30px;left: 15%;z-index: 1">赠品金额占比</div>
    </div>
    <div id="giveawaySummary"></div>
    <div style="height: 10px;background-color: red"></div>

  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'GiveawayCost',
  data () {
    return {
      advancedSettings: false,
      giveawayCostList: [
        {
          id: 1,
          giveaway: 'iPhone 12',
          cost: '999',
          takeEffectTime: '2021-02-06 20:15:12'
        },
        {
          id: 2,
          giveaway: 'iPhone 12 Pro Max',
          cost: '999',
          takeEffectTime: '2021-02-06 20:15:12'
        }
      ],
      dateOption: 0
    }
  },
  methods: {
    initGiveawaySummaryPie () {
      echarts.init(document.getElementById('giveawaySummary')).setOption({
        tooltip: {
          trigger: 'item',
          backgroundColor: '#2B3E4C',
          borderColor: '#2B3E4C',
          animation: false,
          animationDurationUpdate: 0,
          formatter: params => {
            return '<div style="position: relative;background-color: #2B3E4C;border-radius: 4px;padding: 0px 40px 13px 10px;color: white;"><div style="font-size: 14px;">' +
              params.data.name + '</div><div style="font-size: 24px;font-weight: bold;margin-top:10px;">' +
              params.data.value +
              '</div><div style="display: block;width: 0;height: 0;border-top: 15px solid transparent;border-bottom: 15px solid transparent;border-right: 20px solid #2B3E4C;position: absolute;left: -25px;top: 36%;"></div></div>'
          },
          position: (point, params, dom, rect, size) => {
            // 固定在顶部
            return [point[0] + 30, point[1] - 50]
          }
        },
        legend: [
          {
            top: '45%',
            left: 'right',
            itemWidth: 12,
            itemHeight: 12,
            itemGap: 25,
            icon: 'rect',
            data: ['赠品1', '赠品2', '赠品3', '赠品4', '赠品5']
          },
          {
            top: '55%',
            right: '23%',
            itemWidth: 12,
            itemHeight: 12,
            itemGap: 25,
            icon: 'rect',
            data: ['赠品6', '赠品7']
          }
        ],
        series: [{
          type: 'pie',
          radius: ['30%', '60%'],
          center: ['20%', '55%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 0,
            borderColor: '#fff',
            borderWidth: 5
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: [{
            value: 50,
            name: '赠品1'
          },
            {
              value: 30,
              name: '赠品2'
            },
            {
              value: 20,
              name: '赠品3'
            },
            {
              value: 25,
              name: '赠品4'
            },
            {
              value: 15,
              name: '赠品5'
            },
            {
              value: 15,
              name: '赠品6'
            },
            {
              value: 15,
              name: '赠品7'
            }
          ],
          color: ['#F8B500', '#935FED', '#5F77ED', '#5FA6ED', '#92D154', '#54D175', '#54C5D1']
        }
        ]
      })
    }
  },
  mounted () {
    this.initGiveawaySummaryPie()
  }
}
</script>

<style scoped>
#giveawaySummary {
  height: 320px;
}
</style>
